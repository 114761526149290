import React from "react";
import { Avatar, Divider, Grid, Menu, Typography } from "@mui/material";
import "./NotificationsModal.css";
import TimeAgo from "javascript-time-ago";
import useNotifications from "../../hooks/useNotifications";

const BoldText = ({ children }) => {
  return <span className="bold-600">{children}</span>;
};

const NotificationCard = ({ color, children, avatar, time, onClick }) => {
  return (
    <Grid
      className={`p-3 d-flex align-items-center notification-card`}
      onClick={onClick}
    >
      {avatar && (
        <Avatar
          className="notification-avatar"
          style={{
            backgroundColor: color,
          }}
        >
          {avatar}
        </Avatar>
      )}
      <Grid className="px-2 text-color font-14">
        {children}
        <Typography className="secondary-text-color font-12">{time}</Typography>
      </Grid>
    </Grid>
  );
};

const NotificationsModal = ({ anchorEl, open, onClose }) => {
  const { notifications, updateNotificationApi } = useNotifications();
  // Create formatter (English).
  const timeAgo = new TimeAgo("en-US");

  const handleNotificationClick = (payload) => {
    const data = { ids: [payload.id], receiverId: payload.receiverId };
    updateNotificationApi(data, { ...payload, isSeen: true });
    switch (payload.type) {
      case "MESSAGE_MENTION":
        // Navigate("/messagecenter");
        break;

      default:
        break;
    }
  };

  const notificationData = notifications.filter((f) => !f.isSeen);

  return (
    <Menu id="menu-appbar" anchorEl={anchorEl} open={open} onClose={onClose}>
      <Grid style={{ width: 500 }}>
        <Grid>
          <Typography style={{ fontSize: 26 }} className="px-3 py-1 bold-600">
            Notifications
          </Typography>
        </Grid>
        <Divider />
        <div className="notifications-div">
          {notificationData.length ? (
            notificationData.map((notification) => {
              if (notification.isSeen) {
                return null;
              }
              return (
                <div key={notification.id}>
                  <NotificationCard
                    avatar={"ME"}
                    color={"#FBD5E2"}
                    time={timeAgo.format(new Date(notification.createdAt))}
                    onClick={() => handleNotificationClick(notification)}
                  >
                    {" "}
                    <Typography>
                      {notification.data.message?.map((msg, idx) => {
                        if (msg.isBold) {
                          return (
                            <BoldText key={idx}>
                              &nbsp;{msg.text}&nbsp;
                            </BoldText>
                          );
                        }
                        return <span key={idx}>{msg.text}</span>;
                      })}
                    </Typography>
                  </NotificationCard>
                  <Divider />
                </div>
              );
            })
          ) : (
            <div className="d-flex justify-content-center py-3">
              <span>No Notifications</span>
            </div>
          )}
        </div>
      </Grid>
    </Menu>
  );
};

export default NotificationsModal;
