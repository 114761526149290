import { Grid } from "@mui/material";
import React, { useState } from "react";
import TableFilter from "../../../components/Filter/TableFilter";
import { Form } from "react-bootstrap";
import CustomButton from "../../../components/Buttons/CustomButton";
import usePatients from "../../../hooks/usePatients";

const Notes = ({ note, data, initCall }) => {
  const [isNoteEnabled, setIsNoteEnabled] = useState(false);
  const [text, setText] = useState(data.Notes);
  const { updatePatientApi } = usePatients({ noInitial: true });

  const onSave = () => {
    const variables = {
      PatientID: data.PatientID,
      Notes: text,
    };
    updatePatientApi(variables).then(initCall);
    setIsNoteEnabled(false);
  };
  return (
    <Grid>
      <Grid className="my-3">
        <TableFilter
          sectionName={"Notes"}
          noAdd={true}
          noSearch={true}
          noSort={true}
        />
      </Grid>
      <Grid className="px-4">
        {!isNoteEnabled && (
          <div className="d-flex justify-content-end">
            <CustomButton
              onClick={() => setIsNoteEnabled(true)}
              className={"font-14 p-1 bg-white text-black border-1 px-4 mx-0"}
            >
              Edit
            </CustomButton>
          </div>
        )}
        <Form.Control
          disabled={!isNoteEnabled}
          as="textarea"
          rows={8}
          placeholder="Enter Note"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        {isNoteEnabled && (
          <div className="d-flex justify-content-end">
            <CustomButton className={"font-14 p-1 px-3 bg-1"} onClick={onSave}>
              Save
            </CustomButton>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default Notes;
