import { Grid, Typography } from "@mui/material";
import React from "react";
import clsx from "clsx";

const IconText = ({ icon, text, textClass, className, iconClass, Icon }) => {
  if (!text) return null;
  return (
    <Grid className={clsx("d-flex", "align-items-center", className)}>
      <div
        className="d-flex align-items-center"
        style={{ width: 20, height: 20 }}
      >
        {Icon ? (
          <Icon color="#333333" />
        ) : (
          <img
            src={icon}
            alt="icon"
            className={clsx(iconClass, "icon")}
            style={{ width: 20, height: 20 }}
          />
        )}
      </div>
      <Typography
        className={clsx("px-1", textClass)}
        style={{ color: "#333333", fontSize: 14, fontFamily: "Inter" }}
      >
        {text}
      </Typography>
    </Grid>
  );
};

export default IconText;
