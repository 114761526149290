import { useCallback, useEffect, useState } from "react";
import api from "../services/api";
import {
  replacePatients,
  addPatient,
  updatePatient,
  replaceTagPatients,
} from "../redux/reducers/patients.reducer";
import { useDispatch, useSelector } from "react-redux";
import { queryString } from "../utilities/resuseFunctions";
import MessagingApi from "../services/messagingApi";
import moment from "moment";

export default function usePatients({
  providerId,
  communityId = "",
  noInitial,
} = {}) {
  const dispatch = useDispatch();
  const { data, count, tagPatients } = useSelector((state) => state.patients);
  const [loading, setLoading] = useState(false);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const handleClose = () => {
    setLoading(false);
  };
  const handleOpen = () => {
    setLoading(true);
  };

  const getPatients = useCallback(
    async ({
      limit = "20",
      skip = "",
      communityId = "",
      providerId = "",
      search,
      sort,
      filter,
    } = {}) => {
      handleOpen();
      const { order, orderBy } = sort || {};
      const { ZoneID, VisitType } = filter || {};
      return await api
        .get(
          `/patients?${queryString({
            limit,
            skip,
            communityId,
            providerId,
            search,
            order,
            orderBy,
            ZoneID,
            VisitType,
          })}`
        )
        .then((res) => {
          // if (communityId || providerId) {
          //   setFilteredPatients(res?.data?.data);
          // } else {
          dispatch(replacePatients(res?.data || []));
          return res.data.data;
          // }
        })
        .finally(handleClose);
    },
    [dispatch]
  );

  useEffect(() => {
    !noInitial && getPatients({ communityId, providerId });
  }, [getPatients, communityId, providerId]);

  const getPatientApi = (data) => {
    handleOpen();
    return api
      .get(`/patients/${data.PatientID}`, data)
      .then((res) => {
        handleClose();
        return res?.data?.data;
      })
      .catch(handleClose);
  };

  const addPatientApi = (data) => {
    handleOpen();
    return api
      .post("/patients", data)
      .then((res) => {
        dispatch(addPatient(res?.data?.data || []));
        handleClose();
        return res?.data?.data;
      })
      .catch(handleClose);
  };

  const updatePatientApi = (data) => {
    handleOpen();
    return api
      .put(`/patients/${data.PatientID}`, data)
      .then((res) => {
        dispatch(updatePatient(res?.data?.data || []));
        return;
      })
      .finally(handleClose);
  };

  async function getTagPatients({ providerId, communityId, limit } = {}) {
    try {
      const response = await getPatients({ providerId, communityId, limit });
      const respData = response.map((data) => {
        return {
          id: data.PatientID,
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/2048px-Windows_10_Default_Profile_Picture.svg.png",
          display: `${data.PatientFirstName} ${
            data.PatientLastName
          } (DOB: ${moment(data.PatientDOB).format("MM/DD/YYYY")})`,
          dob: moment(data.PatientDOB).format("MM/DD/YYYY"),
          name: `${data.PatientFirstName} ${data.PatientLastName}`,
        };
      });
      dispatch(replaceTagPatients(respData));
    } catch (err) {
      // setError(err.response.data.error);
      console.log("ERR", err);
    }
  }

  return {
    patients: data,
    count,
    addPatientApi,
    updatePatientApi,
    loading,
    handleClose,
    getPatientApi,
    filteredPatients,
    getPatients,
    tagPatients,
    getTagPatients,
  };
}
