import React, { useState } from "react";
import { colors } from "../../styles";
import CustomButton from "../Buttons/CustomButton";
import { Dialog, Grid, IconButton, Typography } from "@mui/material";
import "./AddRegionModal.css";
import CloseIcon from "@mui/icons-material/Close";
import useCareTags from "../../hooks/useCareTags";
import TagSvg from "../../assets/svgs/TagSvg";
import { Form } from "react-bootstrap";

const TagModal = ({ open: isOpen, handleClose, handleSubmit }) => {
  const { careTags } = useCareTags();
  const [open, setOpen] = useState(isOpen);
  const [tag, setTag] = useState("");
  const [description, setDescription] = useState("");

  const onClose = () => {
    setOpen(true);
    handleClose();
  };

  const onSubmit = () => {
    onClose();
    handleSubmit({ tag, description });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
      <Grid>
        <Grid
          className="d-flex justify-content-between py-2 px-3 align-items-center"
          style={{
            backgroundColor: colors.OFFICE_COLOR,
          }}
        >
          <div className="d-flex align-items-center">
            <TagSvg color={"white"} />
            <Typography className="region-modal-title mx-2">
              {"Tag this Message"}
            </Typography>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Grid>
        <Grid className="px-2">
          <Grid className="m-2 px-3">
            <Typography className="fieldName py-2">Select CareTag</Typography>
            <Form.Select
              aria-label="Default select example"
              value={tag}
              onChange={(e) => {
                setTag(e.target.value);
                const tag = careTags?.find((tag) => tag.Tag == e.target.value);
                setDescription(tag?.Description);
              }}
            >
              <option>Select CareTag</option>
              {careTags?.map((careTag) => {
                return (
                  <option key={careTag.Tag} value={careTag.Tag}>
                    {careTag.Tag}
                  </option>
                );
              })}

              {/* <option value="Scheduler">Scheduler</option> */}
            </Form.Select>
          </Grid>
          <Grid className="px-3 m-2">
            <Typography className="fieldName py-2">
              CareTag Description
            </Typography>
            <textarea
              disabled={true}
              placeholder="This tag’s description that’s been saved in the system."
              className="inputField p-2 border-1 w-100"
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
          <Grid>
            <CustomButton className="button mx-4 p-2 px-5" onClick={onSubmit}>
              <Typography>{"Confirm and tag message"}</Typography>
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default TagModal;
