import React, { useEffect, useState } from "react";
import { Avatar, Dialog, Divider, Grid, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useMessenger from "../../hooks/useMessenger";
import "./ViewGroupModal.css";
import {
  PermMediaOutlined as MediaIcon,
  StarBorderOutlined as StarIcon,
  EnhancedEncryptionOutlined as EncryptionIcon,
  ChevronRightOutlined as RightIcon,
} from "@mui/icons-material";
import UsersSvg from "../../assets/svgs/UsersSvg";
import Chat from "../../assets/svgs/Chat";
import { avatarName } from "../../utilities/resuseFunctions";
import Loading from "../Loading";
import StarredMessages from "../../screens/Chat/StarredMessages";
import ReferencedPatients from "../../screens/Chat/ReferencedPatients";
import SectionCard from "../Cards/SectionCard";
import { Icon } from "@virtuslab/tetrisly-icons";
import FilterByCaretags from "../../screens/Chat/FilterByCaretags";
import moment from "moment";

const ViewContactModal = ({ isOpen, onClose, chat, isCommunity }) => {
  const { height } = useWindowDimensions();
  const {
    getContactInfo,
    loading,
    getStarredMessages,
    getReferencedPatients,
    getTaggedCaretags,
    userData,
    setCurrentChat,
  } = useMessenger();
  const [open, setOpen] = useState(isOpen);
  // const [selected, setSelected] = useState([]);
  const [data, setData] = useState({});
  const [starred, setStarred] = useState([]);
  const [referenced, setReferenced] = useState([]);
  const [tagged, setTagged] = useState([]);
  const [isStarred, setIsStarred] = useState(false);
  const [isReferenced, setIsReferenced] = useState(false);
  const [isCaretags, setIsCaretags] = useState(false);

  useEffect(() => {
    getContactInfo({
      contactId: chat.contactId,
    }).then((res) => {
      const final = {
        id: res.id,
        firstName: res.firstName,
        lastName: res.lastName,
        profileImg: res.profileImg,
        position: res.position,
        groups: res.ConversationUser.map((data) => {
          return {
            id: data.Conversation.id,
            img: data.Conversation.conversationImg,
            name: data.Conversation.name,
            noOfMembers: data.Conversation._count.ConversationUser,
          };
        }),
      };
      setData(final);
    });
  }, []);

  useEffect(() => {
    getStarredMessages({ conversationId: chat?.conversationId }).then(
      (data) => {
        setStarred(data);
      }
    );
    getReferencedPatients(chat).then((data) => setReferenced(data));
    getTaggedCaretags(chat).then((data) => setTagged(data));
  }, []);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const onStarredClick = () => setIsStarred(!isStarred);

  const onReferenceClick = () => setIsReferenced(!isReferenced);

  const onCaretagClick = () => setIsCaretags(!isCaretags);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          position: "absolute",
          right: 0,
          margin: 0,
          top: 20,
          bottom: 0,
          backgroundColor: "#F2F2F2",
        },
      }}
    >
      {loading && <Loading loading={loading} />}
      {isStarred ? (
        <StarredMessages
          onStarredClick={onStarredClick}
          starred={starred}
          chat={chat}
        />
      ) : isReferenced ? (
        <ReferencedPatients
          onReferenceClick={onReferenceClick}
          referenced={referenced}
          chat={chat}
        />
      ) : isCaretags ? (
        <FilterByCaretags
          onTagClick={onCaretagClick}
          tagged={tagged}
          chat={chat}
        />
      ) : (
        <Grid
          sx={{
            width: {
              xs: "300px",
              sm: "300px",
              md: "350px",
            },
            //   height: "100vh",
            backgroundColor: "#F2F2F2",
          }}
          className="py-3 overflow-hidden"
        >
          <div className="d-flex align-items-center px-3">
            <div role="button" className="close-box " onClick={handleClose}>
              <CloseOutlinedIcon className="close-icon-1" />
            </div>
            <Typography className="px-3 add-patients-modal-title group-header">
              Contact Information
            </Typography>
          </div>
          <Grid className="px-3 group-content">
            <Grid className="text-center p-3">
              <div className="d-flex justify-content-center">
                <Avatar className="m-2 avatar-group" src={data?.profileImg} />
              </div>
              <Typography className="group-name">
                {`${data?.firstName || ""} ${data?.lastName || ""}`}
              </Typography>
              <Typography className="group-subtext">
                {data?.position}
              </Typography>
            </Grid>
            <div className="section-card rounded my-2 mb-4">
              <Typography className="message-text text-center">
                Message this Contact
              </Typography>
            </div>
            <Grid>
              <SectionCard
                text={"Media, Links, Docs"}
                // count={24}
                icon={MediaIcon}
                className={"my-2 rounded"}
                showDeleteButton={false}
                handleClick={() => {
                  console.log("handleClick");
                }}
              />
              <SectionCard
                text={"Starred Messages"}
                // count={24}
                icon={StarIcon}
                className={"my-2 rounded"}
                onClick={onStarredClick}
                showDeleteButton={false}
                handleClick={() => {
                  onStarredClick();
                }}
              />
              <SectionCard
                text={"Filter by Patient"}
                // count={3}
                icon={UsersSvg}
                className={"my-2 rounded"}
                showDeleteButton={false}
                handleClick={() => {
                  onReferenceClick();
                }}
              />
              <SectionCard
                text={"Filter by CareTag"}
                // count={3}
                icon={(props) => <Icon name="20-hashtag" {...props} />}
                className={"my-2 rounded"}
                showDeleteButton={false}
                handleClick={onCaretagClick}
              />
              {!isCommunity && (
                <SectionCard
                  text={"Messages sent to Athena"}
                  // count={3}
                  icon={Chat}
                  className={"my-2 rounded"}
                  showDeleteButton={false}
                  // handleClick={onReferenceClick}
                />
              )}
              <SectionCard
                text={"Privacy Policy"}
                icon={EncryptionIcon}
                className={"my-2 rounded"}
                showDeleteButton={false}
                handleClick={() => {
                  window.open("https://www.caresms.io/privacy-policy");
                }}
              />
              <SectionCard
                text={"HIPAA Compliance"}
                icon={EncryptionIcon}
                className={"my-2 rounded"}
                showDeleteButton={false}
                handleClick={() => {
                  window.open(
                    "https://www.caresms.io/hipaa-compliance-security"
                  );
                }}
              />
            </Grid>
            <Typography className="text-center participants-title my-3">
              {data?.groups?.length} Groups Together
            </Typography>
            <Grid className="rounded">
              {data?.groups?.map((i, index) => {
                return (
                  <div key={i.id}>
                    <SectionCard
                      text={i.name}
                      count={`${i.noOfMembers} members`}
                      profileImg={i.img}
                      handleClick={async () => {
                        setCurrentChat({
                          conversationId: i?.id,
                          createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
                          isAdmin: false,
                          isCreator: false,
                          updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
                          userId: userData.id,
                          conversationName: i.name,
                          conversationImg: i.img,
                          conversationType: "GROUP",
                        });
                      }}
                    />
                    {index !== data.groups.length && <Divider />}
                  </div>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};

export default ViewContactModal;
