import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import "./Login2FA.css";
import CustomButton from "../../components/Buttons/CustomButton";
import { Form } from "react-bootstrap";
import { Navigate, useLocation } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useSpring, animated } from "react-spring";
import Logo from "../../assets/images/CareSMS_Logo.png";
import useAuth from "../../hooks/useAuth";
import Loading from "../../components/Loading";
import { convertToNumber } from "../../utilities/resuseFunctions";

const roles = {
  backoffice: {
    title: "Back Office Sign In",
    role: "back office",
  },
  provider: {
    title: "Provider Sign In",
    role: "provider",
  },
  community: {
    title: "Community Sign In",
    role: "community",
  },
};

const subheads = {
  email: "Enter your email address",
  login: "Login to your account",
  verification: "Please enter the 6-digit code that we just sent to you",
};

const Login2FA = () => {
  const location = useLocation();
  const {
    userData,
    isUserExist,
    userLoginApi,
    loading,
    error,
    verifyOTP,
    otpVerified,
    sendOTP,
    forgotPassword,
  } = useAuth();
  const { width } = useWindowDimensions();
  const [login, setLogin] = useState("");
  const [step, setStep] = useState("email");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [data, setData] = useState(null);
  const props = useSpring({
    position: "relative",
    left: login ? 0 : width / 2,
  });

  const landingProps = useSpring({
    position: "relative",
    left: login ? -(width / 2) : 0,
  });

  const onBack = () => {
    if (login && step === "email") {
      setLogin("");
    }
    if (login && step === "login") {
      setStep("email");
    }
    if (login && step === "verification") {
      setStep("login");
    }
    if (login && step === "forgot") {
      setStep("login");
    }
  };

  const onLogin = () => {
    switch (step) {
      case "email":
        const payload = {
          email: email,
          role:
            login === "backoffice"
              ? roles?.[login]?.role
              : roles?.[login]?.role.toUpperCase(),
        };
        isUserExist(payload).then((res) => {
          setData(res.data.data);
          setStep("login");
        });
        break;
      case "login":
        const variables = {
          email,
          password,
          phoneNo: convertToNumber(data?.phoneNo || ""),
        };
        userLoginApi(variables).then(() => {
          setStep("verification");
        });
        break;
      case "verification":
        const phoneNo = convertToNumber(data?.phoneNo || "");
        verifyOTP({ phone: phoneNo, code: otp, email });
        break;
      case "forgot":
        forgotPassword({
          email: data?.email,
          id: data?.id,
        });
        break;

      default:
        break;
    }
  };

  const onResendOtp = () => {
    const phoneNo = convertToNumber(data?.phoneNo || "");
    sendOTP({ phone: phoneNo, email });
  };

  if (userData && otpVerified) {
    return <Navigate to="/home" state={{ from: location }} replace />;
  }

  return (
    <Grid container className="vh-100">
      <Loading loading={loading} />
      <Grid item xs={12} md={12} style={{ overflow: "hidden" }}>
        {login ? (
          <animated.div
            style={props}
            className={"d-flex flex-column justify-content-between vh-100"}
          >
            <span
              className="contact-support p-3"
              role="button"
              onClick={onBack}
            >
              {"< Back"}
            </span>
            <Grid
              style={{}}
              className="login-view d-flex justify-content-center"
            >
              <Grid
                style={{ width: "350px" }}
                className="d-grid justify-content-center"
              >
                <div className="d-flex justify-content-center">
                  <img src={Logo} alt="logo" className="logo-login" />
                </div>
                <p className="landing-title sign-in-text text-center mb-0">
                  {step === "verification"
                    ? "Verification"
                    : step === "forgot"
                    ? "Forgot Password"
                    : roles?.[login]?.title}
                </p>
                <p className="text-center mb-0 subhead2">{subheads[step]}</p>
                {(step === "email" ||
                  step === "login" ||
                  step === "forgot") && (
                  <Grid className="mx-2 my-2">
                    <Grid>
                      <Form.Control
                        placeholder="Work Email Address"
                        value={email}
                        onChange={(val) => setEmail(val.target.value)}
                      />
                    </Grid>
                  </Grid>
                )}
                {step === "verification" && (
                  <Grid className="mx-2 my-2">
                    <Grid>
                      <Form.Control
                        placeholder="Enter your verification code"
                        value={otp}
                        onChange={(val) => setOtp(val.target.value)}
                      />
                    </Grid>
                  </Grid>
                )}
                {step === "verification" && (
                  <p
                    className="color-1 text-center m-0"
                    role="button"
                    onClick={onResendOtp}
                  >
                    Didn’t receive a code? Click here to resend one
                  </p>
                )}
                <Grid className="mx-2 my-2">
                  {step === "login" && (
                    <Grid>
                      <Form.Control
                        placeholder="Enter your Password"
                        type="password"
                        value={password}
                        onChange={(val) => setPassword(val.target.value)}
                      />
                    </Grid>
                  )}
                  {step === "login" && (
                    <div className="d-flex justify-content-end">
                      <Typography
                        onClick={() => setStep("forgot")}
                        className="font-14 color-1 py-1"
                        role="button"
                      >
                        Forgot Password
                      </Typography>
                    </div>
                  )}
                  {error && (
                    <div>
                      <Typography
                        style={{ color: "red" }}
                        className="font-14 py-1"
                      >
                        {error}
                      </Typography>
                    </div>
                  )}
                </Grid>
                <CustomButton
                  style={{ width: "350px" }}
                  onClick={onLogin}
                  className={
                    "d-flex justify-content-center align-items-center p-2"
                  }
                >
                  {/* <img src={Icons.Circle_Icon} alt="close" className="icon1" /> */}
                  <Typography className="px-2">Continue</Typography>
                </CustomButton>
              </Grid>
            </Grid>
            <Grid className="d-flex justify-content-center">
              <p>Powered by Savisoft Systems & Services, Inc.</p>
            </Grid>
          </animated.div>
        ) : (
          <animated.div
            style={landingProps}
            className={"d-flex flex-column vh-100"}
          >
            <span className="contact-support p-3">Contact Support</span>
            <Grid className="login-view my-auto">
              <div className="d-flex justify-content-center">
                <img src={Logo} alt="logo" className="logo-login" />
              </div>
              <p className="landing-title login-text my-2 text-center">
                Scheduling and Messaging
              </p>

              <Grid className="d-flex align-items-center flex-column">
                <button
                  variant="light"
                  className="btn login-buttons my-3 p-3"
                  onClick={() => setLogin("backoffice")}
                >
                  Back Office Sign In
                </button>
                <button
                  variant="light"
                  className="btn login-buttons my-3 p-3"
                  onClick={() => setLogin("provider")}
                >
                  Provider Sign In
                </button>
                <button
                  variant="light"
                  className="btn login-buttons my-3 p-3"
                  onClick={() => setLogin("community")}
                >
                  Community Sign In
                </button>
              </Grid>
            </Grid>
            <Grid className="d-flex justify-content-center">
              <p>Powered by Savisoft Systems & Services, Inc.</p>
            </Grid>
          </animated.div>
        )}
      </Grid>
      {/* <Grid item xs={12} md={6} className="login-right-half">
        <Grid className="logo-view">
          <img
            src={Logo}
            alt="logo"
            className="logo"
            style={{ marginLeft: -5 }}
          />
          <p className="logo-subtext">Mobile care coordination</p>
        </Grid>
      </Grid> */}
    </Grid>
  );
};

export default Login2FA;
