import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  count: 0,
  tagPatients: [],
};

export const patientsSlice = createSlice({
  name: "patients",
  initialState,
  reducers: {
    addPatient: (state, action) => {
      state.data = [...state.data, action.payload] || [];
    },
    replacePatients: (state, action) => {
      state.data = action.payload.data || [];
      state.count = action.payload.count || 0;
    },
    replaceTagPatients: (state, action) => {
      state.tagPatients = action.payload || [];
    },
    updatePatient: (state, action) => {
      const tmpUser = state.data.map((i) => {
        if (i.PatientID === action.payload.PatientID) {
          return action.payload;
        }
        return i;
      });
      state.data = tmpUser || [];
    },
  },
  extraReducers: (builder) => {},
});

export const {
  addPatient,
  updatePatient,
  replacePatients,
  replaceTagPatients,
} = patientsSlice.actions;

export default patientsSlice.reducer;
