import React, { useEffect, useState } from "react";
import MenuHeader from "../../../components/Header/MenuHeader";
import DataTable from "../../../components/DataTable/DataTable";
import { patientHeader } from "../../../data";
import Name from "../../../components/DataTable/Name";
import IconComponent from "../../../components/DataTable/Icon";
import TableFilter from "../../../components/Filter/TableFilter";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import AddPatientsModal from "../../../components/Modals/AddPatientsModal";
import usePatients from "../../../hooks/usePatients";
import UpdatePatientModal from "../../../components/Modals/UpdatePatientModal";
import useDebounce from "../../../hooks/useDebounce";
import moment from "moment";
import FilterModal from "../../../components/Modals/FilterModal";

const Patients = ({
  noHeader,
  noAdd,
  noSearchFilter,
  buttonText,
  onButton,
  communityId,
  providerId,
}) => {
  const navigate = useNavigate();
  const {
    patients,
    loading,
    handleClose,
    filteredPatients,
    getPatients,
    count,
  } = usePatients({
    providerId,
    communityId,
    noInitial: true,
  });
  const [search, setSearch] = useState("");
  const [update, setUpdate] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const debouncedSearchText = useDebounce(search, 750);

  useEffect(() => {
    getPatients({
      search: debouncedSearchText,
      providerId,
      communityId,
      sort,
      filter,
    });
  }, [debouncedSearchText, providerId, communityId, sort, filter]);

  const onAdd = () => setUpdate({});

  // const patientsList = filteredPatients.length ? filteredPatients : patients;
  const patientsList = patients;

  const patientsCount = count || filteredPatients.length;

  const onSubmit = (submitData) => {
    // setData([...data, ...submitData]);
  };

  const onChangePage = (data, limit) => {
    setPage(data);
    getPatients({
      skip: data * 20,
      providerId,
      communityId,
      limit: limit
        ? limit === "View All"
          ? 0
          : limit
        : rowsPerPage === "View All"
        ? 0
        : rowsPerPage,
      sort,
      filter,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const rows = event.target.value;
    setRowsPerPage(rows);
    onChangePage(0, rows);
  };

  const onFilterClose = () => {
    setOpenFilter(false);
    setFilter(null);
  };

  const onFilterSubmit = (data) => {
    setOpenFilter(false);
    setFilter({ ...data });
  };

  const onFilterOpen = () => {
    setOpenFilter(true);
  };

  const generatedRows = patientsList.map((x) => {
    return {
      // ...x,
      name: (
        <Name
          i={x}
          name={`${x.PatientFirstName} ${x.PatientLastName}`}
          onClick={() =>
            providerId || communityId
              ? setUpdate(x)
              : navigate(`/patients/${x.PatientID}`)
          }
        />
      ),
      nameSort: `${x.PatientFirstName} ${x.PatientLastName}`,
      region: x?.zones?.ZoneName || x?.communitylocation?.zones?.ZoneName,
      community: x.Facility === "PH" ? "Private Residence" : x.Facility,
      lastVisitDate: x.lastVisitDate
        ? moment(x.lastVisitDate).format("MMMM DD, YYYY")
        : null,
      lastVisitType:
        x?.providerschedule?.[x?.providerschedule?.length - 1]?.VisitType,
      edit: (
        <IconComponent
          icon={"Edit_Icon"}
          i={x}
          onClick={() => {
            setIsEdit(true);
            setUpdate(x);
          }}
        />
      ),
    };
  });

  return (
    <Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!noHeader && <MenuHeader isSecondary />}
      <Grid className="my-3">
        <TableFilter
          count={count}
          sectionName={"Patients"}
          search={search}
          setSearch={setSearch}
          // isFilter={!noSearchFilter}
          noAdd={true}
          // onAdd={() => (onButton ? onButton() : navigate("/patients/add"))}
          onAdd={onAdd}
          // buttonText={onButton ? onButton : buttonText}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          onChangePage={onChangePage}
          rowsPerPage={rowsPerPage}
          // noSort={true}
          onFilterClick={onFilterOpen}
        />
      </Grid>
      <Grid className="mx-4">
        <DataTable
          columns={patientHeader}
          rows={generatedRows}
          onChangePage={onChangePage}
          page={page}
          count={patientsCount}
          setSort={setSort}
        />
      </Grid>
      {/* {open && (
        <AddPatientsModal
          isOpen={open}
          onClose={() => setOpen(false)}
          onSubmit={onSubmit}
        />
      )} */}
      {update && (
        <UpdatePatientModal
          isOpen={!!update}
          selected={update}
          handleClose={() => {
            setIsEdit(false);
            setUpdate(false);
          }}
          onSubmit={onSubmit}
          isEdited={(!providerId && !communityId) || isEdit}
        />
      )}
      {openFilter && (
        <FilterModal
          open={openFilter}
          handleClose={onFilterClose}
          selectedFilter={filter}
          onSubmit={onFilterSubmit}
          isPatient
        />
      )}
    </Grid>
  );
};

export default Patients;
