import React, { useState } from "react";
import DataTable from "../../../components/DataTable/DataTable";
import { pointofContactHeader } from "../../../data";
import Name from "../../../components/DataTable/Name";
import TableFilter from "../../../components/Filter/TableFilter";
import { Grid } from "@mui/material";
import IconComponent from "../../../components/DataTable/Icon";
import EditPointOfContact from "../../../components/Modals/EditPointOfContact";
import { numberToPhone } from "../../../utilities/resuseFunctions";

const PointOfContact = ({ data, initCall }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [selected, setSelected] = useState({});
  const [search, setSearch] = useState("");
  const onEdit = (x) => {
    setIsEdit(true);
    setSelected(x);
  };
  const handleClose = () => {
    setIsEdit(false);
    setSelected({});
    initCall();
  };

  const dataList = [
    {
      id: 1,
      patientID: data.PatientID,
      name: data.FamilyName1,
      contactCellNumber: numberToPhone(data.FamilyContactCellNumber1),
      contactHomeNumber: numberToPhone(data.FamilyContactHomeNumber1),
      contactWorkNumber: numberToPhone(data.FamilyContactWorkNumber1),
      contactEmail: data.FamilyContactEmail1,
      contactCategory: data.ContactCategory1,
      contactType: data.ContactType1,
    },
    {
      id: 2,
      patientID: data.PatientID,
      name: data.FamilyName2,
      contactCellNumber: numberToPhone(data.FamilyContactCellNumber2),
      contactHomeNumber: numberToPhone(data.FamilyContactHomeNumber2),
      contactWorkNumber: numberToPhone(data.FamilyContactWorkNumber2),
      contactEmail: data.FamilyContactEmail2,
      contactCategory: data.ContactCategory2,
      contactType: data.ContactType2,
    },
    {
      id: 3,
      patientID: data.PatientID,
      name: data.FamilyName3,
      contactCellNumber: numberToPhone(data.FamilyContactCellNumber3),
      contactHomeNumber: numberToPhone(data.FamilyContactHomeNumber3),
      contactWorkNumber: numberToPhone(data.FamilyContactWorkNumber3),
      contactEmail: data.FamilyContactEmail3,
      contactCategory: data.ContactCategory3,
      contactType: data.ContactType3,
    },
  ]
    .filter((i) => i.name)
    .filter((i) =>
      search ? i.name?.toLowerCase()?.includes(search?.toLowerCase()) : true
    );
  const generatedRows = dataList.map((x) => ({
    ...x,
    name: x.name,
    edit: <IconComponent icon={"Edit_Icon"} i={x} onClick={() => onEdit(x)} />,
  }));

  return (
    <Grid>
      <Grid className="my-3">
        <TableFilter
          length={dataList.length}
          sectionName={"Points of Contact"}
          isFilter={false}
          // noAdd={true}
          onAdd={() => setIsEdit(true)}
          setSearch={setSearch}
          search={search}
          noSearch={true}
          noSort={true}
        />
      </Grid>
      <Grid className="mx-4">
        <DataTable columns={pointofContactHeader} rows={generatedRows} />
      </Grid>
      {isEdit && (
        <EditPointOfContact
          open={isEdit}
          handleClose={handleClose}
          selected={selected}
          count={dataList.length}
          patientID={data.PatientID}
          data={generatedRows}
        />
      )}
    </Grid>
  );
};

export default PointOfContact;
