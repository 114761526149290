import * as React from "react";
const MessageSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      fillRule="evenodd"
      d="M1 4.75A2.75 2.75 0 0 1 3.75 2h12.5A2.75 2.75 0 0 1 19 4.75v7.5A2.75 2.75 0 0 1 16.25 15h-5.63a.25.25 0 0 0-.163.06l-3.219 2.76A.75.75 0 0 1 6 17.25v-2a.25.25 0 0 0-.25-.25h-2A2.75 2.75 0 0 1 1 12.25v-7.5ZM3.75 3.5c-.69 0-1.25.56-1.25 1.25v7.5c0 .69.56 1.25 1.25 1.25h2c.966 0 1.75.784 1.75 1.75v.37l1.981-1.699a1.75 1.75 0 0 1 1.139-.421h5.63c.69 0 1.25-.56 1.25-1.25v-7.5c0-.69-.56-1.25-1.25-1.25H3.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export default MessageSvg;
