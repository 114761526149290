import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    replaceNotifications: (state, action) => {
      state.data = action.payload.data || [];
    },
    updateNotification: (state, action) => {
      const tmpUser = state.data.map((i) => {
        if (+i.id === +action.payload.id) {
          return action.payload;
        }
        return i;
      });
      state.data = tmpUser || [];
    },
    deleteNotification: (state, action) => {
      const tmpUser = state.data.filter((i) => +i.id !== +action.payload.id);
      state.data = tmpUser || [];
    },
  },
  extraReducers: (builder) => {},
});

export const { updateNotification, replaceNotifications, deleteNotification } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
