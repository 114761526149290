import * as React from "react";
const LeftArrowSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      fillRule="evenodd"
      d="M8.264 5.204a.75.75 0 0 1 .032 1.06L5.486 9.25H16.25a.75.75 0 0 1 0 1.5H5.486l2.81 2.986a.75.75 0 1 1-1.092 1.028l-4-4.25a.75.75 0 0 1 0-1.028l4-4.25a.75.75 0 0 1 1.06-.032Z"
      clipRule="evenodd"
    />
  </svg>
);
export default LeftArrowSvg;
