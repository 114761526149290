import React, { useState } from "react";
import { colors, fonts } from "../../styles";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Icons } from "../../assets";
import "./VisitCancelModal.css";
import CustomButton from "../Buttons/CustomButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const reasons = [
  "PATIENT CANCELLED",
  "PATIENT NO SHOW",
  "PATIENT RESCHEDULED",
  "PROVIDER CANCELLED",
  "PROVIDER UNAVAILABLE",
  "SCHEDULING ERROR",
];

const VisitCancelModal = ({
  open: isOpen,
  handleClose,
  handleCancel,
  provider,
  visit,
  selectedVisit,
}) => {
  const [open, setOpen] = useState(isOpen);
  const [selected, setSelected] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openReasons = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseReasons = () => {
    setAnchorEl(null);
  };

  const dateString = moment().format("MMMM D, yyyy");

  const handleSelect = (data) => {
    setSelected(data);
    setAnchorEl(null);
  };

  const onClose = () => {
    setOpen(false);
    handleClose();
  };
  const onCancel = () => {
    setOpen(false);
    handleCancel(selected);
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <Grid className="d-flex justify-content-between align-items-center mx-3 mt-3">
        <Grid className="d-flex align-items-center">
          <img src={Icons.Cancel_Icon} className="icon" alt="cancel icon" />
          <Typography className="cancel-title px-2">Cancel Visit</Typography>
        </Grid>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <DialogContentText
        // className="cancel-subtext"
        >
          Based on their last visit date and rotation schedule,{" "}
          <span className="bold-600">"{selectedVisit?.PatientName}"</span> is
          overdue by{" "}
          <span className="bold-600">
            {Math.abs(selectedVisit?.Slack || 0)} days
          </span>{" "}
          for an appointment
        </DialogContentText>
      </DialogContent>
      <Button
        id="region-button"
        aria-controls={openReasons ? "region-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openReasons ? "true" : undefined}
        onClick={handleClick}
        className="btn text-capitalize reasons-dropdown note-text mx-4 my-3 px-2 py-1 d-flex justify-content-between"
      >
        <Typography>{selected || "Reason for cancellation"}</Typography>
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openReasons}
        onClose={handleCloseReasons}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {reasons?.map((reason) => {
          return (
            <MenuItem key={reason} onClick={() => handleSelect(reason)}>
              {reason}
            </MenuItem>
          );
        })}
      </Menu>

      <input
        type="text"
        placeholder="Add a note (optional)"
        className="note-text mx-4 px-2 py-1"
      />

      <DialogActions>
        <DialogActions>
          <CustomButton className={"button-dont"} onClick={onClose}>
            Don’t cancel visit
          </CustomButton>
          <CustomButton className={"button-cancel"} onClick={onCancel}>
            Cancel Visit
          </CustomButton>
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
};

export default VisitCancelModal;
