import React, { useState } from "react";
import MenuHeader from "../../components/Header/MenuHeader";
import IconText from "../../components/Text/IconText";
import Patients from "../Admin/Patients/Patients";
import IconText2 from "../../components/Text/IconText2";
import {
  Avatar,
  Badge,
  Card,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import IconComponent from "../../components/DataTable/Icon";
import EmailSvg from "../../assets/svgs/EmailSvg";
import CallSvg from "../../assets/svgs/CallSvg";
import HomeSvg from "../../assets/svgs/HomeSvg";
import UsersSvg from "../../assets/svgs/UsersSvg";
import { numberToPhone } from "../../utilities/resuseFunctions";
import Communities from "../Admin/Communities/Communities";
import Settings from "./Settings";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { colors } from "../../styles";
import CustomButton from "../../components/Buttons/CustomButton";
import useAuth from "../../hooks/useAuth";
import { Icon } from "@virtuslab/tetrisly-icons";
import YourCommunity from "./YourCommunity";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const Profile = ({ noHeader, isCommunity }) => {
  const { height } = useWindowDimensions();
  const { userData, userLogout } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const [sectionSelected, setSectionSelected] = useState("patients");
  const [selected, setSelected] = useState({});

  return (
    <Grid>
      {!noHeader && <MenuHeader isSecondary />}
      <Grid container>
        <Grid item sm={12} md={3} className="px-md-4">
          <Card>
            <Grid className="overflow-auto" style={{ height: height - 150 }}>
              <Grid className="d-flex justify-content-end p-3">
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => {
                      setSectionSelected("settings");
                      setIsEdit(true);
                    }}
                    style={{ width: 35, height: 35 }}
                  >
                    <IconComponent icon={"Edit_Icon"} i={selected} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                className="d-flex flex-column align-items-center py-2"
                style={{ borderBottom: "1px solid #E9ECEF" }}
              >
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <Avatar
                    size={"lg"}
                    src={userData?.profileImg}
                    className="my-1"
                    style={{ fontFamily: "Inter" }}
                  />
                </StyledBadge>
                <Typography
                  className="py-1"
                  style={{
                    fontSize: 18,
                    fontFamily: "Inter",
                    fontWeight: 600,
                    color: "#333333",
                  }}
                >
                  {`${userData.firstName || ""} ${userData.lastName || ""}`}
                </Typography>
                <Typography
                  style={{
                    fontSize: 12,
                    color: "#828282",
                    fontFamily: "Inter",
                  }}
                >
                  {userData?.position}
                </Typography>
                {userData?.communitylocation && (
                  <Typography
                    className="my-1"
                    style={{
                      fontSize: 12,
                      color: "#828282",
                      fontFamily: "Inter",
                    }}
                  >
                    {userData?.communitylocation?.LocationName}
                  </Typography>
                )}
              </Grid>
              <Divider />
              <Grid
                className="mx-2 p-2"
                style={{
                  alignSelf: "flex-start",
                }}
              >
                {/* <IconText
                text={selected.dob}
                icon={Icons.Heart_Icon}
                textClass="mx-4"
                className={"py-2"}
              /> */}
                <IconText
                  text={userData.email}
                  Icon={EmailSvg}
                  textClass="mx-4"
                  className={"py-2"}
                />
                <IconText
                  text={numberToPhone(userData.phoneNo)}
                  Icon={CallSvg}
                  textClass="mx-4"
                  className={"py-2"}
                />
              </Grid>
              <Grid
                className="align-self-start p-2"
                style={{ borderTop: "1px solid #E9ECEF", flexGrow: 2 }}
              >
                <IconText2
                  text="Patients"
                  Icon={UsersSvg}
                  selected={sectionSelected === "patients"}
                  onClick={() => setSectionSelected("patients")}
                />
                <IconText2
                  text="Your Community"
                  Icon={HomeSvg}
                  selected={sectionSelected === "community"}
                  onClick={() => setSectionSelected("community")}
                />
                {/* <IconText2
                  text="Your Contacts"
                  Icon={HomeSvg}
                  selected={sectionSelected === "contacts"}
                  onClick={() => setSectionSelected("contacts")}
                /> */}
                <IconText2
                  text="Settings"
                  Icon={({ color }) => (
                    <Icon name="20-settings" color={color} />
                  )}
                  selected={sectionSelected === "settings"}
                  onClick={() => setSectionSelected("settings")}
                />
              </Grid>
            </Grid>
            <Grid className="mt-2">
              <CustomButton
                className={"m-0 w-100 p-3"}
                onClick={userLogout}
                style={{
                  backgroundColor: colors.PROVIDER_COLOR,
                }}
              >
                <Typography
                  style={{
                    alignSelf: "center",
                    color: colors.WHITE_COLOR,
                    fontWeight: 600,
                    fontSize: 16,
                    fontFamily: "Inter",
                  }}
                >
                  Sign out
                </Typography>
              </CustomButton>
            </Grid>
          </Card>
        </Grid>
        <Grid item sm={12} md={9}>
          {sectionSelected === "patients" && (
            <Patients
              noHeader
              noAdd
              providerId={userData?.ProviderID}
              communityId={userData?.communityId}
            />
          )}
          {sectionSelected === "community" && <YourCommunity noHeader />}
          {sectionSelected === "settings" && (
            <Settings
              noHeader
              isCommunity={isCommunity}
              edit={isEdit}
              setEdit={setIsEdit}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Profile;
