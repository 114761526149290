import * as React from "react";
const Lock = ({ color }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 2.66667C3 1.19391 4.34315 0 6 0C7.65685 0 9 1.19391 9 2.66667V4.66667H9.9375C11.0766 4.66667 12 5.48748 12 6.5V10.1667C12 11.1792 11.0766 12 9.9375 12H2.0625C0.923413 12 0 11.1792 0 10.1667V6.5C0 5.48748 0.923413 4.66667 2.0625 4.66667H3V2.66667ZM4.125 4.66667H7.875V2.66667C7.875 1.74619 7.03553 1 6 1C4.96447 1 4.125 1.74619 4.125 2.66667V4.66667ZM2.0625 5.66667C1.54473 5.66667 1.125 6.03976 1.125 6.5V10.1667C1.125 10.6269 1.54473 11 2.0625 11H9.9375C10.4553 11 10.875 10.6269 10.875 10.1667V6.5C10.875 6.03976 10.4553 5.66667 9.9375 5.66667H2.0625ZM6 7.33333C6.31066 7.33333 6.5625 7.55719 6.5625 7.83333V8.83333C6.5625 9.10948 6.31066 9.33333 6 9.33333C5.68934 9.33333 5.4375 9.10948 5.4375 8.83333V7.83333C5.4375 7.55719 5.68934 7.33333 6 7.33333Z"
      fill={color || "#000"}
    />
  </svg>
);
export default Lock;
