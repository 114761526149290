import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Dialog,
  DialogActions,
  Grid,
  IconButton,
  Typography,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Icons } from "../../assets";
import "./VisitCancelModal.css";
import CustomButton from "../Buttons/CustomButton";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import useProviders from "../../hooks/useProviders";
import InfoModal from "./InfoModal";

const PublishToAthenaModal = ({ open: isOpen, handleClose, handlePublish }) => {
  const { selectedProvider } = useSelector((state) => state.selections);
  const { providers, getPublishDateRange, loading } = useProviders();
  const provider = providers?.find((i) => i.ProviderID == selectedProvider);
  const [open, setOpen] = useState(isOpen);
  const [isAlert, setIsAlert] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [range, setRange] = useState([]);

  useEffect(() => {
    getPublishDateRange({
      ProviderID: selectedProvider,
    }).then((data) => {
      setRange(data || []);
    });
  }, []);

  const dateRange = range?.map((visit) => {
    return {
      ...visit,
      VisitDate: visit.VisitDate?.slice(0, 10),
    };
  });

  const onClose = () => {
    setOpen(false);
    handleClose();
  };
  const onPublish = () => {
    if (!startDate && !endDate) {
      setIsAlert("Please select Start Date and End Date");
      return;
    }
    if (!startDate) {
      setIsAlert("Please select Start Date");
      return;
    }
    if (!endDate) {
      setIsAlert("Please select End Date");
      return;
    }
    setOpen(false);
    handlePublish({ startDate, endDate });
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <Grid className="d-flex justify-content-between align-items-center mx-3 mt-3">
        <Grid className="d-flex align-items-center">
          <Typography className="cancel-title px-2 title-publish">
            Publish to Athena
          </Typography>
        </Grid>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className="cancel-title"
        >
          Please specify the date range for this provider's schedule that you
          would like to publish to Athena.
        </DialogContentText>
        <Grid className="mb-2">
          <Grid className="d-flex align-items-center my-2">
            <Typography className="field-input">Provider Name:</Typography>
          </Grid>
          <Typography>
            {provider.ProviderFN} {provider.ProviderLN}
          </Typography>
        </Grid>
        <Grid className="mb-2">
          <Grid className="d-flex align-items-center my-2">
            <Typography className="field-input">
              Start Date<span className="text-danger">*</span>
            </Typography>
          </Grid>
          <Form.Select
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          >
            <option value={""}>Select Start Date</option>
            {dateRange?.map((visit, idx) => (
              <option key={idx}>{visit.VisitDate}</option>
            ))}
          </Form.Select>
        </Grid>
        <Grid className="mb-2">
          <Grid className="d-flex align-items-center my-2">
            <Typography className="field-input">
              End Date<span className="text-danger">*</span>
            </Typography>
          </Grid>
          <Form.Select
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          >
            <option value={""}>Select End Date</option>
            {dateRange?.map((visit, idx) => (
              <option key={idx}>{visit.VisitDate}</option>
            ))}
          </Form.Select>
        </Grid>
        {startDate && (
          <Typography>
            You have selected{" "}
            {startDate && moment(startDate).format("MMMM DD, YYYY")} -{" "}
            {endDate && moment(endDate).format("MMMM DD, YYYY")}
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <DialogActions>
          <CustomButton
            className={"button-dont button-publish"}
            onClick={onClose}
          >
            No, don't publish to Athena
          </CustomButton>
          <CustomButton
            className={"button-cancel button-primary button-publish"}
            onClick={onPublish}
          >
            Yes, publish to Athena
          </CustomButton>
        </DialogActions>
      </DialogActions>
      {!!isAlert && (
        <InfoModal
          type="error"
          open={!!isAlert}
          handleClose={() => {
            setIsAlert("");
          }}
          title={"Warning"}
          subtext={isAlert}
        />
      )}
    </Dialog>
  );
};

export default PublishToAthenaModal;
