import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

export const scheduleSettingsSlice = createSlice({
  name: "scheduleSettings",
  initialState,
  reducers: {
    addSchedulerSetting: (state, action) => {
      state.data = [...state.data, action.payload] || [];
    },
    replaceSchedulerSettings: (state, action) => {
      state.data = action.payload.data || [];
      state.count = action.payload.count || 0;
    },
    updateSchedulerSetting: (state, action) => {
      const tmpUser = state.data.map((i) => {
        if (i.ProviderID == action.payload.ProviderID) {
          return action.payload;
        }
        return i;
      });
      state.data = tmpUser || [];
    },
    deleteteSchedulerSetting: (state, action) => {
      const tmpUser = state.data.filter(
        (i) => i.ProviderID !== action.payload.ProviderID
      );
      state.data = tmpUser || [];
    },
  },
  extraReducers: (builder) => {},
});

export const {
  addSchedulerSetting,
  updateSchedulerSetting,
  replaceSchedulerSettings,
  deleteteSchedulerSetting,
} = scheduleSettingsSlice.actions;

export default scheduleSettingsSlice.reducer;
