import * as React from "react";
const EmailSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      fillRule="evenodd"
      d="M10 2.5a7.5 7.5 0 1 0 0 15 .75.75 0 0 1 0 1.5 9 9 0 1 1 9-9v2a3 3 0 0 1-5.535 1.605 5 5 0 1 1 1.044-1.441A1.5 1.5 0 0 0 17.5 12v-2A7.5 7.5 0 0 0 10 2.5Zm3.104 9.119a3.5 3.5 0 1 0-6.208-3.238 3.5 3.5 0 0 0 6.208 3.238Z"
      clipRule="evenodd"
    />
  </svg>
);
export default EmailSvg;
