import React, { useState } from "react";
import AppointmentButton from "../Buttons/AppointmentButton";
import { colors } from "../../styles";
import {
  Avatar,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  // Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
// import Check_Icon from "../../assets/images/Check_Icon.png";
// import Schedule_Icon from "../../assets/images/Schedule_Icon.png";
// import VisitAdd from "../../assets/images/VisitAdd.png";
import "./AppointmentCard.css";
import VisitCancelModal from "../Modals/VisitCancelModal";
import VisitDetailsModal from "../Modals/VisitDetailsModal";
import CalendarCheckSvg from "../../assets/svgs/CalendarCheckSvg";
import CalendarPlusSvg from "../../assets/svgs/CalendarPlusSvg";
import PublishAthenaCheck from "../../assets/images/PublishAthenaCheck.png";
import Delete from "../../assets/svgs/Delete";
import Lock from "../../assets/svgs/Lock";
import Unlock from "../../assets/svgs/Unlock";
import CheckSvg from "../../assets/svgs/CheckSvg";
import {
  capitalizeFirstWords,
  numberToPhone,
} from "../../utilities/resuseFunctions";
import useProviders from "../../hooks/useProviders";
// import Loading from "../Loading";
// import { changeDate } from "../../redux/reducers/selections.reducer";
import { useDispatch, useSelector } from "react-redux";
// import moment from "moment";
import useAuth from "../../hooks/useAuth";
import moment from "moment";
// import RightSvg from "../../assets/svgs/RightSvg";
// import { status } from "../../data/listOptions";
import { focusMarker } from "../../redux/reducers/selections.reducer";
// import NoteToProvider from "../Modals/NoteToProvider";
import useLogs from "../../hooks/useLogs";
import ScheduleApi from "../../services/scheduleApi";
import { appointmentTypes } from "../../data/listOptions";
import CustomButton from "../Buttons/CustomButton";
import { Close as CloseIcon } from "@mui/icons-material";
import UserSvg from "../../assets/svgs/user";
import useNotifications from "../../hooks/useNotifications";
import { NotificationType } from "../../constants/types";
// import InfoModal from "../Modals/InfoModal";
// import NoteToProvider from "../Modals/NoteToProvider";

const AppointmentsCard = ({
  data,
  index,
  setData,
  totalData,
  provider,
  groupedItems,
  isCommunity,
}) => {
  const dispatch = useDispatch();
  // const { date } = useSelector((state) => state.selections);
  const [open, setOpen] = useState(false);
  const [isVisit, setIsVisit] = useState(false);
  const [isNote, setIsNote] = useState(false);
  const {
    cancelSchedulesApi,
    loading,
    // getEligibleSchedules,
    getSchedulesApi,
    getCalendarSchedules,
    getIsPublishEnabled,
  } = useProviders(true);
  const { userData } = useAuth();
  const { addLog } = useLogs();
  const { createNotificationApi } = useNotifications(true);
  const { date } = useSelector((state) => state.selections);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = async (selected) => {
    try {
      setOpen(false);
      setIsVisit(false);
      await cancelSchedulesApi({
        ScheduleID: data.ID,
        Reason: selected,
        PatientID: data.PatientID,
        ProviderID: data.ProviderID,
        AppointmentID: data.AppointmentID,
        canceledBy: String(userData?.id),
        CommunityID: data?.CommunityID,
        PatientName: data?.PatientName,
      });
      await createNotificationApi({
        ids: [data.ProviderID],
        type: NotificationType.APPOINTMENT_CANCELED,
        data: null,
        metadata: {
          communityId: data?.CommunityID || null,
          patientName: data?.PatientName,
        },
      });
      const logPayload = {
        UserID: userData?.id,
        Activity: `Schedule (${data.ID}) on ${data?.VisitDate?.slice(
          0,
          10
        )} Cancelled for Provider ID ${data.ProviderID} and Patient ID ${
          data.PatientID
        } by User - ${userData?.firstName || ""} ${
          userData?.lastName || ""
        } with Reason ${selected}`,
      };
      await addLog(logPayload);
      // getEligibleSchedules({ id: data.ProviderID });
      getIsPublishEnabled({ ProviderID: data.ProviderID });
      const payload = {
        ID: isCommunity ? userData?.communityId : data.ProviderID,
        date: moment(date).format("YYYY-MM-DD"),
        isFor: isCommunity ? "Community" : "Provider",
      };
      getSchedulesApi(payload);
      getCalendarSchedules(payload);
      const tmpData = totalData.filter((i) => i.ID !== data.ID);
      setData(tmpData);
    } catch (error) {
      const logPayload = {
        UserID: userData?.id,
        Activity: `Failed (${
          error?.Response?.data?.message || error?.message || ""
        }) -> Schedule (${data.ID}) on ${data?.VisitDate?.slice(
          0,
          10
        )} Cancelled for Provider ID ${data.ProviderID} and Patient ID ${
          data.PatientID
        } by User - ${userData?.firstName || ""} ${
          userData?.lastName || ""
        } with Reason ${selected}`,
      };
      await addLog(logPayload);
      console.log(error);
    }
  };

  const onCancel = () => {
    setOpen(true);
  };

  const onVisit = () => {
    setIsVisit(true);
  };

  const onFocusPatientLocation = () => {
    const idx = groupedItems.findIndex((item) =>
      item.data.find((i) => i.ID === data.ID)
    );
    dispatch(
      focusMarker({
        latlng: {
          ZoneLatitude: data.PatientLatitude,
          ZoneLongitude: data.PatientLongitude,
        },
        id: `c${idx}`,
        seq: `a${data.SequenceNo}`,
      })
    );
  };

  const lockAppointment = async (id) => {
    try {
      console.log({
        id: userData?.id,
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        position: userData?.position,
      });
      await ScheduleApi.patch(`/schedule/lock/${id}`, {
        userId: userData?.id,
      });
      const tmpData = totalData.map((i) =>
        i.ID !== data.ID
          ? { ...i }
          : {
              ...i,
              isLocked: true,
              lastLockedBy: {
                id: userData?.id,
                firstName: userData?.firstName,
                lastName: userData?.lastName,
                position: userData?.position,
              },
            }
      );
      const logPayload = {
        UserID: userData?.id,
        Activity: `Appointment (${data.ID}) on ${data?.VisitDate?.slice(
          0,
          10
        )} Locked for Provider ID ${data.ProviderID} and Patient ID ${
          data.PatientID
        } by User - ${userData?.firstName || ""} ${userData?.lastName || ""}`,
      };
      await addLog(logPayload);
      setData(tmpData);
    } catch (error) {
      const logPayload = {
        UserID: userData?.id,
        Activity: `Failed (${
          error?.Response?.data?.message || error?.message || ""
        }) -> Appointment (${data.ID}) on ${data?.VisitDate?.slice(
          0,
          10
        )} Locked for Provider ID ${data.ProviderID} and Patient ID ${
          data.PatientID
        } by User - ${userData?.firstName || ""} ${userData?.lastName || ""}`,
      };
      await addLog(logPayload);
      console.log(error);
    }
  };

  const unlockAppointment = async (id) => {
    try {
      await ScheduleApi.patch(`/schedule/unlock/${id}`);
      const tmpData = totalData.map((i) =>
        i.ID !== data.ID
          ? { ...i }
          : { ...i, isLocked: false, lastLockedBy: null }
      );
      const logPayload = {
        UserID: userData?.id,
        Activity: `Appointment (${data.ID}) on ${data?.VisitDate?.slice(
          0,
          10
        )} Unlocked for Provider ID ${data.ProviderID} and Patient ID ${
          data.PatientID
        } by User - ${userData?.firstName || ""} ${userData?.lastName || ""}`,
      };
      await addLog(logPayload);
      setData(tmpData);
    } catch (error) {
      const logPayload = {
        UserID: userData?.id,
        Activity: `Failed (${
          error?.Response?.data?.message || error?.message || ""
        }) -> Appointment (${data.ID}) on ${data?.VisitDate?.slice(
          0,
          10
        )} Unlocked for Provider ID ${data.ProviderID} and Patient ID ${
          data.PatientID
        } by User - ${userData?.firstName || ""} ${userData?.lastName || ""}`,
      };
      await addLog(logPayload);
      console.log(error);
    }
  };

  // const app = data.isCompleted
  //   ? status.isCompleted
  //   : data.isConfirmed
  //   ? status.isConfirmed
  //   : data.isCanceled
  //   ? status.isCanceled
  //   : status.isPending;

  const name = data.PatientName || "";
  const phone =
    numberToPhone(data?.sourcepatient?.FamilyContactCellNumber1) ?? "";
  const community = data?.Facility ?? "";
  const distance =
    index === 0 || index === totalData.length - 1
      ? (data?.DistanceFromHome?.toFixed(2) ??
          data?.Distance?.toFixed(2) ??
          "0") + " miles"
      : (data?.Distance?.toFixed(2) ?? "0") + " miles" || "";

  const type = appointmentTypes.find(
    (appTypes) =>
      appTypes.name === data?.VisitType || appTypes.id === +data?.VisitType
  );
  const [infoOpen, setInfoOpen] = useState(false);
  const [isLockLoading, setIsLockLoading] = useState(false);
  const [info, setInfo] = useState({});

  const onLock = async (data) => {
    if (userData?.id === data?.lastLockedBy?.id) {
      setIsLockLoading(true);
      await unlockAppointment(data.ID);
      setIsLockLoading(false);
      return;
    }
    setInfo({
      title: "Unlock Schedule",
      text: `This appointment was locked by ${data?.lastLockedBy?.firstName} ${data?.lastLockedBy?.lastName}. Do you wish to unlock it?`,
      type: "unlock",
    });
    setInfoOpen(true);
  };

  const isLockDisabled =
    data.isPublished || moment().isAfter(moment(data.VisitDate.slice(0, 10)));

  return (
    <>
      <Card
        // disableRipple={true}
        className={
          data.new
            ? "m-3 p-3 new"
            : data.isCanceled
            ? "m-3 p-3 cancelled"
            : "m-3 p-3"
        }
      >
        {/* <Loading loading={loading} /> */}
        {!isCommunity && (
          <Grid className="d-flex justify-content-between">
            <Grid className="d-flex">
              <Avatar
                onClick={onFocusPatientLocation}
                style={{
                  backgroundColor: data.isCompleted
                    ? colors.SUCCESS_COLOR
                    : data.new
                    ? colors.WHITE_COLOR
                    : "#F3CA50",
                }}
              >
                <Typography
                  style={{
                    color: data.new ? colors.BLACK_COLOR : colors.WHITE_COLOR,
                  }}
                >
                  {index + 1}
                </Typography>
              </Avatar>
              <Grid className="mx-3">
                <Typography className="mainText">
                  {name} {name && community && "|"} {community}
                </Typography>
                <Typography className="subText">
                  {phone} {phone && distance && "|"} {distance}{" "}
                  {index === totalData.length - 1 ? "to " : "from "}
                  {index === 0 || index === totalData.length - 1
                    ? "HOME"
                    : `Visit ${index}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <Grid>
                {data.isCompleted ? (
                  <Tooltip
                    title="Visit Completed"
                    style={{
                      backgroundColor: "#F5F7F9",
                      padding: 10,
                      borderRadius: 6,
                    }}
                  >
                    <div>
                      <CheckSvg
                        color="#27AE60"
                        // className="checkIcon"
                        className="align-self-center"
                      />
                    </div>
                  </Tooltip>
                ) : data.isPublished ? (
                  <Tooltip title="Appointment sent to Athena">
                    <div>
                      <img
                        src={PublishAthenaCheck}
                        alt="Published to Athena"
                        width={40}
                        height={40}
                      />
                    </div>
                  </Tooltip>
                ) : data.isLocked ? (
                  <Tooltip
                    title="Appointment has been locked"
                    style={{
                      backgroundColor: "#F5F7F9",
                      borderRadius: 6,
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <Lock color="#2F80ED" />
                    </div>
                  </Tooltip>
                ) : data.new ? (
                  <Tooltip
                    title="New Visit"
                    style={{
                      backgroundColor: "#F5F7F9",
                      padding: 10,
                      borderRadius: 6,
                    }}
                  >
                    <div>
                      <CalendarPlusSvg color="#F3CA50" />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Appointment Confirmed"
                    style={{
                      backgroundColor: "#F5F7F9",
                      padding: 10,
                      borderRadius: 6,
                    }}
                  >
                    <div>
                      <CalendarCheckSvg color="#F3CA50" />
                    </div>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
        {isCommunity && (
          <Grid className="">
            <Typography className="mainText color-1">{name}</Typography>
            <Divider className="w-100" sx={{ borderBottomWidth: 2 }} />
            <Grid className="d-flex align-items-center py-2">
              <UserSvg color="#272e35" />
              <Typography className="proText px-2">
                {capitalizeFirstWords(data?.ProviderName)}
              </Typography>
            </Grid>
            {data?.LastVisitDate && (
              <Typography className="subText">
                Patient last seen:{" "}
                {moment(data.LastVisitDate).format("MMMM DD, yyyy")}
              </Typography>
            )}
          </Grid>
        )}
        <Grid
          className={`d-flex mt-3 ${!isCommunity && "justify-content-around"}`}
        >
          {/* <AppointmentButton title="Note to Provider" disabled={true} /> */}
          <Grid
            className="d-flex align-items-center justify-content-center rounded-2 px-2"
            style={{ background: "rgba(0,0,0,0.05)" }}
          >
            <Typography className="title-text text-center">
              {type?.name || ""}
            </Typography>
          </Grid>
          <AppointmentButton
            title="Appointment details"
            onClick={onVisit}
            className={isCommunity ? "mx-3" : ""}
          />
          {!isCommunity && (
            <button
              className="button-unset d-flex flex-row g-1 align-items-center justify-content-center rounded-2 px-2"
              style={{
                background: !data.isLocked ? "#FFF" : "#2D9CDB",
                outline: !data.isLocked ? "1.5px solid #CFD6DD" : "none",
                opacity: isLockLoading || isLockDisabled ? 0.7 : 1,
              }}
              onClick={async () => {
                if (!data.isLocked && !isLockLoading) {
                  // await lockAppointment(data.ID);
                  setIsLockLoading(true);
                  await lockAppointment(data.ID);
                  setIsLockLoading(false);
                } else {
                  // await unlockAppointment(data.ID);
                  onLock(data);
                }
              }}
              disabled={isLockLoading || isLockDisabled}
            >
              {data.isLocked ? (
                <>
                  {isLockLoading ? (
                    <CircularProgress color={"inherit"} size={"0.8rem"} />
                  ) : (
                    <Lock color="#fff" />
                  )}
                  <Typography
                    className="title-text text-center"
                    style={{
                      color: "#fff",
                      paddingLeft: "4px",
                    }}
                  >
                    Locked
                  </Typography>
                </>
              ) : (
                <>
                  {isLockLoading ? (
                    <CircularProgress
                      color="inherit"
                      size={"0.8rem"}
                      style={{ opacity: 0.5 }}
                    />
                  ) : (
                    <Unlock color="#2D9CDB" />
                  )}
                  <Typography
                    className="title-text text-center"
                    style={{
                      color: "#2D9CDB",
                      paddingLeft: "4px",
                    }}
                  >
                    Unlocked
                  </Typography>
                </>
              )}
            </button>
          )}
          {!isCommunity && (
            <button
              className="button-unset d-flex flex-row g-2 align-items-center rounded-2 px-2"
              style={{ background: "#fcd9d9" }}
              disabled={
                data.isCompleted ||
                moment(data.VisitDate?.slice(0, 10)).isBefore(
                  moment().format("YYYY-MM-DD")
                )
              }
              onClick={onCancel}
            >
              <Delete
                color={
                  data.isCompleted ||
                  moment(data.VisitDate?.slice(0, 10)).isBefore(
                    moment().format("YYYY-MM-DD")
                  )
                    ? "rgba(0,0,0,0.3)"
                    : "#4a0d0d"
                }
              />
              <Typography
                className={`${"title-text text-center"}${
                  data.isCompleted ||
                  moment(data.VisitDate?.slice(0, 10)).isBefore(
                    moment().format("YYYY-MM-DD")
                  )
                    ? " disable"
                    : ""
                }`}
                style={{
                  color: data.isCompleted ? "#000" : "#4a0d0d",
                  paddingLeft: "4px",
                }}
              >
                Cancel
              </Typography>
            </button>
          )}
          {/* <AppointmentButton
          title="Cancel"
          variant="red"
          disabled={
            data.isCompleted ||
            moment(data.VisitDate?.slice(0, 10)).isBefore(
              moment().format("YYYY-MM-DD")
            )
          }
          // disabled={true}
          className="mx-5"
          onClick={onCancel}
          textClass={data.isCompleted ? "" : "cancel-text"}
        /> */}
        </Grid>
        {open && (
          <VisitCancelModal
            open={open}
            handleClose={handleClose}
            handleCancel={handleCancel}
            visit={data?.id || ""}
            provider={provider}
            selectedVisit={data}
          />
        )}
        {isVisit && (
          <VisitDetailsModal
            isOpen={isVisit}
            onClose={() => setIsVisit(false)}
            selectedVisit={data}
            onCancel={onCancel}
            isCommunity={isCommunity}
          />
        )}
        {infoOpen && (
          <Dialog
            open={infoOpen}
            onClose={() => {
              setInfoOpen(false);
              setInfo({});
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"sm"}
            fullWidth
          >
            <Grid className="d-flex justify-content-between align-items-center mx-3 mt-3">
              <Grid className="d-flex align-items-center">
                <Typography className="cancel-title px-2">
                  {info?.title}
                </Typography>
              </Grid>
              <IconButton
                onClick={() => {
                  setInfoOpen(false);
                  setInfo({});
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                className="cancel-subtext"
              >
                {info?.text}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <DialogActions>
                <CustomButton
                  className={"button-cancel"}
                  onClick={() => {
                    setInfoOpen(false);
                    setInfo({});
                  }}
                  disabled={isLockLoading}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  className={"button-cancel button-primary"}
                  onClick={async () => {
                    if (info.type === "lock") {
                      setIsLockLoading(true);
                      await lockAppointment(data.ID);
                    } else if (info.type === "unlock") {
                      setIsLockLoading(true);
                      await unlockAppointment(data.ID);
                    }
                    setIsLockLoading(false);
                    setInfoOpen(false);
                    setInfo({});
                  }}
                  disabled={isLockLoading}
                >
                  {!isLockLoading ? (
                    <>{info.type === "lock" ? "Lock" : "Unlock"}</>
                  ) : (
                    <CircularProgress color="inherit" size={"1.3rem"} />
                  )}
                </CustomButton>
              </DialogActions>
            </DialogActions>
          </Dialog>
        )}
      </Card>
    </>
  );
};

export default AppointmentsCard;
