import React, { useState } from "react";
import { colors } from "../../styles";
import CustomButton from "../Buttons/CustomButton";
import {
  Dialog,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  IconButton,
} from "@mui/material";
import "./AddRegionModal.css";
import CloseIcon from "@mui/icons-material/Close";
import { Form } from "react-bootstrap";
import PhoneInput from "../Input/PhoneInput";
import ConfirmModal from "./DeleteModal";

const AddUserModal = ({
  open: isOpen,
  handleClose,
  selectedUser,
  onSubmit,
}) => {
  const [open, setOpen] = useState(isOpen);
  const [isConfirm, setIsConfirm] = useState(false);
  const [edit, setEdit] = useState(
    selectedUser || {
      hasAthenaIntegrationAccess: false,
      firstName: "",
      lastName: "",
      position: "Scheduler",
      phoneNo: "",
      email: "",
    }
  );

  // console.log(edit, selectedUser);

  const onChangeHandler = (val, name) => {
    setEdit({
      ...edit,
      [name]: val,
    });
  };

  const onClose = () => {
    setOpen(true);
    handleClose();
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
        fullWidth
      >
        <Grid>
          <Grid
            className="d-flex justify-content-between py-1 px-3 align-items-center"
            style={{
              backgroundColor: colors.OFFICE_COLOR,
            }}
          >
            <Typography className="region-modal-title">
              {selectedUser?.role ? "Edit Staff" : "Add New Office Staff"}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon className="close-icon" />
            </IconButton>
          </Grid>
          <Grid className="px-2">
            <Grid className="m-2 px-3">
              <Typography className="fieldName py-2">Name*</Typography>
              <Grid container>
                <Grid item md={6}>
                  <Form.Control
                    placeholder="First Name"
                    className="inputField px-2 border-1 w-100"
                    value={edit?.firstName}
                    onChange={(val) =>
                      onChangeHandler(val.target.value, "firstName")
                    }
                  />
                </Grid>
                <Grid item md={6} className="px-md-2 py-2 py-md-0">
                  <Form.Control
                    placeholder="Last Name"
                    className="inputField px-2 border-1 w-100"
                    value={edit?.lastName}
                    onChange={(val) =>
                      onChangeHandler(val.target.value, "lastName")
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid className="m-2 px-3">
              <Typography className="fieldName py-2">Email*</Typography>
              <Form.Control
                placeholder="Email address"
                className="inputField px-2 border-1 w-100"
                value={edit?.email}
                onChange={(val) => onChangeHandler(val.target.value, "email")}
              />
            </Grid>
            <Grid container className="p-2 ">
              <Grid item md={6}>
                <Typography className="fieldName px-3 py-2">
                  User Role*
                </Typography>
                <Grid className="inputField px-3">
                  <Form.Select
                    aria-label="Default select example"
                    value={edit?.position}
                    onChange={(e) =>
                      onChangeHandler(e.target.value, "position")
                    }
                  >
                    <option>Select Role</option>
                    <option value="Admin">Admin</option>
                    <option value="Scheduler">Scheduler</option>
                    <option value="Staff">Staff</option>
                    <option value="Manager">Manager</option>
                    <option value="Hospice">Hospice</option>
                  </Form.Select>
                </Grid>
              </Grid>
              <Grid item md={6}>
                <Typography className="fieldName py-2 px-3">Phone</Typography>
                <Grid className="px-3">
                  <PhoneInput
                    value={edit?.phoneNo}
                    onChange={(val) => onChangeHandler(val, "phoneNo")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid className="p-2">
              <Typography className="fieldName px-3 py-2">
                Athena Integration access?
              </Typography>
              <RadioGroup
                row
                value={edit?.hasAthenaIntegrationAccess ? "yes" : "no"}
                onChange={(val) =>
                  onChangeHandler(
                    val.target.value === "no" ? false : true,
                    "hasAthenaIntegrationAccess"
                  )
                }
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="hasAthenaIntegrationAccess"
                className="mx-3"
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Yes, allow this user to create Athena cases from CareSMS."
                />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>

            <Grid>
              <CustomButton
                className="button mx-4 py-2 px-5"
                onClick={() => setIsConfirm(true)}
              >
                <Typography>{selectedUser?.role ? "Update" : "Add"}</Typography>
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      {isConfirm && (
        <ConfirmModal
          open={isConfirm}
          handleClose={() => {
            setIsConfirm(false);
            onClose();
          }}
          handleDelete={() => onSubmit(edit)}
          title={`${selectedUser?.role ? "Edit" : "Add"} User`}
          subtext={`Are you sure you want to ${
            selectedUser?.role ? "update" : "add"
          } this User`}
          primaryText={"Confirm"}
        />
      )}
    </>
  );
};

export default AddUserModal;
