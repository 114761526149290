import React, { useState } from "react";
import { colors } from "../../styles";
import CustomButton from "../Buttons/CustomButton";
import { Dialog, Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./AddRegionModal.css";
import usePatients from "../../hooks/usePatients";
import PhoneInput from "../Input/PhoneInput";
import { Form } from "react-bootstrap";
import { contactCategories, contactTypes } from "../../data/listOptions";

const EditPointOfContact = ({
  open: isOpen,
  handleClose,
  selected,
  count,
  patientID,
  data,
}) => {
  const { updatePatientApi } = usePatients();
  const [open, setOpen] = useState(isOpen);
  const [edit, setEdit] = useState(selected);

  const onClose = () => {
    setOpen(true);
    handleClose();
  };

  const onAdd = () => {
    const sequence = edit.patientID ? edit.id : count + 1;
    const variables = {
      PatientID: edit.patientID || patientID,
      [`FamilyName${sequence}`]: edit.name,
      [`FamilyContactCellNumber${sequence}`]: edit.contactCellNumber,
      [`FamilyContactHomeNumber${sequence}`]: edit.contactHomeNumber,
      [`FamilyContactWorkNumber${sequence}`]: edit.contactWorkNumber,
      [`FamilyContactEmail${sequence}`]: edit.contactEmail,
      [`ContactCategory${sequence}`]: edit.contactCategory,
      [`ContactType${sequence}`]: edit.contactType,
    };
    updatePatientApi(variables).then(onClose);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <Grid>
        <Grid
          className="d-flex justify-content-between py-1 px-3 align-items-center"
          style={{
            backgroundColor: colors.OFFICE_COLOR,
          }}
        >
          <Typography className="region-modal-title">
            {edit.patientID ? "Edit" : "Add New"} Point of Contact
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Grid>
        <Grid className="px-2">
          <Grid className="m-2 px-3">
            <Typography className="fieldName py-2">Name*</Typography>
            <Grid className="d-flex">
              <Form.Control
                placeholder="Name"
                className="inputField px-2  border-1"
                name="name"
                value={edit?.name}
                onChange={(e) =>
                  setEdit({ ...edit, [e.target.name]: e.target.value })
                }
              />
            </Grid>
          </Grid>
          <Grid className="m-2 px-3">
            <Typography className="fieldName py-2">Cell Number</Typography>
            <PhoneInput
              className="inputField px-2 border-1 w-100"
              name="contactCellNumber"
              value={edit?.contactCellNumber}
              onChange={(val) => setEdit({ ...edit, contactCellNumber: val })}
            />
          </Grid>
          <Grid className="m-2 px-3">
            <Typography className="fieldName py-2">Home Number</Typography>
            <PhoneInput
              className="inputField px-2 border-1 w-100"
              name="contactHomeNumber"
              value={edit?.contactHomeNumber}
              onChange={(val) => setEdit({ ...edit, contactHomeNumber: val })}
            />
          </Grid>
          <Grid className="m-2 px-3">
            <Typography className="fieldName py-2">Work Number</Typography>
            <PhoneInput
              className="inputField px-2 border-1 w-100"
              name="contactWorkNumber"
              value={edit?.contactWorkNumber}
              onChange={(val) => setEdit({ ...edit, contactWorkNumber: val })}
            />
          </Grid>
          <Grid className="m-2 px-3">
            <Typography className="fieldName py-2">Email</Typography>
            <Form.Control
              placeholder="Email"
              className="inputField px-2 border-1 w-100"
              name="contactEmail"
              value={edit?.contactEmail}
              onChange={(e) =>
                setEdit({ ...edit, [e.target.name]: e.target.value })
              }
            />
          </Grid>
          <Grid container className="mx-2">
            <Grid className=" px-3">
              <Typography className="fieldName py-2">Category</Typography>
              <Form.Select
                aria-label="Default select example"
                value={edit?.contactCategory}
                onChange={(e) =>
                  setEdit({ ...edit, contactCategory: e.target.value })
                }
              >
                <option>Select Contact Category</option>
                {contactCategories
                  ?.filter(
                    (x) =>
                      !data.find((y) =>
                        edit.patientID
                          ? y.contactCategory !== edit.contactCategory &&
                            y.contactCategory === x
                          : y.contactCategory === x
                      )
                  )
                  ?.map((i) => {
                    return (
                      <option key="i" value={i}>
                        {i}
                      </option>
                    );
                  })}
              </Form.Select>
            </Grid>
            <Grid className="px-3">
              <Typography className="fieldName py-2">Type</Typography>
              <Form.Select
                aria-label="Default select example"
                value={edit?.contactType}
                onChange={(e) =>
                  setEdit({ ...edit, contactType: e.target.value })
                }
              >
                <option>Select Contact Type</option>
                {contactTypes?.map((i) => {
                  return (
                    <option key="i" value={i}>
                      {i}
                    </option>
                  );
                })}
              </Form.Select>
            </Grid>
          </Grid>
          <Grid>
            <CustomButton className="button mx-4 px-5 py-2" onClick={onAdd}>
              <Typography>{edit.patientID ? "Update" : "Add"}</Typography>
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default EditPointOfContact;
