import React, { useState } from "react";
import { colors } from "../../styles";
import CustomButton from "../Buttons/CustomButton";
import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import "./AddRegionModal.css";
import CloseIcon from "@mui/icons-material/Close";
import { Form } from "react-bootstrap";
import useCommunities from "../../hooks/useCommunities";
import PhoneInput from "../Input/PhoneInput";
import ConfirmModal from "./DeleteModal";
import useZones from "../../hooks/useZones";
import { states } from "../../data/listOptions";

const AddCommunityModal = ({ open: isOpen, handleClose, selected }) => {
  const { zones } = useZones();
  const { communities, loading, addCommunityApi, updateCommunityApi } =
    useCommunities(true);
  const [open, setOpen] = useState(isOpen);
  const [edit, setEdit] = useState(selected || {});
  const [isConfirm, setIsConfirm] = useState(false);

  const onChangeHandler = (val, name) => {
    setEdit({
      ...edit,
      [name]: val,
    });
  };

  const onClose = () => {
    setOpen(false);
    handleClose();
  };

  const onSubmit = () => {
    handleClose();
    const payload = {
      LocationID: selected?.LocationID,
      LocationType: null,
      ...edit,
    };
    if (selected?.LocationID) {
      updateCommunityApi(payload);
    } else {
      addCommunityApi({ LocationID: communities?.length + 1, ...payload });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
        fullWidth
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid>
          <Grid
            className="d-flex justify-content-between py-1 px-3 align-items-center"
            style={{
              backgroundColor: colors.OFFICE_COLOR,
            }}
          >
            <Typography className="region-modal-title">
              {selected?.LocationID ? "Edit Community" : "Add New Community"}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon className="close-icon" />
            </IconButton>
          </Grid>
          <Grid className="px-2">
            <Grid className="m-2 px-3">
              <Typography className="fieldName py-2">Name*</Typography>
              <Grid container>
                <Grid item md={6}>
                  <Form.Control
                    placeholder="Name"
                    className="inputField px-2 border-1 w-100"
                    value={edit?.LocationName}
                    onChange={(val) =>
                      onChangeHandler(val.target.value, "LocationName")
                    }
                  />
                </Grid>
                {/* <Grid item md={6} className="px-md-2 py-2 py-md-0">
                <Form.Control
                  placeholder="Last Name"
                  className="inputField px-2 border-1 w-100"
                  value={edit?.lastName}
                  onChange={(val) =>
                    onChangeHandler(val.target.value, "lastName")
                  }
                />
              </Grid> */}
              </Grid>
            </Grid>

            <Grid container className="p-2 ">
              <Grid item md={6}>
                <Typography className="fieldName px-3 py-2">Zone*</Typography>
                <Grid className="inputField px-3">
                  <Form.Select
                    aria-label="Default select example"
                    value={edit?.LocationZoneID}
                    onChange={(e) =>
                      onChangeHandler(e.target.value, "LocationZoneID")
                    }
                  >
                    <option>Select Zone</option>
                    {zones?.map((zone) => {
                      return (
                        <option key={zone.ZoneID} value={zone.ZoneID}>
                          {zone?.ZoneName}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Grid>
              </Grid>
              <Grid item md={6}>
                <Typography className="fieldName py-2 px-3">Phone</Typography>
                <Grid className="px-3">
                  <PhoneInput
                    value={edit?.LocationPhone}
                    onChange={(val) => onChangeHandler(val, "LocationPhone")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container className="p-2 ">
              <Grid item md={6}>
                <Typography className="fieldName px-3 py-2">Street</Typography>
                <Grid className="inputField px-3">
                  <Form.Control
                    placeholder="Street"
                    value={edit?.LocationAddress1}
                    onChange={(val) =>
                      onChangeHandler(val.target.value, "LocationAddress1")
                    }
                  />
                </Grid>
              </Grid>
              <Grid item md={6}>
                <Typography className="fieldName py-2 px-3">City</Typography>
                <Grid className="px-3">
                  <Form.Control
                    placeholder="City"
                    value={edit?.LocationCity}
                    onChange={(val) =>
                      onChangeHandler(val.target.value, "LocationCity")
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container className="p-2 ">
              <Grid item md={6}>
                <Typography className="fieldName px-3 py-2">State</Typography>
                <Grid className="inputField px-3">
                  <Form.Select
                    aria-label="State"
                    value={edit?.LocationState}
                    onChange={(val) =>
                      onChangeHandler(val.target.value, "LocationState")
                    }
                  >
                    <option>Select State</option>
                    {states?.map((state) => {
                      return (
                        <option key={state.value} value={state.value}>
                          {state?.text}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Grid>
              </Grid>
              <Grid item md={6}>
                <Typography className="fieldName py-2 px-3">Country</Typography>
                <Grid className="px-3">
                  <Form.Control
                    placeholder="Country"
                    value={edit?.LocationCountry}
                    onChange={(val) =>
                      onChangeHandler(val.target.value, "LocationCountry")
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container className="p-2 ">
              <Grid item md={6}>
                <Typography className="fieldName px-3 py-2">Zip</Typography>
                <Grid className="inputField px-3">
                  <Form.Control
                    placeholder="Zip"
                    value={edit?.LocationZip}
                    onChange={(val) =>
                      onChangeHandler(val.target.value, "LocationZip")
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid>
              <CustomButton
                className="button mx-4 py-2 px-5"
                onClick={() => setIsConfirm(true)}
              >
                <Typography>
                  {selected?.LocationID ? "Update" : "Add"}
                </Typography>
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      {isConfirm && (
        <ConfirmModal
          open={isConfirm}
          handleClose={() => {
            setIsConfirm(false);
            onClose();
          }}
          handleDelete={onSubmit}
          title={`${selected?.LocationID ? "Edit" : "Add"} Community`}
          subtext={`Are you sure you want to ${
            selected?.LocationID ? "update" : "add"
          } this Community`}
          primaryText={"Confirm"}
        />
      )}
    </>
  );
};

export default AddCommunityModal;
