import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

export const schedulingRulesSlice = createSlice({
  name: "schedulingRules",
  initialState,
  reducers: {
    addSchedulingRule: (state, action) => {
      state.data = [...state.data, action.payload] || [];
    },
    replaceSchedulingRules: (state, action) => {
      state.data = action.payload.data || [];
      state.count = action.payload.count || 0;
    },
    updateSchedulingRule: (state, action) => {
      const tmpUser = state.data.map((i) => {
        if (i.id == action.payload.id) {
          return action.payload;
        }
        return i;
      });
      state.data = tmpUser || [];
    },
    deleteteSchedulingRule: (state, action) => {
      const tmpUser = state.data.filter((i) => i.id !== action.payload.id);
      state.data = tmpUser || [];
    },
  },
  extraReducers: (builder) => {},
});

export const {
  addSchedulingRule,
  updateSchedulingRule,
  replaceSchedulingRules,
  deleteteSchedulingRule,
} = schedulingRulesSlice.actions;

export default schedulingRulesSlice.reducer;
