import React, { useState, useEffect } from "react";
import Reply_Icon from "../../assets/images/Reply.png";
import Play_Icon from "../../assets/images/Play.png";
import Users_Icon from "../../assets/images/Users.png";
// import Tag_Icon from "../../assets/images/Tag.png";
import Tag from "../Tags/Tag";
import MessageText from "../Text/MessageText";
// import MentionsRenderText from "../Text/MentionsRenderText";
import { Avatar, Grid, Typography, Tooltip } from "@mui/material";
import "./ChatCard.css";
import clsx from "clsx";
import { chatOptions } from "../../data/listOptions";
import MessageOptions from "./MessageOptions";
import moment from "moment";
import ImageSvg from "../../assets/svgs/ImageSvg";
import BoldString from "../Text/BoldString";
import useChats from "../../hooks/useMessenger";
import DeleteModal from "../Modals/DeleteModal";
import StarIcon from "@mui/icons-material/Star";
import UserSvg from "../../assets/svgs/user";
import TagSvg from "../../assets/svgs/TagSvg";
import WarningSvg from "../../assets/svgs/WarningSvg";

const ChatCard = ({
  data = {},
  onClick,
  isFullScreen,
  selected,
  onSend,
  isMe,
}) => {
  const { isLastMessaged } = data;
  const { removeTag, removePatientTag, userData, getUnreadMessages, count } =
    useChats();
  const [isDelete, setIsDelete] = useState(null);
  const isPending =
    !data?.lastMessage?.Metadata?.length ||
    data?.lastMessage?.Metadata?.find((i) => !i.sentToEHR);
  const time = moment(data?.lastMessage?.createdAt);
  const isToday = time.isSame(moment(), "day");
  const isSentOn = isToday ? time.format("hh:mm A") : time.format("MM/DD/YYYY");
  const isGroup = data?.conversationType === "GROUP";
  const isAdmin = userData.role === "back office";
  // const lastMessage = messages?.length ? messages[messages.length - 1] : {};
  const indexG = data?.lastMessage?.TaggedPatients.map((data) => ({
    startIndex: data.startIndex,
    endIndex: data.endIndex,
    color: "orange",
  }));
  const indexG2 = data?.lastMessage?.TaggedUsers.map((data) => ({
    startIndex: data.startIndex,
    endIndex: data.endIndex,
    color: "#3062D4",
  }));
  const indexGroups = [...indexG, ...indexG2].sort(
    (a, b) => a.startIndex - b.startIndex
  );

  const isChatSelected = selected?.conversationId === data?.conversationId;

  const isStarred = data?.lastMessage?.StarredMessages?.find((star) => {
    return (
      +star.userId === +userData.id && +star.messageId === +data?.lastMessage.id
    );
  });

  const isUnread =
    !data.lastMessage.fromMe &&
    moment(data.updatedAt).isBefore(data.lastMessage.createdAt);

  useEffect(() => {
    isUnread && getUnreadMessages(data);
  }, []);

  // console.log(data, "data");
  const onRemoveTag = () => {
    const { type, tag } = isDelete;
    setIsDelete(null);
    const payload = {
      // patientId,
      messageId: data?.lastMessage.messageId,
      conversationId: data?.lastMessage.conversationId,
    };
    if (type === "tag") {
      removeTag({ ...payload, id: tag.id });
    } else if (type === "patient-tag") {
      removePatientTag({ ...payload, id: tag.id });
    }
  };

  const isAccessible =
    userData.role === "back office" &&
    ["Admin", "Scheduler"].includes(userData.position);

  const isTags = !!data.lastMessage?.Metadata?.length;
  const isSentToEHR =
    isTags && data.lastMessage?.Metadata?.find((i) => i.sentToEHR);

  return (
    <Grid
      role="button"
      className={clsx(
        "d-flex p-2 w-100 main border-top",
        isFullScreen &&
          selected?.conversationId === data?.conversationId &&
          "selectedChat"
      )}
      onClick={() => isFullScreen && onClick(data)}
    >
      <Grid>
        <Avatar
          size={"md"}
          className="my-3"
          src={isGroup ? data.conversationImg : data.contactImg}
        />
      </Grid>
      <Grid className="mx-2 position-relative w-100">
        <Grid
          className={"d-flex py-1 align-items-center justify-content-between"}
        >
          <Grid className={clsx("d-flex align-items-center")}>
            <Typography
              className={clsx(
                "title-part-1 mr-2",
                isLastMessaged && !isFullScreen && "lastMessaged"
              )}
            >
              {isGroup
                ? data?.conversationName
                : data?.contactFN + " " + data?.contactLN}
            </Typography>
            {!isFullScreen && data?.lastMessage?.createdAt && (
              <Typography className="font-14 time">
                &nbsp; ·
                {isStarred && (
                  <StarIcon className="star-icon text-black mx-1" />
                )}
                &nbsp;
                {isSentOn}
              </Typography>
            )}
          </Grid>
          <Grid>
            {isFullScreen && data?.lastMessage?.createdAt && (
              <Typography className="font-14 time">{isSentOn}</Typography>
            )}
            {!isFullScreen && (
              <MessageOptions
                options={chatOptions}
                message={data.lastMessage}
                data={data}
                noSendToAthena
              />
            )}
          </Grid>
        </Grid>
        <Grid className="d-flex align-items-center justify-content-between">
          {
            data?.lastMessage && (
              <Typography
                className={`two-line-text ${
                  isUnread && isFullScreen && !!count?.[data.conversationId]
                    ? "w-90"
                    : ""
                }`}
              >
                <span
                  className={`font-14 bold-600 ${
                    !isFullScreen || isChatSelected ? "primary" : "time"
                  }`}
                >
                  {isMe
                    ? "You:"
                    : `${data?.lastMessage?.User?.firstName || ""}:`}
                </span>
                &nbsp;
                {data?.lastMessage?.File && <ImageSvg color="#666" />}
                &nbsp;
                <BoldString
                  text={data?.lastMessage?.messageBody}
                  indexGroups={indexGroups}
                  textColor={
                    isFullScreen && !isChatSelected && "rgba(74, 84, 94, 0.6)"
                  }
                />
              </Typography>
            )
            // ))
          }
          {isUnread && isFullScreen && !!count?.[data.conversationId] && (
            <Grid className="unread">{count?.[data?.conversationId] || 0}</Grid>
          )}
        </Grid>

        {!isFullScreen && (
          <Grid className="d-flex py-2 justify-content-between">
            <Grid className="d-flex">
              <Tooltip title="Reply">
                <button
                  onClick={() => onClick(data)}
                  className="d-flex btn align-items-center"
                >
                  <img src={Reply_Icon} alt="reply" className="mx-1 icon" />
                  <Typography className="font-14">Reply</Typography>
                </button>
              </Tooltip>
              {data?.lastMessage?.isUrgent && (
                <Tag
                  className="mx-1"
                  text={"URGENT"}
                  varient={isSentToEHR ? "urgentSuccess" : "urgent"}
                  // success={isSentToEHR}
                  isBold={true}
                  Icon={WarningSvg}
                  viewBox={"0 0 24 24"}
                />
              )}
              {data?.lastMessage?.Metadata &&
                Array.isArray(data?.lastMessage?.Metadata) &&
                data?.lastMessage?.Metadata?.map((i, idx) => (
                  <Tag
                    key={idx}
                    className="mx-1"
                    text={i?.action?.slice(1)}
                    varient={isSentToEHR ? "" : "metatag"}
                    success={isSentToEHR}
                    isBold={true}
                    onClose={() => setIsDelete({ tag: i, type: "tag" })}
                    isRem={!isSentToEHR && isAccessible}
                    Icon={TagSvg}
                    viewBox={"0 0 24 24"}
                  />
                ))}
              {data?.lastMessage?.TaggedPatients &&
                Array.isArray(data?.lastMessage?.TaggedPatients) &&
                data?.lastMessage?.TaggedPatients?.map((i, idx) => (
                  <Tag
                    key={idx}
                    className="mx-1"
                    text={data?.lastMessage?.messageBody?.slice(
                      i.startIndex,
                      i.endIndex
                    )}
                    varient={isSentToEHR ? "patientSuccess" : "patient"}
                    // success={isSentToEHR}
                    isBold={true}
                    onClose={() => setIsDelete({ tag: i, type: "patient-tag" })}
                    // isRem -> isRemovable
                    isRem={!isSentToEHR && isAccessible}
                    Icon={UserSvg}
                  />
                ))}
            </Grid>
            {isAdmin && !!data?.lastMessage?.Metadata?.length && (
              <Tag
                text={!isPending ? "Sent to Athena" : "Send to Athena"}
                success={!isPending}
                varient="grey"
                onClick={onSend}
              />
            )}
          </Grid>
        )}
        {isDelete && (
          <DeleteModal
            open={isDelete}
            handleClose={() => {
              setIsDelete(false);
            }}
            handleDelete={onRemoveTag}
            title={"Remove Tag"}
            subtext={"Are you sure you want to remove this Tag?"}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ChatCard;
