import React, { useEffect, useState } from "react";
import MenuHeader from "../../components/Header/MenuHeader";
import { Divider, Grid, Typography } from "@mui/material";
import "./Settings.css";
import useAuth from "../../hooks/useAuth";
import useMessenger from "../../hooks/useMessenger";
import Loading from "../../components/Loading";
import ContactCard from "../../components/Cards/ContactCard";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const YourCommunity = ({ noHeader }) => {
  const { height } = useWindowDimensions();
  const { userData, loading } = useAuth();
  const { getAllContacts } = useMessenger();
  const { communitylocation } = userData || {};
  const {
    LocationName,
    LocationAddress1,
    LocationCity,
    LocationState,
    LocationCountry,
    LocationZip,
  } = communitylocation || {};
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    getAllContacts().then((res) => setContacts(res.data));
  }, []);

  return (
    <div>
      {!noHeader && <MenuHeader />}
      <Loading loading={loading} />
      <div className="px-4 position-relative">
        <Grid container className="p-3">
          <Grid item sx={12} md={6}>
            <Typography className="px-1 acc-title">Your Community</Typography>
          </Grid>
        </Grid>
        <Grid className="settings-fileds">
          <Grid item xs={12} md={6} container className="p-3 px-4">
            <Grid item xs={12} md={4}>
              <Typography className="acc-field-head">
                Community Name:
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} container>
              <Typography className="acc-field">{LocationName}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} container className="p-3 px-4">
            <Grid item xs={12} md={4}>
              <Typography className="acc-field-head">Address:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography className="acc-field">{`${LocationAddress1 || ""}, ${
                LocationCity || ""
              }, ${LocationState || ""}, ${LocationZip || ""}, ${
                LocationCountry || ""
              }`}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="p-3">
          <Grid>
            <Typography className="px-1 acc-title">Your Contacts</Typography>
          </Grid>
          <Grid
            style={{
              height: height - 420,
              overflowY: "auto",
              margin: "10px 0",
            }}
          >
            {contacts?.map((item, idx) => {
              return (
                <>
                  <ContactCard
                    profile={item.profileImg}
                    label={`${item.firstName || ""} ${item.lastName || ""}`}
                    subLabel={
                      item?.communitylocation
                        ? `${item?.communitylocation?.LocationName}`
                        : item.role === "back office"
                        ? `${item.position}, ${item.role}`
                        : item.position
                    }
                  />
                  {idx !== contacts.length - 1 && (
                    <Divider
                      ml={6}
                      style={{ margin: 10 }}
                      width={"95%"}
                      thickness="0.8"
                    />
                  )}
                </>
              );
            })}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default YourCommunity;
