import * as React from "react";
const FlagSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props?.color || "#000"}
      fillRule="evenodd"
      d="M4.75 2.5c-.69 0-1.25.56-1.25 1.25v7.75h12.236l-3.083-4.045a.75.75 0 0 1 0-.91L15.736 2.5H4.75ZM3.5 13v5.25a.75.75 0 0 1-1.5 0V3.75A2.75 2.75 0 0 1 4.75 1h12.5a.75.75 0 0 1 .597 1.205L14.193 7l3.654 4.796A.75.75 0 0 1 17.25 13H3.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default FlagSvg;
