import * as React from "react";
const CheckSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      fillRule="evenodd"
      d="M10 1a9 9 0 1 0 0 18 9 9 0 0 0 0-18Zm3.844 6.709a.75.75 0 0 0-1.188-.918l-3.7 4.79-1.649-1.833a.75.75 0 0 0-1.114 1.004l2.25 2.5a.75.75 0 0 0 1.15-.043l4.25-5.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CheckSvg;
