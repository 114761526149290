import { createSlice } from "@reduxjs/toolkit";
import { regions } from "../../data";

const initialState = {
  data: [...regions],
};

export const regionsSlice = createSlice({
  name: "regions",
  initialState,
  reducers: {
    addRegion: (state, action) => {
      state.data = [...state.data, action.payload] || [];
    },
    updateRegion: (state, action) => {
      const tmpUser = state.data.map((i) => {
        if (i.id === action.payload.id) {
          return action.payload;
        }
        return i;
      });
      state.data = tmpUser || [];
    },
  },
  extraReducers: (builder) => {},
});

export const { addRegion, updateRegion } = regionsSlice.actions;

export default regionsSlice.reducer;
