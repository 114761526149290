import { AppBar, Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import LeftArrowSvg from "../../assets/svgs/LeftArrowSvg";
// import MessageCard from "../../components/Cards/MessageCard";
import "./MediaFiles.css";
import { TemplateMessage } from "../../components/Cards/MessageCard";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import moment from "moment";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const MediaFiles = ({ handleClick, media, chat }) => {
  const { width } = window.innerWidth;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const imagesData = media.filter((m) => m.File.mimeType === "image");
  const docsData = media.filter((m) => m.File.mimeType === "pdf");

  return (
    <Grid
      sx={{
        width: {
          xs: "300px",
          sm: "300px",
          md: "350px",
        },
        height: "100vh",
        backgroundColor: "#F2F2F2",
      }}
      className="py-3 overflow-hidden"
    >
      <div className={`d-flex align-items-center px-3`}>
        <div role="button" className="close-box " onClick={handleClick}>
          {/* <LeftIcon className="close-icon-1" /> */}
          <LeftArrowSvg className="close-icon-1" />
        </div>
        <Typography className="px-3 add-patients-modal-title group-header">
          Media, Links, Docs
        </Typography>
      </div>
      <Grid className="my-2 group-content">
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            className="main-tab"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#2f80ed", // Customize the active indicator color
              },
            }}
          >
            <Tab className="custom-tab" label="Media" {...a11yProps("0")} />
            <Tab className="custom-tab" label="Docs" {...a11yProps("1")} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          {imagesData?.map((image) => {
            return (
              <img
                alt="bb"
                src={image?.File?.url}
                className="m-1"
                style={{ width: "30%", height: `calc(${width} - 70%)` }}
              />
            );
          })}
          {/* Media */}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {docsData?.map((doc) => {
            return (
              <TemplateMessage
                isSentOn={moment(doc?.File?.createdAt).format("MM/DD/YYYY")}
              >
                <Grid className="d-flex">
                  <InsertDriveFileIcon
                    style={{ width: 50, height: 50, color: "#79909B" }}
                  />
                  <Typography>{doc?.File.name}</Typography>
                </Grid>
              </TemplateMessage>
            );
          })}
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default MediaFiles;
