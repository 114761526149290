import * as React from "react";
const WarningSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "currentColor"}
      //   fill="currentColor"
      fillRule="evenodd"
      d="M11.161 4.147c-.507-.863-1.815-.863-2.323 0L2.672 14.624c-.473.803.101 1.876 1.161 1.876h12.334c1.06 0 1.634-1.073 1.161-1.876L11.161 4.147Zm-3.615-.761c1.088-1.848 3.82-1.848 4.908 0l6.167 10.477C19.727 15.742 18.298 18 16.167 18H3.833c-2.131 0-3.56-2.258-2.454-4.137L7.546 3.386ZM10 6a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 10 6Zm0 6a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-1.5 0v-1.5A.75.75 0 0 1 10 12Z"
      clipRule="evenodd"
    />
  </svg>
);
export default WarningSvg;
