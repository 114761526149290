import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import LeftArrowSvg from "../../assets/svgs/LeftArrowSvg";
import ReferencedPatientCard from "../../components/Cards/ReferencedPatientCard";
import { calculateAge } from "../../utilities/resuseFunctions";
import MessageCard from "../../components/Cards/MessageCard";

const ReferencedPatients = ({ onReferenceClick, referenced, chat }) => {
  const [specific, setSpecific] = useState(null);
  const onSpecificBack = () => setSpecific(!specific);
  return (
    <Grid
      sx={{
        width: {
          xs: "300px",
          sm: "300px",
          md: "350px",
        },
        height: "100vh",
        backgroundColor: "#fff",
      }}
      className="py-3 overflow-hidden"
    >
      {specific ? (
        <>
          <div className={`d-flex align-items-center px-2`}>
            <div role="button" className="close-box " onClick={onSpecificBack}>
              <LeftArrowSvg className="close-icon-1" />
            </div>
            <Typography className="px-3 add-patients-modal-title group-header d-flex">
              {specific?.patientName}{" "}
              <Grid className="unread mx-2">
                {specific?.messages?.length || 0}
              </Grid>
            </Typography>
          </div>
          <Grid className="my-2 px-2 group-content">
            {specific?.messages.map((dataX, ind) => (
              <MessageCard
                key={ind}
                message={{ ...dataX, conversationId: chat.conversationId }}
                data={chat}
                starred={true}
              />
            ))}
          </Grid>
        </>
      ) : (
        <>
          <div className={`d-flex align-items-center px-3`}>
            <div
              role="button"
              className="close-box "
              onClick={onReferenceClick}
            >
              {/* <LeftIcon className="close-icon-1" /> */}
              <LeftArrowSvg className="close-icon-1" />
            </div>
            <Typography className="px-3 add-patients-modal-title group-header">
              Filter by Patients
            </Typography>
          </div>
          <Grid className="my-2 px-2 group-content">
            {(referenced || [])?.map((item, idx) => {
              const patientName =
                item.items[0].patientdemographic.PatientFirstName +
                " " +
                item.items[0].patientdemographic.PatientLastName;
              const patientID = parseInt(item.patientId, 10);
              const messages = item.items.map((data) => data.Message);
              return (
                <ReferencedPatientCard
                  key={idx}
                  message={item.Message}
                  data={chat}
                  patientName={patientName}
                  patientID={patientID}
                  messages={messages}
                  onClick={() =>
                    setSpecific({
                      patientID,
                      patientName,
                      messages,
                    })
                  }
                />
              );
            })}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ReferencedPatients;
