import React, { useEffect, useState } from "react";
import MenuHeader from "../../../components/Header/MenuHeader";
import DataTable from "../../../components/DataTable/DataTable";
import { providerHeader } from "../../../data";
import Name from "../../../components/DataTable/Name";
import IconComponent from "../../../components/DataTable/Icon";
import TableFilter from "../../../components/Filter/TableFilter";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import AddProviderModal from "../../../components/Modals/AddProviderModal";
import useProviders from "../../../hooks/useProviders";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import useDebounce from "../../../hooks/useDebounce";
import {
  capitalizeFirstWords,
  numberToPhone,
} from "../../../utilities/resuseFunctions";
import FilterModal from "../../../components/Modals/FilterModal";
import DeleteModal from "../../../components/Modals/DeleteModal";

const Providers = ({
  noHeader,
  noAdd,
  noSearchFilter,
  title,
  provider,
  noDelete,
}) => {
  // const providers = useSelector((state) => state.providers.data);
  const {
    providers,
    loading,
    handleClose,
    getProviders,
    count,
    deleteProviderApi,
  } = useProviders();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [selected, setSelected] = useState({});
  const [sort, setSort] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const debouncedSearchText = useDebounce(search, 750);

  useEffect(() => {
    getProviders({ search: debouncedSearchText, sort, filter });
  }, [debouncedSearchText, sort, filter]);

  const onAdd = () => setOpen(true);

  const onCancel = () => {
    setOpen(false);
    setIsEdit(false);
    setSelected({});
  };

  const onEdit = (tmp) => {
    setSelected(tmp);
    onAdd();
  };

  const onChangePage = (data, limit) => {
    setPage(data);
    const _limit = limit
      ? limit === "View All"
        ? 0
        : limit
      : rowsPerPage === "View All"
      ? 0
      : rowsPerPage;
    getProviders({
      skip: data * _limit,
      limit: _limit,
      sort,
      filter,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const rows = event.target.value;
    setRowsPerPage(rows);
    onChangePage(0, rows);
  };

  const onFilterClose = () => {
    setOpenFilter(false);
    setFilter(null);
  };

  const onFilterSubmit = (data) => {
    setOpenFilter(false);
    setFilter({ ...data });
  };

  const onFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleDelete = () => {
    deleteProviderApi(selected);
  };

  const generatedRows = providers
    .filter((fil) => (provider ? provider.includes(fil.ProviderID) : true))
    .map((x) => ({
      name: (
        <Name
          hasAvatar
          i={x}
          name={capitalizeFirstWords(`${x?.ProviderFN} ${x?.ProviderLN}`)}
          onClick={() =>
            provider ? onEdit(x) : navigate(`/providers/${x.ProviderID}`)
          }
          avatar={x?.User?.[0]?.profileImg}
        />
      ),
      nameSort: `${x?.ProviderFN} ${x?.ProviderLN}`,
      type: x.ProviderSpecialty,
      region: x.zones?.ZoneName || "-",
      phone: numberToPhone(x.ProviderPhone),
      email: x.ProviderEmail,
      edit: (
        <IconComponent
          icon={"Edit_Icon"}
          i={x}
          onClick={() => {
            onEdit(x);
            setIsEdit(true);
          }}
        />
      ),
      delete: (
        <IconComponent
          icon="Delete_Icon"
          i={x}
          onClick={() => {
            setIsDelete(true);
            setSelected(x);
          }}
        />
      ),
    }));

  return (
    <Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!noHeader && <MenuHeader isSecondary />}
      <Grid className="my-3">
        <TableFilter
          count={generatedRows?.length > 20 ? count : generatedRows.length}
          sectionName={title || "Providers"}
          search={search}
          setSearch={setSearch}
          // isFilter={!noSearchFilter}
          onAdd={onAdd}
          // onAdd={() => navigate("/providers/add")}
          noAdd={noAdd}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          onChangePage={onChangePage}
          rowsPerPage={rowsPerPage}
          onFilterClick={onFilterOpen}
        />
      </Grid>
      <Grid className="mx-4">
        <DataTable
          columns={
            noDelete
              ? providerHeader.filter((f) => f.label !== "DELETE")
              : providerHeader
          }
          rows={generatedRows}
          count={count}
          onChangePage={onChangePage}
          page={page}
          setSort={setSort}
        />
      </Grid>
      {open && (
        <AddProviderModal
          open={open}
          handleClose={onCancel}
          selected={selected}
          isEdited={!provider || isEdit}
        />
      )}
      {openFilter && (
        <FilterModal
          open={openFilter}
          handleClose={onFilterClose}
          selectedFilter={filter}
          onSubmit={onFilterSubmit}
          isProvider
        />
      )}
      {isDelete && (
        <DeleteModal
          open={isDelete}
          handleClose={() => {
            setIsDelete(false);
            setSelected({});
          }}
          handleDelete={handleDelete}
          title={"Delete Provider"}
          subtext={"Are you sure you want to delete this provider?"}
        />
      )}
    </Grid>
  );
};

export default Providers;
