import "./App.css";
import MainRoutes from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/styles";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ErrorBoundary } from "react-error-boundary";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

let theme = createTheme({
  palette: {
    primary: {
      main: "#3062D4",
    },
  },
});

function App() {
  useEffect(() => {
    // Request permission for notifications
    if ("Notification" in window && Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
          <ThemeProvider theme={theme}>
            <ErrorBoundary
              fallback={
                <h1 className="d-flex justify-content-center align-items-center vh-100">
                  Something went wrong !
                </h1>
              }
            >
              <MainRoutes />
              <ToastContainer />
            </ErrorBoundary>
          </ThemeProvider>
        </DndProvider>
      </Provider>
    </div>
  );
}

export default App;
