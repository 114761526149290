import React, { useState } from "react";
import { colors } from "../../styles";
import CustomButton from "../Buttons/CustomButton";
import { Dialog, Grid, IconButton, Typography } from "@mui/material";
// import "./AddPTOModal.css";
import CloseIcon from "@mui/icons-material/Close";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Form } from "react-bootstrap";
import moment from "moment";
import { holidaysOptions } from "../../data/listOptions";
import dayjs from "dayjs";

const AddPTOModal = ({ open: isOpen, handleClose, selected, onSave }) => {
  const [open, setOpen] = useState(isOpen);
  const [startDate, setStartDate] = useState(
    selected?.StartDate && dayjs(selected.StartDate)
  );
  const [endDate, setEndDate] = useState(
    selected?.EndDate && dayjs(selected?.EndDate)
  );
  const [name, setName] = useState(selected?.Name || "");
  const isHoliday = open === "holidays";
  const onClose = () => {
    handleClose(false);
    setOpen(false);
    setStartDate(null);
    setEndDate(null);
    setName("");
  };

  const onSubmit = () => {
    onSave({ StartDate: startDate, EndDate: endDate, Name: name });
    onClose();
  };

  return (
    <Dialog
      open={!!open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid>
          <Grid
            className="d-flex justify-content-between py-2 px-3 align-items-center"
            style={{
              backgroundColor: colors.OFFICE_COLOR,
            }}
          >
            <Typography className="region-modal-title">
              {isHoliday ? "Company Holiday" : "Provider PTO"}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon className="close-icon" />
            </IconButton>
          </Grid>

          {/* {isHoliday && ( */}
          <Grid className="m-2 mx-3 px-3">
            <Typography className="fieldName py-2">
              {isHoliday ? "Holiday Name" : "Reason"}
            </Typography>
            {isHoliday ? (
              <Form.Select
                className="inputField px-2 border-1 w-100"
                value={name}
                onChange={(val) => setName(val.target.value)}
              >
                <option>Select Holiday</option>
                {holidaysOptions?.map((holiday) => {
                  return (
                    <option key={holiday.id} value={holiday.name}>
                      {holiday.name}
                    </option>
                  );
                })}
              </Form.Select>
            ) : (
              <Form.Control
                placeholder={"Reason"}
                className="inputField px-2 border-1 w-100"
                value={name}
                onChange={(val) => setName(val.target.value)}
              />
            )}
          </Grid>
          {/* )} */}
          <Grid className="px-3 py-2">
            <Grid container className="p-3">
              <Grid sm={12} md={6}>
                <Typography>From</Typography>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="From"
                    value={startDate}
                    onChange={(val) => setStartDate(val)}
                  />
                </DemoContainer>
              </Grid>
              <Grid sm={12} md={6}>
                <Typography>To</Typography>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="To"
                    value={endDate}
                    onChange={(val) => setEndDate(val)}
                  />
                </DemoContainer>
              </Grid>
            </Grid>

            <Grid className="d-flex justify-content-center pt-2">
              <CustomButton className="button px-5 py-2" onClick={onSubmit}>
                <Typography>Save</Typography>
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Dialog>
  );
};

export default AddPTOModal;
