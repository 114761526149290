import React, { useRef, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Avatar, IconButton, Typography } from "@mui/material";
import {
  avatarName,
  createImageWithAlphabets,
  groupedVisits,
} from "../../utilities/resuseFunctions";
import "./MapsComponent.css";
import MessageSvg from "../../assets/svgs/MessageSvg";
import ChatModal from "../Modals/ChatModal";
import { useDispatch, useSelector } from "react-redux";
import useProviders from "../../hooks/useProviders";
import useMessenger from "../../hooks/useMessenger";
import moment from "moment";
import {
  changeAppointmentOpen,
  changeIsOpen,
  focusMarker,
} from "../../redux/reducers/selections.reducer";
import CloseSvg from "../../assets/svgs/CloseSvg";
import Home from "../../assets/images/home_light.png";

const newGrpImg = "https://caresms.s3.us-east-2.amazonaws.com/defaultGroup.png";

const MapsComponent = ({ selectedRegion, data }) => {
  const dispatch = useDispatch();
  const { height } = useWindowDimensions();
  const { visits } = useProviders(true);
  const selections = useSelector((state) => state.selections);
  const isMessengerView = selections.type === "Messenger";
  const selectedVisit = selections.visit;
  const [open, setOpen] = useState(false);
  // const [chatActive, setChatActive] = useState({});
  const coords = {
    lat: +selectedRegion?.ZoneLatitude,
    lng: +selectedRegion?.ZoneLongitude,
    zoom: selectedRegion?.Zoom,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_API_KEY,
  });

  // console.log(data, "data");
  const mapRef = useRef(null);
  const [map, setMap] = React.useState(null);
  const {
    createPrivateConversation,
    setCurrentChat,
    currentChat,
    newGroupCreationEmit,
    userData,
  } = useMessenger();
  // const [isOpen, setIsOpen] = React.useState(null);
  const { isOpen, appointmentOpen } = selections;

  const setIsOpen = (id, latlng) => {
    if (id) {
      dispatch(focusMarker({ id, latlng }));
    } else {
      dispatch(changeIsOpen(id));
    }
  };

  const setAppointmentOpen = (id, latlng, seq) => {
    id && dispatch(focusMarker({ id, latlng, seq }));
    dispatch(changeAppointmentOpen(seq));
  };

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(coords);
    // map.fitBounds(bounds);
    // setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const groupedItems = groupedVisits(data?.isProvider, visits);

  const CustomInfoWindow = ({ item, isProvider, isCommunity, zone, color }) => {
    const selected = isProvider
      ? item?.data?.find(
          (visit) =>
            appointmentOpen && appointmentOpen?.includes(visit.SequenceNo)
        )
      : item;

    if (isCommunity && !appointmentOpen && isProvider) {
      return (
        <div className="info-card">
          <Typography className="community-name">{item.Facility}</Typography>
          {item?.data?.map((visit) => {
            const color = visit?.isCompleted ? "#1D7C4D" : "#F3CA50";
            return (
              <button
                className="d-flex align-items-center px-0 btn"
                onClick={() =>
                  setAppointmentOpen(null, zone, `a${visit.SequenceNo}`)
                }
                key={visit.SequenceNo}
              >
                <div>
                  <Avatar
                    style={{ backgroundColor: color }}
                    className="info-avatar"
                    // src={visit?.profileImg}
                  >
                    {visit.SequenceNo}
                  </Avatar>
                </div>
                <div className="mx-2">
                  <h6 className="m-0 bold-medium text-decoration-underline">
                    {visit?.name || `${visit?.PatientName}`}
                  </h6>
                </div>
              </button>
            );
          })}
          <CloseSvg
            role="button"
            className="close-marker"
            color={"#2F80ED"}
            onClick={() => {
              setIsOpen(null);
              setAppointmentOpen(null);
            }}
          />
        </div>
      );
    }

    return (
      <div className="info-card">
        <div>
          {isProvider && (
            <Avatar style={{ backgroundColor: color }} className="info-avatar">
              {selected.SequenceNo}
            </Avatar>
          )}
        </div>
        <div className="d-flex align-items-center">
          <div>
            {!isProvider && (
              <Avatar className="me-2" src={selected?.profileImg}>
                {avatarName(
                  selected?.name ||
                    `${selected?.firstName || ""} ${
                      selected?.lastName || ""
                    }` ||
                    ""
                )}
              </Avatar>
            )}
          </div>
          <div className="py-1">
            <h5 className="m-0">
              {selected?.name ||
                selected?.PatientName ||
                `${selected?.firstName || ""} ${selected?.lastName || ""}`}
            </h5>
            <p className="m-0 py-1">
              {isProvider ? selected?.Facility : selected?.specialty}
            </p>
            {isProvider && (
              <p className="m-0 py-1">{selected?.PatientAddress}</p>
            )}
          </div>
        </div>
        {!isProvider && (
          <div className="info-chat">
            <IconButton
              disabled={true}
              onClick={async () => {
                setOpen(true);
                const privateGroup = await createPrivateConversation([
                  +selected.id,
                ]);
                newGroupCreationEmit([`U${selected.id}`]);
                setCurrentChat({
                  conversationImg: newGrpImg,
                  conversationId: privateGroup.id,
                  createdAt: moment(),
                  isAdmin: false,
                  isCreator: false,
                  updatedAt: moment(),
                  userId: userData.id,
                  conversationName:
                    selected.firstName + " " + selected.lastName,
                  contactFN: selected.firstName,
                  contactLN: selected.lastName,
                  contactImg: selected.profileImg,
                });
              }}
            >
              <MessageSvg color="#2F80ED" />
            </IconButton>
          </div>
        )}
        <CloseSvg
          role="button"
          className="close-marker"
          color={"#2F80ED"}
          onClick={() => {
            setIsOpen(null);
            setAppointmentOpen(null);
          }}
        />
      </div>
    );
  };

  return isLoaded ? (
    <>
      <GoogleMap
        ref={mapRef}
        mapContainerStyle={{
          height: isMessengerView ? height - 230 : height - 250,
          border: "1px solid black",
          borderRadius: "6px",
        }}
        center={coords}
        zoom={coords.zoom || 11}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {/* Child components, such as markers, info windows, etc. */}
        {data?.providers?.map((i, idx) => {
          const lat =
            i?.liveTracking?.latitude ||
            (i?.ProviderLatitude && parseFloat(i?.ProviderLatitude));
          const lng =
            i?.liveTracking?.longitude ||
            (i?.ProviderLongitude && parseFloat(i?.ProviderLongitude));
          const avatar =
            i?.User?.[0]?.profileImg ||
            createImageWithAlphabets(
              i?.ProviderFN?.[0],
              i?.ProviderLN?.[0] || i?.ProviderFN?.[1]
            );
          const zone = {
            ZoneLatitude: lat,
            ZoneLongitude: lng,
          };

          const position = {
            lat,
            lng,
          };

          return (
            <Marker
              key={idx}
              onClick={() => {
                setIsOpen(`p${idx}`, { ZoneLatitude: lat, ZoneLongitude: lng });
              }}
              position={position}
              // icon={iconMarker}
              icon={{
                url:
                  avatar ||
                  `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB6klEQVR4nO2XzUoDMRSFU30ERR9BX8GN4EJXmS7E+gNqIoLPoJg4O8Hcgq6tomv3zlQ3ggo+goWuFdxYRTf+4JVMa1Wk2JlpmxRy4EBgMu13cu+EhBAnJyenn8KATmBI9zCgJQy856qjcUE/I7YKi3QYQ3qJoYf/+ELPJTYJi9kxDOhDE/BV67nF7BixZuXjwNdNK1ZUosm2aRTiCpFkDH+wSeHr7TRhLkBI99MH8ArmAujtMX0FSiYDPLUgwFN3Bwi9R5MBuryFQrqXvgJ011yAbt9GtTDwzlJsoefEkkNcJUHrVDDMDpGuPMxF5yBLDnO/K+FdNNM21qx8ww87urx4198Xmmhs94XGySmFFlY3+7iASS5gmwlVZEKVuYR7JtWrdjQWqqyf6Tl6rn6HmFQud9S7JGCKSThmAt64BIxj/Q6TcKx/w/f9ng6iY4ZvbM3UVjkWdOMwqrwoYLrt6MtrO4NMqNNWgfO/Ppn3YaAt8IvrMMoE3LQRHiMLdcdlfryl8EzAbJI+T95S8Kb/syXwSxv5OS7Ve6fged3qPXWIBalGuICXzsNDtRJSverWTQS/4uf7uVS3puD5dyVuNUvsAEyoQ/Pw8OWD2AG4VB8WgGOtCh8JApiGhl92AbirALgWcnJyciLW6RPglref6aHf7QAAAABJRU5ErkJggg==`,
                scaledSize: new window.google.maps.Size(30, 30),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(25, 25),
              }}
            >
              {isOpen === `p${idx}` && (
                <InfoWindow
                  position={position}
                  onCloseClick={() => {
                    new window.google.maps.InfoWindow();
                    setIsOpen(null);
                  }}
                  options={{ closeBoxURL: "" }}
                >
                  <CustomInfoWindow
                    item={{
                      id: i.ProviderID,
                      firstName: i.ProviderFN,
                      lastName: i.ProviderLN,
                      profileImg: i?.User?.[0]?.profileImg,
                      specialty: i?.ProviderSpecialty,
                    }}
                    zone={zone}
                  />
                </InfoWindow>
              )}
            </Marker>
          );
        })}
        {data?.isProvider &&
          groupedItems?.map((i, idx) => {
            const icon = i?.isCompleted ? "green-dot" : "yellow-dot";
            const color = i?.isCompleted ? "#1D7C4D" : "#F3CA50";
            const isCommunity = i.data.length > 1;
            const title = isCommunity
              ? `${i.data?.[0].SequenceNo}-${
                  i.data?.[i.data.length - 1].SequenceNo
                }`
              : String(i.data?.[0].SequenceNo);
            const avatar = createImageWithAlphabets(
              // String(idx + 1),
              title,
              null,
              color
            );

            let providerOptions = {};
            if (navigator.geolocation) {
              providerOptions = {
                icon: {
                  url:
                    avatar ||
                    `https://maps.google.com/mapfiles/ms/icons/${icon}.png`, // Replace with desired pin color image URL
                  scaledSize: isCommunity
                    ? new window.google.maps.Size(30, 30)
                    : new window.google.maps.Size(30, 30),
                },
              };
            }
            const lat = i?.PatientLatitude && parseFloat(i?.PatientLatitude);
            const lng = i?.PatientLongitude && parseFloat(i?.PatientLongitude);

            const position = {
              lat: lat && parseFloat(lat),
              lng: lng && parseFloat(lng),
            };

            const zone = {
              ZoneLatitude: lat,
              ZoneLongitude: lng,
            };

            return (
              <Marker
                key={idx}
                onClick={() => {
                  if (!isCommunity) {
                    setAppointmentOpen(
                      `c${idx}`,
                      zone,
                      `a${i.data?.[0].SequenceNo || ""}`
                    );
                  } else {
                    setIsOpen(`c${idx}`, zone);
                  }
                }}
                position={position}
                options={providerOptions}
              >
                {isOpen === `c${idx}` && (
                  <InfoWindow
                    position={position}
                    onCloseClick={() => {
                      setIsOpen(null);
                      setAppointmentOpen(null);
                    }}
                    options={{ closeBoxURL: "" }}
                  >
                    <CustomInfoWindow
                      color={color}
                      item={i}
                      isProvider
                      isCommunity={isCommunity}
                      zone={zone}
                    />
                  </InfoWindow>
                )}
              </Marker>
            );
          })}
        {data?.isProvider &&
          data?.providers?.map((i, idx) => {
            const lat = i?.ProviderLatitude && parseFloat(i?.ProviderLatitude);
            const lng =
              i?.ProviderLongitude && parseFloat(i?.ProviderLongitude);
            const zone = {
              ZoneLatitude: lat,
              ZoneLongitude: lng,
            };

            const position = {
              lat,
              lng,
            };

            return (
              <Marker
                key={idx}
                onClick={() => {
                  setIsOpen(`p${idx}`, {
                    ZoneLatitude: lat,
                    ZoneLongitude: lng,
                  });
                }}
                position={position}
                // icon={iconMarker}
                icon={{
                  url: Home,
                  scaledSize: new window.google.maps.Size(30, 30),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(25, 25),
                }}
              >
                {isOpen === `p${idx}` && (
                  <InfoWindow
                    position={position}
                    onCloseClick={() => {
                      new window.google.maps.InfoWindow();
                      setIsOpen(null);
                    }}
                    options={{ closeBoxURL: "" }}
                  >
                    <CustomInfoWindow
                      item={{
                        id: i.ProviderID,
                        firstName: i.ProviderFN,
                        lastName: i.ProviderLN,
                        profileImg: i?.User?.[0]?.profileImg,
                        specialty: i?.ProviderSpecialty,
                      }}
                      zone={zone}
                    />
                  </InfoWindow>
                )}
              </Marker>
            );
          })}
        {selectedVisit &&
          [selectedVisit]?.map((i, idx) => {
            const icon = "blue-dot";
            const lat = i?.PatientLatitude && parseFloat(i?.PatientLatitude);
            const lng = i?.PatientLongitude && parseFloat(i?.PatientLongitude);
            const zone = {
              ZoneLatitude: lat,
              ZoneLongitude: lng,
            };

            const position = {
              lat,
              lng,
            };

            const title = i.PatientFirstName[0];
            const color = "#2f80ed";
            const avatar = createImageWithAlphabets(title, null, color);

            let providerOptions = {};
            if (navigator.geolocation) {
              providerOptions = {
                icon: {
                  url:
                    avatar ||
                    `https://maps.google.com/mapfiles/ms/icons/${icon}.png`, // Replace with desired pin color image URL
                  scaledSize: new window.google.maps.Size(30, 30),
                },
              };
            }

            return (
              <Marker
                key={idx}
                onClick={() => {
                  setIsOpen(`np${idx}`, {
                    ZoneLatitude: lat,
                    ZoneLongitude: lng,
                  });
                }}
                position={position}
                // icon={iconMarker}
                options={providerOptions}
              >
                {isOpen === `np${idx}` && (
                  <InfoWindow
                    position={position}
                    onCloseClick={() => {
                      new window.google.maps.InfoWindow();
                      setIsOpen(null);
                    }}
                    options={{ closeBoxURL: "" }}
                  >
                    <CustomInfoWindow
                      item={{
                        id: i.PatientID,
                        firstName: i.PatientFirstName,
                        lastName: i.PatientLastName,
                        profileImg: avatar,
                      }}
                      zone={zone}
                    />
                  </InfoWindow>
                )}
              </Marker>
            );
          })}
      </GoogleMap>
      {open && (
        <ChatModal
          open={open}
          handleClose={() => {
            setOpen(false);
          }}
          chat={currentChat}
          onSendMessage={() => {}}
        />
      )}
    </>
  ) : (
    <></>
  );
};

export default React.memo(MapsComponent);
