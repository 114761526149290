import React, { useEffect, useState } from "react";
import MenuHeader from "../../components/Header/MenuHeader";
import { Avatar, Divider, Grid, Typography } from "@mui/material";
import SelectableButton from "../../components/Buttons/SelectableButton";
import CustomButton from "../../components/Buttons/CustomButton";
import "./Settings.css";
import useAuth from "../../hooks/useAuth";
import { numberToPhone } from "../../utilities/resuseFunctions";
import moment from "moment";
import { Form } from "react-bootstrap";
import PhoneInput from "../../components/Input/PhoneInput";
import Loading from "../../components/Loading";

const Settings = ({ noHeader, isCommunity, edit, setEdit }) => {
  const { userData, userLogout, updateUser, loading, uploadDocument } =
    useAuth();
  const [isEdit, setIsEdit] = useState(edit);
  const [firstName, setFirstName] = useState(userData?.firstName || "");
  const [lastName, setLastName] = useState(userData?.lastName || "");
  const [phone, setPhone] = useState(userData?.phoneNo || "");
  const [password, setPassword] = useState("");
  const [dob, setDob] = useState(userData?.dob ? moment(userData?.dob) : null);

  useEffect(() => {
    setIsEdit(edit);
  }, [edit]);

  const onPrimaryAction = () => {
    if (isEdit) {
      const data = {
        id: userData.id,
        firstName,
        lastName,
        dob,
        phoneNo: phone,
        password,
      };
      updateUser(data).then(() => {
        setIsEdit(false);
        setEdit(false);
      });
    } else {
      userLogout();
    }
  };

  const onAddMedia = () => {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".jpg, .jpeg, .png";
    input.onchange = (_) => {
      // you can use this method to get file and perform respective operations
      let files = Array.from(input.files);
      // console.log(files);
      uploadDocument({
        file: files[0],
      }).then((res) => {
        const payload = {
          id: userData.id,
          profileImg: res?.data?.data?.url,
        };
        updateUser(payload);
      });
    };
    input.click();
  };

  return (
    <div>
      {!noHeader && <MenuHeader />}
      <Loading loading={loading} />
      <div className="px-4 position-relative">
        <Grid container className="p-3">
          <Grid item sx={12} md={6}>
            <Typography className="px-3 acc-title">Account Settings</Typography>
          </Grid>
          <Grid
            item
            sx={12}
            md={6}
            container
            className="px-4 justify-content-end"
          >
            <Grid item sx={12} md={6} className="px-2">
              <SelectableButton
                title={isEdit ? "Cancel" : "Edit Account Settings"}
                className={"p-2"}
                onClick={() => {
                  setIsEdit(!isEdit);
                  setEdit(!isEdit);
                }}
              />
            </Grid>
            {(!isCommunity || isEdit) && (
              <Grid item sx={12} md={6} className="px-2">
                <CustomButton
                  className="d-flex justify-content-center align-items-center w-100 p-2 m-0"
                  onClick={onPrimaryAction}
                >
                  <Typography>{isEdit ? "Save" : "Sign out"}</Typography>
                </CustomButton>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid className="settings-fileds">
          <div className="d-flex align-items-center px-4">
            <Avatar
              className="my-3 acc-avatar"
              alt={`${firstName || ""} ${lastName || ""}`}
              src={userData?.profileImg}
            />
            <Typography
              className="mx-3 acc-change-profile"
              role="button"
              onClick={onAddMedia}
            >
              Change profile picture
            </Typography>
          </div>
          <Grid item xs={12} md={6} container className="p-3 px-4">
            <Grid item xs={12} md={4}>
              <Typography className="acc-field-head">Name:</Typography>
            </Grid>
            <Grid item xs={12} md={8} container>
              {isEdit ? (
                <>
                  <Grid item xs={12} md={6}>
                    <Form.Control
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} className="px-md-2">
                    <Form.Control
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Grid>
                </>
              ) : (
                <Typography className="acc-field">{`${firstName || ""} ${
                  lastName || ""
                }`}</Typography>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} container className="p-3 px-4">
            <Grid item xs={12} md={4}>
              <Typography className="acc-field-head">Phone:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              {!isEdit ? (
                <Typography className="acc-field">
                  {numberToPhone(phone)}
                </Typography>
              ) : (
                <PhoneInput
                  type="text"
                  name="phone"
                  placeholder="Enter phone number"
                  value={phone}
                  onChange={(val) => setPhone(val)}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} container className="p-3 px-4">
            <Grid item xs={12} md={4}>
              <Typography className="acc-field-head">Email:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography className="acc-field">{userData?.email}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} container className="p-3 px-4">
            <Grid item xs={12} md={4}>
              <Typography className="acc-field-head">Password:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              {!isEdit ? (
                <Typography className="acc-field">xxxxxxxxxxxx</Typography>
              ) : (
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Enter New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              )}
            </Grid>
          </Grid>
          {!isCommunity && (
            <Grid item xs={12} md={6} container className="p-3 px-4">
              <Grid item xs={12} md={4}>
                <Typography className="acc-field-head">DOB:</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                {!isEdit ? (
                  <Typography className="acc-field">
                    {dob ? moment(dob)?.format("MMMM DD, YYYY") : "-"}
                  </Typography>
                ) : (
                  <Form.Control
                    type="date"
                    name="dob"
                    placeholder="Date of birth"
                    value={dob ? moment(dob)?.format("YYYY-MM-DD") : null}
                    onChange={(e) => setDob(e.target.value)}
                  />
                )}
              </Grid>
            </Grid>
          )}
          {!isCommunity && (
            <Grid item xs={12} md={6} container className="p-3 px-4">
              <Grid item xs={12} md={4}>
                <Typography className="acc-field-head">Role:</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography className="acc-field">
                  {userData?.position}
                </Typography>
              </Grid>
            </Grid>
          )}
          {!isCommunity && (
            <Grid item xs={12} md={6} container className="p-3 px-4">
              <Grid item xs={12} md={4}>
                <Typography className="acc-field-head">
                  Notifications:
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography className="acc-field">Email</Typography>
              </Grid>
            </Grid>
          )}
          {!isCommunity && (
            <Grid item xs={12} md={6} container className="p-3 px-4">
              <Grid item xs={12} md={4}>
                <Typography className="acc-field-head">
                  Member since:
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography className="acc-field">-</Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid className="p-3 px-4 mt-auto settings-footer w-100">
          <Divider />
          <Typography
            className="acc-change-profile font-14 py-2 pt-3"
            role="button"
            onClick={() => {
              window.open("https://www.caresms.io/privacy-policy");
            }}
          >
            Privacy
          </Typography>
          <Typography
            className="acc-change-profile font-14 py-2"
            role="button"
            onClick={() => {
              window.open("https://www.caresms.io/hipaa-compliance-security");
            }}
          >
            HIPAA Compliance
          </Typography>
          <Typography className="acc-change-profile font-14 py-2" role="button">
            CareSMS Support
          </Typography>
        </Grid>
      </div>
    </div>
  );
};

export default Settings;
