import React, { useEffect, useState } from "react";
import { Avatar, Grid, Tooltip, Typography } from "@mui/material";
import "./Login2FA.css";
import { Icons } from "../../assets";
import CustomButton from "../../components/Buttons/CustomButton";
import { Form } from "react-bootstrap";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useSpring, animated } from "react-spring";
import Logo from "../../assets/images/CareSMS_Logo.png";
import useAuth from "../../hooks/useAuth";
import Loading from "../../components/Loading";
import PhoneInput from "../../components/Input/PhoneInput";
import {
  convertToNumber,
  isWithin24Hours,
} from "../../utilities/resuseFunctions";

const roles = {
  backoffice: {
    title: "Back Office Sign In",
  },
  provider: {
    title: "Provider Sign In",
  },
  community: {
    title: "Community Sign In",
  },
};

const heads = {
  email: "Back Office Sign In",
  password_creation: "Create a password",
  phone: "Enter your phone number",
  verification: "Verification",
  complete_profile: "Complete your profile",
};

const subheads = {
  email: "Enter your email address",
  password_creation: "Let’s get your account set up!",
  phone: "We’ll use this to verify your identity",
  verification: "Please enter the 6-digit code that we just sent to you",
  complete_profile: "Add a profile picture and your date of birth",
};

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get("token");
  const role = urlParams.get("role");
  const {
    onboardUser,
    loading,
    error,
    isValidInvite,
    isUserExist,
    verifyOTP,
    sendOTP,
    updateUser,
    uploadDocument,
    userData,
    updateAlertNote,
  } = useAuth();
  const { width } = useWindowDimensions();
  const [login, setLogin] = useState("backoffice");
  const [step, setStep] = useState("email");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [invalid, setInvalid] = useState("");
  const [error1, setError1] = useState("");
  const [data, setData] = useState(null);
  const props = useSpring({
    position: "relative",
    left: login ? 0 : width / 2,
  });

  const landingProps = useSpring({
    position: "relative",
    left: login ? -(width / 2) : 0,
  });

  useEffect(() => {
    isValidInvite({ code: token })
      .then((res) => {
        const isValid = isWithin24Hours(res.createdAt);
        if (!isValid) {
          setInvalid("Token Expired");
        }
      })
      .catch((err) => setInvalid(err?.response?.data?.error));
  }, []);

  const onBack = () => {
    if (login && step === "email") {
      setLogin("email");
    }
    if (login && step === "password_creation") {
      setStep("email");
    }
    if (login && step === "phone") {
      setStep("password_creation");
    }
    if (login && step === "verification") {
      setStep("phone");
    }
    if (login && step === "complete_profile") {
      setStep("verification");
    }
  };

  const onComplete = () => {
    if (userData?.role === "back office") {
      navigate("/home", { replace: true });
    } else {
      updateAlertNote(
        "Sign up completed use IOS app for Community and Provider roles",
        "success"
      );
    }
  };

  const onAddMedia = () => {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".jpg, .jpeg, .png";
    input.onchange = (_) => {
      // you can use this method to get file and perform respective operations
      let files = Array.from(input.files);
      // console.log(files);
      uploadDocument({
        file: files[0],
      }).then((res) => {
        const payload = {
          id: userData.id,
          profileImg: res?.data?.data?.url,
        };
        updateUser(payload);
      });
    };
    input.click();
  };

  const onLogin = () => {
    if (step === "email") {
      const payload = {
        email: email,
        role: role,
      };
      isUserExist(payload)
        .then((res) => {
          setData(res.data.data);
          setStep("password_creation");
        })
        .catch((error) => {
          console.error("Error checking user status:", error);
        });
    }
    if (step === "password_creation") {
      if (password === confirmPassword) {
        setError1("");
        setStep("phone");
      } else {
        setError1("Password does not match");
      }
    }
    if (step === "phone") {
      const phoneNo = convertToNumber(phone);
      sendOTP({ phone: phoneNo, email }).then(() => {
        setStep("verification");
      });
    }
    if (step === "verification") {
      const phoneNo = convertToNumber(phone || data?.phoneNo);
      verifyOTP({ phone: phoneNo, code: otp }).then(() => {
        const payload = {
          id: data.id,
          email,
          phoneNo: phone,
          password,
          token,
        };
        onboardUser(payload, true).then(() => {
          setStep("complete_profile");
        });
      });
    }
    if (step === "complete_profile") {
      const payload = {
        id: data.id,
        profileImg,
        dob,
      };
      updateUser(payload).then((res) => {
        onComplete();
      });
    }
  };

  const onResendOtp = () => {
    const phoneNo = convertToNumber(phone || "");
    sendOTP({ phone: phoneNo, email });
  };

  if (invalid) {
    return (
      <div>
        <p>{invalid}</p>
      </div>
    );
  }

  return (
    <Grid container className="vh-100">
      <Loading loading={loading} />
      <Grid item xs={12} md={12} style={{ overflow: "hidden" }}>
        {/* {login ? ( */}
        <animated.div
          style={props}
          className={"d-flex flex-column justify-content-between vh-100"}
        >
          <div className="d-flex justify-content-between">
            <div>
              {step !== "complete_profile" && (
                <span
                  className="contact-support p-3"
                  role="button"
                  onClick={onBack}
                >
                  {"< Back"}
                </span>
              )}
            </div>
            {step === "complete_profile" && (
              <span
                className="contact-support p-3"
                role="button"
                style={{ fontWeight: 700 }}
                onClick={onComplete}
              >
                Skip this step
              </span>
            )}
          </div>
          <Grid style={{}} className="login-view d-flex justify-content-center">
            <Grid
              style={{ width: "350px" }}
              className="d-grid justify-content-center"
            >
              <div className="d-flex justify-content-center">
                <img src={Logo} alt="logo" className="logo-login" />
              </div>
              <p className="landing-title sign-in-text text-center mb-0">
                {heads[step]}
              </p>
              <p className="text-center mb-0 subhead2">{subheads[step]}</p>
              {(step === "email" || step === "password_creation") && (
                <Grid className="mx-2 my-2">
                  <Grid>
                    <Form.Control
                      placeholder="Work Email Address"
                      value={email}
                      onChange={(val) => setEmail(val.target.value)}
                    />
                  </Grid>
                </Grid>
              )}
              {step === "phone" && (
                <Grid className="mx-2 my-2">
                  <Grid>
                    <PhoneInput
                      placeholder="Enter your phone number"
                      value={phone}
                      onChange={(val) => setPhone(val)}
                    />
                  </Grid>
                </Grid>
              )}
              {step === "verification" && (
                <Grid className="mx-2 my-2">
                  <Grid>
                    <Form.Control
                      placeholder="Enter your verification code"
                      value={otp}
                      onChange={(val) => setOtp(val.target.value)}
                    />
                  </Grid>
                </Grid>
              )}
              {step === "verification" && (
                <p
                  className="color-1 text-center m-0"
                  role="button"
                  onClick={onResendOtp}
                >
                  Didn’t receive a code? Click here to resend one
                </p>
              )}
              {step === "complete_profile" && (
                <Grid className="d-flex justify-content-center">
                  <Tooltip title="Add Photo">
                    <Avatar
                      onClick={onAddMedia}
                      alt="Add Photo"
                      src="/static/images/avatar/1.jpg"
                      sx={{ width: 100, height: 100 }}
                    />
                  </Tooltip>
                </Grid>
              )}
              {step === "complete_profile" && (
                <Grid className="mx-2 my-2">
                  <Form.Control
                    disabled
                    className="my-2"
                    placeholder="John doe"
                    type="text"
                    // value={password}
                    // onChange={(val) => setPassword(val.target.value)}
                  />
                  <Form.Control
                    disabled
                    className="my-2"
                    placeholder="Scheduler"
                    type="text"
                    // value={password}
                    // onChange={(val) => setPassword(val.target.value)}
                  />
                  <Form.Control
                    className="my-2"
                    placeholder="Date of Birth"
                    type="date"
                    value={dob}
                    onChange={(val) => setDob(val.target.value)}
                  />
                </Grid>
              )}
              {/* <Grid className="mx-2 my-2"> */}
              {step === "password_creation" && (
                <Grid className="mx-2 my-2">
                  <Form.Control
                    className="mb-2"
                    placeholder="Create your Password"
                    type="password"
                    value={password}
                    onChange={(val) => setPassword(val.target.value)}
                  />
                  <Form.Control
                    className="my-2 mt-3"
                    placeholder="Confirm your Password"
                    type="password"
                    value={confirmPassword}
                    onChange={(val) => setConfirmPassword(val.target.value)}
                  />
                </Grid>
              )}
              {error && (
                <div>
                  <Typography style={{ color: "red" }} className="font-14 py-1">
                    {error}
                  </Typography>
                </div>
              )}
              {error1 && (
                <div>
                  <Typography style={{ color: "red" }} className="font-14 py-1">
                    {error1}
                  </Typography>
                </div>
              )}
              {/* </Grid> */}
              <CustomButton
                style={{ width: "350px" }}
                onClick={onLogin}
                className={
                  "d-flex justify-content-center align-items-center p-2"
                }
              >
                {/* <img src={Icons.Circle_Icon} alt="close" className="icon1" /> */}
                <Typography className="px-2">
                  {step === "complete_profile"
                    ? "Complete Sign Up"
                    : "Continue"}
                </Typography>
              </CustomButton>
            </Grid>
          </Grid>
          <Grid className="d-flex justify-content-center">
            <p>Powered by Savisoft Systems & Services, Inc.</p>
          </Grid>
        </animated.div>
        {/* // ) : (
        //   <animated.div
        //     style={landingProps}
        //     className={"d-flex flex-column vh-100"}
        //   >
        //     <span className="contact-support p-3">Contact Support</span>
        //     <Grid className="login-view my-auto">
        //       <div className="d-flex justify-content-center">
        //         <img src={Logo} alt="logo" className="logo-login" />
        //       </div>
        //       <p className="landing-title login-text my-2 text-center">
        //         Scheduling and Messaging
        //       </p>

        //       <Grid className="d-flex align-items-center flex-column">
        //         <button
        //           variant="light"
        //           className="btn login-buttons my-3 p-3"
        //           onClick={() => setLogin("backoffice")}
        //         >
        //           Back Office Sign In
        //         </button>
        //         <button
        //           variant="light"
        //           className="btn login-buttons my-3 p-3"
        //           onClick={() => setLogin("provider")}
        //         >
        //           Provider Sign In
        //         </button>
        //         <button variant="light" className="btn login-buttons my-3 p-3">
        //           Community Sign In
        //         </button>
        //       </Grid>
        //     </Grid>
        //     <Grid className="d-flex justify-content-center">
        //       <p>Powered by Savisoft Systems, Inc.</p>
        //     </Grid>
        //   </animated.div>
        // )} */}
      </Grid>
    </Grid>
  );
};

export default Register;
