import React, { useEffect, useState } from "react";
import MenuHeader from "../../../components/Header/MenuHeader";
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { Icons } from "../../../assets";
import { Form, Table } from "react-bootstrap";
import CustomButton from "../../../components/Buttons/CustomButton";
import "./SchedulePolicy.css";
import SpeedMeterSvg from "../../../assets/svgs/SpeedMeterSvg";
import CalendarSvg from "../../../assets/svgs/CalendarSvg";
import FlagSvg from "../../../assets/svgs/FlagSvg";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import useProviders from "../../../hooks/useProviders";
import useSchedulersettings from "../../../hooks/useSchedulerSettings";
import AddPTOModal from "../../../components/Modals/AddPTOModal";
import moment from "moment";
import IconComponent from "../../../components/DataTable/Icon";
import DeleteModal from "../../../components/Modals/DeleteModal";
import useLogs from "../../../hooks/useLogs";
import useAuth from "../../../hooks/useAuth";
import { capitalizeFirstWords } from "../../../utilities/resuseFunctions";
import ConfirmModal from "../../../components/Modals/DeleteModal";

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const SchedulePolicy = () => {
  // const providers = useSelector((state) => state.providers.data);
  const { providers } = useProviders(null, 0);
  const { addLog } = useLogs();
  const { userData } = useAuth();
  // const scheduleSettings = useSelector((state) => state.scheduleSettings.data);
  const {
    schedulerSettings,
    loading,
    addSchedulerSettingApi,
    updateSchedulerSettingApi,
    getHolidays,
    addHoliday,
    updateHoliday,
    deleteHoliday,
    getScheduleSettingById,
  } = useSchedulersettings(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [checked, setChecked] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [pto, setPto] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [visitsPerMonth, setVisitsPerMonth] = useState("");
  // const [name, setName] = useState("");
  const [open, setOpen] = useState(null);
  const [selected, setSelected] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  useEffect(() => {
    const init = async () => {
      // !holidays?.length &&
      //   getHolidays().then((res) => {
      //     setHolidays(res?.data?.data || []);
      //   });
      if (id) {
        let selected = schedulerSettings?.find((i) => i.ProviderID == id);
        if (!selected) {
          const tmpData = await getScheduleSettingById({ Id: id });
          selected = tmpData?.data?.data || {};
        }

        setChecked(
          [
            selected?.workingMonday ? days[0] : undefined,
            selected?.workingTuesday ? days[1] : undefined,
            selected?.workingWednesday ? days[2] : undefined,
            selected?.workingThursday ? days[3] : undefined,
            selected?.workingFriday ? days[4] : undefined,
            selected?.workingSaturday ? days[5] : undefined,
            selected?.workingSunday ? days[6] : undefined,
          ].filter(Boolean)
        );
        setSelectedProvider(selected.ProviderID);
        setPto(selected?.holidays?.filter((i) => i.Type === "PTO") || []);
        setHolidays(selected?.holidays?.filter((i) => i.Type === "GEN") || []);
        setVisitsPerMonth(selected.visitsPerMonth);
        // setName(selected.name);
      }
    };
    init();
  }, [id]);

  const onDaysChange = (e) =>
    setChecked((prev) => {
      if (!e.target.checked) {
        return prev.filter((i) => i !== e.target.name);
      }
      return [...prev, e.target.name];
    });

  const onSubmit = () => {
    if (!checked.length || !selectedProvider || !visitsPerMonth) {
      alert("Please fill all details");
      return;
    }
    // const provider = providers.find((i) => )
    const data = {
      ProviderID: selectedProvider,
      visitsPerMonth: +visitsPerMonth,
      pto,
      workingMonday: checked.includes(days[0]),
      workingTuesday: checked.includes(days[1]),
      workingWednesday: checked.includes(days[2]),
      workingThursday: checked.includes(days[3]),
      workingFriday: checked.includes(days[4]),
      workingSaturday: checked.includes(days[5]),
      workingSunday: checked.includes(days[6]),
      holidays,
    };
    if (id) {
      updateSchedulerSettingApi(data).then(async () => {
        const logPayload = {
          UserID: userData?.id,
          Activity: `Schedule Settings Updated for Provider ID ${id} by User - ${
            userData?.firstName || ""
          } ${userData?.lastName || ""} `,
        };
        await addLog(logPayload);
        navigate("/scheduler/settings");
      });
    } else {
      addSchedulerSettingApi(data).then(async () => {
        const logPayload = {
          UserID: userData?.id,
          Activity: `Schedule Setting Added for Provider ID ${selectedProvider} by User - ${
            userData?.firstName || ""
          } ${userData?.lastName || ""} `,
        };
        await addLog(logPayload);
        navigate("/scheduler/settings");
      });
    }
  };

  const onSave = ({ StartDate, EndDate, Name }) => {
    setOpen(false);
    const setData = open === "holidays" ? setHolidays : setPto;
    const data = open === "holidays" ? holidays : pto;
    const isHoliday = open === "holidays";
    const updated = {
      Id: selected?.Id,
      StartDate: StartDate?.format("MM/DD/YYYY"),
      EndDate: EndDate?.format("MM/DD/YYYY"),
      Name,
      Type: isHoliday ? "GEN" : "PTO",
      ProviderID: selectedProvider,
    };
    if (selected) {
      if (id) {
        updateHoliday(updated).then(async (res) => {
          const logPayload = {
            UserID: userData?.id,
            Activity: `${
              isHoliday ? "Company Holiday" : "PTO"
            } - ${Name} is Updated for Provider ID ${selectedProvider} by User - ${
              userData?.firstName || ""
            } ${userData?.lastName || ""} `,
          };
          await addLog(logPayload);
          setData((prev) =>
            prev.map((i) => {
              if (i.Id === selected.Id) {
                return updated;
              }
              return i;
            })
          );
        });
      } else {
        setData((prev) =>
          prev.map((i) => {
            if (i.Id === selected.Id) {
              return updated;
            }
            return i;
          })
        );
      }
    } else {
      if (id) {
        addHoliday(updated).then(async (res) => {
          const logPayload = {
            UserID: userData?.id,
            Activity: `${
              isHoliday ? "Company Holiday" : "PTO"
            } - ${Name} is Added for Provider ID ${selectedProvider} by User - ${
              userData?.firstName || ""
            } ${userData?.lastName || ""} `,
          };
          await addLog(logPayload);
          setData((prev) => [...prev, res?.data?.data]);
        });
      } else {
        setData((prev) => [...prev, { ...updated, Id: data?.length + 1 }]);
      }
    }
  };

  const handleClose = () => {
    setIsDelete(false);
    setSelected(null);
  };

  const handleDelete = () => {
    handleClose();
    const setData = selected?.Type === "PTO" ? setPto : setHolidays;
    if (!id) {
      setData((prev) => prev.filter((i) => i.Id !== selected.Id));
      return;
    }
    deleteHoliday({
      Id: selected?.Id,
    }).then(async () => {
      const logPayload = {
        UserID: userData?.id,
        Activity: `${
          selected?.Type === "holidays" ? "Company Holiday" : "PTO"
        } - ${
          selected?.Name
        } is Deleted for Provider ID ${selectedProvider} by User - ${
          userData?.firstName || ""
        } ${userData?.lastName || ""} `,
      };
      await addLog(logPayload);
      setData((prev) => prev.filter((i) => i.Id !== selected.Id));
    });
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MenuHeader />
      <Grid className="mx-4 px-2">
        <Typography className="schedule-text schedule-policy-title">
          Provider Schedule Settings
        </Typography>
        <div className="d-flex align-items-center my-2 py-2">
          <div
            role="button"
            className="close-box "
            //   onClick={handleClose}
          >
            <img src={Icons.Add_Icon} alt="add" className="icon" />
          </div>
          <Typography className="mx-2 schedule-text">
            Select Provider:
          </Typography>
          <div>
            <Form.Select
              disabled={!!id}
              aria-label="Default select example"
              value={selectedProvider}
              onChange={(e) => {
                setSelectedProvider(e.target.value);
              }}
            >
              <option value={""}>Select Provider</option>
              {providers
                ?.filter((fil) => {
                  return !schedulerSettings.find((x) => {
                    return id ? false : x.ProviderID === fil.ProviderID;
                  });
                })
                .map((i) => {
                  return (
                    <option key={i.id} value={i.ProviderID}>
                      {capitalizeFirstWords(
                        `${i.ProviderFN} ${i.ProviderLN || ""}`
                      )}
                    </option>
                  );
                })}
            </Form.Select>
          </div>
        </div>
        <div className="d-flex align-items-center  py-2">
          <div
            role="button"
            className="close-box "
            //   onClick={handleClose}
          >
            <SpeedMeterSvg color="#272E35" />
          </div>
          <Typography className="mx-2 schedule-text">
            Target Monthly Visits:
          </Typography>
          <div>
            <Form.Control
              placeholder="Visits"
              value={visitsPerMonth}
              onChange={(e) => setVisitsPerMonth(e.target.value)}
            />
          </div>
        </div>
        <div className="d-flex align-items-center py-2">
          <div
            role="button"
            className="close-box "
            //   onClick={handleClose}
          >
            <CalendarSvg color="#272E35" />
          </div>
          <Typography className="mx-2 schedule-text">Working days:</Typography>
          <div>
            <FormGroup row>
              {days?.map((day, i) => {
                return (
                  <FormControlLabel
                    key={i}
                    label={day}
                    name={day}
                    control={
                      <Checkbox
                        defaultChecked={checked?.includes(day)}
                        checked={checked?.includes(day)}
                        onChange={onDaysChange}
                      />
                    }
                  />
                );
              })}
            </FormGroup>
          </div>
        </div>
        <div className="py-2">
          <div className="d-flex align-items-center">
            <div
              role="button"
              className="close-box "
              //   onClick={handleClose}
            >
              <FlagSvg color="#272E35" />
            </div>
            <Typography className="mx-2 schedule-text">
              Provider PTO Days:
            </Typography>
            <CustomButton
              className={"px-4 py-1 font-16"}
              onClick={() => {
                setOpen("pto");
              }}
            >
              Add
            </CustomButton>
          </div>
          {!!pto?.length && (
            <div className="d-flex my-3 ">
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                {pto?.map((i) => {
                  return (
                    <tr>
                      <td className="px-2 border-1">{i.Name}</td>
                      <td className="px-2 border-1">
                        {moment(i.StartDate?.slice(0, 10)).format("YYYY-MM-DD")}
                      </td>
                      <td className="px-2 border-1">
                        {moment(i.EndDate?.slice(0, 10)).format("YYYY-MM-DD")}
                      </td>
                      <td className="px-2 border-1">
                        {" "}
                        <IconComponent
                          icon="Edit_Icon"
                          i={i}
                          key={i.Id}
                          onClick={() => {
                            setSelected(i);
                            setOpen("pto");
                          }}
                        />
                      </td>
                      <td className="px-2 border-1">
                        {" "}
                        <IconComponent
                          icon="Delete_Icon"
                          i={i}
                          key={i.Id}
                          onClick={() => {
                            setSelected(i);
                            setIsDelete(true);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </Table>
            </div>
          )}
        </div>
        <div className="py-2">
          <div className="d-flex align-items-center">
            <div
              role="button"
              className="close-box "
              //   onClick={handleClose}
            >
              <FlagSvg color="#272E35" />
            </div>
            <Typography className="mx-2 schedule-text">
              Company Holidays:
            </Typography>
            <CustomButton
              className={"px-4 py-1 font-16"}
              onClick={() => {
                setOpen("holidays");
              }}
            >
              Add
            </CustomButton>
          </div>
          {!!holidays?.length && (
            <div className="d-flex my-3">
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Holiday Name</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                {holidays?.map((i) => {
                  return (
                    <tr>
                      <td className="px-2 border-1">{i.Name}</td>
                      <td className="px-2 border-1">
                        {moment(i.StartDate?.slice(0, 10)).format("YYYY-MM-DD")}
                      </td>
                      <td className="px-2 border-1">
                        {moment(i.EndDate?.slice(0, 10)).format("YYYY-MM-DD")}
                      </td>
                      <td className="px-2 border-1">
                        {" "}
                        <IconComponent
                          icon="Edit_Icon"
                          i={i}
                          key={i.Id}
                          onClick={() => {
                            setSelected(i);
                            setOpen("holidays");
                          }}
                        />
                      </td>
                      <td className="px-2 border-1">
                        {" "}
                        <IconComponent
                          icon="Delete_Icon"
                          i={i}
                          key={i.Id}
                          onClick={() => {
                            setSelected(i);
                            setIsDelete(true);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </Table>
            </div>
          )}
        </div>
        <Grid container item md={6} xs={12} className="mb-3">
          <Grid item md={6} xs={12} className="px-3 my-sm-2 my-md-0">
            <CustomButton
              className={"p-2 w-100 m-0"}
              onClick={() => {
                setIsConfirm(true);
              }}
            >
              <Typography className="font-14">
                {id ? "Update" : "Save"}
              </Typography>
            </CustomButton>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            className="px-3"
            onClick={() => {
              navigate("/scheduler/settings");
            }}
          >
            <button className="btn button-secondary p-2 w-100">Cancel</button>
          </Grid>
        </Grid>
        {open && (
          <AddPTOModal
            open={open}
            selected={selected}
            onSave={onSave}
            handleClose={() => setOpen(null)}
          />
        )}
        {isDelete && (
          <DeleteModal
            open={isDelete}
            handleDelete={handleDelete}
            handleClose={handleClose}
            title={`Delete ${selected?.Type === "PTO" ? "" : "Holiday"}`}
            subtext={`Are you sure you want to delete this ${
              selected?.Type === "PTO" ? "PTO" : "Holiday"
            } - ${selected?.Name}?`}
          />
        )}
        {isConfirm && (
          <ConfirmModal
            open={isConfirm}
            handleClose={() => {
              setIsConfirm(false);
            }}
            handleDelete={onSubmit}
            title={`${id ? "Edit" : "Add"} Provider Schedule Setting`}
            subtext={`Are you sure you want to ${
              id ? "update" : "add"
            } this Provider Schedule Setting`}
            primaryText={"Confirm"}
          />
        )}
      </Grid>
    </div>
  );
};

export default SchedulePolicy;
