import React, { useState } from "react";
import {
  Dialog,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import "./AddVisitModal.css";
import UserSvg from "../../assets/svgs/user";
import moment from "moment";
import useProviders from "../../hooks/useProviders";
import Loading from "../Loading";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Button, Form } from "react-bootstrap";
import Bottom_Arrow from "../../assets/images/Bottom_Arrow.png";
import { appointmentTypes } from "../../data/listOptions";
import SelectableButton from "../Buttons/SelectableButton";
import useAuth from "../../hooks/useAuth";
import ConfirmModal from "./DeleteModal";
import { updateVisit } from "../../redux/reducers/selections.reducer";
import useNotifications from "../../hooks/useNotifications";
import { NotificationType } from "../../constants/types";
import InfoModal from "./InfoModal";

const AddVisitModal = ({
  isOpen,
  onClose,
  onSubmit,
  data,
  eligibleVisits,
  total,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(isOpen);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isAlert, setIsAlert] = useState("");
  const [selected, setSelected] = useState(null);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [type, setType] = useState("Normal");
  const [patientType, setPatientType] = useState("All");
  const [appointmentType, setAppointmentType] = useState(null);
  const [regionAnchor, setRegionAnchor] = useState(null);
  const {
    createAppointment,
    loading,
    // getEligibleSchedules,
    getSchedulesApi,
    getCalendarSchedules,
    getIsPublishEnabled,
  } = useProviders(true);
  const { userData } = useAuth();
  const { createNotificationApi } = useNotifications(true);
  const { date } = useSelector((state) => state.selections);
  const isCommunity = userData.role === "COMMUNITY";

  const isDisabled =
    (type !== "Normal" ? !appointmentDate : false) ||
    !appointmentType ||
    !selected;

  const handleClose = () => {
    setOpen(false);
    dispatch(updateVisit(null));
    onClose();
  };

  const handleSubmit = () => {
    if (type !== "Normal") {
      setIsAlert("Ad-Hoc appointment is in development");
      return;
    }
    if (!appointmentType) {
      setIsAlert("Please select Appointment Type");
      return;
    }
    if (type !== "Normal" && !appointmentDate) {
      setIsAlert("Please select Appointment Date");
      return;
    }
    let appDate = date
      ? moment(date).format("YYYY-MM-DD")
      : moment(selected?.providerschedule?.[0]?.VisitDate?.slice(0, 10)).format(
          "YYYY-MM-DD"
        );
    if (type !== "Normal") {
      appDate = appointmentDate
        ? moment(appointmentDate).format("YYYY-MM-DD")
        : moment(
            selected?.providerschedule?.[0]?.VisitDate?.slice(0, 10)
          ).format("YYYY-MM-DD");
    }
    dispatch(updateVisit(null));
    createAppointment({
      appointmentdate: appDate,
      providerid: data.ProviderID,
      patientid: selected.PatientID,
      ScheduleID: selected?.providerschedule?.[0]?.ID,
      // AppointmentID: selected.AppointmentID,
      SequenceNo: total + 1,
      LastVisitDate: selected?.providerschedule?.[0]?.LastVisitDate,
      Rotation: selected?.providerschedule?.[0]?.Rotation,
      TargetVisitDate: selected?.providerschedule?.[0]?.TargetVisitDate,
      Slack: selected?.slack || selected?.providerschedule?.[0]?.Slack,
      VisitType: appointmentType || "Primary Follow up",
      CommunityID: selected?.providerschedule?.[0]?.CommunityID,
      Distance: selected?.providerschedule?.[0]?.Distance,
      DistanceFromHome: selected?.providerschedule?.[0]?.DistanceFromHome,
      updatedBy: +userData?.id,
      type,
      userData,
      prevDate: selected?.providerschedule?.[0]?.VisitDate?.slice(0, 10),
    }).then(async (res) => {
      handleClose();
      createNotificationApi({
        ids: [data.ProviderID],
        type: NotificationType.NEW_APPOINTMENT_ADDED,
        data: null,
        metadata: {
          communityId: selected?.providerschedule?.[0]?.CommunityID || null,
        },
      });
      // getEligibleSchedules({ id: data.ProviderID });
      getIsPublishEnabled({ ProviderID: data.ProviderID });
      const payload = {
        ID: isCommunity ? userData?.communityId : data.ProviderID,
        date: moment(date).format("YYYY-MM-DD"),
        isFor: isCommunity ? "Community" : "Provider",
      };
      getSchedulesApi(payload);
      getCalendarSchedules(payload);
      onSubmit({
        ...selected,
        new: true,
        // AppointmentID: res?.[0]?.appointmentid,
      });
    });
  };

  const patientsList = eligibleVisits
    .filter((i) =>
      patientType === "All" ? true : i.patientType === patientType
    )
    .map((i) => {
      return {
        label:
          i.patientType === "New"
            ? `${i.PatientFirstName || ""} ${i.PatientLastName || ""} |
              ${i?.Facility || ""} |
              ${i?.AddressLine1 || ""}, ${i?.City || ""}, ${i?.State || ""} |
              Added on: ${moment(i.addedOn).format("MMMM DD, YYYY")}
              `
            : i.patientType === "Overdue"
            ? `${i.PatientFirstName || ""} ${i.PatientLastName || ""} |
                ${i?.Facility || ""} |
                ${i?.AddressLine1 || ""}, ${i?.City || ""}, ${i?.State || ""} |
                Overdue by: ${i.slack} days |
                Last seen on: ${moment(i.lastVisitDate).format(
                  "MMMM DD, YYYY"
                )} |
                Last Appointment Type: ${
                  i.providerschedule[i.providerschedule.length - 1].VisitType
                }
              `
            : `${i.PatientFirstName || ""} ${i.PatientLastName || ""} |
              ${i?.Facility || ""} |
              ${i?.AddressLine1 || ""}, ${i?.City || ""}, ${i?.State || ""} |
              ${
                i?.providerschedule?.[0]?.VisitDate &&
                `Currently Scheduled: ${moment(
                  i.providerschedule[0].VisitDate.slice(0, 10)
                ).format("MMMM DD, YYYY")} |`
              }
              ${
                i?.providerschedule?.[i.providerschedule.length - 1]
                  ?.VisitType &&
                `Last Appointment Type: ${
                  i.providerschedule[i.providerschedule.length - 1].VisitType
                }`
              }
            `,
        value: i.PatientID,
        ...i,
      };
    });

  const CustomOption = ({ innerProps, label, data, isFocused, isSelected }) => (
    <div
      {...innerProps}
      className={`custom-option ${isFocused ? "focused" : ""} ${
        isSelected ? "selected" : ""
      }`}
    >
      {label.split("|").map((value, i) => {
        return (
          <Typography className={`${i === 0 ? "bold-600" : "font-14"}`}>
            {value.split(":").length < 2 ? (
              value
            ) : (
              <Grid className="d-flex flex-row gap-1">
                {value.split(":").map((data, i) => (
                  <Typography
                    key={i}
                    className={`${i === 0 ? "bold-medium" : ""}`}
                    style={{
                      fontSize: 14,
                      color: "rgba(0,0,0,0.6)",
                    }}
                  >
                    {data}
                    {i === 0 && ":"}
                  </Typography>
                ))}
              </Grid>
            )}
          </Typography>
        );
      })}
    </div>
  );

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            position: "absolute",
            right: 0,
            margin: 0,
          },
        }}
      >
        <Loading loading={loading} />
        <Grid
          sx={{
            width: {
              xs: "300px",
              sm: "300px",
              md: "450px",
            },
            height: "100vh",
            //   backgroundColor: "#F2F2F2",
          }}
          // className="p-2 px-4"
        >
          <Grid className="p-2 px-4 pt-3">
            <Typography
              role="button"
              onClick={handleClose}
              className="cancel-text-1"
            >
              Cancel
            </Typography>
            <Typography className="add-appointment mt-2">
              Add Appointment
            </Typography>
            <Typography className="add-date mb-2">
              {moment(date).format("MMMM DD, YYYY")}
            </Typography>
            <div className="d-flex align-items-center my-2 mt-3">
              <UserSvg color="#2F80ED" />
              <Typography className="px-2 provider-name">{`${
                data?.ProviderFN || ""
              } ${data?.ProviderLN || ""}`}</Typography>
            </div>
          </Grid>
          <Divider />
          <Grid container className="d-flex px-2">
            <Grid item md={6} xs={12} className="px-2">
              <SelectableButton
                title={"Normal"}
                isSelected={type === "Normal"}
                onClick={() => {
                  setType("Normal");
                }}
              />
            </Grid>
            <Grid item md={6} xs={12} className="px-2 py-2 py-md-0">
              <SelectableButton
                title={"Ad-Hoc"}
                isSelected={type === "Ad-Hoc"}
                onClick={() => {
                  setType("Ad-Hoc");
                }}
              />
            </Grid>
          </Grid>
          <Grid className="mx-3 px-2 mt-4">
            <Grid className="d-flex align-items-center justify-content-between my-2">
              <Typography className="eligible-appointments">Patient</Typography>
              <Grid
                style={{ cursor: "pointer" }}
                onClick={(event) => {
                  setRegionAnchor(event.currentTarget);
                }}
                className="flex-row d-flex align-items-center gap-2 justify-content-center"
              >
                <Typography
                  className="button-active-text pointer-event"
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                  }}
                >
                  {patientType}
                </Typography>
                <img
                  src={Bottom_Arrow}
                  width={10}
                  height={10}
                  alt="Dropdown Arrow"
                  className="object-fit-contain"
                />
              </Grid>
              <Menu
                open={Boolean(regionAnchor)}
                anchorEl={regionAnchor}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                onClose={() => {
                  setRegionAnchor(null);
                }}
              >
                {["All", "Overdue", "New"].map((data, ind) => (
                  <MenuItem
                    key={ind}
                    style={{
                      width: "200px",
                      fontSize: 14,
                    }}
                    onClick={() => {
                      setPatientType(data);
                      setRegionAnchor(null);
                    }}
                  >
                    {data}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
            <Select
              className="basic-single"
              classNamePrefix="select"
              value={selected}
              onChange={(val) => setSelected(val)}
              // isLoading={isLoading}
              isClearable={true}
              // isRtl={isRtl}
              // isSearchable={isSearchable}
              name="Select Patient"
              options={
                patientType === "New"
                  ? patientsList.sort(
                      (x, y) =>
                        new Date(x.addedOn).getTime() -
                        new Date(y.addedOn).getTime()
                    )
                  : patientType === "Overdue"
                  ? patientsList.sort((x, y) => y.slack - x.slack)
                  : patientsList
              }
              components={{ Option: CustomOption }} // Use the custom option component
            />
          </Grid>
          {type === "Ad-Hoc" && (
            <Grid className="mx-3 px-2 mb-2">
              <Grid className="d-flex align-items-center my-2">
                <Typography className="eligible-appointments">Date</Typography>
              </Grid>
              <Form.Control
                type="date"
                value={appointmentDate}
                onChange={(e) => setAppointmentDate(e.target.value)}
              />
            </Grid>
          )}
          <Grid className="mx-3 px-2">
            <Grid className="d-flex align-items-center my-2">
              <Typography className="eligible-appointments">
                Appointment Type
              </Typography>
            </Grid>
            <Form.Select
              value={appointmentType}
              onChange={(e) => setAppointmentType(e.target.value)}
            >
              <option>Select Appointment Type</option>
              {appointmentTypes
                ?.filter((appFil) => appFil.type === type)
                .filter((obj, index) => {
                  return (
                    appointmentTypes
                      ?.filter((appFil) => appFil.type === type)
                      .findIndex((item) => item.id === obj.id) === index
                  );
                })
                ?.map((i) => {
                  return <option value={i.name}>{i.name}</option>;
                })}
            </Form.Select>
          </Grid>
          <Divider />
          <Grid className="p-2 px-4">
            {selected?.ID && (
              <Typography className="my-2 para font-14">
                You are choosing to add this appointment for{" "}
                <span className="highlighted-text">
                  {selected?.PatientName} to{" "}
                  {`${data?.ProviderFN || ""} ${data?.ProviderLN || ""}`}’s
                </span>{" "}
                schedule for{" "}
                <span className="highlighted-text">
                  {moment(date).format("MMMM DD, YYYY")}
                </span>
                . This appointment will be placed last in the Provider’s
                schedule for the day.
              </Typography>
            )}
            {selected?.id && (
              <Typography className="para font-14">
                A notification will be sent to the Provider and the Patient’s
                point of contact, once you confirm this action.
              </Typography>
            )}
            <Grid className="my-2">
              <button
                // disabled={!selected?.ID}
                disabled={isDisabled}
                onClick={() => {
                  setIsConfirm(true);
                  console.log("isConfirm");
                }}
                className="btn button-confirm w-100 my-2"
              >
                Confirm
              </button>
              <button
                onClick={handleClose}
                className="btn button-secondary w-100 my-2"
              >
                Cancel
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      {isConfirm && (
        <ConfirmModal
          open={isConfirm}
          handleClose={() => {
            setIsConfirm(false);
            setOpen(false);
          }}
          handleDelete={handleSubmit}
          title={"Add Appointment"}
          subtext={"Are you sure you want to add this appointment"}
          primaryText={"Confirm"}
        />
      )}
      {!!isAlert && (
        <InfoModal
          open={!!isAlert}
          handleClose={() => {
            setIsAlert("");
          }}
          title={"Info"}
          subtext={isAlert}
        />
      )}
    </>
  );
};

export default AddVisitModal;
