import React from "react";
import SearchComponent from "../Search/Search";
import { Grid, TablePagination, Typography } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import AppointmentButton from "../Buttons/AppointmentButton";
import "./TableFilter.css";

const TableFilter = ({
  count,
  sectionName,
  search,
  setSearch,
  isFilter = false,
  noAdd,
  onAdd,
  buttonText,
  noSearch,
  noSort,
  page,
  onChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
  onFilterClick,
}) => {
  const handleChangePage = (event, newPage) => {
    onChangePage(newPage, rowsPerPage);
  };
  return (
    <Grid
      container
      className="d-flex justify-content-between align-items-center p-3 px-3"
    >
      <Grid item sm={12} md={6} className="d-flex align-items-center">
        <Grid
          className="d-flex align-items-center"
          // item sm={12} md={4}
        >
          <Typography className="px-2 filter-section-name">
            {sectionName}
          </Typography>
          {/* <Typography className="px-2 filter-section-length">
            {length}
          </Typography> */}
        </Grid>
        {!noSearch && (
          <Grid item sm={12} md={8} className="my-3 my-md-0 mx-md-3">
            <SearchComponent
              value={search}
              onChange={setSearch}
              isFilter={isFilter}
            />
          </Grid>
        )}
      </Grid>
      <Grid
        item
        sm={12}
        md={6}
        className="d-flex justify-content-end align-items-center"
      >
        {count > 20 && (
          <div className="d-flex flex-row-reverse align-items-center">
            <TablePagination
              component="div"
              count={count}
              rowsPerPageOptions={[
                10,
                20,
                50,
                100,
                rowsPerPage === "View All" && count,
                "View All",
              ]}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage === "View All" ? count : rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
        {!noSort && (
          <button
            className={"mx-2 btn px-2 sort-button"}
            onClick={onFilterClick}
          >
            <FilterListIcon className="sort-icon" />
          </button>
        )}
        {!noAdd && (
          <AppointmentButton
            title={buttonText || "Add new"}
            className={"px-3 py-2 mx-2"}
            onClick={onAdd}
            textClass="button-text"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default TableFilter;
