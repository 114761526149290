const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const BASE_URL = `${SERVER_URL}/caresms_be`;
export const MESSAGING_BASE_URL = `${SERVER_URL}/messenger`;
export const SOCKET_URL = `${SERVER_URL}`;
export const SCHEDULE_BASE_URL = `${SERVER_URL}/scheduler`;
export const ATHENA_BASE_URL = `${SERVER_URL}/athena`;
// export const BASE_URL = `http://localhost:4000`;
// export const ATHENA_BASE_URL = "http://localhost:4005";
// export const SCHEDULE_BASE_URL = "http://localhost:4002";
// export const MESSAGING_BASE_URL = "http://localhost:8085";
// export const SOCKET_URL = "http://localhost:8085";
