import React from "react";
import Tag from "../Tags/Tag";
import { Grid } from "@mui/material";

const TagComponent = ({ i }) => {
  return (
    <Grid key={i.id} className="p-2 align-align-self-stretch">
      <Tag text={i.Tag} varient="grey" />
    </Grid>
  );
};

export default TagComponent;
