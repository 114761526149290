import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Loading from "../components/Loading";
import { officeRoutes } from "./routesOptions";
import useChats from "../hooks/useMessenger";
import TabNotification from "../screens/TabNotifications/TabNotification";
import useNotifications from "../hooks/useNotifications";
import socket from "../services/socket";
import InfoModal from "../components/Modals/InfoModal";
import useAuth from "../hooks/useAuth";

const MainRoutes = () => {
  const { userData, alertNote, alertType, updateAlertNote } = useAuth();
  const chats = useChats();
  const { getNotifications } = useNotifications(true);

  useEffect(() => {
    // console.log("mounted routes", window.location.pathname);
    const onNotify = (data) => {
      getNotifications();
      if (data?.data?.type === "SCHEDULE_PUBLISHED") {
        updateAlertNote(data.description, "success");
      }
      if (data?.data?.type === "SCHEDULE_PUBLISHED_FAILED") {
        updateAlertNote(data.description, "error");
      }
    };
    socket.on("notify", onNotify);
    return () => {
      socket.off("notify", onNotify);
      // console.log("unmounted routes", window.location.pathname);
    };
  }, [window.location.pathname]);

  useEffect(() => {
    socket.on("newGroupCreated", () => {
      console.log("new group created");
    });
    socket.on("message", (data) => {
      console.log("message", data);
    });
    return () => {
      socket.off("newGroupCreated");
      socket.off("message");
    };
  }, [window.location.pathname]);

  return (
    <BrowserRouter>
      <Routes>
        {officeRoutes.map(({ element: Component, ...i }, index) => {
          return (
            <Route
              key={index}
              {...i}
              element={
                <Suspense fallback={<Loading loading={true} />}>
                  {i.isPublic ? (
                    <Component />
                  ) : (
                    <ProtectedRoute user={userData} data={i}>
                      <Component />
                      <TabNotification />
                    </ProtectedRoute>
                  )}
                </Suspense>
              }
            />
          );
        })}
        <Route
          path="*"
          element={
            <h1 className="d-flex justify-content-center align-items-center vh-100">
              Page Not found !
            </h1>
          }
        />
      </Routes>
      {!!alertNote && (
        <InfoModal
          open={!!alertNote}
          handleClose={() => {
            updateAlertNote();
          }}
          type={alertType}
          title={"Info"}
          subtext={alertNote}
        />
      )}
    </BrowserRouter>
  );
};

export default MainRoutes;

const ProtectedRoute = ({ children, user, data }) => {
  if (!user) {
    return <Navigate to="/" replace />;
  }
  const role = user && data?.access?.find((r) => r.role === user?.role);
  const position = role ? role?.position?.includes(user?.position) : undefined;

  if (!user || (!data.access.includes("*") && (!role || !position))) {
    return (
      <h1 className="d-flex justify-content-center align-items-center vh-100">
        Page Not found !
      </h1>
    );
  }
  return children;
};
