import { useCallback, useEffect, useState } from "react";
import {
  updateNotification,
  replaceNotifications,
} from "../redux/reducers/notifications.reducer";
import { useDispatch, useSelector } from "react-redux";
import { queryString } from "../utilities/resuseFunctions";
import useAuth from "./useAuth";
import MessagingApi from "../services/messagingApi";

export default function useNotifications(noStartup, noData) {
  const dispatch = useDispatch();
  const { userData } = useAuth();
  const { data: notifications, count } = useSelector(
    (state) => state.notifications
  );
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setLoading(false);
    return;
  };
  const handleOpen = () => {
    setLoading(true);
  };

  const getNotifications = useCallback(
    ({ limit = "20", skip = "" } = {}) => {
      handleOpen();
      MessagingApi.get(
        `/notification/?${queryString({
          limit,
          skip,
          receiverId: Number(userData?.id),
        })}`
      ).then((res) => {
        dispatch(replaceNotifications(res?.data || []));
        handleClose();
      });
    },
    [dispatch]
  );

  useEffect(() => {
    (!noStartup || (noData && !notifications.length)) && getNotifications();
  }, []);

  const createNotificationApi = async (data) => {
    handleOpen();
    return await MessagingApi.post(`/notification/create`, data)
      .then((res) => {
        // dispatch(addNotification(res?.data?.data || []));
        handleClose();
        return;
      })
      .catch(() => {
        handleClose();
      });
  };

  const updateNotificationApi = async (payload, data) => {
    handleOpen();
    return await MessagingApi.put(`/notification/seen`, { ...payload })
      .then((res) => {
        dispatch(updateNotification(data || []));
        handleClose();
        return;
      })
      .catch(() => {
        handleClose();
      });
  };

  return {
    notifications,
    updateNotificationApi,
    loading,
    handleClose,
    count,
    getNotifications,
    createNotificationApi,
  };
}
