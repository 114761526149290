import { useCallback, useEffect, useState } from "react";
import api from "../services/api";
import {
  replaceSchedulerSettings,
  addSchedulerSetting,
  updateSchedulerSetting,
  deleteteSchedulerSetting,
} from "../redux/reducers/schedulersettings.reducer";
import { useDispatch, useSelector } from "react-redux";
import { queryString } from "../utilities/resuseFunctions";
import useAuth from "./useAuth";

export default function useSchedulersettings(noStartup) {
  const dispatch = useDispatch();
  const { updateAlertNote } = useAuth();
  const { data: schedulerSettings, count } = useSelector(
    (state) => state.scheduleSettings
  );
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setLoading(false);
  };
  const handleOpen = () => {
    setLoading(true);
  };

  const getScheduleSettings = useCallback(
    ({ limit = "20", skip = "", providerId = "", search } = {}) => {
      handleOpen();
      api
        .get(`/scheduler/settings?${queryString({ limit, skip, search })}`)
        .then((res) => {
          dispatch(replaceSchedulerSettings(res?.data || []));
          handleClose();
        });
    },
    [dispatch]
  );

  const getHolidays = useCallback(async () => {
    return await api.get(`/scheduler/settings/holidays`);
  }, []);

  useEffect(() => {
    !noStartup && getScheduleSettings();
  }, []);

  const getScheduleSettingById = useCallback(async ({ Id }) => {
    handleOpen();
    return await api.get(`/scheduler/settings/${Id}`).then((res) => {
      handleClose();
      return res;
    });
  }, []);

  const addSchedulerSettingApi = async (data) => {
    handleOpen();
    return await api
      .post("/scheduler/settings", data)
      .then((res) => {
        dispatch(addSchedulerSetting(res?.data?.data || []));
        return res;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const addHoliday = async (data) => {
    handleOpen();
    return await api
      .post("/scheduler/settings/holiday", data)
      .then((res) => {
        // dispatch(addSchedulerSetting(res?.data?.data || []));
        updateAlertNote("Schedule Setting Added!", "success");
        return res;
      })
      .catch((err) => {
        updateAlertNote(err?.message, "error");
        throw new Error(err);
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const updateHoliday = async (data) => {
    handleOpen();
    return await api
      .put(`/scheduler/settings/holiday/${data.Id}`, data)
      .then((res) => {
        updateAlertNote("Scheduler Setting updated!", "success");
        return res;
      })
      .catch((err) => {
        updateAlertNote(err?.message, "error");
        throw new Error(err);
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const deleteHoliday = async (data) => {
    handleOpen();
    return await api
      .delete(`/scheduler/settings/holiday/${data.Id}`, data)
      .then((res) => {
        return res;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const updateSchedulerSettingApi = async (data) => {
    handleOpen();
    return await api
      .put(`/scheduler/settings/${data.ProviderID}`, data)
      .then((res) => {
        dispatch(updateSchedulerSetting(res?.data?.data || []));
        handleClose();
        return res;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const deleteSchedulerSettingApi = async (data) => {
    handleOpen();
    return await api
      .delete(`/scheduler/settings/${data.ProviderID}`)
      .then((res) => {
        dispatch(deleteteSchedulerSetting(res?.data?.data || []));
        handleClose();
        return res;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  return {
    schedulerSettings,
    addSchedulerSettingApi,
    updateSchedulerSettingApi,
    deleteSchedulerSettingApi,
    loading,
    handleClose,
    count,
    getScheduleSettings,
    getHolidays,
    addHoliday,
    updateHoliday,
    deleteHoliday,
    getScheduleSettingById,
  };
}
