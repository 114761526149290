import React from "react";

const UsersSvg = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      {...props}
    >
      <path
        fill={props.color || "#000"}
        fillRule="evenodd"
        d="M7 2.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM3 5a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm9.25-3.25A.75.75 0 0 1 13 1a4 4 0 0 1 0 8 .75.75 0 0 1 0-1.5 2.5 2.5 0 0 0 0-5 .75.75 0 0 1-.75-.75ZM1 16.625A5.625 5.625 0 0 1 6.625 11h.75A5.625 5.625 0 0 1 13 16.625 2.375 2.375 0 0 1 10.625 19h-7.25A2.375 2.375 0 0 1 1 16.625ZM6.625 12.5A4.125 4.125 0 0 0 2.5 16.625c0 .483.392.875.875.875h7.25a.875.875 0 0 0 .875-.875A4.125 4.125 0 0 0 7.375 12.5h-.75Zm5.875-.75a.75.75 0 0 1 .75-.75h.125A5.625 5.625 0 0 1 19 16.625 2.375 2.375 0 0 1 16.625 19H15a.75.75 0 0 1 0-1.5h1.625a.875.875 0 0 0 .875-.875 4.125 4.125 0 0 0-4.125-4.125h-.125a.75.75 0 0 1-.75-.75Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default UsersSvg;
