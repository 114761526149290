import * as React from "react";
const LocationSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      fillRule="evenodd"
      d="M14.24 4.279a5.941 5.941 0 0 0-8.48 0c-2.347 2.377-2.347 6.234 0 8.611L10 17.183l4.24-4.293c2.347-2.377 2.347-6.234 0-8.611ZM10 18.25l-.534.527-4.773-4.833c-2.924-2.96-2.924-7.758 0-10.72a7.441 7.441 0 0 1 10.614 0c2.924 2.962 2.924 7.76 0 10.72l-4.773 4.832L10 18.25Zm0 0 .534.526a.752.752 0 0 1-1.068.001L10 18.25ZM10 6.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM7 8a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default LocationSvg;
