import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

export const zonesSlice = createSlice({
  name: "zones",
  initialState,
  reducers: {
    addZone: (state, action) => {
      state.data = [...state.data, action.payload] || [];
    },
    replaceZones: (state, action) => {
      state.data = action.payload.data || [];
      state.count = action.payload.count || 0;
    },
    updateZone: (state, action) => {
      const tmpUser = state.data.map((i) => {
        if (+i.ZoneID === +action.payload.ZoneID) {
          return action.payload;
        }
        return i;
      });
      state.data = tmpUser || [];
    },
    deleteZone: (state, action) => {
      const tmpUser = state.data.filter(
        (i) => +i.ZoneID !== +action.payload.ZoneID
      );
      state.data = tmpUser || [];
    },
  },
  extraReducers: (builder) => {},
});

export const { addZone, updateZone, replaceZones, deleteZone } =
  zonesSlice.actions;

export default zonesSlice.reducer;
