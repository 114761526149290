import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import usersReducer from "./reducers/users.reducer";
import selectionReducer from "./reducers/selections.reducer";
import scheduleReducer from "./reducers/schedules.reducer";
import providersReducer from "./reducers/providers.reducer";
import communitiesReducer from "./reducers/communities.reducer";
import regionsReducer from "./reducers/regions.reducer";
import scheduleSettingsReducer from "./reducers/schedulersettings.reducer";
import patientsReducer from "./reducers/patients.reducer";
import zonesReducer from "./reducers/zones.reducer";
import careTagsReducer from "./reducers/caretags.reducer";
import staffsReducer from "./reducers/staffs.reducer";
import chatsReducer from "./reducers/chats.reducer";
import schedulingRulesReducer from "./reducers/schedulingrules.reducer";
import notificationsReducer from "./reducers/notifications.reducer";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const rootReducer = persistReducer(persistConfig, usersReducer);
const reducer = {
  users: rootReducer,
  selections: selectionReducer,
  schedules: scheduleReducer,
  providers: providersReducer,
  communities: communitiesReducer,
  regions: regionsReducer,
  scheduleSettings: scheduleSettingsReducer,
  patients: patientsReducer,
  zones: zonesReducer,
  caretags: careTagsReducer,
  staffs: staffsReducer,
  chats: chatsReducer,
  schedulingRules: schedulingRulesReducer,
  notifications: notificationsReducer,
};

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export let persistor = persistStore(store);
