import { Icons } from "../../assets";
import EmailIcon from "@mui/icons-material/EmailOutlined";

const menuOptions = [
  {
    id: 1,
    name: "Command Center",
    path: "/home",
    icon: Icons.Communities_Icon1,
    hasSubsections: false,
    access: ["*"],
  },
  {
    id: 2,
    name: "Data Management",
    path: "/",
    icon: Icons.Stats_Icon,
    hasSubsections: true,
    access: [
      {
        role: "back office",
        position: ["Admin", "Scheduler"],
      },
    ],
    subSections: [
      {
        id: 5,
        name: "Providers",
        path: "/providers",
        access: [
          {
            role: "back office",
            position: ["Admin", "Scheduler"],
          },
        ],
      },
      {
        id: 3,
        name: "Patients",
        path: "/patients",
        access: [
          {
            role: "back office",
            position: ["Admin", "Scheduler"],
          },
        ],
      },
      {
        id: 4,
        name: "Communities",
        path: "/communities",
        access: [
          {
            role: "back office",
            position: ["Admin", "Scheduler"],
          },
        ],
      },
      {
        id: 6,
        name: "Zones",
        path: "/regions",
        access: [
          {
            role: "back office",
            position: ["Admin", "Scheduler"],
          },
        ],
      },
      // {
      //   id: 7,
      //   name: "Reporting",
      //   path: "/",
      //    access: [
      //   {
      //     role: "back office",
      //     position: ["Admin", "Scheduler"],
      //   },
      // ],
      // },
      {
        id: 8,
        name: "Rules Editor",
        path: "/schedule/rules",
        access: [
          {
            role: "back office",
            position: ["Admin", "Scheduler"],
          },
        ],
      },
      // {
      //   id: 9,
      //   name: "Reports Builder",
      //   path: "/reports",
      //   access: [
      //     {
      //       role: "back office",
      //       position: ["Admin", "Scheduler"],
      //     },
      //   ],
      // },
    ],
  },

  {
    id: 8,
    name: "Messenger",
    path: "/",
    icon: EmailIcon,
    noIcon: true,
    hasSubsections: true,
    access: ["*"],
    subSections: [
      {
        id: 9,
        name: "Message Center",
        path: "/messagecenter",
        // disabled: true,
        access: ["*"],
      },
      {
        id: 10,
        name: "Manage CareTags",
        path: "/caretags",
        access: [
          {
            role: "back office",
            position: ["Admin", "Scheduler"],
          },
        ],
      },
      {
        id: 11,
        name: "CareTags Glossary",
        path: "/caretags",
        access: ["COMMUNITY"],
      },
    ],
  },
  {
    id: 11,
    name: "Administrator",
    path: "/",
    icon: Icons.Group_Icon,
    hasSubsections: true,
    access: [
      {
        role: "back office",
        position: ["Admin", "Scheduler"],
      },
    ],
    subSections: [
      // {
      //   id: 10,
      //   name: "Patient Care Plans",
      //   path: "/",
      // },
      {
        id: 13,
        name: "Scheduler Settings",
        path: "/scheduler/settings",
        access: [
          {
            role: "back office",
            position: ["Admin", "Scheduler"],
          },
        ],
      },
      {
        id: 11,
        name: "Users",
        path: "/users",
        access: [
          {
            role: "back office",
            position: ["Admin", "Scheduler"],
          },
        ],
      },
      {
        id: 12,
        name: "Data sources",
        path: "/",
        disabled: true,
        access: [
          {
            role: "back office",
            position: ["Admin", "Scheduler"],
          },
        ],
      },
    ],
  },
  {
    id: 12,
    name: "Support",
    path: "/",
    icon: EmailIcon,
    noIcon: true,
    hasSubsections: true,
    access: ["COMMUNITY"],
    subSections: [],
    disabled: true,
  },
];
export default menuOptions;
