import React, { useCallback, useEffect, useState } from "react";
import MenuHeader from "../../../components/Header/MenuHeader";
import IconText from "../../../components/Text/IconText";
import Patients from "../Patients/Patients";
import IconText2 from "../../../components/Text/IconText2";
import { avatarName, numberToPhone } from "../../../utilities/resuseFunctions";
import {
  Avatar,
  Card,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Staff from "../Staff/Staff";
import { useParams } from "react-router-dom";
import AddCommunityModal from "../../../components/Modals/AddCommunityModal";
import CallSvg from "../../../assets/svgs/CallSvg";
import UsersSvg from "../../../assets/svgs/UsersSvg";
import { useNavigate } from "react-router-dom";
import IconComponent from "../../../components/DataTable/Icon";
import ListSvg from "../../../assets/svgs/ListSvg";
import useCommunities from "../../../hooks/useCommunities";
import useZones from "../../../hooks/useZones";
import Providers from "../Providers/Providers";
import usePatients from "../../../hooks/usePatients";
import { Icon } from "@virtuslab/tetrisly-icons";

const CommunityGrid = () => {
  const navigate = useNavigate();
  const { communities, loading, getCommunity } = useCommunities(true);
  const { zones } = useZones(true, true);
  const { Id } = useParams();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("staff");
  const [community, setCommunity] = useState({});
  const [providers, setProviders] = useState([]);

  const { getPatients } = usePatients({
    communityId: Id,
    noInitial: true,
  });

  const initCall = useCallback(() => {
    const community = communities.find((i) => i.LocationID === +Id);
    !community
      ? getCommunity({ communityID: Id }).then((res) => setCommunity(res))
      : setCommunity(community);
  }, [Id]);

  useEffect(() => {
    getPatients({
      communityId: Id,
    }).then((data) => {
      getProviderIds(data);
    });
  }, []);

  useEffect(initCall, [initCall]);

  const onCancel = () => {
    setOpen(false);
  };

  const zone = zones?.find((zone) => zone.ZoneID == community.LocationZoneID);

  const getProviderIds = (patients) => {
    const Ids = [];
    patients.forEach((patient) => {
      Ids.push(
        patient?.PhysicianID,
        patient?.PodiatristID,
        patient?.PsychID,
        patient?.CardioID
      );
    });
    setProviders(Ids);
  };

  return (
    <Grid>
      <MenuHeader isSecondary />
      <Grid container>
        <Grid item sm={12} md={3} className="px-md-4">
          <Card className="p-0 h-100" style={{ position: "relative" }}>
            <Grid className="d-flex justify-content-between p-3">
              <Typography
                className="color-1 font-medium"
                role="button"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </Typography>
              <Tooltip title="Edit">
                <IconButton
                  onClick={() => {
                    setOpen(community);
                  }}
                  style={{ width: 35, height: 35 }}
                >
                  <IconComponent icon={"Edit_Icon"} i={community} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid
              className="d-flex flex-column align-items-center py-2"
              style={{ borderBottom: "1px solid #E9ECEF" }}
            >
              <Avatar
                size={"lg"}
                className="my-1"
                style={{ fontFamily: "Inter" }}
              >
                {avatarName(community?.LocationName || "")}
              </Avatar>
              <Typography
                className="py-1"
                style={{
                  fontSize: 18,
                  fontFamily: "Inter",
                  fontWeight: 600,
                  color: "#333333",
                }}
              >
                {community.LocationName}
              </Typography>
              <Typography
                style={{
                  fontSize: 12,
                  color: "#828282",
                  fontFamily: "Inter",
                }}
                className="mx-2"
              >
                {`${community.LocationAddress1 || ""}, ${
                  community.LocationCity || ""
                }, ${community.LocationState || ""}, ${
                  community.LocationZip || ""
                }`}
              </Typography>
              <Typography
                className="my-1"
                style={{
                  fontSize: 12,
                  color: "#828282",
                  fontFamily: "Inter",
                }}
              >
                {zone?.ZoneName}
              </Typography>
            </Grid>
            <Grid
              className="mx-2"
              style={{
                alignSelf: "flex-start",
              }}
            >
              {community?.LocationPhone && (
                <IconText
                  text={numberToPhone(community.LocationPhone)}
                  Icon={CallSvg}
                  textClass="mx-4"
                  className={"py-2"}
                />
              )}
            </Grid>
            <Grid
              className="align-self-start p-2"
              style={{ borderTop: "1px solid #E9ECEF", flexGrow: 2 }}
            >
              <IconText2
                text="Patients"
                Icon={UsersSvg}
                selected={selected === "patients"}
                onClick={() => setSelected("patients")}
              />
              <IconText2
                text="Providers"
                Icon={() => <Icon name="20-health" />}
                selected={selected === "providers"}
                onClick={() => setSelected("providers")}
              />
              <IconText2
                text="Staff"
                Icon={ListSvg}
                selected={selected === "staff"}
                onClick={() => setSelected("staff")}
              />
            </Grid>
          </Card>
        </Grid>
        <Grid item sm={12} md={9}>
          {selected === "staff" && (
            <Staff noHeader noSearchFilter communityId={Id} />
          )}
          {selected === "patients" && (
            <Patients
              noHeader
              // noAdd
              noSearchFilter
              buttonText="Export"
              onButton={() => {}}
              communityId={Id}
            />
          )}
          {selected === "providers" && (
            <Providers
              noHeader
              noAdd
              title="Providers"
              noSearchFilter
              provider={providers?.filter(Boolean)}
              noDelete
            />
          )}
        </Grid>
      </Grid>
      {open && (
        <AddCommunityModal
          open={open}
          handleClose={onCancel}
          selected={community}
        />
      )}
    </Grid>
  );
};

export default CommunityGrid;
