import React, { useEffect, useState } from "react";
// import { appointments, providers } from "../../data";
import CommandCenterComponent from "./Component/CommandCenterComponent";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import { useLocation, Navigate } from "react-router-dom";
import useProviders from "../../hooks/useProviders";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { changeSelectedProvider } from "../../redux/reducers/selections.reducer";
import useSchedulersettings from "../../hooks/useSchedulerSettings";
import usePatients from "../../hooks/usePatients";

const ProviderView = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { getSchedulesApi, visits, loading, getIsPublishEnabled } =
    useProviders();
  const { patients, getPatients } = usePatients({
    noInitial: true,
  });
  const { schedulerSettings } = useSchedulersettings();
  const { date } = useSelector((state) => state.selections);
  const { data: providers } = useSelector((state) => state.providers);
  const { state } = location;
  const [data, setData] = useState(visits || []);
  const [providersData, setProvidersData] = useState(providers || []);
  const { id, onRegion, type, view } = state || {};

  const pto = schedulerSettings?.find((setting) => +setting.ProviderID === +id);
  const isPTO = pto?.holidays?.find((holiday) => {
    return (
      moment(date).isBetween(
        holiday.StartDate.slice(0, 10),
        holiday.EndDate.slice(0, 10)
      ) ||
      moment(date).isSame(holiday.StartDate.slice(0, 10), "day") ||
      moment(date).isSame(holiday.EndDate.slice(0, 10), "day")
    );
  });

  const eligibleData = patients.filter((visit) => {
    return !data.find((v) => v.PatientID === visit.PatientID);
  });

  useEffect(() => {
    dispatch(changeSelectedProvider(id));
  }, [dispatch]);

  useEffect(() => {
    setData(visits);
  }, [visits]);

  useEffect(() => {
    setProvidersData(providers);
  }, [providers]);

  useEffect(() => {
    const data = {
      ID: id,
      date: moment(date).format("YYYY-MM-DD"),
      // endDate: date?.endDate
      //   ? moment(date?.endDate).format("YYYY-MM-DD")
      //   : undefined,
      isFor: "Provider",
    };
    getSchedulesApi(data);
    getPatients({ providerId: id, limit: 0 });
  }, [date, id]);

  useEffect(() => {
    id &&
      getIsPublishEnabled({
        ProviderID: id,
      });
  }, [id]);

  if (!state) {
    return <Navigate to="/home" state={{ from: location }} replace />;
  }

  return (
    <Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CommandCenterComponent
        isProvider
        id={id}
        providers={providersData}
        onRegion={onRegion}
        visits={data}
        data={data}
        setData={setData}
        type={type}
        view={view}
        noData={
          !!isPTO
            ? "Provider is unavailable for the selected date"
            : "No Appointments for the selected date"
        }
        date={date}
        loading={loading}
        eligibleVisits={eligibleData}
        isPTO={!!isPTO}
      />
    </Grid>
  );
};

export default ProviderView;
