import * as React from "react";
const HomeSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      fillRule="evenodd"
      d="M9.574 1.133a.75.75 0 0 1 .852 0l6.385 4.403A2.75 2.75 0 0 1 18 7.8v8.45A2.75 2.75 0 0 1 15.25 19H4.75A2.75 2.75 0 0 1 2 16.25V7.8c0-.904.444-1.75 1.189-2.264l6.385-4.403ZM8.5 17.5h3v-4.75a.25.25 0 0 0-.25-.25h-2.5a.25.25 0 0 0-.25.25v4.75Zm4.5 0v-4.75A1.75 1.75 0 0 0 11.25 11h-2.5A1.75 1.75 0 0 0 7 12.75v4.75H4.75c-.69 0-1.25-.56-1.25-1.25V7.8c0-.41.202-.795.54-1.029L10 2.661l5.96 4.11c.338.234.54.618.54 1.03v8.449c0 .69-.56 1.25-1.25 1.25H13Z"
      clipRule="evenodd"
    />
  </svg>
);
export default HomeSvg;
