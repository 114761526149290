import { Grid, IconButton, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import CommandCenterHeader from "../../components/Header/CommandCenterHeader";
import MenuHeader from "../../components/Header/MenuHeader";
import CommandCenterTabs from "../../components/Tabs/CommandCenterTabs";
import "./Component/CommandCenterComponent.css";
import AppointmentsCard from "../../components/Cards/AppointmentCard";
import CalendarComponent from "../../components/Calendar/CalendarNew";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import SearchComponent from "../../components/Search/Search";
import { useSelector } from "react-redux";
import Chat from "../Chat/Chat";
import FilterListIcon from "@mui/icons-material/FilterList";
import Loading from "../../components/Loading";
import { groupedVisits } from "../../utilities/resuseFunctions";
import useProviders from "../../hooks/useProviders";
import useAutoLogout from "../../hooks/useAutoLogout";

const CommunityCC = ({ isProvider }) => {
  const { height } = useWindowDimensions();
  const { userData } = useAutoLogout();
  const selections = useSelector((state) => state.selections);
  const { selectedProvider } = selections;

  const [search, setSearch] = useState("");
  const [type, setType] = useState(selections?.type || "Scheduler");

  const { getSchedulesApi, visits, loading } = useProviders();

  const { date } = useSelector((state) => state.selections);

  const [data, setData] = useState(visits || []);

  useEffect(() => {
    setData(visits);
  }, [visits]);

  useEffect(() => {
    const data = {
      ID: userData?.communityId,
      date: moment(date).format("YYYY-MM-DD"),
      isFor: "Community",
      isHospice: userData.isSageHospice,
    };
    getSchedulesApi(data);
  }, [date]);

  let dataFilter = data?.filter(
    (i) =>
      i.PatientName?.toLowerCase()?.includes(search?.toLowerCase()) ||
      i.Facility?.toLowerCase()?.includes(search?.toLowerCase())
  );

  const groupedItems = groupedVisits(isProvider, dataFilter);

  return (
    <div>
      <Loading loading={loading} />
      <MenuHeader />
      <CommandCenterHeader setType={setType} type={type} isCommunity={true} />
      <Grid container className="px-3">
        <Grid item md={6} xs={12} className="p-3">
          <CommandCenterTabs
            tabComponents={[CalendarComponent]}
            isCommunity={true}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          {selections?.type === "Scheduler" && (
            <>
              <div>
                <Typography className="today mx-3">
                  {" "}
                  {moment(selections.date).isSame(moment(), "day")
                    ? "Today"
                    : moment(selections.date).format("dddd, MMMM DD YYYY")}
                </Typography>
              </div>
              <Grid className="d-flex justify-content-between mx-2">
                <div className="w-100" style={{ marginLeft: 8 }}>
                  <SearchComponent value={search} onChange={setSearch} />
                </div>
                <IconButton>
                  <FilterListIcon className="text-black" />
                </IconButton>
              </Grid>
              <Grid
                sx={{
                  height: isProvider ? height - 320 : height - 270,
                  overflowY: "auto",
                }}
                className={
                  isProvider && !dataFilter?.length
                    ? "d-flex justify-content-center align-items-center"
                    : ""
                }
              >
                {dataFilter.length ? (
                  dataFilter?.map((i, idx) => {
                    return (
                      <AppointmentsCard
                        key={idx}
                        data={i}
                        index={idx}
                        setData={setData}
                        totalData={dataFilter}
                        provider={selectedProvider}
                        groupedItems={groupedItems}
                        isCommunity={true}
                      />
                    );
                  })
                ) : (
                  <Typography className="m-3 no-data">
                    No Appointments for the selected date
                  </Typography>
                )}
              </Grid>
            </>
          )}
          {selections?.type === "Messenger" && <Chat />}
        </Grid>
      </Grid>
    </div>
  );
};

export default CommunityCC;
