import * as React from "react";
const CallSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      fillRule="evenodd"
      d="M1 5.375A4.375 4.375 0 0 1 5.375 1c.439 0 .86.174 1.17.485l.006.005.096.099a168.388 168.388 0 0 1 1.026 1.06c.282.294.569.597.79.838.11.12.208.23.283.318a2.931 2.931 0 0 1 .162.212l.001.001c.013.02.06.093.091.191.114.352.114.73 0 1.082a1.193 1.193 0 0 1-.194.347l-.002.002a4.015 4.015 0 0 1-.197.233c-.14.155-.32.34-.497.522l-.027.028c-.37.379-.711.73-.873.945-.242.322-.293.854-.177 1.176l.001.003.237.498a9.253 9.253 0 0 0 .467.827c.728 1.114 1.276 1.646 2.396 2.394.29.194.666.398 1 .565a12.213 12.213 0 0 0 .606.28l.003.002c.294.096.614.08.897-.046.155-.069.563-.33 1.032-.637l.106-.07c.18-.117.355-.233.5-.324.085-.054.168-.106.239-.146a2.17 2.17 0 0 1 .114-.062c.026-.013.103-.051.198-.075a1.75 1.75 0 0 1 .842 0 1.273 1.273 0 0 1 .289.12c.068.037.146.082.227.13.162.099.36.224.559.352.396.255.81.53.966.635A2.892 2.892 0 0 1 19 15.396 3.604 3.604 0 0 1 15.396 19h-2.48a7.564 7.564 0 0 1-3.49-.853 17.784 17.784 0 0 1-7.573-7.573A7.564 7.564 0 0 1 1 7.084v-1.71Zm4.483-2.83a.156.156 0 0 0-.108-.045A2.875 2.875 0 0 0 2.5 5.375v1.71c0 .973.235 1.933.684 2.797a16.283 16.283 0 0 0 6.934 6.934c.864.45 1.824.684 2.798.684h2.48a2.104 2.104 0 0 0 2.104-2.104c0-.465-.232-.9-.62-1.158-.156-.104-.561-.374-.947-.622-.193-.125-.377-.24-.52-.327a4.981 4.981 0 0 0-.15-.087h-.02l-.162.1c-.134.085-.297.193-.478.311l-.109.071c-.407.268-.948.622-1.245.754a2.75 2.75 0 0 1-1.974.1 8.21 8.21 0 0 1-.811-.365 11.205 11.205 0 0 1-1.163-.66c-1.275-.85-1.971-1.524-2.818-2.82a11.498 11.498 0 0 1-.861-1.64c-.274-.76-.18-1.83.389-2.586.223-.296.624-.708.952-1.044l.073-.075c.181-.186.34-.35.458-.48.039-.043.069-.078.091-.105v-.007a11.508 11.508 0 0 0-.228-.255c-.21-.229-.488-.522-.767-.815a147.705 147.705 0 0 0-1.016-1.049l-.09-.093Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CallSvg;
