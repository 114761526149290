import React, { useCallback, useEffect, useState } from "react";
import MenuHeader from "../../../components/Header/MenuHeader";
import { Icons } from "../../../assets";
import IconText from "../../../components/Text/IconText";
import Patients from "../Patients/Patients";
import IconText2 from "../../../components/Text/IconText2";
import {
  Avatar,
  Badge,
  Card,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddProviderModal from "../../../components/Modals/AddProviderModal";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import IconComponent from "../../../components/DataTable/Icon";
import LocationSvg from "../../../assets/svgs/LocationSvg";
import { useNavigate } from "react-router-dom";
import EmailSvg from "../../../assets/svgs/EmailSvg";
import CallSvg from "../../../assets/svgs/CallSvg";
import HomeSvg from "../../../assets/svgs/HomeSvg";
import { numberToPhone } from "../../../utilities/resuseFunctions";
import usePatients from "../../../hooks/usePatients";
import Communities from "../Communities/Communities";
import useProviders from "../../../hooks/useProviders";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const ProviderGrid = ({ noHeader }) => {
  const navigate = useNavigate();
  // const providers = useSelector((state) => state.providers.data);
  const { providers, getProvider } = useProviders(true);
  const { Id } = useParams();
  const [open, setOpen] = useState(false);
  const [communities, setCommunities] = useState(false);
  const [sectionSelected, setSectionSelected] = useState("patients");
  const [selected, setSelected] = useState({});

  // const selected = providers.find((i) => i.ProviderID === Id) || {};

  const initCall = useCallback(() => {
    const provider = providers.find((i) => i.ProviderID === Id);
    !provider
      ? getProvider({ ProviderID: Id }).then((res) => setSelected(res))
      : setSelected(provider);
  }, [Id]);

  const { getPatients } = usePatients({
    providerId: Id,
    noInitial: true,
  });

  useEffect(() => {
    getPatients({
      providerId: Id,
    }).then((data) => {
      getCommunityIds(data);
    });
  }, []);

  useEffect(initCall, [initCall]);

  const getCommunityIds = (patients) => {
    const Ids = [];
    patients.forEach((patient) => {
      Ids.push(patient?.PatientLocationID);
    });
    setCommunities(Ids);
  };

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <Grid>
      {!noHeader && <MenuHeader isSecondary />}
      <Grid container>
        <Grid item sm={12} md={3} className="px-md-4">
          <Card className="p-0 h-100" style={{ position: "relative" }}>
            <Grid className="d-flex justify-content-between p-3">
              <Typography
                className="color-1 font-medium"
                role="button"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </Typography>
              <Tooltip title="Edit">
                <IconButton
                  onClick={() => {
                    setOpen(selected);
                  }}
                  style={{ width: 35, height: 35 }}
                >
                  <IconComponent icon={"Edit_Icon"} i={selected} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid
              className="d-flex flex-column align-items-center py-2"
              style={{ borderBottom: "1px solid #E9ECEF" }}
            >
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar
                  size={"lg"}
                  src={selected?.User?.[0]?.profileImg}
                  className="my-1"
                  style={{ fontFamily: "Inter" }}
                />
              </StyledBadge>
              <Typography
                className="py-1"
                style={{
                  fontSize: 18,
                  fontFamily: "Inter",
                  fontWeight: 600,
                  color: "#333333",
                }}
              >
                {`${selected.ProviderFN} ${selected.ProviderLN}`}
              </Typography>
              <Typography
                style={{
                  fontSize: 12,
                  color: "#828282",
                  fontFamily: "Inter",
                }}
              >
                {selected?.ProviderSpecialty}
              </Typography>
              {selected.ZoneID && selected.ZoneID !== "0" && (
                <Typography
                  className="my-1"
                  style={{
                    fontSize: 12,
                    color: "#828282",
                    fontFamily: "Inter",
                  }}
                >
                  {selected?.zones?.ZoneName}
                </Typography>
              )}
            </Grid>
            <Divider />
            <Grid
              className="mx-2 p-2"
              style={{
                alignSelf: "flex-start",
              }}
            >
              <IconText
                text={selected.dob}
                icon={Icons.Heart_Icon}
                textClass="mx-4"
                className={"py-2"}
              />
              <IconText
                text={selected.ProviderEmail}
                Icon={EmailSvg}
                textClass="mx-4"
                className={"py-2"}
              />
              <IconText
                text={numberToPhone(selected.ProviderPhone)}
                Icon={CallSvg}
                textClass="mx-4"
                className={"py-2"}
              />
              {selected?.ProviderStreet && (
                <IconText
                  text={`${selected?.ProviderStreet || ""}, ${
                    selected?.ProviderCity || ""
                  }, ${selected?.ProviderState || ""}, ${
                    selected?.ProviderZip || ""
                  }`}
                  Icon={LocationSvg}
                  textClass="mx-4"
                  className={"py-2"}
                />
              )}
            </Grid>
            <Grid
              className="align-self-start p-2"
              style={{ borderTop: "1px solid #E9ECEF", flexGrow: 2 }}
            >
              <IconText2
                text="Patients"
                icon={Icons.Group_Blue_Icon}
                selected={sectionSelected === "patients"}
                onClick={() => setSectionSelected("patients")}
              />
              <IconText2
                text="Communities"
                Icon={HomeSvg}
                selected={sectionSelected === "communities"}
                onClick={() => setSectionSelected("communities")}
              />
            </Grid>
          </Card>
        </Grid>
        <Grid item sm={12} md={9}>
          {sectionSelected === "patients" && (
            <Patients noHeader noAdd providerId={selected?.ProviderID} />
          )}
          {sectionSelected === "communities" && (
            <Communities
              noHeader
              noAdd
              communityList={communities?.filter(Boolean)}
            />
          )}
        </Grid>
      </Grid>
      {open && (
        <AddProviderModal
          open={open}
          handleClose={onCancel}
          selected={selected}
          isEdited={true}
        />
      )}
    </Grid>
  );
};

export default ProviderGrid;
