import axios from "axios";
import { BASE_URL } from "../constants/endpoints";
import {
  CONTENT_TYPE,
  APPLICATION_JSON,
  AUTHORIZATION,
  ACCEPT,
  CORS,
  ACCESS_CONTROL,
} from "../constants/api-constants";
import { REQUEST_TIME_OUT_ERROR_MESSAGE } from "../constants/strings";

export function setAuthToken(token) {
  if (token) {
    api.defaults.headers.common[AUTHORIZATION] = `Bearer ${token}`;
  }
}

const api = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  timeoutErrorMessage: REQUEST_TIME_OUT_ERROR_MESSAGE,
  headers: {
    [ACCEPT]: APPLICATION_JSON,
    [CORS]: ACCESS_CONTROL,
    [CONTENT_TYPE]: APPLICATION_JSON,
  },
});

export default api;
