import * as React from "react";
const ListSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      fillRule="evenodd"
      d="M1 4.747a.75.75 0 0 1 .75-.75L4.25 4a.75.75 0 0 1 0 1.5l-2.5-.001a.75.75 0 0 1-.75-.75Zm7 0a.75.75 0 0 1 .75-.75l9.5.006a.75.75 0 1 1 0 1.5l-9.5-.005a.75.75 0 0 1-.75-.75ZM1 10a.75.75 0 0 1 .75-.749l2.5.002a.75.75 0 0 1 0 1.5l-2.5-.002A.75.75 0 0 1 1 10Zm7 0a.75.75 0 0 1 .75-.749l9.5.005a.75.75 0 1 1 0 1.5l-9.5-.005A.75.75 0 0 1 8 10ZM1 15.25a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 0 1.5h-2.5a.75.75 0 0 1-.75-.75Zm7 0a.75.75 0 0 1 .75-.75h9.5a.75.75 0 0 1 0 1.5h-9.5a.75.75 0 0 1-.75-.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ListSvg;
