import React, { useEffect } from "react";
import CommandCenterComponent from "./Component/CommandCenterComponent";
import { useDispatch, useSelector } from "react-redux";
import { changeSelectedProvider } from "../../redux/reducers/selections.reducer";
import useProviders from "../../hooks/useProviders";
import moment from "moment";

const OfficeCC = () => {
  const dispatch = useDispatch();
  const { date } = useSelector((state) => state.selections);
  const { providersCount, getProvidersWithCount, loading } = useProviders(true);

  useEffect(() => {
    const data = { date: moment(date).format("YYYY-MM-DD") };
    getProvidersWithCount(data);
  }, [date]);

  useEffect(() => {
    dispatch(changeSelectedProvider("All Providers"));
  }, [dispatch]);
  return (
    <div>
      <CommandCenterComponent
        providers={providersCount}
        visits={[]}
        data={providersCount}
        loading={loading}
      />
    </div>
  );
};

export default OfficeCC;
