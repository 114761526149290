import { Badge, Grid, Typography } from "@mui/material";
import React from "react";
import "./SelectableButton.css";
import clsx from "clsx";

const SelectableButton = ({
  onClick,
  title,
  isSelected,
  isBadge,
  className,
  disabled,
  count,
}) => {
  return (
    <button
      disabled={disabled}
      className={clsx(
        isSelected
          ? "btn button-inactive button-active w-100"
          : "btn button-inactive w-100",
        className
      )}
      onClick={onClick}
    >
      <Grid className="position-relative">
        <Typography
          style={{ fontWeight: 450 }}
          className={isSelected ? "button-active-text font-14" : "font-14"}
        >
          {title}
        </Typography>
        {!!count && (
          <Grid className="unread unread-position">{count || 0}</Grid>
        )}
      </Grid>
    </button>
  );
};

export default SelectableButton;
