import * as React from "react";
const MapSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      fillRule="evenodd"
      d="M12.828 3.675a3.99 3.99 0 0 0-5.656 0 4.023 4.023 0 0 0 0 5.677L10 12.188l2.828-2.836a4.023 4.023 0 0 0 0-5.677Zm-2.296 10.104H9.468L6.11 10.412a5.523 5.523 0 0 1 0-7.795 5.49 5.49 0 0 1 7.78 0 5.523 5.523 0 0 1 0 7.795L10.53 13.78Zm0 0a.75.75 0 0 1-1.063 0h1.063ZM8.5 6.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm-5.963 4.51a.75.75 0 0 1 .621.86l-.452 2.813c-.105.648-.174 1.085-.198 1.423-.023.33.006.482.045.579.106.266.309.496.582.645.107.058.276.11.628.14.358.03.825.03 1.51.03h9.454c.685 0 1.152 0 1.51-.03.352-.03.52-.082.629-.14.273-.149.475-.379.582-.645.038-.096.067-.249.044-.579-.024-.338-.093-.775-.198-1.423l-.452-2.814a.75.75 0 0 1 1.48-.238l.459 2.847c.097.606.178 1.11.207 1.524.03.429.011.84-.147 1.239a2.779 2.779 0 0 1-1.259 1.407c-.373.202-.781.28-1.221.317-.427.035-.957.035-1.602.035H5.241c-.645 0-1.175 0-1.602-.035-.44-.037-.848-.115-1.221-.317a2.778 2.778 0 0 1-1.259-1.407c-.158-.398-.177-.81-.147-1.24.029-.412.11-.917.207-1.523l.458-2.847a.75.75 0 0 1 .86-.622Z"
      clipRule="evenodd"
    />
  </svg>
);
export default MapSvg;
