import { useCallback, useEffect, useState } from "react";
import api from "../services/api";
import {
  replaceZones,
  addZone,
  updateZone,
  deleteZone,
} from "../redux/reducers/zones.reducer";
import { useDispatch, useSelector } from "react-redux";
import { queryString } from "../utilities/resuseFunctions";
import useAuth from "./useAuth";

export default function useZones(noStartup, noData) {
  const dispatch = useDispatch();
  const { updateAlertNote } = useAuth();
  const { data: zones, count } = useSelector((state) => state.zones);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setLoading(false);
    return;
  };
  const handleOpen = () => {
    setLoading(true);
  };

  const getZones = useCallback(
    ({ limit = "20", skip = "", search } = {}) => {
      handleOpen();
      api.get(`/zones?${queryString({ limit, skip, search })}`).then((res) => {
        dispatch(replaceZones(res?.data || []));
        handleClose();
      });
    },
    [dispatch]
  );

  useEffect(() => {
    (!noStartup || (noData && !zones.length)) && getZones();
  }, []);

  const addZoneApi = async (data) => {
    handleOpen();
    return await api
      .post("/zones", data)
      .then((res) => {
        dispatch(addZone(res?.data?.data || []));
        return res;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const updateZoneApi = async (data) => {
    handleOpen();
    return await api
      .put(`/zones/${data.ZoneID}`, data)
      .then((res) => {
        dispatch(updateZone(res?.data?.data || []));
        handleClose();
        updateAlertNote("Zone updated!", "success");
        return;
      })
      .catch(() => {
        handleClose();
        updateAlertNote("Zone Failed to update", "error");
      });
  };

  const deleteZoneApi = async (data) => {
    handleOpen();
    return await api
      .delete(`/zones/${data.ZoneID}`)
      .then((res) => {
        dispatch(deleteZone(res?.data?.data || []));
        handleClose();
        updateAlertNote("Zone deleted successfully", "success");
      })
      .catch(() => {
        handleClose();
        updateAlertNote("Zone Failed to delete", "error");
      });
  };

  return {
    zones,
    addZoneApi,
    updateZoneApi,
    deleteZoneApi,
    loading,
    handleClose,
    count,
    getZones,
  };
}
