import React, { useState } from "react";
import { colors } from "../../styles";
import CustomButton from "../Buttons/CustomButton";
import { Dialog, Divider, Grid, IconButton, Typography } from "@mui/material";
import "./AddRegionModal.css";
import CloseIcon from "@mui/icons-material/Close";
import UserSvg from "../../assets/svgs/user";
import { Form } from "react-bootstrap";
import usePatients from "../../hooks/usePatients";
import Select from "react-select";
import CalendarSvg from "../../assets/svgs/CalendarSvg";

const TagPatientModal = ({ open: isOpen, handleClose, handleSubmit }) => {
  const { tagPatients } = usePatients({ noInitial: true });
  const [open, setOpen] = useState(isOpen);
  const [selected, setSelected] = useState("");

  const onClose = () => {
    setOpen(true);
    handleClose();
  };

  const onSubmit = () => {
    onClose();
    handleSubmit({ patient: selected.id });
  };

  const CustomOption = ({ innerProps, label, data, isFocused, isSelected }) => {
    console.log(data, "data");
    return (
      <div
        {...innerProps}
        className={`custom-option ${isFocused ? "focused" : ""} ${
          isSelected ? "selected" : ""
        }`}
      >
        <div className="d-flex flex-column">
          <span style={{ fontWeight: 700 }} className="py-1">
            {data.display?.split("(")?.[0]}
          </span>
          <div className="d-flex align-items-center gap-2">
            <CalendarSvg color={isSelected ? "#fff" : "#46505B"} />
            <span style={{ color: isSelected ? "#fff" : "#46505B" }}>
              {data.dob}
            </span>
          </div>
          <Divider />
        </div>
      </div>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"xs"} fullWidth>
      <Grid>
        <Grid
          className="d-flex justify-content-between py-2 px-3 align-items-center"
          style={{
            backgroundColor: colors.OFFICE_COLOR,
          }}
        >
          <div className="d-flex align-items-center">
            <UserSvg color={"white"} />
            <Typography className="region-modal-title mx-2">
              {"Tag Patient"}
            </Typography>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Grid>
        <Grid className="px-2">
          <Grid className="m-2 px-3">
            <Typography className="fieldName py-2">Select Patient</Typography>
            <Select
              className="basic-single"
              classNamePrefix="select"
              value={selected}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              menuPosition={"absolute"}
              // menuPlacement={menuPlacement}
              onChange={(val) => setSelected(val)}
              isClearable={true}
              name="Select Patient"
              options={tagPatients.map((p) => {
                return {
                  label: p.display,
                  value: p.id,
                  ...p,
                };
              })}
              components={{ Option: CustomOption }} // Use the custom option component
              menuShouldScrollIntoView={false}
            />
          </Grid>
          <Grid>
            <CustomButton className="button mx-4 p-2 px-5" onClick={onSubmit}>
              <Typography>{"Confirm"}</Typography>
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default TagPatientModal;
