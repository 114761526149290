import { useCallback, useEffect, useState } from "react";
import api from "../services/api";
import {
  replaceCommunities,
  addCommunity,
  updateCommunity,
} from "../redux/reducers/communities.reducer";
import { useDispatch, useSelector } from "react-redux";
import { queryString } from "../utilities/resuseFunctions";

export default function useCommunities(skipStartup) {
  const dispatch = useDispatch();
  const { data: communities, count } = useSelector(
    (state) => state.communities
  );
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setLoading(false);
  };
  const handleOpen = () => {
    setLoading(true);
  };

  const getCommunities = useCallback(
    ({ limit = "20", skip = 0, search, sort, filter, communityList }) => {
      handleOpen();
      const { order, orderBy } = sort || {};
      return api
        .post(`/communities`, {
          limit,
          skip,
          search,
          order,
          orderBy,
          ZoneID: filter?.ZoneID,
          includes: communityList,
        })
        .then((res) => {
          dispatch(replaceCommunities(res?.data || []));
          handleClose();
          return res.data.data;
        })
        .catch(() => handleClose());
    },
    [dispatch]
  );

  useEffect(() => {
    !skipStartup && getCommunities({ limit: 20 });
  }, [getCommunities, skipStartup]);

  const getCommunity = useCallback(async ({ communityID }) => {
    handleOpen();
    return await api
      .get(`/communities/${communityID}`)
      .then((res) => {
        handleClose();
        return res.data.data;
      })
      .catch(() => handleClose());
  }, []);

  const addCommunityApi = (data) => {
    handleOpen();
    api
      .post("/communities/create", data)
      .then((res) => {
        dispatch(addCommunity(res?.data?.data || []));
        handleClose();
      })
      .catch(() => handleClose());
  };

  const updateCommunityApi = (data) => {
    handleOpen();
    api
      .put(`/communities/${data.LocationID}`, data)
      .then((res) => {
        dispatch(updateCommunity(res?.data?.data || []));
        handleClose();
      })
      .catch(() => handleClose());
  };

  return {
    communities,
    addCommunityApi,
    updateCommunityApi,
    loading,
    handleClose,
    getCommunities,
    count,
    getCommunity,
  };
}
