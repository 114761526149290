import React, { useEffect, useState } from "react";
import { colors } from "../../styles";
import CustomButton from "../Buttons/CustomButton";
import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import "./AddRegionModal.css";
import CloseIcon from "@mui/icons-material/Close";
import { Form } from "react-bootstrap";
import usePatients from "../../hooks/usePatients";
import AsyncSelect from "react-select/async";
import useCommunities from "../../hooks/useCommunities";
import { contactCategories, contactTypes } from "../../data/listOptions";
import PhoneInput from "../Input/PhoneInput";
import ConfirmModal from "./DeleteModal";
import useZones from "../../hooks/useZones";

const UpdatePatientModal = ({ isOpen, handleClose, selected, isEdited }) => {
  const { zones } = useZones();
  const [open, setOpen] = useState(isOpen);
  const [edit, setEdit] = useState(selected || {});
  const [isConfirm, setIsConfirm] = useState(false);
  const [community, setCommunity] = useState({
    label: selected?.Facility,
    value: selected?.PatientLocationID,
  });
  const { updatePatientApi, addPatientApi } = usePatients({ noInitial: true });
  const { getCommunities } = useCommunities(true);

  const comunityOptions = async (inputValue) => {
    const final = await getCommunities({
      search: inputValue || selected?.Facility,
      limit: 0,
    })
      .then((res) => {
        return res?.map((i) => ({
          label: i.LocationName,
          value: i.LocationID,
          ...i,
        }));
      })
      .finally((res) => res || []);
    return final;
  };

  const onChangeHandler = (val, name) => {
    setEdit({
      ...edit,
      [name]: val,
    });
  };

  const onClose = () => {
    setOpen(false);
    handleClose();
  };

  const onSubmit = () => {
    const { PatientPhone, residence, ...rest } = {
      ...edit,
      Status: "a",
      PatientZoneID: +edit.PatientZoneID,
    };
    if (selected.PatientID) {
      updatePatientApi(rest).then(() => onClose());
    } else {
      addPatientApi(rest).then(() => onClose());
    }
    // const allFieldsHaveValue =
    // Object.keys(edit)?.length === 7 &&
    //   Object.values(edit).every((value) => Boolean(value));
    // console.log(allFieldsHaveValue, "allFieldsHaveValue", edit);
    // if (!allFieldsHaveValue) return;
    // handleClose();
    // if (selected?.type) {
    //   dispatch(updateProvider(edit));
    //   // setData([...tmpUser]);
    // } else {
    //   dispatch(addProvider({ id: providers?.length + 1, ...edit }));
    //   // setData([...data, { id: data?.length + 1, ...edit }]);
    // }
  };

  const residence =
    edit?.residence ||
    (edit?.Facility === "PH" ? "Private Residence" : "Community");
  // console.log(residence);
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
        fullWidth
      >
        <Grid>
          <Grid
            className="d-flex justify-content-between py-1 px-3 align-items-center"
            style={{
              backgroundColor: colors.OFFICE_COLOR,
            }}
          >
            <Typography className="region-modal-title">
              {isEdited
                ? selected.PatientID
                  ? "Edit Patient"
                  : "Add Patient"
                : "View Patient"}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon className="close-icon" />
            </IconButton>
          </Grid>
          <Grid className="px-2">
            <Grid className="m-2 px-3">
              <Typography className="fieldName py-2">Name*</Typography>
              <Grid container>
                <Grid item md={6}>
                  <Form.Control
                    disabled={!isEdited}
                    placeholder="First Name"
                    className="inputField px-2 border-1 w-100"
                    value={edit?.PatientFirstName}
                    onChange={(val) =>
                      onChangeHandler(val.target.value, "PatientFirstName")
                    }
                  />
                </Grid>
                <Grid item md={6} className="px-md-2 py-2 py-md-0">
                  <Form.Control
                    disabled={!isEdited}
                    placeholder="Last Name"
                    className="inputField px-2 border-1 w-100"
                    value={edit?.PatientLastName}
                    onChange={(val) =>
                      onChangeHandler(val.target.value, "PatientLastName")
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid className="p-2">
              <Typography className="fieldName px-3 py-2">
                Community or Private Residence
              </Typography>
              <RadioGroup
                row
                value={residence}
                onChange={(val) =>
                  onChangeHandler(val.target.value, "residence")
                }
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="residence"
                className="mx-3"
              >
                <FormControlLabel
                  value="Community"
                  disabled={!isEdited}
                  control={<Radio />}
                  label="Community"
                />
                <FormControlLabel
                  value="Private Residence"
                  disabled={!isEdited}
                  control={<Radio />}
                  label="Private Residence"
                />
              </RadioGroup>
            </Grid>
            {residence === "Community" && (
              <Grid className="m-2 px-3">
                <Typography className="fieldName py-2">
                  Select Community
                </Typography>
                <AsyncSelect
                  isDisabled={!isEdited}
                  cacheOptions
                  defaultOptions
                  loadOptions={comunityOptions}
                  value={community || +selected?.PatientLocationID}
                  onChange={(val) => {
                    setCommunity(val);
                    setEdit({
                      ...edit,
                      AddressLine1: val.LocationStreet,
                      City: val.LocationCity,
                      State: val.LocationState,
                      Zip: val.LocationZip,
                      PatientZoneID: val.LocationZoneID,
                    });
                  }}
                />
              </Grid>
            )}
            {residence === "Private Residence" ||
              (community.label && (
                <>
                  <Grid container className="p-2 ">
                    <Grid item md={6}>
                      <Typography className="fieldName py-2 px-3">
                        Address Line 1
                      </Typography>
                      <Grid className="px-3">
                        <Form.Control
                          disabled={!isEdited}
                          placeholder="AddressLine1"
                          value={edit?.AddressLine1}
                          onChange={(val) =>
                            onChangeHandler(val.target.value, "AddressLine1")
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <Typography className="fieldName px-3 py-2">
                        Address Line 2
                      </Typography>
                      <Grid className="inputField px-3">
                        <Form.Control
                          disabled={!isEdited}
                          placeholder="AddressLine2"
                          value={edit?.AddressLine2}
                          onChange={(val) =>
                            onChangeHandler(val.target.value, "AddressLine2")
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container className="p-2 ">
                    <Grid item md={6}>
                      <Typography className="fieldName py-2 px-3">
                        City
                      </Typography>
                      <Grid className="px-3">
                        <Form.Control
                          disabled={!isEdited}
                          placeholder="City"
                          value={edit?.City}
                          onChange={(val) =>
                            onChangeHandler(val.target.value, "City")
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <Typography className="fieldName px-3 py-2">
                        State
                      </Typography>
                      <Grid className="inputField px-3">
                        <Form.Control
                          disabled={!isEdited}
                          placeholder="State"
                          value={edit?.State}
                          onChange={(val) =>
                            onChangeHandler(val.target.value, "State")
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container className="p-2 ">
                    <Grid item md={6}>
                      <Typography className="fieldName px-3 py-2">
                        Zip
                      </Typography>
                      <Grid className="inputField px-3">
                        <Form.Control
                          disabled={!isEdited}
                          placeholder="Zip"
                          value={edit?.Zip}
                          onChange={(val) =>
                            onChangeHandler(val.target.value, "Zip")
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <Typography className="fieldName px-3 py-2">
                        Zone*
                      </Typography>
                      <Grid className="inputField px-3">
                        <Form.Select
                          disabled={!isEdited}
                          aria-label="Default select example"
                          value={edit?.PatientZoneID}
                          onChange={(e) =>
                            onChangeHandler(e.target.value, "PatientZoneID")
                          }
                        >
                          <option>Select Zone</option>
                          {zones?.map((zone) => {
                            return (
                              <option value={zone.ZoneID}>
                                {zone?.ZoneName}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ))}
            <Grid className="m-2 px-3">
              <Typography className="fieldName py-2">Name</Typography>
              <Form.Control
                disabled={!isEdited}
                placeholder="Name"
                className="inputField px-2 border-1 w-100"
                value={edit?.FamilyName1}
                onChange={(val) =>
                  onChangeHandler(val.target.value, "FamilyName1")
                }
              />
            </Grid>
            <Grid className="m-2 px-3">
              <Typography className="fieldName py-2">Contact</Typography>
              <PhoneInput
                disabled={!isEdited}
                placeholder="Contact"
                className="inputField px-2 border-1 w-100"
                value={edit?.FamilyContactCellNumber1}
                onChange={(val) =>
                  onChangeHandler(val, "FamilyContactCellNumber1")
                }
              />
            </Grid>
            <Grid container className="p-2 ">
              <Grid item md={6}>
                <Typography className="fieldName px-3 py-2">Type*</Typography>
                <Grid className="inputField px-3">
                  <Form.Select
                    disabled={!isEdited}
                    aria-label="Default select example"
                    value={edit?.ContactType1}
                    onChange={(e) =>
                      setEdit({ ...edit, ContactType1: e.target.value })
                    }
                  >
                    <option>Select Contact Type</option>
                    {contactTypes?.map((i) => {
                      return (
                        <option key="i" value={i}>
                          {i}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Grid>
              </Grid>
              <Grid item md={6}>
                <Typography className="fieldName px-3 py-2">
                  Category*
                </Typography>
                <Grid className="inputField px-3">
                  <Form.Select
                    disabled={!isEdited}
                    aria-label="Default select example"
                    value={edit?.ContactCategory1}
                    onChange={(e) =>
                      setEdit({ ...edit, ContactCategory1: e.target.value })
                    }
                  >
                    <option>Select Contact Category</option>
                    {contactCategories?.map((i) => {
                      return (
                        <option key="i" value={i}>
                          {i}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Grid>
              </Grid>
            </Grid>

            {isEdited && (
              <Grid>
                <CustomButton
                  className="button mx-4 py-2 px-5"
                  onClick={() => setIsConfirm(true)}
                >
                  <Typography>
                    {selected.PatientID ? "Update" : "Add"}
                  </Typography>
                </CustomButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Dialog>
      {isConfirm && (
        <ConfirmModal
          open={isConfirm}
          handleClose={() => {
            setIsConfirm(false);
            onClose();
          }}
          handleDelete={onSubmit}
          title={`${selected.PatientID ? "Edit" : "Add"} Patient`}
          subtext={`Are you sure you want to ${
            selected.PatientID ? "update" : "add"
          } this Patient`}
          primaryText={"Confirm"}
        />
      )}
    </>
  );
};

export default UpdatePatientModal;
