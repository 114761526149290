import React from "react";
import update from "immutability-helper";
import { useCallback } from "react";
import DragCard from "../../../components/Cards/DragCard";
import AppointmentsCard from "../../../components/Cards/AppointmentCard";
import { Grid } from "@mui/material";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const DraggableAppointments = ({
  data,
  setData,
  selectedProvider,
  isProvider,
}) => {
  const { height } = useWindowDimensions();
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setData((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);
  const renderCard = useCallback((card, index) => {
    return (
      <DragCard
        key={card.id}
        index={index}
        id={card.id}
        text={card.text}
        moveCard={moveCard}
        data={data}
      >
        <AppointmentsCard
          data={card}
          index={index}
          setData={setData}
          totalData={data}
          provider={selectedProvider}
        />
      </DragCard>
    );
  }, []);
  return (
    <>
      <Grid
        sx={{
          height: isProvider ? height - 320 : height - 270,
          overflowY: "auto",
        }}
      >
        {data.map((card, i) => renderCard(card, i))}
      </Grid>
    </>
  );
};

export default DraggableAppointments;
