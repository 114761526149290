import { Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TagModal from "../Modals/TagModal";
import ForwardModal from "../Modals/ForwardModal";
import useMessenger from "../../hooks/useMessenger";
// import { customToast } from "../../utilities/resuseFunctions";
import Loading from "../Loading";
import TagPatientModal from "../Modals/TagPatientModal";
import DeleteModal from "../Modals/DeleteModal";
import PatientCaseCreatedModal from "../Modals/PatientCaseCreated";
import moment from "moment";

// const DeleteModal = ({ warning, handleWarning, deleteMessage, deleteMsg }) => {
//   return (
//     <div
//       style={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//       }}
//     >
//       <Dialog open={warning} onClose={handleWarning}>
//         <p
//           style={{
//             paddingInline: "12px",
//           }}
//         >
//           Deleting Message?
//         </p>
//         <p
//           style={{
//             paddingInline: "12px",
//           }}
//         >
//           This message will be removed from everyone's history!
//         </p>
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             alignItems: "center",
//           }}
//         >
//           <p
//             style={{
//               flex: 1,
//               textAlign: "center",
//               borderRight: "solid",
//               borderColor: "red",
//               borderWidth: "0.5px",
//               cursor: "pointer",
//             }}
//             className="leave-text"
//             onClick={() => {
//               handleWarning();
//             }}
//           >
//             Cancel
//           </p>
//           <p
//             style={{
//               flex: 1,
//               textAlign: "center",
//               cursor: "pointer",
//             }}
//             onClick={() => {
//               deleteMessage(deleteMsg.msgID, deleteMsg.convID);
//               handleWarning();
//             }}
//           >
//             Delete
//           </p>
//         </div>
//       </Dialog>
//     </div>
//   );
// };

const MessageOptions = ({ color, options, message, data, noSendToAthena }) => {
  const [isApplyTag, setIsApplyTag] = useState(false);
  const [isApplyPatientTag, setIsApplyPatientTag] = useState(false);
  const [forward, setForward] = useState(false);
  const [isMarkedUrgent, setIsMarkedUrgent] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);
  const [warning, setWarning] = useState(false);

  const [deleteMsg, setDeleteMsg] = useState({});

  const [caseModalOpen, setCaseModalOpen] = useState(false);
  const isTags = !!message?.Metadata?.length;
  const isPatientTags = !!message?.TaggedPatients?.length;
  const isPending = isTags && message?.Metadata?.find((i) => !i.sentToEHR);

  const {
    createPrivateConversation,
    newGroupCreationEmit,
    sendMessage,
    userData,
    starMessage,
    applyTag,
    loading,
    updateEHR,
    applyPatientTag,
    deleteMessage,
    unstarMessage,
    markUrgent,
    setEditMsg,
  } = useMessenger();

  const isStarred = message?.StarredMessages?.find((star) => {
    return +star.userId === +userData.id && +star.messageId === +message.id;
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      // customToast("Message Copied to Clipboard", "success");
      // setCopySuccess("Copied!");
    } catch (err) {
      // setCopySuccess("Failed to copy!");
    }
  };

  const onForward = async (selected) => {
    await selected.map(async (i) => {
      if (i.conversationId) {
        sendMessage(
          message.messageBody,
          userData.id,
          i.conversationId,
          message.TaggedUsers,
          message.TaggedPatients,
          message.Metadata,
          "text",
          message.fileId ? message.fileId : undefined,
          true
        );
      } else {
        const privateGroup = await createPrivateConversation([selected.id]);
        newGroupCreationEmit([`U${userData.id}`, `U${selected.id}`]);
        sendMessage(
          message.messageBody,
          userData.id,
          privateGroup.id,
          message.TaggedUsers,
          message.TaggedPatients,
          message.Metadata,
          "text",
          message.fileId ? message.fileId : undefined,
          true
        );
      }
    });
    setForward(false);
    handleClose();
    // customToast("Message Forwarded", "success");
  };

  const handleApplyTag = ({ tag }) => {
    applyTag({
      action: tag,
      sentToEHR: false,
      messageId: message.id,
      conversationId: message.conversationId,
    });
  };

  const handleApplyPatientTag = ({ patient }) => {
    applyPatientTag({
      patientId: patient,
      messageId: message.id,
      conversationId: message.conversationId,
    });
  };

  const handleMarkedModal = () => setIsMarkedUrgent((prev) => !prev);

  const handleMarkUrgent = () => {
    markUrgent({
      messageId: message.id,
      conversationId: message.conversationId,
      M_data: message,
      groupName: data.name,
      memberIds: data?.memberUsernames?.map((m) => m.id),
    });
  };

  const handleWarning = (msgID, convID) => {
    // setWarning(!warning);
    if (msgID !== undefined && convID !== undefined) {
      setDeleteMsg({
        msgID,
        convID,
      });
      setWarning(true);
    } else {
      setDeleteMsg({});
      setWarning(false);
    }
  };

  const handleDelete = () => {
    deleteMessage(deleteMsg.msgID, deleteMsg.convID);
    handleWarning();
  };
  const sendToEHR = () => {
    updateEHR({
      metadata: message?.Metadata || [],
      message,
    }).then(() => setCaseModalOpen(true));
  };

  const handleStarMessage = async () => {
    await starMessage({
      conversationId: data.conversationId,
      messageId: message.id,
    });
    // customToast("Starred Message", "success");
  };

  const handleUnstarMessage = async () => {
    await unstarMessage({
      conversationId: data.conversationId,
      id: isStarred.id,
    });
    // customToast("Message Unstarred", "success");
  };

  return (
    <>
      {warning && (
        <DeleteModal
          open={warning}
          handleClose={handleWarning}
          handleDelete={handleDelete}
          title={"Deleting Message?"}
          subtext={"This message will be removed from everyone's history!"}
        />
      )}
      {isMarkedUrgent && (
        <DeleteModal
          open={isMarkedUrgent}
          handleClose={handleMarkedModal}
          handleDelete={handleMarkUrgent}
          title={"Mark this message as Urgent?"}
          subtext={"This message will be shown as urgent to everyone!"}
          primaryText={"Yes"}
          secondaryText={"No"}
        />
      )}
      {loading && <Loading loading={loading} />}
      {isApplyTag && (
        <TagModal
          open={isApplyTag}
          handleClose={() => setIsApplyTag(false)}
          handleSubmit={handleApplyTag}
        />
      )}
      {isApplyPatientTag && (
        <TagPatientModal
          open={isApplyPatientTag}
          handleClose={() => setIsApplyPatientTag(false)}
          handleSubmit={handleApplyPatientTag}
        />
      )}
      {forward && (
        <ForwardModal
          open={forward}
          handleClose={() => setForward(false)}
          onSubmit={onForward}
        />
      )}
      {caseModalOpen && (
        <PatientCaseCreatedModal
          open={caseModalOpen}
          handleClose={() => setCaseModalOpen(false)}
        />
      )}
      <Tooltip title="Options">
        <KeyboardArrowDownIcon
          role="button"
          id="chat-button"
          aria-controls={open ? "chat-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className="options-menu"
          style={{ color: color || "black" }}
        />
      </Tooltip>
      <Menu
        id="basic-menu"
        className="w-100"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options
          ?.filter((i) => {
            return isTags
              ? isPending && !noSendToAthena
                ? isPatientTags
                  ? ![5, 8, 7].includes(i.id)
                  : ![5, 6, 8].includes(i.id)
                : noSendToAthena
                ? !isPatientTags
                  ? ![5, 8, 6].includes(i.id)
                  : ![5, 6, 7, 8].includes(i.id)
                : ![5, 6, 7].includes(i.id)
              : isPatientTags
              ? ![8, 7, 6].includes(i.id)
              : ![8, 6].includes(i.id);
          })
          ?.filter((x) => x.id !== 4 || message.createdByID === userData.id)
          ?.filter(
            (x) =>
              x.id !== 11 ||
              (message.createdByID === userData.id &&
                moment().diff(message.createdAt, "minutes") < 15)
          )
          ?.filter((x) => (!!isStarred ? x.id !== 1 : x.id !== 9))
          ?.filter((x) => (!!message.isUrgent ? x.id !== 10 : true))
          .map((item, i) => {
            const Icon = item.icon;
            return (
              <MenuItem
                key={i}
                // disabled={item.disabled}
                onClick={async () => {
                  try {
                    if (item?.id === 5) {
                      setIsApplyTag(true);
                    }
                    if (item?.id === 7) {
                      setIsApplyPatientTag(true);
                    }
                    if (item?.id === 6) {
                      sendToEHR();
                    }
                    if (item?.id === 10) {
                      handleMarkedModal();
                    }
                    if (item?.id === 11) {
                      setEditMsg(message);
                      handleClose();
                    }
                    if (item?.id === 2) {
                      setForward(true);
                    }
                    if (item?.id === 1) {
                      handleStarMessage();
                    }
                    if (item?.id === 9) {
                      handleUnstarMessage();
                    }
                    if (item?.id === 4) {
                      handleWarning(message.id, data.conversationId);
                      // deleteMessage(message.id, data.conversationId);
                    }
                    if (item?.id === 3) {
                      copyToClipBoard(message?.messageBody);
                      handleClose();
                    }
                    // handleSelect(item);
                    handleClose();
                  } catch (error) {
                    handleClose();
                  }
                }}
                style={{ color: item.color }}
              >
                <Icon className="mx-2" color={item.color} />{" "}
                <span className="mx-2">{item.name}</span>
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
};

export default MessageOptions;
