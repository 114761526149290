import { Grid, IconButton, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Icons } from "../../../assets";
import CommandCenterHeader from "../../../components/Header/CommandCenterHeader";
import MenuHeader from "../../../components/Header/MenuHeader";
import MapsComponent from "../../../components/Maps/MapsComponent";
import CommandCenterTabs from "../../../components/Tabs/CommandCenterTabs";
import { latlong, regions } from "../../../data";
import "./CommandCenterComponent.css";
import ProvidersCard from "../../../components/Cards/ProvidersCard";
import AppointmentsCard from "../../../components/Cards/AppointmentCard";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CalendarComponent from "../../../components/Calendar/CalendarNew";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import SearchComponent from "../../../components/Search/Search";
import { useDispatch, useSelector } from "react-redux";
import Chat from "../../Chat/Chat";
import DraggableAppointments from "./DraggableAppointments";
import AddVisitModal from "../../../components/Modals/AddVisitModal";
import FilterListIcon from "@mui/icons-material/FilterList";
import Loading from "../../../components/Loading";
import CustomButton from "../../../components/Buttons/CustomButton";
import InfoModal from "../../../components/Modals/InfoModal";
import useProviders from "../../../hooks/useProviders";
import useAuth from "../../../hooks/useAuth";
import PublishToAthenaModal from "../../../components/Modals/PublishToAthenaModal";
import { ALL_ZONES } from "../../../constants/strings";
import { groupedVisits } from "../../../utilities/resuseFunctions";
import useZones from "../../../hooks/useZones";
import { changeRegion } from "../../../redux/reducers/selections.reducer";

const CommandCenterComponent = ({
  isProvider,
  providers,
  data,
  setData,
  id,
  noData,
  loading,
  eligibleVisits,
  isPTO,
}) => {
  const { height } = useWindowDimensions();
  const dispatch = useDispatch();
  const selections = useSelector((state) => state.selections);
  // console.log(selections, "selections");
  const [selectedRegion, setSelectedRegion] = useState(
    selections?.region || regions[0]
  );
  const { selectedProvider } = selections;
  // const [selectedProvider, setSelectedProvider] = useState(
  //   selections.selectedProvider || "All Providers"

  const { userData } = useAuth();
  const { zones } = useZones();

  const {
    isPublishEnabled,
    publishToAthena,
    getIsProviderLocked,
    ProviderLock,
    updateSequence,
    loading: loadingSeq,
  } = useProviders();
  // );
  const [search, setSearch] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isPublish, setIsPublish] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [info, setInfo] = useState({});
  const [type, setType] = useState(selections?.type || "Scheduler");

  useEffect(() => {
    setSelectedRegion(selections.region);
  }, [selections.region]);

  useEffect(() => {
    if (isProvider && id) {
      const proId = providers.find((pro) => pro.ProviderID === id);
      const zoneData = zones.find((zone) => zone.ZoneID === proId?.ZoneID);
      const lat =
        proId?.ProviderLatitude && parseFloat(proId?.ProviderLatitude);
      const lng =
        proId?.ProviderLongitude && parseFloat(proId?.ProviderLongitude);
      dispatch(
        changeRegion({
          ...zoneData,
          ZoneLatitude: lat,
          ZoneLongitude: lng,
        })
      );
    }
  }, [selectedProvider, providers.length]);

  useEffect(() => {
    isProvider &&
      id &&
      getIsProviderLocked({
        ProviderID: id,
      }).then((res) => {
        if (res && res.UserID !== userData?.id) {
          // isPublishEnabled && setInfoOpen(true);
          // isPublishEnabled &&
          //   setInfo({
          //     title: "Schedule In Review",
          //     text: `UserID: ${res.UserID || ""} ${
          //       res?.User?.firstName || ""
          //     } ${
          //       res?.User?.lastName || ""
          //     } is currently reviewing this Providers schedule. Any changes made by you will overwrite the changes made.`,
          //   });
        } else {
          !res &&
            ProviderLock({
              ProviderID: id,
              UserID: userData.id,
            });
        }
      });
  }, []);

  let providersFilter = isProvider
    ? providers?.filter((i) => i.ProviderID == selectedProvider)
    : providers?.filter(
        (i) =>
          i.zones?.ZoneName === selectedRegion?.ZoneName ||
          selectedRegion?.ZoneName === ALL_ZONES
      );
  let dataFilter = data.filter((i) =>
    isProvider
      ? i.PatientName?.toLowerCase()?.includes(search?.toLowerCase()) ||
        i.Facility?.toLowerCase()?.includes(search?.toLowerCase())
      : (i.zones?.ZoneName === selectedRegion?.ZoneName ||
          selectedRegion?.ZoneName === ALL_ZONES) &&
        !i.cancelled &&
        (search
          ? i.ProviderFN?.toLowerCase()?.includes(search?.toLowerCase()) ||
            i.ProviderLN?.toLowerCase()?.includes(search?.toLowerCase())
          : true)
  );

  const groupedItems = groupedVisits(isProvider, dataFilter);

  const onAdd = () => {
    setIsAdd(true);
  };

  const onEdit = () => {
    setIsEdit(true);
  };

  const onEditDone = () => {
    setIsEdit(false);
    onUpdateSeq();
  };

  const onSubmit = (selected) => {
    setData((prev) => [...prev, { ...selected }]);
  };

  const onPublish = ({ startDate, endDate }) => {
    publishToAthena({
      ProviderID: id,
      startDate,
      endDate,
      userId: userData?.id,
      userData,
    }).then(async (res) => {
      setInfo(res.message);
      setInfoOpen(true);
      setIsPublish(false);
    });
  };

  const onPublishClick = () => setIsPublish(true);

  const onUpdateSeq = () => {
    const payload = {
      Schedules:
        data?.map((i) => ({ ID: i.ID, SequenceNo: i.SequenceNo })) || [],
      VisitDate: moment(selections.date?.startDate || selections.date).format(
        "YYYY-MM-DD"
      ),
      ProviderID: id,
      userId: userData?.id,
    };
    updateSequence(payload);
  };

  return (
    <div>
      <Loading loading={loading || loadingSeq} />
      <MenuHeader />
      <CommandCenterHeader
        selectedRegion={selectedRegion}
        setSelectedRegion={setSelectedRegion}
        setType={setType}
        type={type}
        isProvider={isProvider}
        // selectedProvider={selectedProvider}
        // setSelectedProvider={setSelectedProvider}
      />
      <Grid container className="px-3">
        <Grid item md={6} xs={12} className="px-3">
          <CommandCenterTabs
            tabComponents={[MapsComponent, CalendarComponent]}
            selectedRegion={selectedRegion}
            date={selections.date}
            data2={{
              providers: providersFilter,
              appointments: dataFilter,
              isProvider,
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          {selections?.type === "Scheduler" && (
            <>
              {/* {selections?.view === "Calendar" && !isAdd && (
                <div>
                  <Typography className="today-date mx-3">
                    {moment(selections.date).format("MMMM DD, YYYY")}
                  </Typography>
                </div>
              )} */}
              {!isAdd && (
                <div>
                  <Typography className="today mx-3">
                    {" "}
                    {moment(selections.date).isSame(moment(), "day")
                      ? "Today"
                      : moment(selections.date).format("dddd, MMMM DD YYYY")}
                  </Typography>
                </div>
              )}
              <Grid className="d-flex justify-content-between mx-2">
                <div className="w-100" style={{ marginLeft: 8 }}>
                  <SearchComponent value={search} onChange={setSearch} />
                </div>
                <IconButton>
                  <FilterListIcon className="text-black" />
                </IconButton>
                {isProvider && (
                  <Grid className="mx-2 d-flex">
                    {isProvider &&
                      !isAdd &&
                      !isEdit &&
                      !moment(selections.date).isBefore(
                        moment().format("YYYY-MM-DD")
                      ) &&
                      !isPTO && (
                        <>
                          <button className="btn rightIcons " onClick={onAdd}>
                            <img
                              src={Icons.Add_Icon}
                              alt="add"
                              className="icon1"
                            />
                          </button>
                          <button
                            className="btn rightIcons mx-3"
                            onClick={onEdit}
                          >
                            <img
                              src={Icons.Edit_Icon}
                              alt="edit"
                              className="icon1"
                            />
                          </button>
                        </>
                      )}
                    {isEdit && (
                      <>
                        <button
                          className="btn rightIcons mx-3 p-2"
                          onClick={() => setIsEdit(false)}
                        >
                          <CloseIcon sx={{ fontSize: 25 }} color="black" />
                        </button>
                        <button
                          className="btn rightIcons p-2"
                          onClick={onEditDone}
                        >
                          <CheckIcon sx={{ fontSize: 25 }} color="black" />
                        </button>
                      </>
                    )}
                  </Grid>
                )}
              </Grid>
              {!isEdit && (
                <Grid
                  sx={{
                    height: isProvider ? height - 320 : height - 270,
                    overflowY: "auto",
                  }}
                  className={
                    isProvider && !dataFilter?.length
                      ? "d-flex justify-content-center align-items-center"
                      : ""
                  }
                >
                  {!isProvider &&
                    dataFilter?.map((i, idx) => {
                      return (
                        <ProvidersCard
                          key={i.ProviderID}
                          index={idx}
                          data={i}
                          view={selections?.view}
                          type={type}
                        />
                      );
                    })}
                  {isProvider &&
                    (dataFilter.length ? (
                      dataFilter?.map((i, idx) => {
                        return (
                          <AppointmentsCard
                            key={idx}
                            data={i}
                            index={idx}
                            setData={setData}
                            totalData={dataFilter}
                            provider={selectedProvider}
                            groupedItems={groupedItems}
                          />
                        );
                      })
                    ) : (
                      <Typography className="m-3 no-data">{noData}</Typography>
                    ))}
                </Grid>
              )}
              {isEdit && (
                <>
                  <DraggableAppointments
                    data={dataFilter}
                    setData={setData}
                    selectedProvider={selectedProvider}
                    isProvider={isProvider}
                  />
                </>
              )}
              {isProvider && !!dataFilter?.length && (
                <Grid className="d-flex justify-content-center align-items-center">
                  <CustomButton
                    className="publish-btn"
                    disabled={false}
                    // disabled={!isPublishEnabled}
                    onClick={onPublishClick}
                  >
                    Publish to Athena
                  </CustomButton>
                </Grid>
              )}
              {isAdd && (
                <AddVisitModal
                  isOpen={isAdd}
                  onClose={() => setIsAdd(false)}
                  onSubmit={onSubmit}
                  data={providersFilter?.[0] || {}}
                  total={dataFilter?.length}
                  eligibleVisits={eligibleVisits}
                />
              )}
            </>
          )}
          {selections?.type === "Messenger" && <Chat />}
          {infoOpen && (
            <InfoModal
              open={infoOpen}
              handleClose={() => {
                setInfoOpen(false);
                setInfo({});
              }}
              title={info?.title}
              subtext={info?.text}
            />
          )}
          {isPublish && (
            <PublishToAthenaModal
              open={isPublish}
              handleClose={() => {
                setIsPublish(false);
              }}
              handlePublish={onPublish}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default CommandCenterComponent;
