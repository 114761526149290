import React from "react";
import { Typography } from "@mui/material";
import "./AppointmentButton.css";

const AppointmentButton = ({
  title,
  onClick,
  className = "",
  disabled,
  variant,
  textClass = "",
}) => {
  const classes = ["button-appointment ", className];
  const classesText = ["title-text ", textClass];
  if (variant && !disabled) {
    classes.push(variant + "Scheme");
    classesText.push(variant + "Text");
  }
  if (disabled) {
    classes.push("disable");
    classes.push(variant + "Scheme");
    classesText.push(variant + "Text");
    // classesText.push("disable");
  }
  return (
    <button disabled={disabled} onClick={onClick} className={classes.join(" ")}>
      <Typography className={classesText.join(" ")}>{title}</Typography>
    </button>
  );
};

export default AppointmentButton;
