import * as React from "react";
const ImageSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 20}
    height={props.height || 20}
    fill="none"
    preserveAspectRatio="none"
    // viewBox="0 0 200 200"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      fillRule="evenodd"
      d="M1 3.75A2.75 2.75 0 0 1 3.75 1h12.5A2.75 2.75 0 0 1 19 3.75v12.5A2.75 2.75 0 0 1 16.25 19H3.75A2.75 2.75 0 0 1 1 16.25V3.75Zm1.5 9.187v3.313c0 .69.56 1.25 1.25 1.25h12.5c.69 0 1.25-.56 1.25-1.25v-3.174l-3.078-2.873-2.744 1.83 2.113 2.197a.75.75 0 1 1-1.082 1.04L6.941 9.27 2.5 12.937Zm8.121-2.003L7.541 7.73a.75.75 0 0 0-1.018-.058L2.5 10.992V3.75c0-.69.56-1.25 1.25-1.25h12.5c.69 0 1.25.56 1.25 1.25v7.274l-2.488-2.322a.75.75 0 0 0-.928-.076l-3.463 2.308Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ImageSvg;
