import React from "react";
import { styled } from "@mui/material/styles";
import { Tabs, Tab, Box, Radio } from "@mui/material";
import { useDispatch } from "react-redux";
import { changeView } from "../../redux/reducers/selections.reducer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    // backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    // maxWidth: 40,
    width: "100%",
    backgroundColor: "#3062D4",
  },
  margin: "0px 9px",
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    width: "50%",
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "#4A545E",
    "&.Mui-selected": {
      color: "#3062D4",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

const CommandCenterTabs = ({
  tabComponents,
  selectedRegion,
  data2,
  isCommunity,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(changeView(newValue === 0 ? "Map" : "Calendar"));
  };
  return (
    <Box>
      {!isCommunity && (
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab label="Map" />
          <StyledTab label="Calendar" />
        </StyledTabs>
      )}
      {tabComponents?.map((TabComponent, idx) => {
        return (
          <TabPanel key={idx} value={value} index={idx}>
            <TabComponent
              selectedRegion={selectedRegion}
              data={idx === 0 ? data2 : []}
            />
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default CommandCenterTabs;
