import React, { useEffect, useState } from "react";
import MenuHeader from "../../../components/Header/MenuHeader";
import DataTable from "../../../components/DataTable/DataTable";
import { regionsHeader } from "../../../data";
import Name from "../../../components/DataTable/Name";
import IconComponent from "../../../components/DataTable/Icon";
import TableFilter from "../../../components/Filter/TableFilter";
import AddRegionModal from "../../../components/Modals/AddRegionModal";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
// import { useSelector } from "react-redux";
import useZones from "../../../hooks/useZones";
// import ZipCodesModal from "../../../components/Modals/ZipCodesModal";
import useDebounce from "../../../hooks/useDebounce";
import DeleteModal from "../../../components/Modals/DeleteModal";

const Regions = () => {
  // const regions = useSelector((state) => state.regions.data);
  const { zones, loading, count, getZones, deleteZoneApi } = useZones();
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  // const [zipCodeOpen, setZipCodeOpen] = useState(false);
  const [selected, setSelected] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const debouncedSearchText = useDebounce(search, 750);

  useEffect(() => {
    getZones({ search: debouncedSearchText });
  }, [debouncedSearchText]);

  const onChangePage = (data, limit) => {
    setPage(data);
    getZones({
      skip: data * 20,
      limit: limit
        ? limit === "View All"
          ? 0
          : limit
        : rowsPerPage === "View All"
        ? 0
        : rowsPerPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const rows = event.target.value;
    setRowsPerPage(rows);
    onChangePage(0, rows);
  };

  const onEdit = (tmp) => {
    setSelected(tmp);
    setOpen(true);
  };

  const onDelete = (tmp) => {
    setSelected(tmp);
    setIsDelete(true);
  };

  const handleDelete = () => {
    deleteZoneApi(selected);
  };

  const generateRows = zones.map((region, i) => {
    return {
      name: region.ZoneName,
      nameSort: region.ZoneName,
      latitude: region?.ZoneLatitude,
      longitude: region?.ZoneLongitude,
      dataSource: region.ZoneDataSource,
      ZoneZipCodes: (
        <Name
          key={i}
          i={region}
          name={"View All"}
          onClick={() => {
            setOpen(true);
            setSelected(region);
          }}
        />
      ),
      edit: (
        <IconComponent
          key={i}
          i={region}
          icon="Edit_Icon"
          onClick={() => onEdit(region)}
        />
      ),
      delete: (
        <IconComponent
          key={i}
          i={region}
          onClick={() => onDelete(region)}
          icon="Delete_Icon"
        />
      ),
    };
  });

  return (
    <Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MenuHeader isSecondary />
      <Grid className="my-3">
        <TableFilter
          count={count}
          sectionName={"Zones"}
          search={search}
          setSearch={setSearch}
          onAdd={() => setOpen(true)}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          onChangePage={onChangePage}
          rowsPerPage={rowsPerPage}
        />
      </Grid>
      <Grid className="mx-5">
        <DataTable
          columns={regionsHeader}
          rows={generateRows}
          count={count}
          onChangePage={onChangePage}
          page={page}
        />
      </Grid>
      {open && (
        <AddRegionModal
          open={open}
          handleClose={() => {
            setOpen(false);
            setSelected({});
          }}
          selected={selected}
        />
      )}
      {isDelete && (
        <DeleteModal
          open={isDelete}
          handleClose={() => {
            setIsDelete(false);
            setSelected({});
          }}
          handleDelete={handleDelete}
          title={"Delete Zone"}
          subtext={"Are you sure you want to delete this zone?"}
        />
      )}
    </Grid>
  );
};

export default Regions;
