import * as React from "react";
const CalendarPlusSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      fillRule="evenodd"
      d="M6.75 1a.75.75 0 0 1 .75.75V3h5V1.75a.75.75 0 0 1 1.5 0V3h2.25A2.75 2.75 0 0 1 19 5.75v3a.75.75 0 0 1-.75.75H2.5v6.75c0 .69.56 1.25 1.25 1.25h5a.75.75 0 0 1 0 1.5h-5A2.75 2.75 0 0 1 1 16.25V5.75A2.75 2.75 0 0 1 3.75 3H6V1.75A.75.75 0 0 1 6.75 1ZM6 4.5H3.75c-.69 0-1.25.56-1.25 1.25V8h15V5.75c0-.69-.56-1.25-1.25-1.25H14v.75a.75.75 0 0 1-1.5 0V4.5h-5v.75a.75.75 0 0 1-1.5 0V4.5ZM16 12a.75.75 0 0 1 .75.75v2.5h2.5a.75.75 0 0 1 0 1.5h-2.5v2.5a.75.75 0 0 1-1.5 0v-2.5h-2.5a.75.75 0 0 1 0-1.5h2.5v-2.5A.75.75 0 0 1 16 12Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CalendarPlusSvg;
