import { useEffect, useState } from "react";
import MessagingApi from "../services/messagingApi";
import {
  refreshError,
  userLogin,
  userLoginFailure,
  fetchUsers,
  otpVerify,
  logOut,
  fetchUserData,
  deleteUser,
  updateError,
  setAlertNote,
} from "../redux/reducers/users.reducer";
import { useDispatch, useSelector } from "react-redux";

export default function useAuth() {
  const dispatch = useDispatch();
  const { userData, error, users, otpVerified, alertNote, alertType } =
    useSelector((state) => state.users);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setLoading(false);
  };
  const handleOpen = () => {
    setLoading(true);
  };

  useEffect(() => {
    dispatch(refreshError());
  }, []);

  const updateAlertNote = (text, type) =>
    dispatch(setAlertNote({ text: text || "", type: type || "" }));

  const initializeUser = async (data) => {
    handleOpen();
    return MessagingApi.post("/user/init", data)
      .then((res) => {
        // dispatch(addCareTag(res?.data?.data || []));
        return res;
      })
      .catch((err) => {
        updateAlertNote(err?.response?.data?.error, "error");
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const onboardUser = async (data, otpVerified) => {
    handleOpen();
    return MessagingApi.post("/user/signup", data)
      .then((res) => {
        dispatch(userLogin({ ...res?.data, otpVerified }));
        return res;
      })
      .catch((err) => {
        dispatch(userLoginFailure(err?.response?.data?.error || err.message));
        throw new Error(err.response?.data?.error);
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const inviteUser = async ({ email, role }) => {
    handleOpen();
    return MessagingApi.post("/user/invite", { email, role })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw new Error(err.response?.data?.error);
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const isUserExist = async (data, skip) => {
    handleOpen();
    return MessagingApi.post("/user/exist", data)
      .then((res) => {
        handleClose();
        dispatch(refreshError());
        return res;
      })
      .catch((err) => {
        handleClose();
        if (skip) {
          dispatch(userLoginFailure(err.response?.data?.error || err.message));
          throw new Error(err.response?.data?.error);
        }
      });
  };

  const sendOTP = async (data) => {
    handleOpen();
    return MessagingApi.post("/user/otp/send", data)
      .then((res) => {
        handleClose();
        dispatch(refreshError(null));
        updateAlertNote("OTP Sent!!!", "success");
        return res;
      })
      .catch((err) => {
        handleClose();
        dispatch(userLoginFailure(err.response?.data?.error));
        throw new Error(err.response?.data?.error);
      });
  };

  const verifyOTP = async (data) => {
    handleOpen();
    return MessagingApi.post("/user/otp/verify", data)
      .then((res) => {
        handleClose();
        dispatch(otpVerify());
        return res;
      })
      .catch((err) => {
        handleClose();
        dispatch(updateError(err.response?.data?.error));
        throw new Error(err.response?.data?.error);
      });
  };

  const isValidInvite = async (data) => {
    handleOpen();
    return MessagingApi.post("/user/invite/valid", data)
      .then((res) => {
        return res.data.data;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const userLoginApi = async (data) => {
    handleOpen();
    return await MessagingApi.post(`/user/login`, data)
      .then((res) => {
        dispatch(userLogin(res?.data));
        handleClose();
        return res;
      })
      .catch((err) => {
        dispatch(userLoginFailure(err?.response?.data?.error || err.message));
        throw new Error(err);
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const fetchUsersApi = async (data) => {
    handleOpen();
    return MessagingApi.get(`/user/fetch`, data)
      .then((res) => {
        dispatch(fetchUsers(res?.data?.data || []));
        return res;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const fetchCommunityUsers = async ({ communityId }) => {
    handleOpen();
    return MessagingApi.post(`/user/community/${communityId}`)
      .then((res) => {
        // dispatch(fetchUsers(res?.data?.data || []));
        return res.data.data;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const updateUser = async (data, notMe) => {
    handleOpen();
    return MessagingApi.patch(`/user/update/${data.id}`, data)
      .then((res) => {
        !notMe && dispatch(fetchUserData(res?.data?.data || {}));
        return res;
      })
      .catch((err) => {
        updateAlertNote(err?.response?.data?.error, "error");
        throw new Error(err);
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const forgotPassword = async (data) => {
    handleOpen();
    return MessagingApi.post(`/user/forgotpass/${data.id}`, data)
      .then((res) => {
        // !notMe && dispatch(fetchUserData(res?.data?.data || {}));
        updateAlertNote(
          "We have sent an Email with new Password!! ",
          "success"
        );
        return res;
      })
      .catch((err) => {
        updateAlertNote(err?.response?.data?.error, "error");
        throw new Error(err);
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const removeUser = async (data) => {
    handleOpen();
    return MessagingApi.delete(`/user/remove/${data.id}`)
      .then((res) => {
        dispatch(deleteUser(res?.data?.data || {}));
        return res.data?.data;
      })
      .catch((err) => {
        updateAlertNote(err?.response?.data?.error, "error");
        throw new Error(err);
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const uploadDocument = async (data) => {
    handleOpen();
    return MessagingApi.post(`/document/upload`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        return res;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const userLogout = () => {
    dispatch(logOut());
    window.OneSignal?.logout();
  };

  const isSageHospice =
    userData?.role === "back office" && userData?.position === "Hospice";

  return {
    userData: userData && {
      ...userData,
      communityId: isSageHospice ? 19420 : userData?.communityId,
      isSageHospice,
    },
    error,
    onboardUser,
    userLoginApi,
    loading,
    handleClose,
    fetchUsersApi,
    users,
    isUserExist,
    initializeUser,
    isValidInvite,
    sendOTP,
    verifyOTP,
    updateUser,
    otpVerified,
    userLogout,
    uploadDocument,
    fetchCommunityUsers,
    removeUser,
    forgotPassword,
    inviteUser,
    alertNote,
    alertType,
    updateAlertNote,
  };
}
