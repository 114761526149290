import React, { useLayoutEffect } from "react";
import MenuHeader from "../../components/Header/MenuHeader";
import { Grid } from "@mui/material";
import Chat from "./Chat";
import { useDispatch } from "react-redux";
import {
  changeIsFullScreen,
  changeType,
} from "../../redux/reducers/selections.reducer";

const MessageCenter = () => {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(changeIsFullScreen(true));
    dispatch(changeType("Messenger"));
  }, []);
  return (
    <div>
      <MenuHeader />
      <Grid>
        <Chat isFullScreen={true} />
      </Grid>
    </div>
  );
};

export default MessageCenter;
