import React, { useEffect, useState } from "react";
// import { chats } from "../../data";
import ChatCard from "../../components/Cards/ChatCard";
import SearchComponent from "../../components/Search/Search";
import CustomButton from "../../components/Buttons/CustomButton";
// import Circle_Icon from "../../assets/images/Circle_Icon.png";
// import Dropdown_Indicator from "../../assets/images/Dropdown_Indicator.png";
import Tag_Icon from "../../assets/images/Tag.png";
import Sort_Icon from "../../assets/images/Sort.png";
import Options_Icon from "../../assets/images/Options.png";
import { Divider, Grid, Tooltip, Typography } from "@mui/material";
import ChatModal from "../../components/Modals/ChatModal";
import ConversationScreen from "./ConversationScreen";
import NewMessageComponent from "./NewMessageComponent";
import PatientCaseCreatedModal from "../../components/Modals/PatientCaseCreated";
import "./Chat.css";
import clsx from "clsx";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useNavigate, useLocation } from "react-router-dom";
import useChats from "../../hooks/useMessenger";
import Loading from "../../components/Loading";
// import EmailSvg from "../../assets/svgs/EmailSvg";
import MessageSvg from "../../assets/svgs/MessageSvg";
import socket from "../../services/socket";
import ArrowUpRight from "../../assets/svgs/ArrowUpRight";
import SelectableButton from "../../components/Buttons/SelectableButton";

const Chat = ({ isFullScreen }) => {
  const { height } = useWindowDimensions();
  const {
    getChats,
    chats,
    loading,
    userData,
    currentChat,
    setCurrentChat,
    updateEHR,
    leftMember,
    updateLastSeen,
    setLatestMessages,
    latestMessages,
    getLatestMessages,
  } = useChats();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  // const [chatActive, setChatActive] = useState(
  //   isFullScreen ? chats?.[0] || {} : {}
  // );
  const [chatsData, setChatsData] = useState(chats || []);
  const [isNewMessage, setIsNewMessage] = useState(null);
  const [tagFilter, setTagFilter] = useState(false);
  const [caseModalOpen, setCaseModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [latest, setLatest] = useState([]);

  useEffect(() => {
    getChats(userData.id).then((chats) => {
      if (true) {
        setCurrentChat(chats.find((chat) => chat.conversationId === 18));
      }
    });
    getLatestMessages(userData.id);
  }, []);

  useEffect(() => {
    const respData = [...chats];
    respData?.sort(
      (a, b) =>
        new Date(b.lastMessage.createdAt).getTime() -
        new Date(a.lastMessage.createdAt).getTime()
    );

    setChatsData(respData);
    // !latestMessages.length && setLatestMessages(respData);
    // !currentChat && setCurrentChat(respData?.[0] || {});
  }, [chats]);

  useEffect(() => {
    if (tagFilter) {
      setLatest([...latestMessages]);
      const tmpData = latestMessages.filter(
        (i) =>
          // i?.lastMessage?.TaggedUsers?.length ||
          // i?.lastMessage?.TaggedPatients?.length ||
          i?.lastMessage?.Metadata?.length
      );
      setLatestMessages([...tmpData]);
    } else {
      setLatestMessages([...latest]);
    }
  }, [tagFilter]);

  const handleChat = (data) => {
    updateLastSeen(
      userData.id,
      data.conversationId
      // "readall"
    );
    setCurrentChat(data);
    !isFullScreen && setOpen(true);
  };

  const onSendMessage = (message) => {};

  // console.log(currentChat, "currentChat", chats);

  useEffect(() => {
    if (Object.keys(leftMember).length !== 0) {
      console.log(leftMember);
    }
  }, [leftMember]);

  const data = !isFullScreen ? latestMessages : chatsData;

  return (
    <Grid
      container
      className={isFullScreen ? "d-flex" : ""}
      // style={isFullScreen ? styles.fsContainer : {height: '100%'}}
    >
      {loading && <Loading loading={loading} />}
      <Grid
        item
        xs={12}
        md={isFullScreen ? 4 : 12}
        // style={styles.fsLeft}
      >
        <div className={isFullScreen ? "border-1 mx-2 my-1" : ""}>
          {/* {isNewMessage ? ( */}
          <>
            <NewMessageComponent
              isFullScreen={isFullScreen}
              setIsNewMessage={setIsNewMessage}
              isNewMessage={isNewMessage}
              setChatActive={currentChat}
              setOpen={setOpen}
            />
          </>
          {/* ) : ( */}
          <>
            {!isFullScreen && (
              <>
                <Grid className="d-flex align-items-center justify-content-between py-1">
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: 25,
                    }}
                  >
                    New Messages
                  </Typography>
                  <Typography
                    onClick={() => navigate("/messagecenter")}
                    role="button"
                    className="mx-3 full-inbox"
                  >
                    All Messages <ArrowUpRight />
                  </Typography>
                </Grid>
                <Divider />
              </>
            )}
            <Grid
              className={clsx(
                "d-flex align-items-center justify-content-between mt-2",
                isFullScreen && "mx-3 mt-3 mb-2"
              )}
            >
              {/* <CustomButton
                  onClick={() => setIsNewMessage(true)}
                  className={
                    "d-flex py-2 mx-1 m-0 align-items-center providersFilter justify-content-center w-100"
                  }
                >
                  <MessageSvg color="#fff" />
                  <Typography className="providerText px-2">
                    {isFullScreen ? "New" : "New Message"}
                  </Typography>
                </CustomButton> */}
              <Grid className="d-flex w-100">
                <SelectableButton
                  onClick={() => setIsNewMessage("direct")}
                  title={"Direct Messages"}
                  isSelected={true}
                  className={"py-2 "}
                />
                <SelectableButton
                  onClick={() => setIsNewMessage("group")}
                  title={"Group Chats"}
                  isSelected={true}
                  className={"py-2 "}
                />
              </Grid>
              <Grid className="d-flex">
                {!isFullScreen && (
                  <Tooltip title="Tagged Messages">
                    <button
                      className={clsx(
                        "btn p-2 mx-2 align-items-center justify-content-center filter-buttons d-flex",
                        tagFilter && "tagBg"
                      )}
                      onClick={() => setTagFilter(!tagFilter)}
                    >
                      <img src={Tag_Icon} alt="tag" className="icon1" />
                    </button>
                  </Tooltip>
                )}
                <Tooltip title="Sort">
                  <button
                    className={
                      "btn align-items-center justify-content-center filter-buttons d-flex mx-2"
                    }
                  >
                    <img src={Sort_Icon} alt="sort" className="icon1" />
                  </button>
                </Tooltip>
                {/* {isFullScreen && (
                  <Tooltip title="Options">
                    <button
                      className={
                        "btn mx-1 align-items-center justify-content-center filter-buttons d-flex"
                      }
                    >
                      <img src={Options_Icon} alt="options" className="icon1" />
                    </button>
                  </Tooltip>
                )} */}
              </Grid>
            </Grid>
            <Grid className={clsx("my-3", isFullScreen && "mx-3")}>
              <SearchComponent value={search} onChange={setSearch} />
            </Grid>
            <Grid
              style={{
                height: isFullScreen ? height - 220 : height - 340,
                overflow: "auto",
              }}
            >
              {[...data]
                ?.sort(
                  (a, b) =>
                    new Date(b.lastMessage.createdAt).getTime() -
                    new Date(a.lastMessage.createdAt).getTime()
                )
                ?.filter(
                  (data) =>
                    data.conversationName
                      ?.toLowerCase()
                      ?.includes(search.toLowerCase()) ||
                    data.contactFN
                      ?.toLowerCase()
                      ?.includes(search.toLowerCase()) ||
                    data.contactLN
                      ?.toLowerCase()
                      ?.includes(search.toLowerCase())
                )
                ?.map((item, idx) => {
                  return (
                    <div key={idx}>
                      <Divider />
                      <ChatCard
                        data={item}
                        onClick={handleChat}
                        isFullScreen={isFullScreen}
                        selected={currentChat}
                        isMe={userData.id === item.lastMessage.createdByID}
                        updateEHR={updateEHR}
                        onSend={() => {
                          updateEHR({
                            metadata: item?.lastMessage?.Metadata || [],
                            note: item?.lastMessage?.messageBody,
                            message: item?.lastMessage,
                          }).then(() => setCaseModalOpen(true));
                        }}
                      />
                    </div>
                  );
                })}
            </Grid>
            {open && (
              <ChatModal
                socket={socket}
                open={open}
                handleClose={() => setOpen(false)}
                chat={currentChat}
                onSendMessage={onSendMessage}
              />
            )}
            {caseModalOpen && (
              <PatientCaseCreatedModal
                open={caseModalOpen}
                handleClose={() => setCaseModalOpen(false)}
              />
            )}
          </>
          {/* )} */}
        </div>
      </Grid>
      {isFullScreen && (
        <Grid
          item
          xs={12}
          md={8}
          // style={isFullScreen && styles.fsRight}
        >
          {
            // !!chats.length &&
            currentChat?.conversationId && (
              <Grid className="border-1 mx-2 my-1">
                <ConversationScreen
                  isFullScreen
                  chat={currentChat}
                  onSendMessage={onSendMessage}
                  socket={socket}
                  setCurrentChat={setCurrentChat}
                />
              </Grid>
            )
          }
        </Grid>
      )}
    </Grid>
  );
};

export default Chat;
