import { Grid, Typography } from "@mui/material";
import React from "react";
import LeftArrowSvg from "../../assets/svgs/LeftArrowSvg";
import MessageCard from "../../components/Cards/MessageCard";

const StarredMessages = ({ onStarredClick, starred, chat }) => {
  return (
    <Grid
      sx={{
        width: {
          xs: "300px",
          sm: "300px",
          md: "350px",
        },
        //   height: "100vh",
        backgroundColor: "#F2F2F2",
      }}
      className="py-3 overflow-hidden"
    >
      <div className={`d-flex align-items-center px-3`}>
        <div role="button" className="close-box " onClick={onStarredClick}>
          {/* <LeftIcon className="close-icon-1" /> */}
          <LeftArrowSvg className="close-icon-1" />
        </div>
        <Typography className="px-3 add-patients-modal-title group-header">
          Starred Messages
        </Typography>
      </div>
      <Grid className="my-2 px-3 group-content">
        {(starred || [])?.map((item, idx) => {
          return (
            <MessageCard key={idx} message={item.Message} data={chat} starred />
          );
        })}
      </Grid>
    </Grid>
  );
};

export default StarredMessages;
