import React, { useState } from "react";
import { colors } from "../../styles";
import CustomButton from "../Buttons/CustomButton";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import "./AddRegionModal.css";
import CloseIcon from "@mui/icons-material/Close";
import { Form } from "react-bootstrap";
import useZones from "../../hooks/useZones";
// import { addRegion, updateRegion } from "../../redux/reducers/regions.reducer";
import ConfirmModal from "./DeleteModal";

const AddRegionModal = ({ open: isOpen, handleClose, selected }) => {
  const { addZoneApi, loading, updateZoneApi, getZones } = useZones(true);
  const [open, setOpen] = useState(isOpen);
  const [edit, setEdit] = useState(selected || {});
  const [isConfirm, setIsConfirm] = useState(false);
  const [zipCodes, setZipCodes] = useState(
    selected?.zoneszipcodes?.map((i) => i.ZipCode).join(", ") || ""
  );

  const onChangeHandler = (val, name) => {
    setEdit({
      ...edit,
      [name]: val,
    });
  };

  const onClose = () => {
    setOpen(false);
    handleClose();
    setEdit({});
  };

  const onSubmit = () => {
    // const allFieldsHaveValue =
    //   // Object.keys(edit)?.length === 7 &&
    //   Object.values(edit).every((value) => Boolean(value));
    // // console.log(allFieldsHaveValue, "allFieldsHaveValue", edit);
    // if (!allFieldsHaveValue) return;
    onClose();
    if (selected?.ZoneID) {
      // find new items (items added to updated array)
      const oldArray = selected?.zoneszipcodes?.map((i) => i.ZipCode) || [];
      const newArray = zipCodes?.split(",")?.map((i) => i.trim()) || [];
      const newItems = newArray.filter((item) => !oldArray.includes(item));
      // console.log("New items:", newItems); // Output: [5, 6]

      // find removed items (items removed from old array)
      const removedItems = oldArray.filter((item) => !newArray.includes(item));
      // console.log("Removed items:", removedItems); // Output: [1, 4]
      updateZoneApi({ ...edit, newItems, removedItems }).then(() => getZones());
    } else {
      addZoneApi(edit);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
        fullWidth
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid>
          <Grid
            className="d-flex justify-content-between py-1 px-3 align-items-center"
            style={{
              backgroundColor: colors.OFFICE_COLOR,
            }}
          >
            <Typography className="region-modal-title">
              {edit.ZoneID ? "Edit Zone" : "Create New Zone"}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon className="close-icon" />
            </IconButton>
          </Grid>
          <Grid className="px-2">
            <Grid className="m-2 px-3">
              <Typography className="fieldName py-2">Zone</Typography>
              <Form.Control
                placeholder="Zone Name"
                className="inputField px-2 border-1 w-100"
                value={edit?.ZoneName}
                onChange={(val) =>
                  onChangeHandler(val.target.value, "ZoneName")
                }
              />
            </Grid>
            <Grid className=" m-2">
              <Typography className="fieldName px-3 py-2">
                Define your Zone
              </Typography>
              <Grid className="d-flex mx-2">
                <Form.Control
                  placeholder="Enter Zip Codes seperated by comma (,)"
                  as={"textarea"}
                  rows={5}
                  className="px-2 mx-2"
                  value={zipCodes}
                  onChange={(e) => setZipCodes(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid className="m-2">
              <Typography className="fieldName px-3 py-2">
                Data Source
              </Typography>

              <Grid className="inputField px-3">
                <Form.Select
                  aria-label="Default select example"
                  value={edit?.ZoneDataSource}
                  onChange={(val) =>
                    onChangeHandler(val.target.value, "ZoneDataSource")
                  }
                >
                  <option>Select Data Source</option>
                  <option value="Athena EHR">Athena EHR</option>
                </Form.Select>
              </Grid>
            </Grid>
            <Grid>
              <CustomButton
                className="button mx-4"
                onClick={() => setIsConfirm(true)}
              >
                <Typography>
                  {edit.ZoneID ? "Update" : "Save this Zone"}
                </Typography>
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      {isConfirm && (
        <ConfirmModal
          open={isConfirm}
          handleClose={() => {
            setIsConfirm(false);
            onClose();
          }}
          handleDelete={onSubmit}
          title={`${selected?.ZoneID ? "Edit" : "Add"} Zone`}
          subtext={`Are you sure you want to ${
            selected?.ZoneID ? "update" : "add"
          } this Zone`}
          primaryText={"Confirm"}
        />
      )}
    </>
  );
};

export default AddRegionModal;
