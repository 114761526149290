import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  count: 0,
};

export const staffsSlice = createSlice({
  name: "staffs",
  initialState,
  reducers: {
    addStaff: (state, action) => {
      state.data = [...state.data, action.payload] || [];
    },
    replaceStaffs: (state, action) => {
      state.data = action.payload.data || [];
      state.count = action.payload.count || 0;
    },
    updateStaff: (state, action) => {
      const tmpUser = state.data.map((i) => {
        if (i.LocationStaffID == action.payload.LocationStaffID) {
          return action.payload;
        }
        return i;
      });
      state.data = tmpUser || [];
    },
    deleteStaff: (state, action) => {
      const tmpUser = state.data.filter(
        (i) => i.LocationStaffID !== action.payload.LocationStaffID
      );
      state.data = tmpUser || [];
    },
  },
  extraReducers: (builder) => {},
});

export const { addStaff, updateStaff, replaceStaffs, deleteStaff } =
  staffsSlice.actions;

export default staffsSlice.reducer;
