import React from "react";
import OfficeCC from "./OfficeCC";
import CommunityCC from "./CommunityCC";
import useAutoLogout from "../../hooks/useAutoLogout";

const CommandCenter = () => {
  const { userData } = useAutoLogout();

  if (userData?.role === "COMMUNITY" || userData?.isSageHospice) {
    return <CommunityCC />;
  }

  return (
    <div>
      <OfficeCC />
    </div>
  );
};

export default CommandCenter;
