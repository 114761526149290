import React from "react";
import { colors, fonts } from "../../styles";
import { Grid, Typography } from "@mui/material";

const IconText2 = ({ text, icon, selected, onClick, Icon }) => {
  return (
    <button
      className="btn d-flex align-items-center p-0 w-100 my-3"
      onClick={onClick}
    >
      <Grid
        className="d-flex align-items-center"
        style={{
          backgroundColor: "#F5F7F9",
          borderRadius: 6,
          padding: 10,
        }}
      >
        {Icon ? (
          <Icon
            color={!selected ? colors.BLACK_COLOR : colors.PROVIDER_COLOR}
          />
        ) : (
          <img
            alt="icon"
            src={icon}
            style={{
              // width: 20,
              height: 20,
              tintColor: !selected ? colors.BLACK_COLOR : colors.PROVIDER_COLOR,
            }}
          />
        )}
      </Grid>
      <Typography
        className="mx-4"
        style={{
          color: !selected ? colors.BLACK_COLOR : colors.PROVIDER_COLOR,
          fontFamily: "Inter",
          fontWeight: !selected ? 600 : 700,
          fontSize: 14,
        }}
      >
        {text}
      </Typography>
    </button>
  );
};

export default IconText2;
