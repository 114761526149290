import React, { useState } from "react";
import { colors } from "../../styles";
import CustomButton from "../Buttons/CustomButton";
import { Dialog, Grid, Typography, IconButton } from "@mui/material";
import "./AddRegionModal.css";
import CloseIcon from "@mui/icons-material/Close";
import { Form } from "react-bootstrap";
import useZones from "../../hooks/useZones";
import { providersCategory } from "../../data";
import { appointmentTypes } from "../../data/listOptions";

const FilterModal = ({
  open: isOpen,
  handleClose,
  onSubmit,
  selectedFilter,
  isProvider,
  isPatient,
}) => {
  const { zones } = useZones(true, true);
  const [open, setOpen] = useState(isOpen);
  const [edit, setEdit] = useState(
    selectedFilter || {
      ZoneID: "",
    }
  );

  const onChangeHandler = (val, name) => {
    setEdit({
      ...edit,
      [name]: val,
    });
  };

  const onClose = () => {
    setOpen(false);
    handleClose();
  };

  const onSubmitData = () => {
    onSubmit(edit);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
        fullWidth
      >
        <Grid>
          <Grid
            className="d-flex justify-content-between py-1 px-3 align-items-center"
            style={{
              backgroundColor: colors.OFFICE_COLOR,
            }}
          >
            <Typography className="region-modal-title">{"Filter"}</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon className="close-icon" />
            </IconButton>
          </Grid>
          <Grid container className="p-2 ">
            <Grid item md={12}>
              <Typography className="fieldName px-3 py-2">Zone</Typography>
              <Grid className="inputField px-3">
                <Form.Select
                  aria-label="Default select example"
                  value={edit?.ZoneID}
                  onChange={(e) => onChangeHandler(e.target.value, "ZoneID")}
                >
                  <option value="">Select Zone</option>
                  {zones.map((zone) => {
                    return <option value={zone.ZoneID}>{zone.ZoneName}</option>;
                  })}
                </Form.Select>
              </Grid>
            </Grid>
          </Grid>
          {isProvider && (
            <Grid container className="p-2 ">
              <Grid item md={12}>
                <Typography className="fieldName px-3 py-2">Type</Typography>
                <Grid className="inputField px-3">
                  <Form.Select
                    aria-label="Default select example"
                    value={edit?.Type}
                    onChange={(e) => onChangeHandler(e.target.value, "Type")}
                  >
                    <option value={""}>Select Type</option>
                    {providersCategory.map((type) => {
                      return <option value={type.vaue}>{type.name}</option>;
                    })}
                  </Form.Select>
                </Grid>
              </Grid>
            </Grid>
          )}
          {isPatient && (
            <Grid container className="p-2 ">
              <Grid item md={12}>
                <Typography className="fieldName px-3 py-2">
                  Last Visit Type
                </Typography>
                <Grid className="inputField px-3">
                  <Form.Select
                    aria-label="Default select example"
                    value={edit?.VisitType}
                    onChange={(e) =>
                      onChangeHandler(e.target.value, "VisitType")
                    }
                  >
                    <option value={""}>Select Visit Type</option>
                    {appointmentTypes
                      .filter(
                        (obj, index) =>
                          appointmentTypes.findIndex(
                            (item) => item.id === obj.id
                          ) === index
                      )
                      ?.map((i) => {
                        return <option value={i.name}>{i.name}</option>;
                      })}
                  </Form.Select>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid>
            <CustomButton
              className="button mx-4 py-2 px-5"
              onClick={onSubmitData}
            >
              <Typography>{"Save"}</Typography>
            </CustomButton>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default FilterModal;
