import React, { useEffect, useState } from "react";
import MenuHeader from "../../../components/Header/MenuHeader";
import DataTable from "../../../components/DataTable/DataTable";
import { staffHeader } from "../../../data";
import Name from "../../../components/DataTable/Name";
import IconComponent from "../../../components/DataTable/Icon";
import TableFilter from "../../../components/Filter/TableFilter";
// import Password from "../../../components/DataTable/Password";
import AddStaff from "../../../components/Modals/AddStaff";
import { Grid, Typography } from "@mui/material";
// import useStaffs from "../../../hooks/useStaff";
import { numberToPhone } from "../../../utilities/resuseFunctions";
import useDebounce from "../../../hooks/useDebounce";
import useAuth from "../../../hooks/useAuth";
import Loading from "../../../components/Loading";
import DeleteModal from "../../../components/Modals/DeleteModal";
import InfoModal from "../../../components/Modals/InfoModal";
// import Loading from "../../../components/Loading";

const Staff = ({ noHeader, noAdd, communityId }) => {
  // const { staffs, addStaffApi, updateStaffApi, getStaffs } = useStaffs();
  const { fetchCommunityUsers, loading, removeUser, inviteUser } = useAuth();
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selected, setSelected] = useState(null);
  // const debouncedSearchText = useDebounce(search, 750);
  const [infoOpen, setInfoOpen] = useState(false);
  const [info, setInfo] = useState({});

  // useEffect(() => {
  //   getStaffs({ search: debouncedSearchText });
  // }, [debouncedSearchText]);

  const init = () => {
    fetchCommunityUsers({ communityId }).then((res) => setData(res));
  };

  useEffect(() => {
    init();
  }, []);

  const onChangePage = (data) => {
    setPage(data);
  };

  const handleChangeRowsPerPage = (event) => {
    const rows = event.target.value;
    setRowsPerPage(rows);
    onChangePage(0, rows);
  };

  const onInvite = (user) => {
    inviteUser(user).then((res) => {
      setInfo(user);
      setInfoOpen(true);
    });
  };

  // const userFilter = data?.filter((i) => i.communityId === communityId);

  // console.log(loading, "loading", data);
  const handleDelete = () => {
    removeUser(selected).then(() => {
      const remUser = data.filter((i) => +i.id !== +selected.id);
      setData(remUser);
    });
  };

  return (
    <Grid>
      {loading && <Loading loading={loading} />}
      {!noHeader && <MenuHeader isSecondary />}
      {infoOpen && (
        <InfoModal
          open={infoOpen}
          handleClose={() => {
            setInfoOpen(false);
            setInfo({});
          }}
          title={"User Invite"}
          subtext={"User invite was sent successfully"}
        />
      )}
      <Grid className="my-3">
        <TableFilter
          count={data.length}
          sectionName={"Staff"}
          search={search}
          setSearch={setSearch}
          onAdd={() => setOpen(true)}
          noAdd={noAdd}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          onChangePage={onChangePage}
          rowsPerPage={rowsPerPage}
        />
      </Grid>
      <Grid className="mx-3">
        <DataTable
          count={data?.length}
          columns={staffHeader}
          rows={data
            .filter((fil) =>
              search
                ? fil.firstName
                    ?.toLowerCase()
                    ?.includes(search?.toLowerCase()) ||
                  fil.lastName?.toLowerCase()?.includes(search?.toLowerCase())
                : true
            )
            .map((x) => {
              const isInvite =
                x.status === "Pending Invitation" || x.status === "Expired";
              return {
                ...x,
                name: (
                  <Name
                    i={x}
                    flex={2}
                    name={`${x.firstName || ""} ${x.lastName || ""}`}
                    invite={isInvite}
                    isExpired={x.status === "Expired"}
                    onInvite={() => onInvite(x)}
                    noUnderline={true}
                  />
                ),
                email: x.email,
                phone: numberToPhone(x.phoneNo),
                role: x.position,
                invite: x.status,
                edit: (
                  <IconComponent
                    icon="Edit_Icon"
                    i={x}
                    onClick={() => {
                      setOpen(true);
                      setSelected(x);
                    }}
                  />
                ),
                delete: (
                  <IconComponent
                    icon="Delete_Icon"
                    i={x}
                    onClick={() => {
                      setIsDelete(true);
                      setSelected(x);
                    }}
                  />
                ),
              };
            })}
          onChangePage={onChangePage}
          page={page}
        />
      </Grid>
      {open && (
        <AddStaff
          open={open}
          handleClose={() => {
            setOpen(false);
            setSelected({});
          }}
          selected={selected}
          communityId={communityId}
          init={init}
        />
      )}
      {isDelete && (
        <DeleteModal
          open={isDelete}
          handleClose={() => {
            setIsDelete(false);
            setSelected({});
          }}
          handleDelete={handleDelete}
          title={"Delete user"}
          subtext={"Are you sure you want to delete this user?"}
        />
      )}
    </Grid>
  );
};

export default Staff;
