import React, { useEffect } from "react";
import useChats from "../../hooks/useMessenger";

const TabNotification = () => {
  const { count } = useChats();
  // Function to update the document title
  const updateDocumentTitle = (unReadCount, hidden) => {
    document.title = hidden
      ? unReadCount
        ? `(${unReadCount}) New Messages`
        : "CareSMS"
      : "CareSMS";
  };
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Tab is not active, show a notification
        // showNotification();
        const unReadCount = Object.values(count).reduce(
          (acc, curr) => acc + curr,
          0
        );
        updateDocumentTitle(unReadCount, true);
      } else {
        updateDocumentTitle(0, false);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // useEffect(() => {
  //   console.log(document.hidden, "document.hidden");
  //   if (document.hidden) {
  //     const title = "CareSMS";
  //     const timer = setInterval(() => {
  //       const unReadCount = Object.values(count).reduce(
  //         (acc, curr) => acc + curr,
  //         0
  //       );
  //       console.log(unReadCount);
  //       document.title =
  //         title === "CareSMS"
  //           ? unReadCount
  //             ? `(${unReadCount}) New Messages`
  //             : "CareSMS"
  //           : "CareSMS";
  //     }, 1000);
  //     return () => {
  //       clearInterval(timer);
  //     };
  //   } else {
  //     document.title = "CareSMS";
  //   }
  // }, [document.hidden, count]);

  // const showNotification = () => {
  //   if ("Notification" in window && Notification.permission === "granted") {
  //     const notification = new Notification("CareSMS", {
  //       body: "You have a new message!",
  //       // icon: "/path/to/notification-icon.png", // Replace with the path to your notification icon
  //     });

  //     // Optional: Add an event listener for when the user clicks on the notification
  //     notification.onclick = () => {
  //       console.log("Clicked on notification");
  //       // Handle the click event, e.g., switch to the chat window
  //       // window.focus();
  //     };
  //   }
  // };

  return <div></div>;
};

export default TabNotification;
