import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  count: 0,
};

export const communitiesSlice = createSlice({
  name: "communities",
  initialState,
  reducers: {
    addCommunity: (state, action) => {
      state.data = [...state.data, action.payload] || [];
    },
    replaceCommunities: (state, action) => {
      state.data = action.payload.data || [];
      state.count = action.payload.count || 0;
    },
    updateCommunity: (state, action) => {
      const tmpUser = state.data.map((i) => {
        if (i.LocationID === action.payload.LocationID) {
          return action.payload;
        }
        return i;
      });
      state.data = tmpUser || [];
    },
  },
  extraReducers: (builder) => {},
});

export const { addCommunity, updateCommunity, replaceCommunities } =
  communitiesSlice.actions;

export default communitiesSlice.reducer;
