import React from "react";
import Settings from "./Settings";
import Profile from "./Profile";
import useAuth from "../../hooks/useAuth";

const AccountSettings = () => {
  const { userData } = useAuth();
  if (
    userData.role === "COMMUNITY" ||
    (userData.role === "back office" && userData.position === "Hospice")
  ) {
    return <Profile isCommunity />;
  }
  return (
    <div>
      <Settings />
    </div>
  );
};

export default AccountSettings;
