import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableVirtuoso } from "react-virtuoso";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Box, TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import "./DataTable.css";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// Custom sorting function
const weekdaysOrder = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const sortByWeekday = (order, orderBy) => {
  return order === "desc"
    ? (a, b) =>
        weekdaysOrder.indexOf(b[orderBy]) - weekdaysOrder.indexOf(a[orderBy])
    : (a, b) =>
        weekdaysOrder.indexOf(a[orderBy]) - weekdaysOrder.indexOf(b[orderBy]);
};

function getComparator(order, orderBy, customSort) {
  if (customSort === "weekday") {
    return sortByWeekday(order, orderBy);
  }
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]) || [];
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, columns } = props;
  const createSortHandler = (property, custom_sort) => (event) => {
    onRequestSort(event, property, custom_sort);
  };

  return (
    // <TableHead>
    <TableRow style={{ backgroundColor: "#2f80ed" }}>
      {columns.map((headCell) => (
        <TableCell
          style={{ width: headCell.width, overflowWrap: "anywhere" }}
          key={headCell.dataKey}
          align={
            headCell.dataKey === "edit" || headCell.dataKey === "delete"
              ? "center"
              : "left"
          }
          padding={headCell.disablePadding ? "none" : "normal"}
          className="text-white"
          sortDirection={
            orderBy === (headCell.dataKeyPriority || headCell.dataKey)
              ? order
              : false
          }
        >
          {headCell?.isSort ? (
            <TableSortLabel
              className="text-white"
              active={
                true ||
                orderBy === (headCell.dataKeyPriority || headCell.dataKey)
              }
              direction={
                orderBy === (headCell.dataKeyPriority || headCell.dataKey)
                  ? order
                  : "asc"
              }
              onClick={createSortHandler(
                headCell.dataKeyPriority || headCell.dataKey,
                headCell.custom_sort
              )}
            >
              {headCell.label}
              {orderBy === (headCell.dataKeyPriority || headCell.dataKey) ? (
                <Box
                  component="span"
                  sx={visuallyHidden}
                  className="text-white"
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          ) : (
            headCell.label
          )}
        </TableCell>
      ))}
    </TableRow>
    // </TableHead>
  );
}

const DataTable = ({
  columns = [],
  rows = [],
  onChangePage = () => {},
  page = 0,
  count = 0,
  rowsPerPage,
  setSort = () => {},
}) => {
  const { height } = useWindowDimensions();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [customSort, setCustomSort] = React.useState("");
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(20);
  // const rowsPerPage = 20;

  const handleRequestSort = (event, property, custom_sort) => {
    const isAsc = orderBy === property && order === "asc";
    const sortOrd = isAsc ? "desc" : "asc";
    setOrder(sortOrd);
    setOrderBy(property);
    setCustomSort(custom_sort);
    setSort({
      order: sortOrd,
      orderBy: property,
      customSort: custom_sort,
    });
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  //   onChangePage(newPage, rowsPerPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  //   onChangePage(0, parseInt(event.target.value, 10));
  // };

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () => stableSort(rows, getComparator(order, orderBy, customSort)),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
      <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
      <Table
        {...props}
        sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
      />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef((props, ref) => (
      <TableBody {...props} ref={ref} />
    )),
  };

  function fixedHeaderContent() {
    return (
      <EnhancedTableHead
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        rowCount={rows.length}
        columns={columns}
      />
    );
  }

  function rowContent(_index, row) {
    return (
      <React.Fragment>
        {columns?.map((column) => {
          return (
            <TableCell
              style={{ width: column.width, overflowWrap: "anywhere" }}
              key={column.dataKey}
              align={
                column.dataKey === "edit" || column.dataKey === "delete"
                  ? "center"
                  : "left"
              }
            >
              {row[column.dataKey] || "NA"}
            </TableCell>
          );
        })}
      </React.Fragment>
    );
  }

  return (
    <Paper style={{ height: height - 230, width: "100%", marginBottom: 20 }}>
      <TableVirtuoso
        data={visibleRows}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={rowContent}
      />
    </Paper>
  );
};

export default DataTable;
