import React, { useEffect, useState } from "react";
import MenuHeader from "../../../components/Header/MenuHeader";
import DataTable from "../../../components/DataTable/DataTable";
import { schedulePolicyHeader } from "../../../data";
import Name from "../../../components/DataTable/Name";
import IconComponent from "../../../components/DataTable/Icon";
import TableFilter from "../../../components/Filter/TableFilter";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useSchedulersettings from "../../../hooks/useSchedulerSettings";
import useDebounce from "../../../hooks/useDebounce";
import DeleteModal from "../../../components/Modals/DeleteModal";
import useLogs from "../../../hooks/useLogs";
import useAuth from "../../../hooks/useAuth";
import { capitalizeFirstWords } from "../../../utilities/resuseFunctions";
import moment from "moment";

const ViewSchedulePolicy = () => {
  const navigate = useNavigate();
  const { addLog } = useLogs();
  const { userData } = useAuth();
  // const scheduleSettings = useSelector((state) => state.scheduleSettings.data);
  const {
    schedulerSettings,
    loading,
    deleteSchedulerSettingApi,
    count,
    getScheduleSettings,
  } = useSchedulersettings(true);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const debouncedSearchText = useDebounce(search, 750);
  const [selected, setSelected] = useState(null);
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    getScheduleSettings({
      search: debouncedSearchText,
      limit: 0,
    });
  }, [debouncedSearchText]);

  const onChangePage = (data, limit) => {
    setPage(data);
    getScheduleSettings({
      skip: data * 20,
      limit: limit
        ? limit === "View All"
          ? 0
          : limit
        : rowsPerPage === "View All"
        ? 0
        : rowsPerPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const rows = event.target.value;
    setRowsPerPage(rows);
    onChangePage(0, rows);
  };

  const handleClose = () => {
    setIsDelete(false);
    setSelected(null);
  };

  const handleDelete = () => {
    handleClose();
    deleteSchedulerSettingApi({
      ProviderID: selected?.ProviderID,
    }).then(async () => {
      const logPayload = {
        UserID: userData?.id,
        Activity: `Schedule Settings Deleted for Provider ID ${
          selected?.ProviderID
        } by User - ${userData?.firstName || ""} ${userData?.lastName || ""} `,
      };
      await addLog(logPayload);
    });
  };

  const generateRows = schedulerSettings.map((schedule, i) => {
    return {
      // ...schedule,
      name: <Name key={i} i={schedule} />,
      provider: capitalizeFirstWords(
        `${schedule?.providerdemographic?.ProviderFN} ${schedule?.providerdemographic?.ProviderLN}`
      ),
      providerType: schedule?.providerdemographic?.ProviderSpecialty,
      visits: schedule?.visitsPerMonth,
      workingDays: [
        schedule?.workingMonday ? "Monday" : undefined,
        schedule?.workingTuesday ? "Tuesday" : undefined,
        schedule?.workingWednesday ? "Wednesday" : undefined,
        schedule?.workingThursday ? "Thursday" : undefined,
        schedule?.workingFriday ? "Friday" : undefined,
        schedule?.workingSaturday ? "Saturday" : undefined,
        schedule?.workingSunday ? "Sunday" : undefined,
      ]
        .filter(Boolean)
        .join(", "),
      pto: schedule?.holidays
        ?.filter((i) => i.Type === "PTO")
        ?.map(
          (i) =>
            `${
              i.StartDate
                ? moment(i.StartDate?.slice(0, 10)).format("MM/DD/YYYY")
                : ""
            } - ${
              i.EndDate
                ? moment(i.EndDate?.slice(0, 10)).format("MM/DD/YYYY")
                : ""
            }`
        )
        .join(", "),
      edit: (
        <IconComponent
          key={i}
          i={schedule}
          icon="Edit_Icon"
          onClick={() => navigate(`/schedulerpolicy/${schedule.ProviderID}`)}
        />
      ),
      delete: (
        <IconComponent
          key={i}
          i={schedule}
          icon="Delete_Icon"
          onClick={() => {
            setIsDelete(true);
            setSelected(schedule);
          }}
        />
      ),
    };
  });

  return (
    <Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MenuHeader isSecondary />
      <Grid className="my-3">
        <TableFilter
          count={count}
          sectionName={"Provider Schedules"}
          search={search}
          setSearch={setSearch}
          onAdd={() => navigate("/schedulerpolicy")}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          onChangePage={onChangePage}
          rowsPerPage={rowsPerPage}
        />
      </Grid>
      <Grid className="mx-4 px-2">
        <DataTable
          columns={schedulePolicyHeader}
          rows={generateRows}
          count={count}
          onChangePage={onChangePage}
          page={page}
        />
      </Grid>
      {isDelete && (
        <DeleteModal
          open={isDelete}
          handleDelete={handleDelete}
          handleClose={handleClose}
          title={`Delete Scheduler Setting`}
          subtext={`Are you sure you want to delete this Scheduler Setting of ${selected?.providerdemographic?.ProviderFN} ${selected?.providerdemographic?.ProviderLN}?`}
        />
      )}
    </Grid>
  );
};

export default ViewSchedulePolicy;
