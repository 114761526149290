import React, { useState } from "react";
import { colors, fonts } from "../../styles";
import ConfirmButton from "../Buttons/ConfirmButton";
import { Dialog, Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../Buttons/CustomButton";
// import

const PatientCaseCreatedModal = ({ open: isOpen, handleClose }) => {
  const [open, setOpen] = useState(isOpen);

  const onClose = () => {
    setOpen(false);
    handleClose();
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
      <Grid>
        <Grid
          className="d-flex justify-content-between py-2 px-3 align-items-center"
          style={{
            backgroundColor: colors.OFFICE_COLOR,
          }}
        >
          <Typography className="region-modal-title">
            New Athena Case created!
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Grid>

        <Typography
          className="py-4 px-3"
          style={{
            color: colors.SUB_TEXT_COLOR,
            fontFamily: fonts.MEDIUM_FONT,
          }}
        >
          You’ve successfully sent this message to Athena to create a new
          Patient case. The associated Provider has been notified of this new
          case creation.
        </Typography>
        <Grid className="d-flex justify-content-end">
          <CustomButton className={"gotit font-14 px-3 py-1"} onClick={onClose}>
            Okay
          </CustomButton>
        </Grid>
        {/* <ConfirmButton
          title="Got it"
          style={{
            alignSelf: "flex-end",
            backgroundColor: "#3062D4",
            paddingHorizontal: 10,
          }}
          textStyle={{ fontSize: 14 }}
          onPress={onClose}
        /> */}
      </Grid>
    </Dialog>
  );
};

export default PatientCaseCreatedModal;
