import React, { useState, useCallback } from "react";
import { Avatar, Card, Grid, IconButton, Typography } from "@mui/material";
import "./MessageCard.css";
import { messageOptions } from "../../data/listOptions";
import MessageOptions from "./MessageOptions";
import moment from "moment";
import { avatarName, onDownload } from "../../utilities/resuseFunctions";
import BoldString from "../Text/BoldString";
import Tag from "../Tags/Tag";
import useChats from "../../hooks/useMessenger";
import DeleteModal from "../Modals/DeleteModal";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import StarIcon from "@mui/icons-material/Star";
import CheckSvg from "../../assets/svgs/CheckSvg";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import LazyLoad from "react-lazy-load";
import ReactSimpleImageViewer from "react-simple-image-viewer";
import UserSvg from "../../assets/svgs/user";
import TagSvg from "../../assets/svgs/TagSvg";
import WarningSvg from "../../assets/svgs/WarningSvg";

const GroupCreated = ({ message }) => {
  return (
    <Grid className="d-flex justify-content-center my-2">
      <Card className="group-created-card px-4">
        <Typography className="font-14">
          {(message?.fromMe ? "You " : message?.User?.firstName + " ") +
            message.messageBody}
        </Typography>
      </Card>
    </Grid>
  );
};

const MyMessage = ({
  message,
  data,
  indexGroups,
  isSentOn,
  onRemoveTag,
  isAccessible,
  isStarred,
  isSentToEHR,
  noSendToAthena,
}) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const openImageViewer = useCallback((index) => {
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };
  const handleDownload = () =>
    onDownload(message?.File?.url, message?.File?.url?.split("/")?.[4]);
  return (
    <Grid className="d-flex flex-row-reverse">
      <div className="message-card-70">
        <Grid
          className="mx-3 message-card user-message-card position-relative"
          style={{
            border: message.isUrgent ? "3px solid red" : undefined,
          }}
        >
          <MessageOptions
            color={"#fff"}
            options={messageOptions}
            message={message}
            data={data}
            noSendToAthena={noSendToAthena}
          />
          {/* <Grid className="triangleShapeCSS rightMessage" /> */}
          {message.fileId !== null &&
            message.File !== null &&
            (message.File.mimeType === "image" ? (
              <>
                <LazyLoad>
                  <img
                    alt="pic"
                    src={message.File.url}
                    className="chat-image"
                    onClick={openImageViewer}
                  />
                </LazyLoad>
                {isViewerOpen && (
                  <ReactSimpleImageViewer
                    src={[message.File.url]}
                    currentIndex={0}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                  />
                )}
              </>
            ) : message.File.mimeType === "pdf" ? (
              <iframe
                title="PDF"
                src={message.File.url}
                style={{ width: "100%", height: 200, margin: "10px 0px" }}
              />
            ) : ["csv", "doc", "docx", "xlsx", "excel"].includes(
                message.File.mimeType
              ) ? (
              <Grid className="d-flex align-items-center my-2">
                <InsertDriveFileIcon className="download-icons text-white" />
                <Typography className="px-2 text-white">
                  {message?.File?.url?.split("/")?.[4]}
                </Typography>
                <DownloadForOfflineIcon
                  role="button"
                  onClick={handleDownload}
                  className="mx-2 download-icons text-white"
                />
              </Grid>
            ) : null)}
          {/* <MessageText message={message.messageBody} textColor="#fff" /> */}
          <BoldString
            text={message.messageBody}
            indexGroups={indexGroups}
            textColor="#fff"
          />
          <Grid className="mt-2 d-flex">
            {message?.isUrgent && (
              <Tag
                className="mx-1"
                text={"URGENT"}
                varient={isSentToEHR ? "urgentSuccess" : "urgent"}
                // success={isSentToEHR}
                isBold={true}
                Icon={WarningSvg}
                viewBox={"0 0 24 24"}
              />
            )}
            {message?.Metadata &&
              Array.isArray(message?.Metadata) &&
              message?.Metadata?.map((i, idx) => (
                <Tag
                  key={idx}
                  className="mx-1"
                  text={i?.action?.slice(1)}
                  varient={isSentToEHR ? "" : "metatag"}
                  success={isSentToEHR}
                  isBold
                  onClose={() => onRemoveTag({ data: i, type: "tag" })}
                  isRem={!isSentToEHR && isAccessible}
                  Icon={TagSvg}
                  viewBox={"0 0 24 24"}
                />
              ))}
            {message?.TaggedPatients &&
              Array.isArray(message?.TaggedPatients) &&
              message?.TaggedPatients?.map((i, idx) => (
                <Tag
                  key={idx}
                  className="mx-1"
                  text={message?.messageBody?.slice(i.startIndex, i.endIndex)}
                  varient={isSentToEHR ? "patientSuccess" : "patient"}
                  // success={isSentToEHR}
                  isBold={true}
                  onClose={() => onRemoveTag({ data: i, type: "patient-tag" })}
                  isRem={!isSentToEHR && isAccessible}
                  Icon={UserSvg}
                />
              ))}
          </Grid>
          <Grid className="d-flex justify-content-between gap-2">
            <Grid className="d-flex align-items-center gap-2">
              {message.isForwarded && (
                <Grid className="d-flex">
                  <ShortcutIcon className="forward-icon" />
                  <Typography className="forward-text">
                    Forwarded message
                  </Typography>
                </Grid>
              )}
              {message.isEdited && (
                <Grid className="d-flex">
                  <Typography className="forward-text">Edited</Typography>
                </Grid>
              )}
            </Grid>
            <Grid className="d-flex align-items-center">
              {isStarred && <StarIcon className="star-icon" />}
              <Typography className="chat-time text-white">
                {isSentOn}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {message?.fromMe && isSentToEHR && (
          // <Grid className={`d-flex flex-row-reverse`}>
          <Grid className="d-flex align-items-center mx-3 sent-to-athena">
            <CheckSvg
              width={18}
              height={18}
              viewBox="0 0 22 22"
              color={"#27AE60"}
            />
            <span className="mx-1 success-color font-14">
              Message sent To Athena
            </span>
          </Grid>
          // </Grid>
        )}
      </div>
    </Grid>
  );
};

const OthersMessage = ({
  message,
  data,
  indexGroups,
  isSentOn,
  onRemoveTag,
  isAccessible,
  isStarred,
  isSentToEHR,
  noSendToAthena,
}) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const openImageViewer = useCallback((index) => {
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };
  const handleDownload = () =>
    onDownload(message?.File?.url, message?.File?.url?.split("/")?.[4]);
  return (
    <Grid className="d-flex">
      <Avatar size={"md"} className="mx-2" src={message?.User?.profileImg}>
        {avatarName(
          `${message?.User?.firstName || ""} ${message?.User?.lastName || ""}`
        )}
      </Avatar>
      <Grid className="message-card-70">
        <Grid
          className="message-card mx-3 py-2 position-relative"
          style={{
            border: message.isUrgent ? "3px solid red" : undefined,
          }}
        >
          <MessageOptions
            options={messageOptions}
            message={message}
            data={data}
            noSendToAthena={noSendToAthena}
          />
          {/* <Grid className="triangleShapeCSS leftMessage" /> */}
          <Typography className="person-name font-14">
            {" "}
            {message?.User?.firstName + " " + message?.User?.lastName}
          </Typography>
          {message?.User?.communitylocation?.LocationName && (
            <Typography className="community-name">
              {" "}
              Community - {message?.User?.communitylocation?.LocationName || ""}
            </Typography>
          )}
          {message.fileId !== null &&
            message.File !== null &&
            (message.File.mimeType === "image" ? (
              <>
                <LazyLoad className="chat-image">
                  <img
                    alt="pic"
                    loading="lazy"
                    src={message.File.url}
                    className="chat-image"
                    onClick={openImageViewer}
                  />
                </LazyLoad>
                {isViewerOpen && (
                  <ReactSimpleImageViewer
                    src={[message.File.url]}
                    currentIndex={0}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                  />
                )}
              </>
            ) : message.File.mimeType === "pdf" ? (
              <iframe
                title="PDF"
                src={message.File.url}
                style={{ width: "100%", height: 200, margin: "10px 0px" }}
              />
            ) : ["csv", "doc", "docx", "xlsx", "excel"].includes(
                message.File.mimeType
              ) ? (
              <Grid className="d-flex align-items-center my-2">
                <InsertDriveFileIcon className="download-icons forward-color" />
                <Typography className="px-2 text-black">
                  {message?.File?.url?.split("/")?.[4]}
                </Typography>
                <DownloadForOfflineIcon
                  role="button"
                  onClick={handleDownload}
                  className="mx-2 download-icons forward-color"
                />
              </Grid>
            ) : null)}
          {/* <MessageText message={message.messageBody} /> */}
          <BoldString text={message.messageBody} indexGroups={indexGroups} />

          <Grid className="mt-2 d-flex">
            {message?.isUrgent && (
              <Tag
                className="mx-1"
                text={"URGENT"}
                varient={isSentToEHR ? "urgentSuccess" : "urgent"}
                // success={isSentToEHR}
                isBold={true}
                Icon={WarningSvg}
                viewBox={"0 0 24 24"}
              />
            )}
            {message?.Metadata &&
              Array.isArray(message?.Metadata) &&
              message?.Metadata?.map((i, idx) => (
                <Tag
                  key={idx}
                  className="mx-1"
                  text={i?.action?.slice(1)}
                  varient={isSentToEHR ? "" : "metatag"}
                  success={isSentToEHR}
                  isBold
                  onClose={() => onRemoveTag({ data: i, type: "tag" })}
                  isRem={!i.sentToEHR && isAccessible}
                  Icon={TagSvg}
                  viewBox={"0 0 24 24"}
                />
              ))}
            {message?.TaggedPatients &&
              Array.isArray(message?.TaggedPatients) &&
              message?.TaggedPatients?.map((i, idx) => (
                <Tag
                  key={idx}
                  className="mx-1"
                  text={message?.messageBody?.slice(
                    i.startIndex,
                    i.endIndex + 1
                  )}
                  varient={isSentToEHR ? "patientSuccess" : "patient"}
                  // success={isSentToEHR}
                  isBold={true}
                  onClose={() => onRemoveTag({ data: i, type: "patient-tag" })}
                  isRem={!i.sentToEHR && isAccessible}
                  Icon={UserSvg}
                />
              ))}
          </Grid>
          <Grid className="d-flex justify-content-between">
            <Grid className="d-flex align-items-center gap-2">
              {message.isForwarded && (
                <Grid className="d-flex forward-msg">
                  <ShortcutIcon className="forward-icon forward-color" />
                  <Typography className="forward-text forward-color">
                    Forwarded message
                  </Typography>
                </Grid>
              )}
              {message.isEdited && (
                <Grid className="d-flex">
                  <Typography className="forward-text">Edited</Typography>
                </Grid>
              )}
            </Grid>
            <Grid className="d-flex align-items-center">
              {isStarred && <StarIcon className="star-icon text-black mx-1" />}
              <Typography className="chat-time">{isSentOn}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {isSentToEHR && (
          <Grid className="d-flex align-items-center mx-3 sent-to-athena">
            <CheckSvg color={"#27AE60"} viewBox="0 0 22 22" />
            <span className="mx-2 success-color font-14">
              Message sent To Athena
            </span>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const StarredMessage = ({
  message,
  data,
  indexGroups,
  isSentOn,
  isSentToEHR,
  noSendToAthena,
}) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const openImageViewer = useCallback((index) => {
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };
  const handleDownload = () =>
    onDownload(message?.File?.url, message?.File?.url?.split("/")?.[4]);
  return (
    <Grid className="my-2">
      <Grid className="d-flex position-relative">
        <Avatar
          size={"sm"}
          style={{ width: 25, height: 25, fontSize: 14 }}
          className="mx-2"
          src={message?.User?.profileImg}
        >
          {avatarName(
            `${message?.User?.firstName || ""} ${message?.User?.lastName || ""}`
          )}
        </Avatar>
        <Typography className="person-name font-14">
          {" "}
          {message?.User?.firstName + " " + message?.User?.lastName}
        </Typography>
        <MessageOptions
          options={messageOptions}
          message={{ ...message, conversationId: data.conversationId }}
          data={data}
          noSendToAthena={noSendToAthena}
        />
      </Grid>
      <Grid
        className="message-card message-card-full mx-3 position-relative"
        style={{
          border: message.isUrgent ? "3px solid red" : undefined,
        }}
      >
        {/* <Grid className="triangleShapeCSS leftMessage" /> */}

        {message.fileId !== null &&
          message.File !== null &&
          (message.File.mimeType === "image" ? (
            <>
              <LazyLoad height={200} offsetVertical={300}>
                <img
                  alt="pic"
                  src={message.File.url}
                  className="chat-image"
                  onClick={openImageViewer}
                />
              </LazyLoad>
              {isViewerOpen && (
                <ReactSimpleImageViewer
                  src={[message.File.url]}
                  currentIndex={0}
                  disableScroll={false}
                  closeOnClickOutside={true}
                  onClose={closeImageViewer}
                />
              )}
            </>
          ) : message.File.mimeType === "pdf" ? (
            <iframe
              title="PDF"
              src={message.File.url}
              style={{ width: "100%", height: 200, margin: "10px 0px" }}
            />
          ) : ["csv", "doc", "docx", "xlsx"].includes(message.File.mimeType) ? (
            <Grid className="d-flex align-items-center my-2">
              <InsertDriveFileIcon className="download-icons text-white" />
              <Typography className="px-2 text-white">
                {message?.File?.url?.split("/")?.[4]}
              </Typography>
              <DownloadForOfflineIcon
                role="button"
                onClick={handleDownload}
                className="mx-2 download-icons text-white"
              />
            </Grid>
          ) : null)}
        {/* <MessageText message={message.messageBody} /> */}
        <BoldString text={message.messageBody} indexGroups={indexGroups} />
        <Grid className="d-flex">
          {message?.isUrgent && (
            <Tag
              className="mx-1"
              text={"URGENT"}
              varient={isSentToEHR ? "urgentSuccess" : "urgent"}
              // success={isSentToEHR}
              isBold={true}
              Icon={WarningSvg}
              viewBox={"0 0 24 24"}
            />
          )}
          {message?.Metadata &&
            Array.isArray(message?.Metadata) &&
            message?.Metadata?.map((i, idx) => (
              <Tag
                key={idx}
                className="mx-1"
                text={i?.action?.slice(1)}
                varient={isSentToEHR ? "" : "metatag"}
                success={isSentToEHR}
                isBold
                Icon={TagSvg}
                viewBox={"0 0 24 24"}
              />
            ))}
          {message?.TaggedPatients &&
            Array.isArray(message?.TaggedPatients) &&
            message?.TaggedPatients?.map((i, idx) => (
              <Tag
                key={idx}
                className="mx-1"
                text={message?.messageBody?.slice(i.startIndex, i.endIndex + 1)}
                varient={isSentToEHR ? "patientSuccess" : "patient"}
                // success={isSentToEHR}
                isBold={true}
                Icon={UserSvg}
              />
            ))}
        </Grid>
        <Grid className="d-flex justify-content-between">
          <Grid>
            {message.isEdited && (
              <Grid className="d-flex">
                <Typography className="forward-text">Edited</Typography>
              </Grid>
            )}
          </Grid>
          <Typography className="chat-time">{isSentOn}</Typography>
        </Grid>
      </Grid>
      {isSentToEHR && (
        <Grid className="d-flex align-items-center mx-3 sent-to-athena">
          <CheckSvg color={"#27AE60"} viewBox="0 0 22 22" />
          <span className="mx-2 success-color font-14">
            Message sent To Athena
          </span>
        </Grid>
      )}
    </Grid>
  );
};

export const TemplateMessage = ({ Name, children, isSentOn }) => {
  return (
    <Grid className="d-flex">
      <Grid className="w-100">
        <Grid className="message-card w-100 mx-1 py-2">
          <Typography className="person-name font-14">{Name}</Typography>
          {children}
          <Grid className="d-flex justify-content-between">
            <Grid />
            <Grid className="d-flex align-items-center">
              <Typography className="chat-time">{isSentOn}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const MessageCard = ({ message, data, starred, noSendToAthena }) => {
  const { removeTag, removePatientTag, userData } = useChats();
  const [isDelete, setIsDelete] = useState(null);
  const time = moment(message?.createdAt);
  const isToday = time.isSame(moment(), "day");
  const isSentOn = isToday ? time.format("hh:mm A") : time.format("MM/DD/YYYY");
  const indexG = message.TaggedPatients.map((data) => ({
    startIndex: data.startIndex,
    endIndex: data.endIndex,
    color: "orange",
  }));
  const indexG2 = message.TaggedUsers.map((data) => ({
    startIndex: data.startIndex,
    endIndex: data.endIndex,
    color: "#3062D4",
  }));
  const indexGroups = [...indexG, ...indexG2].sort(
    (a, b) => a.startIndex - b.startIndex
  );

  const onRemoveTag = () => {
    const { data, type } = isDelete;
    setIsDelete(null);
    const payload = {
      // patientId,
      messageId: message.messageId,
      conversationId: message.conversationId,
    };
    if (type === "tag") {
      removeTag({ ...payload, id: data.id });
    } else if (type === "patient-tag") {
      removePatientTag({ ...payload, id: data.id });
    }
  };
  const handleDelete = (data) => setIsDelete(data);
  const isAccessible =
    userData.role === "back office" &&
    ["Admin", "Scheduler"].includes(userData.position);

  const isStarred = message?.StarredMessages?.find((star) => {
    return +star.userId === +userData.id && +star.messageId === +message.id;
  });
  const isTags = !!message?.Metadata?.length;
  const isSentToEHR = isTags && message?.Metadata?.find((i) => i.sentToEHR);
  const isAdmin = userData.role === "back office";
  return (
    <Grid className="my-2">
      {message?.messageType === "acc" ? (
        <GroupCreated message={message} data={data} isSentOn={isSentOn} />
      ) : starred ? (
        <StarredMessage
          message={message}
          data={data}
          indexGroups={indexGroups}
          isSentOn={isSentOn}
          starred={starred}
          isSentToEHR={isSentToEHR}
          noSendToAthena={!isAdmin}
        />
      ) : message.fromMe ? (
        <MyMessage
          message={message}
          data={data}
          indexGroups={indexGroups}
          isSentOn={isSentOn}
          starred={starred}
          onRemoveTag={handleDelete}
          isAccessible={isAccessible}
          isStarred={isStarred}
          isSentToEHR={isSentToEHR}
          noSendToAthena={!isAdmin}
        />
      ) : (
        <OthersMessage
          message={message}
          data={data}
          indexGroups={indexGroups}
          isSentOn={isSentOn}
          starred={starred}
          onRemoveTag={handleDelete}
          isAccessible={isAccessible}
          isStarred={isStarred}
          isSentToEHR={isSentToEHR}
          noSendToAthena={!isAdmin}
        />
      )}
      {/* {message?.fromMe && isSentToEHR && (
        <Grid className={`d-flex flex-row-reverse`}>
          <Grid className="d-flex align-items-center mx-2 sent-to-athena">
            <CheckSvg
              width={18}
              height={18}
              viewBox="0 0 22 22"
              color={"#27AE60"}
            />
            <span className="mx-1 success-color font-14">
              Message sent To Athena
            </span>
          </Grid>
        </Grid>
      )} */}
      {isDelete && (
        <DeleteModal
          open={isDelete}
          handleClose={() => {
            setIsDelete(false);
          }}
          handleDelete={onRemoveTag}
          title={"Remove Tag"}
          subtext={"Are you sure you want to remove this Tag?"}
        />
      )}
    </Grid>
  );
};

export default MessageCard;
