const styles = {
  width: "calc(100% - 160px)",
  height: 40,
  justifyContent: "center",
  control: {
    backgroundColor: "#fff",
    fontSize: 14,
    fontWeight: "normal",
  },

  "&multiLine": {
    control: {
      fontFamily: "Inter",
      minHeight: 40,
      height: 40,
    },
    highlighter: {
      padding: "9px",
      border: "1px solid transparent",
      color: "#000",
      height: 40,
      overflow: "hidden",
    },
    input: {
      // padding: 9,
      // border: "1px solid silver",
      border: "0px",
      outline: "0px",
      padding: "10px",
      fontFamily: "Inter",
      fontSize: "14px",
      height: 40,
      overflow: "auto",
      color: "transparent",
      caretColor: "#000",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,
    minHeight: 40,

    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      // padding: 1,
      // border: "2px inset",
      border: "0px",
      outline: "0px",
      padding: "0px 10px",
      fontFamily: "Inter",
      fontSize: "14px",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "#F2F3F3",
      border: "1px solid #ccc",
      fontSize: 14,
      maxHeight: 250,
      width: 300,
      overflow: "auto",
      position: "absolute",
      bottom: 14,
      borderRadius: "5px",
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#fff",
      },
      fontWeight: 700,
    },
  },
};

export default styles;
