import React, { useState } from "react";
import { Dialog, Grid, Typography } from "@mui/material";
import "./VisitDetailsModal.css";
import UserSvg from "../../assets/svgs/user";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CalendarCheckSvg from "../../assets/svgs/CalendarCheckSvg";
import MapSvg from "../../assets/svgs/MapSvg";
import HealthSvg from "../../assets/svgs/HealthSvg";
import InfoSvg from "../../assets/svgs/InfoSvg";
import moment from "moment";
import { appointmentTypes } from "../../data/listOptions";

const VisitDetailsModal = ({
  isOpen,
  onClose,
  onSubmit,
  selectedVisit,
  onCancel,
  isCommunity,
}) => {
  const [open, setOpen] = useState(isOpen);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const handleSubmit = () => {
    handleClose();
  };

  const type = appointmentTypes.find(
    (appTypes) =>
      appTypes.name === selectedVisit?.VisitType ||
      appTypes.id === +selectedVisit?.VisitType
  );

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          position: "absolute",
          right: 0,
          margin: 0,
        },
      }}
    >
      <Grid
        sx={{
          width: {
            xs: "300px",
            sm: "300px",
            md: "350px",
          },
          height: "100vh",
          //   backgroundColor: "#F2F2F2",
        }}
        // className="p-2 px-4"
      >
        <Grid className="p-2 px-4 pt-3">
          <div className="d-flex align-items-center justify-content-between">
            <Typography className="visit-details-title">
              Visit Details
            </Typography>
            <div role="button" className="close-box " onClick={handleClose}>
              <CloseOutlinedIcon className="close-icon-visit" />
            </div>
          </div>
        </Grid>
        <Grid className="p-2 px-4 my-2">
          <div className="d-flex align-items-center my-4">
            <div role="button" className="close-box " onClick={handleClose}>
              <CalendarCheckSvg
              //   className="close-icon-visit"
              />
            </div>
            <div>
              <Typography className="visit-detail">
                Scheduled visit on{" "}
                {selectedVisit?.VisitDate &&
                  moment(selectedVisit?.VisitDate?.slice(0, 10)).format(
                    "MMMM DD, yyyy"
                  )}
              </Typography>
            </div>
          </div>
          <div className="d-flex align-items-center my-4">
            <div role="button" className="close-box " onClick={handleClose}>
              <MapSvg
              //   className="close-icon-visit"
              />
            </div>
            <div>
              <Typography className="visit-detail">
                {selectedVisit?.Facility}
                {", "} {selectedVisit?.PatientAddress}
              </Typography>
            </div>
          </div>
          <div className="d-flex align-items-center my-4">
            <div role="button" className="close-box " onClick={handleClose}>
              <UserSvg
              //   className="close-icon-visit"
              />
            </div>
            <div>
              <Typography className="visit-detail">
                {[
                  selectedVisit?.PatientName,
                  selectedVisit?.PatientID &&
                    `PatientID ${selectedVisit?.PatientID}`,
                  selectedVisit?.sourcepatient?.PatientDOB &&
                    moment().diff(
                      selectedVisit?.sourcepatient?.PatientDOB,
                      "years"
                    ),
                  selectedVisit?.sourcepatient?.PatientGender,
                ]
                  .filter(Boolean)
                  .join(" | ")}
              </Typography>
              <Typography className="visit-detail visit-detail-sub">
                Last visit:{" "}
                {selectedVisit?.LastVisitDate &&
                  moment(selectedVisit?.LastVisitDate?.slice(0, 10)).format(
                    "MMMM DD, yyyy"
                  )}
              </Typography>
            </div>
          </div>
          <div className="d-flex align-items-center my-4">
            <div role="button" className="close-box " onClick={handleClose}>
              <HealthSvg
              //   className="close-icon-visit"
              />
            </div>
            <div>
              <Typography className="visit-detail">
                {selectedVisit?.ProviderName}
              </Typography>
              <Typography className="visit-detail visit-detail-sub">
                {selectedVisit?.ProviderSpecialty}
              </Typography>
            </div>
          </div>
          <div className="d-flex align-items-center my-4">
            <div role="button" className="close-box " onClick={handleClose}>
              <InfoSvg
              //   className="close-icon-visit"
              />
            </div>
            <div>
              <Typography className="visit-detail">
                {type?.name || ""}
              </Typography>
            </div>
          </div>
          <Typography className="visit-detail m-0 text-center">
            {`${+selectedVisit?.Slack < 0 ? "" : "Not due for "}${
              Math.abs(selectedVisit?.Slack) || ""
            } days ${+selectedVisit?.Slack < 0 ? "Overdue" : ""}`}
          </Typography>
        </Grid>

        <Grid className="p-2 px-4 my-2">
          <button
            disabled={true}
            onClick={handleClose}
            className="btn button-secondary text-blue w-100 my-2"
          >
            Note to Provider
          </button>
          {!isCommunity && (
            <button
              disabled={selectedVisit?.isCompleted}
              onClick={onCancel}
              className="btn button-secondary text-cancel w-100 my-2"
            >
              Cancel Appointment
            </button>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default VisitDetailsModal;
