import React from "react";
import { Form } from "react-bootstrap";

const PhoneInput = ({ onChange, ...props }) => {
  function formatSSN(value) {
    if (!value) return value;
    const ssn = value.replace(/[^\d]/g, "");
    const ssnLength = ssn.length;

    if (ssnLength < 4) return ssn;

    if (ssnLength < 7) {
      return `(${ssn.slice(0, 3)}) ${ssn.slice(3)}`;
    }

    return `(${ssn.slice(0, 3)}) ${ssn.slice(3, 6)}-${ssn.slice(6, 10)}`;
  }
  function ssnFormatter() {
    const inputField = document.getElementById("ssn");
    const formattedInputValue = formatSSN(inputField.value);
    inputField.value = formattedInputValue;
  }
  return (
    <div>
      <Form.Control
        onKeyDown={() => ssnFormatter()}
        id="ssn"
        {...props}
        placeholder="(xxx) xxx-xxxx"
        onChange={(e) => {
          const val = formatSSN(e.target.value);
          onChange(val);
        }}
      />
    </div>
  );
};

export default PhoneInput;
