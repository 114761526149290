import { useEffect } from "react";
import useAuth from "./useAuth";
import { fetchUserData } from "../redux/reducers/users.reducer";
import { useDispatch } from "react-redux";

const useAutoLogout = () => {
  const dispatch = useDispatch();
  const { isUserExist, userLogout, userData } = useAuth();
  useEffect(() => {
    // Check user status for every refresh
    const checkStatus = () => {
      const payload = {
        email: userData?.email,
        role: userData?.role,
      };
      userData &&
        isUserExist(payload, true)
          .then(({ data }) => {
            if (data?.data?.isDeleted) {
              // User is disabled or deleted, trigger logout
              userLogout();
            }
            dispatch(fetchUserData(data?.data || null));
          })
          .catch((error) => {
            if (error?.Response?.data?.error) {
              userLogout();
            }
            console.error("Error checking user status:", error);
          });
    };
    checkStatus();
    // Check user status every 2 minutes (adjust the interval as needed)
    // If we want to check with time interval use this code
    // const intervalId = setInterval(() => {
    //   if (!userData) {
    //     // User is already logged out, no need to check further
    //     clearInterval(intervalId);
    //     return;
    //   }

    //   // Call the backend API to check the user status (you'll need to implement this)
    //   // Example API call:
    //   checkStatus();
    // }, 120000); // 2 minutes interval (adjust as needed)

    // // Clean up the interval when the component unmounts
    // return () => clearInterval(intervalId);
  }, []);

  return { userData, userLogout };
};

export default useAutoLogout;
