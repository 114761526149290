import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  currentChat: {},
  messages: [],
  latestMessages: [],
  count: {},
  unreadCount: 0,
  editMessage: null,
};

export const chatsSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    addChat: (state, action) => {
      state.data = [...state.data, action.payload] || [];
    },
    replaceChats: (state, action) => {
      state.data = action.payload || [];
    },
    changeCurrentChat: (state, action) => {
      state.currentChat = action.payload || {};
    },
    updateMessages: (state, action) => {
      state.messages = action.payload || [];
    },
    updateLatestMessages: (state, action) => {
      state.latestMessages = action.payload || [];
    },
    updateCount: (state, action) => {
      state.count[action.payload.conversationId] = action.payload?.count || 0;
    },
    updateUnreadCount: (state, action) => {
      state.unreadCount = action.payload || 0;
    },
    updateChat: (state, action) => {
      const tmpUser = state.data.map((i) => {
        if (i.id == action.payload.id) {
          return action.payload;
        }
        return i;
      });
      state.data = tmpUser || [];
    },
    updateChatMessage: (state, action) => {
      const tmpUser = state.data.map((i) => {
        if (i.id == action.payload.conversationId) {
          return {
            ...i,
            lastMessage: i.lastMessage?.map((msg) => ({
              ...msg,
              isUrgent: true,
            })),
          };
        }
        return i;
      });
      state.data = tmpUser || [];
      const tmpMessages = state.messages.map((i) => {
        if (i.id == action.payload.messageId) {
          return { ...i, isUrgent: true };
        }
        return i;
      });
      state.messages = tmpMessages || [];
    },
    deleteChat: (state, action) => {
      const tmpUser = state.data.filter((i) => i.id !== action.payload.id);
      state.data = tmpUser || [];
    },
    setEditMessage: (state, action) => {
      state.editMessage = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  addChat,
  updateChat,
  replaceChats,
  deleteChat,
  changeCurrentChat,
  updateMessages,
  updateLatestMessages,
  updateCount,
  updateUnreadCount,
  updateChatMessage,
  setEditMessage,
} = chatsSlice.actions;

export default chatsSlice.reducer;
