import { Badge, Grid, Typography, Menu, MenuItem, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Icons } from "../../assets";
import moment from "moment";
import "./CommandCenterHeader.css";
import { providersCategory } from "../../data";
import SelectableButton from "../Buttons/SelectableButton";
import CustomButton from "../Buttons/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  changeRegion,
  changeSelectedProvider,
  changeType,
} from "../../redux/reducers/selections.reducer";
import { useNavigate, useLocation } from "react-router-dom";
import { NestedMenuItem } from "mui-nested-menu";
import useProviders from "../../hooks/useProviders";
import useZones from "../../hooks/useZones";
import UsersSvg from "../../assets/svgs/UsersSvg";
import { ALL_ZONES } from "../../constants/strings";
import useChats from "../../hooks/useMessenger";
import useAutoLogout from "../../hooks/useAutoLogout";
import { updateUnreadCount } from "../../redux/reducers/chats.reducer";

const CommandCenterHeader = ({
  selectedRegion,
  setSelectedRegion,
  setType,
  type,
  isProvider,
  isCommunity,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { zones } = useZones();
  const { userData } = useAutoLogout();
  const { providersCount } = useProviders(true);
  const { count, getUnreadCount, unreadCount } = useChats();
  const { selectedProvider } = useSelector((state) => state.selections);
  const [anchorEl, setAnchorEl] = useState(null);
  const [regionAnchor, setRegionAnchor] = useState(null);
  const open = Boolean(anchorEl);
  const regionOpen = Boolean(regionAnchor);

  useEffect(() => {
    getUnreadCount({ userId: userData.id }).then((res) =>
      dispatch(updateUnreadCount(res))
    );
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRegionClick = (event) => {
    setRegionAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRegionClose = () => {
    setRegionAnchor(null);
  };

  const handleSelect = (i) => {
    handleRegionClose();
    dispatch(changeRegion(i));
    !isCommunity && setSelectedRegion(i);
  };

  const handleSelectProvider = (i) => {
    if (location?.pathname === "/home") {
      dispatch(changeSelectedProvider(i.name));
      handleClose();
    } else {
      dispatch(changeSelectedProvider("All Providers"));
      navigate("/home");
    }
  };

  const providers = !isCommunity
    ? providersCount.filter(
        (i) =>
          i.zones?.ZoneName === selectedRegion?.ZoneName ||
          selectedRegion?.ZoneName === ALL_ZONES
      )
    : [];

  let providersFilter = isProvider
    ? providersCount.filter(
        (i) =>
          i.ProviderID == selectedProvider ||
          i.ProviderID === location?.state?.id
      )
    : providers;

  const providerOptions = (i) =>
    providers
      ?.filter((x) => {
        if (x.ProviderSpecialty === "Family Medicine") {
          return i.name === "Family Medicine";
        }
        if (["Cardiology", "Cardiologists"].includes(x.ProviderSpecialty)) {
          return i.name === "Cardiologists";
        }
        if (["Podiatry", "Podiatrists"].includes(x.ProviderSpecialty)) {
          return i.name === "Podiatrists";
        }
        if (["Psychology", "Psychiatrists"].includes(x.ProviderSpecialty)) {
          return i.name === "Psychiatrists";
        }
        return false;
      })
      .sort((a, b) => {
        return a.ProviderLN.localeCompare(b.ProviderLN);
      });

  const unReadCount = Object.values(count).reduce((acc, curr) => acc + curr, 0);

  return (
    <Grid container className="px-3">
      <Grid item md={6} xs={12}>
        <Grid className="d-flex">
          {isCommunity ? (
            <Typography className="command-title">
              Hello,{" "}
              {`${userData?.firstName || ""} ${userData?.lastName || ""}`}
            </Typography>
          ) : (
            <Typography className="command-title">
              Command Center |{" "}
              <Button
                id="region-button"
                aria-controls={open ? "region-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleRegionClick}
                className="btn text-capitalize"
              >
                <Typography className="command-title region">
                  {selectedRegion?.ZoneName}
                </Typography>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={regionAnchor}
                open={regionOpen}
                onClose={handleRegionClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() =>
                    handleSelect({
                      ZoneName: "All Zones",
                      ZoneLatitude: 33.4484,
                      ZoneLongitude: -112.074,
                      Zoom: 9,
                    })
                  }
                >
                  All Zones
                </MenuItem>
                {zones?.map((region, i) => {
                  return (
                    <MenuItem key={i} onClick={() => handleSelect(region)}>
                      {region?.ZoneName}
                    </MenuItem>
                  );
                })}
              </Menu>{" "}
            </Typography>
          )}
        </Grid>
        <Grid>
          <Typography className="mx-3 command-time">
            {moment().format("MMMM D, YYYY, hh:mm A z")}
          </Typography>
        </Grid>
      </Grid>
      <Grid item md={6} xs={12} className="p-2">
        <Grid container className="d-flex">
          <Grid item md={6} xs={12} className="px-2">
            <SelectableButton
              title={"Scheduler"}
              isSelected={type === "Scheduler"}
              onClick={() => {
                setType("Scheduler");
                dispatch(changeType("Scheduler"));
                location?.pathname === "/messagecenter" && navigate("/home");
              }}
            />
          </Grid>
          <Grid item md={6} xs={12} className="px-2 py-2 py-md-0">
            <SelectableButton
              // disabled={true}
              title={"Messenger"}
              isSelected={type === "Messenger"}
              onClick={() => {
                setType("Messenger");
                dispatch(changeType("Messenger"));
                location?.pathname === "/messagecenter" && navigate("/home");
              }}
              count={unreadCount}
            />
          </Grid>
        </Grid>
        {type === "Scheduler" && !isCommunity && (
          <Grid container>
            <CustomButton
              id="provider-button"
              aria-controls={open ? "provider-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              className="d-flex justify-content-center align-items-center w-100 p-2 provider-button"
              onClick={handleClick}
            >
              <UsersSvg color="#fff" />
              <Typography className="px-2">
                {isProvider
                  ? `${providersFilter?.[0]?.ProviderFN} ${providersFilter?.[0]?.ProviderLN}`
                  : selectedProvider || "All Providers"}
              </Typography>
              <img
                src={Icons.Dropdown_Indicator}
                alt="dropdown"
                className="icon1"
              />
            </CustomButton>
            <Menu
              // id="provider-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {providersCategory?.map((i, idx) => {
                if (idx !== 0) {
                  return (
                    <NestedMenuItem
                      key={idx}
                      // leftIcon={<AdbIcon />}
                      // rightIcon={<ArrowRightIcon />}
                      label={i.name}
                      parentMenuOpen={open}
                      onClick={() => handleSelectProvider(i)}
                    >
                      {providerOptions(i)?.map((i, idx) => {
                        return (
                          <MenuItem
                            key={idx}
                            onClick={() => {
                              handleClose();
                              dispatch(changeSelectedProvider(i.ProviderID));
                              navigate("/provider", {
                                state: {
                                  id: i.ProviderID,
                                },
                              });
                            }}
                          >
                            {i.ProviderFN} {i.ProviderLN}
                          </MenuItem>
                        );
                      })}
                    </NestedMenuItem>
                  );
                }
                return (
                  <MenuItem
                    key={idx}
                    onClick={() => handleSelectProvider(i)}
                    className="position-relative"
                  >
                    {i?.name}
                  </MenuItem>
                );
              })}
            </Menu>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CommandCenterHeader;
