import React, { useState } from "react";
import { colors } from "../../styles";
import CustomButton from "../Buttons/CustomButton";
import { Dialog, Grid, Typography, IconButton } from "@mui/material";
import "./AddRegionModal.css";
import CloseIcon from "@mui/icons-material/Close";
import { Form } from "react-bootstrap";
import { providersCategory } from "../../data";
import PhoneInput from "../Input/PhoneInput";
import useProviders from "../../hooks/useProviders";
import useZones from "../../hooks/useZones";
import ConfirmModal from "./DeleteModal";
import Loading from "../Loading";
import { states } from "../../data/listOptions";
import useAuth from "../../hooks/useAuth";

const AddProviderModal = ({
  open: isOpen,
  handleClose,
  selected,
  isEdited,
}) => {
  const { updateProviderApi, addProviderApi, loading } = useProviders(true);
  const { userData } = useAuth();
  const { zones } = useZones(true, true);
  const [open, setOpen] = useState(isOpen);
  const [edit, setEdit] = useState(selected || {});
  const [isConfirm, setIsConfirm] = useState(false);

  const onChangeHandler = (val, name) => {
    setEdit({
      ...edit,
      [name]: val,
    });
  };

  const onClose = () => {
    setOpen(false);
    handleClose();
  };

  const onSubmit = () => {
    handleClose();
    const data = { ...edit, userId: userData.id };
    if (selected?.ProviderID) {
      updateProviderApi(data);
    } else {
      addProviderApi(data);
    }
  };
  return (
    <>
      {loading && <Loading loading={loading} />}
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
        fullWidth
      >
        <Grid>
          <Grid
            className="d-flex justify-content-between py-1 px-3 align-items-center"
            style={{
              backgroundColor: colors.OFFICE_COLOR,
            }}
          >
            <Typography className="region-modal-title">
              {isEdited
                ? selected?.ProviderID
                  ? "Edit Provider"
                  : "Add New Provider"
                : "View Provider"}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon className="close-icon" />
            </IconButton>
          </Grid>
          <Grid className="px-2">
            <Grid className="m-2 px-3">
              <Typography className="fieldName py-2">Name*</Typography>
              <Grid container>
                <Grid item md={6}>
                  <Form.Control
                    disabled={!isEdited}
                    placeholder="First Name"
                    className="inputField px-2 border-1 w-100"
                    value={edit?.ProviderFN}
                    onChange={(val) =>
                      onChangeHandler(val.target.value, "ProviderFN")
                    }
                  />
                </Grid>
                <Grid item md={6} className="px-md-2 py-2 py-md-0">
                  <Form.Control
                    disabled={!isEdited}
                    placeholder="Last Name"
                    className="inputField px-2 border-1 w-100"
                    value={edit?.ProviderLN}
                    onChange={(val) =>
                      onChangeHandler(val.target.value, "ProviderLN")
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container className="p-2">
              <Grid item md={6} className="px-3">
                <Typography className="fieldName py-2">Email*</Typography>
                <Form.Control
                  disabled={!isEdited}
                  placeholder="Email address"
                  className="inputField px-2 border-1 w-100"
                  value={edit?.ProviderEmail}
                  onChange={(val) =>
                    onChangeHandler(val.target.value, "ProviderEmail")
                  }
                />
              </Grid>
              <Grid item md={6}>
                <Typography className="fieldName py-2 px-3">Phone</Typography>
                <Grid className="px-3">
                  <PhoneInput
                    disabled={!isEdited}
                    className="inputField px-2 border-1 w-100"
                    value={edit?.ProviderPhone}
                    onChange={(val) => onChangeHandler(val, "ProviderPhone")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container className="p-2">
              <Grid item md={6}>
                <Typography className="fieldName px-3 py-2">
                  Specialty*
                </Typography>
                <Grid className="inputField px-3">
                  <Form.Select
                    disabled={!isEdited}
                    aria-label="Default select example"
                    value={edit?.ProviderSpecialty}
                    onChange={(e) =>
                      onChangeHandler(e.target.value, "ProviderSpecialty")
                    }
                  >
                    <option>Select Specialty</option>
                    {providersCategory?.slice(1)?.map((i, idx) => {
                      return (
                        <option key={idx} value={i.name}>
                          {i.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Grid>
              </Grid>
              <Grid item md={6}>
                <Typography className="fieldName px-3 py-2">Zone*</Typography>
                <Grid className="inputField px-3">
                  <Form.Select
                    disabled={!isEdited}
                    aria-label="Default select example"
                    value={edit?.ZoneID}
                    onChange={(e) => onChangeHandler(e.target.value, "ZoneID")}
                  >
                    <option>Select Zone</option>
                    {zones?.map((zone) => {
                      return (
                        <option value={zone.ZoneID}>{zone?.ZoneName}</option>
                      );
                    })}
                  </Form.Select>
                </Grid>
              </Grid>
            </Grid>
            <Grid container className="p-2">
              <Grid item md={6}>
                <Typography className="fieldName px-3 py-2">
                  Address Line 1
                </Typography>
                <Grid className="inputField px-3">
                  <Form.Control
                    disabled={!isEdited}
                    placeholder="Address Line 1"
                    className="inputField px-2 border-1 w-100"
                    value={edit?.ProviderStreet}
                    onChange={(val) =>
                      onChangeHandler(val.target.value, "ProviderStreet")
                    }
                  />
                </Grid>
              </Grid>
              <Grid item md={6}>
                <Typography className="fieldName px-3 py-2">
                  Address Line 2
                </Typography>
                <Grid className="inputField px-3">
                  <Form.Control
                    disabled={!isEdited}
                    placeholder=" Address Line 2"
                    className="inputField px-2 border-1 w-100"
                    value={edit?.AddressLine2}
                    onChange={(val) =>
                      onChangeHandler(val.target.value, "AddressLine2")
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container className="p-2">
              <Grid item md={6}>
                <Typography className="fieldName px-3 py-2">City</Typography>
                <Grid className="inputField px-3">
                  <Form.Control
                    disabled={!isEdited}
                    placeholder="City"
                    className="inputField px-2 border-1 w-100"
                    value={edit?.ProviderCity}
                    onChange={(val) =>
                      onChangeHandler(val.target.value, "ProviderCity")
                    }
                  />
                </Grid>
              </Grid>
              <Grid item md={6}>
                <Typography className="fieldName px-3 py-2">State</Typography>
                <Grid className="inputField px-3">
                  <Form.Select
                    disabled={!isEdited}
                    aria-label="State"
                    className="inputField px-2 border-1 w-100"
                    value={edit?.ProviderState}
                    onChange={(val) =>
                      onChangeHandler(val.target.value, "ProviderState")
                    }
                  >
                    <option>Select State</option>
                    {states?.map((state) => {
                      return (
                        <option key={state.value} value={state.value}>
                          {state?.text}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Grid>
              </Grid>
            </Grid>
            <Grid container className="p-2">
              <Grid item md={6}>
                <Typography className="fieldName px-3 py-2">Zipcode</Typography>
                <Grid className="inputField px-3">
                  <Form.Control
                    disabled={!isEdited}
                    placeholder="Zip Code"
                    className="inputField px-2 border-1 w-100"
                    value={edit?.ProviderZip}
                    onChange={(val) =>
                      onChangeHandler(val.target.value, "ProviderZip")
                    }
                  />
                </Grid>
              </Grid>
              <Grid item md={6}>
                <Typography className="fieldName px-3 py-2">
                  Athena ID
                </Typography>
                <Grid className="inputField px-3">
                  <Form.Control
                    disabled={!isEdited}
                    placeholder="Athena ID"
                    className="inputField px-2 border-1 w-100"
                    value={edit?.ProviderID}
                    onChange={(val) =>
                      onChangeHandler(val.target.value, "ProviderID")
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container className="p-2">
              <Grid item md={6}>
                <Typography className="fieldName px-3 py-2">
                  Provider NPIID
                </Typography>
                <Grid className="inputField px-3">
                  <Form.Control
                    disabled={!isEdited}
                    placeholder="Provider NPIID"
                    className="inputField px-2 border-1 w-100"
                    value={edit?.ProviderNPIID}
                    onChange={(val) =>
                      onChangeHandler(val.target.value, "ProviderNPIID")
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            {isEdited && (
              <Grid>
                <CustomButton
                  className="button mx-4 py-2 px-5"
                  onClick={() => setIsConfirm(true)}
                >
                  <Typography>Save</Typography>
                </CustomButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Dialog>
      {isConfirm && (
        <ConfirmModal
          open={isConfirm}
          handleClose={() => {
            setIsConfirm(false);
            onClose();
          }}
          handleDelete={onSubmit}
          title={`${selected?.ProviderID ? "Edit" : "Add"} Provider`}
          subtext={`Are you sure you want to ${
            selected?.ProviderID ? "update" : "add"
          } this Provider`}
          primaryText={"Confirm"}
        />
      )}
    </>
  );
};

export default AddProviderModal;
