import React, { useState } from "react";
import {
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Box,
  Divider,
  ListItemIcon,
  Avatar,
  Typography,
  Collapse,
  Container,
  Toolbar,
  Menu,
  Tooltip,
  Button,
  MenuItem,
  Badge,
} from "@mui/material";
import "./MenuHeader.css";
import OptionsIcon from "@mui/icons-material/MoreHorizOutlined";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import menuOptions from "./menuOptions";
import Down from "@mui/icons-material/ArrowDropDownOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import DownArrow from "@mui/icons-material/KeyboardArrowDownRounded";
import Logo from "../../assets/images/CareSMS_Logo.png";
import UserSvg from "../../assets/svgs/user";
import LogoutSvg from "../../assets/svgs/LogoutSvg";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/reducers/users.reducer";
import useAuth from "../../hooks/useAuth";
import NotificationsModal from "../Modals/NotificationsModal";
import useNotifications from "../../hooks/useNotifications";

const settings = [
  {
    id: 1,
    name: "Account",
    icon: UserSvg,
    path: "/account/settings",
  },
  {
    id: 2,
    name: "Logout",
    icon: LogoutSvg,
  },
];

const MenuHeader = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const [listData, setListData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { userData, userLogout } = useAuth();
  const { notifications } = useNotifications();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const handleListItemClick = (path) => {
    navigate(path);
    toggleDrawer(false);
  };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      // onClick={toggleDrawer(false)}
      // onKeyDown={toggleDrawer(false)}
    >
      <div className="d-flex align-items-center p-2 mx-2">
        {/* <div className={"sidebar-title"}>Care</div>
        <div className={"sidebar-subtitle"}>SMS</div> */}
        <img src={Logo} alt="logo" className="logo" />
      </div>
      <Divider />
      {/* <List>
        <Divider />
        {menuOptions.map((link, index) => (
          <ListItem
            onClick={() => handleListItemClick(link.path)}
            classes={{
              root: classes.root,
              selected: classes.selected,
            }}
            key={link.id}
            disablePadding
          >
            <ListItemButton>
              <ListItemIcon>
                <img src={link.icon} alt="link icon" className="sidebar-icon" />
              </ListItemIcon>
              <ListItemText className="sidebar-text" primary={link.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
      <List>
        {menuOptions.map(({ icon: Icon, ...i }) => {
          const isSelected = location.pathname === i.path;
          return (
            <div key={i.id}>
              <ListItemButton
                key={i.id}
                className="my-2"
                onClick={() =>
                  i.hasSubsections
                    ? setSelected(i.id)
                    : handleListItemClick(i.path)
                }
              >
                <div>
                  {i?.hasSubsections ? (
                    <Down style={{ fontSize: 16, color: "#7E8B99" }} />
                  ) : (
                    <span className="mx-2"></span>
                  )}
                </div>
                <ListItemIcon>
                  {i?.noIcon ? (
                    <Icon style={{ color: "#4A545E" }} />
                  ) : (
                    <img src={Icon} alt={i.label} className="sidebar-icon" />
                  )}
                </ListItemIcon>
                <Typography
                  className={isSelected ? "selected-section" : "section-title"}
                >
                  {i.name}
                </Typography>
              </ListItemButton>
              {i?.hasSubsections ? (
                <Collapse in={selected === i.id} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding className="mx-2">
                    {i?.subSections?.map(({ SubIcon, ...sub }) => {
                      const issubSelected = location.pathname === sub.path;
                      return (
                        <ListItemButton
                          key={sub.id}
                          sx={{ pl: 10 }}
                          onClick={() => handleListItemClick(sub.path)}
                        >
                          <Typography
                            className={
                              issubSelected
                                ? "selected-section"
                                : "section-title"
                            }
                          >
                            {sub.name}
                          </Typography>
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Collapse>
              ) : null}
            </div>
          );
        })}
      </List>
      <div className="avatarContainer">
        <ListItem className="d-flex justify-content-between">
          <div className="d-flex">
            <Avatar
              className={"avatar"}
              alt={userData ? `${userData.firstName} ${userData.lastName}` : ""}
              src="https://images.unsplash.com/photo-1521572267360-ee0c2909d518?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
            />
            <div>
              <Typography className={"username mx-3"}>
                {userData ? `${userData.firstName} ${userData.lastName}` : ""}
              </Typography>
              <Typography className={"username mx-3"}>
                {userData?.email}
              </Typography>
            </div>
          </div>
          <OptionsIcon />
        </ListItem>
      </div>
    </Box>
  );

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElNotification, setAnchorElNotification] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenNotificationMenu = (event) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseNotificationMenu = () => {
    setAnchorElNotification(null);
  };

  const handleCloseUserMenu = ({ name, path }) => {
    setAnchorElUser(null);
    if (name === "Logout") {
      userLogout();
    }
    if (name === "Account") {
      return path && navigate(path);
    }
  };

  if (!userData) {
    // navigate("/home");
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return (
    <>
      <Grid className="header-main mx-3">
        <Toolbar disableGutters>
          <Grid
            role="button"
            onClick={() => navigate("/home")}
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
            }}
            className="align-items-center px-1"
          >
            <img src={Logo} alt="logo" className="logo" />
            {/* <div className={"sidebar-title"}>Care</div>
              <div className={"sidebar-subtitle"}>SMS</div> */}
          </Grid>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(e) => {
                toggleDrawer(true)(e);
              }}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Drawer
            anchor={"left"}
            open={drawerOpen}
            onClose={toggleDrawer(false)}
          >
            {list()}
          </Drawer>
          <Box
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
            }}
            className="align-items-center"
          >
            <img src={Logo} alt="logo" className="logo" />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {menuOptions
              .filter(
                (fil) => {
                  const role = fil?.access?.find(
                    (r) => r.role === userData?.role
                  );
                  const position = role
                    ? role?.position?.includes(userData?.position)
                    : undefined;
                  return fil.access.includes("*") || (role && position);
                }
                // fil.access.includes("*") || fil.access.includes(userData.role)
              )
              .map((option, idx) => {
                const Icon = option.icon;
                return (
                  <Button
                    key={option.id}
                    disabled={option?.disabled}
                    onClick={(e) => {
                      setListData(option?.subSections || []);
                      option?.subSections
                        ? handleOpenMenu(e)
                        : navigate(option?.path);
                    }}
                    className={[
                      "d-flex my-2",
                      location?.pathname === option?.path ||
                      option?.subSections?.some(
                        (sub) => location?.pathname === sub?.path
                      )
                        ? "selected-option"
                        : "normal-option",
                    ].join(" ")}
                  >
                    {option?.noIcon ? (
                      <Icon style={{ color: "#4A545E" }} />
                    ) : (
                      <img
                        src={option?.icon}
                        alt={option.label}
                        className="sidebar-icon"
                      />
                    )}
                    <Typography className="px-2 font-14 text-capitalize normal-options">
                      {option?.name}
                    </Typography>
                    {idx !== 0 && <DownArrow />}
                  </Button>
                );
              })}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {listData
                ?.filter((fil) => {
                  const role = fil?.access?.find(
                    (r) => r.role === userData?.role
                  );
                  const position = role
                    ? role?.position?.includes(userData?.position)
                    : undefined;
                  return fil.access.includes("*") || (role && position);
                })
                .map((option) => (
                  <MenuItem
                    key={option.id}
                    onClick={() => {
                      // setSelected(option?.name);
                      handleCloseNavMenu();
                      navigate(option?.path);
                    }}
                    disabled={option?.disabled}
                    className={[
                      location?.pathname === option?.path
                        ? "selected-option"
                        : "normal-option",
                    ].join(" ")}
                  >
                    <Typography className="normal-options" textAlign="center">
                      {option?.name}
                    </Typography>
                  </MenuItem>
                ))}
            </Menu>
          </Box>
          <Box className="d-flex align-items-center" sx={{ flexGrow: 0 }}>
            {/* <Tooltip title="Search">
                <SearchRoundedIcon />
              </Tooltip> */}
            <Tooltip title="Notifications">
              <IconButton onClick={handleOpenNotificationMenu} className="mx-3">
                <Badge badgeContent={notifications?.length} color="error">
                  <NotificationsNoneRoundedIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <NotificationsModal
              anchorEl={anchorElNotification}
              open={Boolean(anchorElNotification)}
              onClose={handleCloseNotificationMenu}
            />
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  style={{ width: 32, height: 32 }}
                  alt={
                    userData ? `${userData.firstName} ${userData.lastName}` : ""
                  }
                  src={userData?.profileImg}
                />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map(({ name, id, icon: Icon, path }) => (
                <MenuItem
                  key={id}
                  onClick={() => handleCloseUserMenu({ name, path })}
                >
                  <Icon />
                  <Typography className="mx-2" textAlign="center">
                    {name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
        <Divider />
      </Grid>
    </>
  );
};

export default MenuHeader;
