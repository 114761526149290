import React, { useEffect, useState } from "react";
import ConversationScreen from "../../screens/Chat/ConversationScreen";
import useMessenger from "../../hooks/useMessenger";
import { Dialog } from "@mui/material";

const ChatModal = ({
  open: isOpen,
  handleClose,
  chat,
  onSendMessage,
  socket,
}) => {
  const { leftMember } = useMessenger();

  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    if (Object.keys(leftMember).length !== 0) {
      console.log(leftMember);
    }
  }, [leftMember]);

  const onClose = () => {
    setOpen(true);
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"md"}
      fullWidth
      aria-labelledby="chat reply modal"
      aria-describedby="chat reply modal"
    >
      <ConversationScreen
        socket={socket}
        onClose={onClose}
        chat={chat}
        onSendMessage={onSendMessage}
      />
    </Dialog>
  );
};

export default ChatModal;
