import * as React from "react";
const ForwardSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      fillRule="evenodd"
      d="M9.64 4.093a.75.75 0 0 1 .763.024l8.25 5.25a.75.75 0 0 1 0 1.266l-8.25 5.25a.75.75 0 0 1-1.153-.633v-3.884l-7.097 4.517A.75.75 0 0 1 1 15.25V4.75a.75.75 0 0 1 1.153-.633L9.25 8.634V4.75a.75.75 0 0 1 .39-.657Zm1.11 9.79L16.853 10 10.75 6.116v7.768ZM8.603 10 2.5 6.116v7.768L8.603 10Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ForwardSvg;
