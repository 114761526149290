import React, { useState } from "react";
import { colors } from "../../styles";
import CustomButton from "../Buttons/CustomButton";
import { Icons } from "../../assets";
import { Dialog, Grid, IconButton, Typography } from "@mui/material";
import "./AddRegionModal.css";
import CloseIcon from "@mui/icons-material/Close";
import useCareTags from "../../hooks/useCareTags";
import ConfirmModal from "./DeleteModal";

const AddCareTag = ({ open: isOpen, handleClose, selected = {} }) => {
  const { updateCareTagApi, addCareTagApi } = useCareTags();
  const [open, setOpen] = useState(isOpen);
  const [tag, setTag] = useState(selected?.Tag || "#");
  const [description, setDescription] = useState(selected?.Description || "");
  const [isConfirm, setIsConfirm] = useState(false);

  const onClose = () => {
    setOpen(true);
    handleClose();
  };

  const onSubmit = () => {
    const variables = { ...selected, Tag: tag, Description: description };
    if (selected?.id) {
      updateCareTagApi(variables);
    } else {
      addCareTagApi(variables);
    }
    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
        <Grid>
          <Grid
            className="d-flex justify-content-between py-2 px-3 align-items-center"
            style={{
              backgroundColor: colors.OFFICE_COLOR,
            }}
          >
            <Typography className="region-modal-title">
              {selected?.id ? "Update CareTag" : "Create New CareTag"}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon className="close-icon" />
            </IconButton>
          </Grid>
          <Grid className="px-2">
            <Grid className="m-2 px-3">
              <Typography className="fieldName py-2">
                CareTag{" "}
                <img
                  src={Icons.Info_Icon2}
                  alt="info"
                  style={{ width: 20, height: 20 }}
                />
              </Typography>
              <input
                placeholder="# insert tag"
                className="inputField px-2 border-1 w-100"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
              />
            </Grid>
            <Grid className="px-3 m-2">
              <Typography className="fieldName py-2">Description</Typography>
              <textarea
                placeholder="Describe this reserved messaging hashtag for your CareSMS users"
                className="inputField p-2 border-1 w-100"
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
            <Grid>
              <CustomButton
                className="button mx-4 p-2 px-5"
                onClick={() => setIsConfirm(true)}
              >
                <Typography>
                  {selected?.id ? "Update" : "Save this CareTag"}
                </Typography>
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      {isConfirm && (
        <ConfirmModal
          open={isConfirm}
          handleClose={() => {
            setIsConfirm(false);
            onClose();
          }}
          handleDelete={onSubmit}
          title={`${selected?.id ? "Edit" : "Add"} Care Tag`}
          subtext={`Are you sure you want to ${
            selected?.id ? "update" : "add"
          } this Care Tag`}
          primaryText={"Confirm"}
        />
      )}
    </>
  );
};

export default AddCareTag;
