import * as React from "react";
const Delete = ({ color }) => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.6002 2.24961C2.6002 1.33834 3.33893 0.599609 4.2502 0.599609H5.7502C6.66147 0.599609 7.4002 1.33834 7.4002 2.24961V2.39961H9.3502C9.59872 2.39961 9.8002 2.60108 9.8002 2.84961C9.8002 3.09814 9.59872 3.29961 9.3502 3.29961H9.17589L8.81247 9.84113C8.7639 10.7155 8.04072 11.3996 7.16501 11.3996H2.83538C1.95967 11.3996 1.2365 10.7155 1.18792 9.84113L0.824501 3.29961H0.650195C0.401667 3.29961 0.200195 3.09814 0.200195 2.84961C0.200195 2.60108 0.401667 2.39961 0.650195 2.39961H2.6002V2.24961ZM1.72589 3.29961L2.08653 9.79121C2.10861 10.1886 2.43733 10.4996 2.83538 10.4996H7.16501C7.56306 10.4996 7.89178 10.1886 7.91386 9.79121L8.2745 3.29961H1.72589ZM6.5002 2.39961H3.5002V2.24961C3.5002 1.8354 3.83598 1.49961 4.2502 1.49961H5.7502C6.16441 1.49961 6.5002 1.8354 6.5002 2.24961V2.39961ZM3.6502 4.79961C3.89872 4.79961 4.1002 5.00108 4.1002 5.24961V8.54961C4.1002 8.79814 3.89872 8.99961 3.6502 8.99961C3.40167 8.99961 3.2002 8.79814 3.2002 8.54961V5.24961C3.2002 5.00108 3.40167 4.79961 3.6502 4.79961ZM6.3502 4.79961C6.59872 4.79961 6.8002 5.00108 6.8002 5.24961V8.54961C6.8002 8.79814 6.59872 8.99961 6.3502 8.99961C6.10167 8.99961 5.9002 8.79814 5.9002 8.54961V5.24961C5.9002 5.00108 6.10167 4.79961 6.3502 4.79961Z"
      fill={color || "#000"}
    />
  </svg>
);
export default Delete;
