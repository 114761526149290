import React, { useState } from "react";
import MenuHeader from "../../../components/Header/MenuHeader";
import DataTable from "../../../components/DataTable/DataTable";
import { usersHeader } from "../../../data";
import Name from "../../../components/DataTable/Name";
import IconComponent from "../../../components/DataTable/Icon";
import TableFilter from "../../../components/Filter/TableFilter";
import AddUserModal from "../../../components/Modals/AddUser";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import Loading from "../../../components/Loading";
import DeleteModal from "../../../components/Modals/DeleteModal";

const Users = ({ noHeader }) => {
  const {
    fetchUsersApi,
    users,
    initializeUser,
    updateUser,
    loading,
    removeUser,
  } = useAuth();
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [isDelete, setIsDelete] = useState(false);
  // const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsersApi();
  }, []);

  const handleDelete = () => {
    removeUser(selectedUser);
  };

  const onCancel = () => {
    setOpen(false);
    setSelectedUser({});
  };

  const onSubmit = (data) => {
    onCancel();
    if (selectedUser?.role) {
      updateUser(
        {
          id: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNo: data.phoneNo,
          position: data.position,
          role: data.role,
        },
        true
      ).then(() => fetchUsersApi());
    } else {
      initializeUser({
        ...data,
        role: "back office",
      }).then(() => fetchUsersApi());
    }
  };

  const data = users
    ?.filter((fil) => fil?.role === "back office")
    ?.filter((fil) =>
      search
        ? fil.firstName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          fil.lastName?.toLowerCase()?.includes(search?.toLowerCase())
        : true
    )
    .map((x) => ({
      ...x,
      name: <Name i={x} hasAvatar key={x.id} flex={2} avatar={x?.profileImg} />,
      position: x.position,
      phone: x.phoneNo,
      edit: (
        <IconComponent
          icon="Edit_Icon"
          i={x}
          key={x.id}
          onClick={() => {
            setSelectedUser(x);
            setOpen(true);
          }}
        />
      ),
      delete: (
        <IconComponent
          icon="Delete_Icon"
          i={x}
          onClick={() => {
            setIsDelete(true);
            setSelectedUser(x);
          }}
        />
      ),
    }));

  return (
    <Grid>
      {loading && <Loading loading={loading} />}
      {!noHeader && <MenuHeader isSecondary />}
      <Grid className="my-3">
        <TableFilter
          length={users.length}
          sectionName={"Users"}
          search={search}
          setSearch={setSearch}
          onAdd={() => setOpen(true)}
        />
      </Grid>
      <Grid className="mx-5">
        <DataTable columns={usersHeader} rows={data} />
      </Grid>
      {open && (
        <AddUserModal
          open={open}
          handleClose={onCancel}
          onSubmit={onSubmit}
          selectedUser={selectedUser}
        />
      )}
      {isDelete && (
        <DeleteModal
          open={isDelete}
          handleClose={() => {
            setIsDelete(false);
            setSelectedUser({});
          }}
          handleDelete={handleDelete}
          title={"Delete user"}
          subtext={"Are you sure you want to delete this user?"}
        />
      )}
    </Grid>
  );
};

export default Users;
