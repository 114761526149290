import { useCallback, useEffect, useState } from "react";
import {
  replaceChats,
  addChat,
  updateChat,
  deleteChat,
  changeCurrentChat,
  updateMessages,
  updateLatestMessages,
  updateCount,
  updateUnreadCount,
  updateChatMessage,
  setEditMessage,
} from "../redux/reducers/chats.reducer";
import { useDispatch, useSelector } from "react-redux";
// import { queryString } from "../utilities/resuseFunctions";
import MessagingApi from "../services/messagingApi";
// import io from "socket.io-client";
// import { ATHENA_BASE_URL, MESSAGING_BASE_URL } from "../constants/endpoints";
import moment from "moment";
import AthenaApi from "../services/athenaApi";
import { CONTENT_TYPE, MULTIPART_FORM_DATA } from "../constants/api-constants";
import { updateProvidersCount } from "../redux/reducers/providers.reducer";
import socket from "../services/socket";
import { tagMapping } from "../data/listOptions";
import useNotifications from "./useNotifications";
import { NotificationType } from "../constants/types";

// const socket = io(MESSAGING_BASE_URL);

export default function useChats() {
  const dispatch = useDispatch();
  const {
    data: chats,
    count,
    currentChat,
    messages,
    latestMessages,
    unreadCount,
    editMessage,
  } = useSelector((state) => state.chats);
  const { userData } = useSelector((state) => state.users);
  const { createNotificationApi } = useNotifications(true);
  const [loading, setLoading] = useState(false);
  const [leftMember, setLeftMember] = useState({});
  const [deletedGroupID, setDeletedGroupID] = useState({});
  // const [user, setUser] = useState(null);
  // const [chatHomeData, setChatHomeData] = useState(null);
  // const [currentChat, setCurrentChat] = useState({});
  const [isConnected, setIsConnected] = useState(socket.connected);
  // const [messages, setMessages] = useState([]);
  const [offset, setOffset] = useState(0);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);

  const handleClose = () => {
    setLoading(false);
  };
  const handleOpen = () => {
    setLoading(true);
  };

  const setMessages = (messages) => {
    dispatch(updateMessages(messages));
  };

  const setEditMsg = (message) => {
    dispatch(setEditMessage(message));
  };

  useEffect(() => {
    // console.log(leftMember);
  }, [leftMember]);

  function sendJoin() {
    const groupIDs = chats?.map((data) => `G${data.conversationId}`);
    const userID = `U${userData?.id}`;
    socket.emit("join", {
      user: {
        userID: userID,
      },
      groupIDs: groupIDs,
    });
  }

  function newGroupCreationEmit(members) {
    socket.emit("newGroup", {
      members,
    });
  }

  const groupDeletionEmit = (groupID) => {
    socket.emit("deleteGroup", {
      groupID,
    });
    if (chats && chats?.length > 0) {
      dispatch(
        replaceChats(chats?.filter((data) => data.conversationId !== groupID))
      );
    }
  };

  async function leaveGroup(groupID, userID) {
    try {
      socket.emit("leaveGroup", {
        groupID,
        userID,
      });
      if (chats && chats?.length > 0 && userData?.id === userID) {
        dispatch(
          replaceChats(chats?.filter((data) => data.conversationId !== groupID))
        );
      }
      // console.log("Leave group", groupID, userID);
      await MessagingApi.post(`/group/kickuser/${groupID}`, {
        userId: userID,
      });
    } catch (err) {
      console.log(err);
    }
  }

  function updateLastSeen(userId, conversationId, type) {
    socket.emit("lastSeen", {
      userId,
      conversationId,
    });
    dispatch(updateUnreadCount(unreadCount - (count?.[conversationId] || 0)));
    dispatch(updateCount({ conversationId, count: 0 }));
    if (chats && type === undefined) {
      dispatch(
        replaceChats(
          chats.map((data) => {
            if (data.conversationId === conversationId) {
              return {
                conversationId: data.conversationId,
                isCreator: data.isCreator,
                isAdmin: data.isAdmin,
                createdAt: data.createdAt,
                conversationName: data.conversationName,
                conversationImg: data.conversationImg,
                conversationType: data.conversationType,
                communityId: data.communityId,
                lastSeen: moment().format("YYYY-MM-DD HH:mm:ss"),
                lastMessage: data.lastMessage,
                selected: !data.selected,
                contactId: data.contactId,
                contactFN: data.contactFN,
                contactLN: data.contactLN,
                contactImg: data.contactImg,
                updatedAt: data.updatedAt,
              };
            } else {
              return {
                conversationId: data.conversationId,
                isCreator: data.isCreator,
                isAdmin: data.isAdmin,
                createdAt: data.createdAt,
                conversationName: data.conversationName,
                conversationImg: data.conversationImg,
                conversationType: data.conversationType,
                communityId: data.communityId,
                lastSeen: moment(data.lastSeen).format("YYYY-MM-DD HH:mm:ss"),
                lastMessage: data.lastMessage,
                selected: data.selected,
                contactId: data.contactId,
                contactFN: data.contactFN,
                contactLN: data.contactLN,
                contactImg: data.contactImg,
                updatedAt: data.updatedAt,
              };
            }
          })
        )
      );
    }
    if (chats && type === "readall") {
      dispatch(
        replaceChats(
          chats.map((data) => {
            if (data.selected) {
              return {
                conversationId: data.conversationId,
                isCreator: data.isCreator,
                isAdmin: data.isAdmin,
                createdAt: data.createdAt,
                conversationName: data.conversationName,
                conversationImg: data.conversationImg,
                conversationType: data.conversationType,
                communityId: data.communityId,
                lastSeen: moment().format("YYYY-MM-DD HH:mm:ss"),
                lastMessage: data.lastMessage,
                selected: !data.selected,
                contactId: data.contactId,
                contactFN: data.contactFN,
                contactLN: data.contactLN,
                contactImg: data.contactImg,
                updatedAt: data.updatedAt,
              };
            } else {
              return {
                conversationId: data.conversationId,
                isCreator: data.isCreator,
                isAdmin: data.isAdmin,
                createdAt: data.createdAt,
                conversationName: data.conversationName,
                conversationImg: data.conversationImg,
                conversationType: data.conversationType,
                communityId: data.communityId,
                lastSeen: moment(data.lastSeen).format("YYYY-MM-DD HH:mm:ss"),
                lastMessage: data.lastMessage,
                selected: data.selected,
                contactId: data.contactId,
                contactFN: data.contactFN,
                contactLN: data.contactLN,
                contactImg: data.contactImg,
                updatedAt: data.updatedAt,
              };
            }
          })
        )
      );
    }
  }

  function emitSignOut() {
    socket.emit("signOut");
  }

  function updateChatHomeData(data) {
    if (chats) {
      const newChatHomeData = chats.map((CH_data) => ({
        ...CH_data,
        lastMessage:
          CH_data.conversationId === data.conversationId
            ? data
            : CH_data.lastMessage,
      }));
      dispatch(replaceChats(newChatHomeData));
    }
  }

  function updateChatData(data) {
    const newMsg = {
      ...data,
      sentOn: `${new Date(data.createdAt).getHours() % 12}:${
        new Date(data.createdAt).getMinutes() > 10 ? "" : "0"
      }${new Date(data.createdAt).getMinutes()} ${
        new Date(data.createdAt).getHours() > 12 ? "PM" : "AM"
      }`,
      fromMe: userData?.id === data.createdByID,
    };
    // console.log(newMsg, "mes");
    // eslint-disable-next-line eqeqeq
    if (
      userData &&
      messages !== null
      // &&
      // currentChat.conversationId == data.conversationId
    ) {
      setMessages([newMsg, ...messages]);
      setLatestMsg(newMsg);
      // updateLastSeen(userData.id, data.conversationId);
      if (currentChat.conversationId == data.conversationId) {
        updateLastSeen(userData.id, data.conversationId, "readall");
      } else {
        !newMsg.fromMe &&
          dispatch(
            updateCount({
              conversationId: data.conversationId,
              count: (count?.[data.conversationId] || 0) + 1,
            })
          );
      }
    }
    updateChatHomeData(data);
  }

  useEffect(() => {
    socket.on("connect", () => {
      setIsConnected(true);
    });
    socket.on("disconnect", () => {
      setIsConnected(false);
    });
    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, []);

  useEffect(() => {
    socket.on("newGroupCreated", () => {
      // console.log("new group created");
      getChats();
    });
    return () => {
      socket.off("newGroupCreated");
    };
  }, [userData, isConnected]);

  useEffect(() => {
    // socket.emit("getLL", { message: "Hello, world!" });
    socket.on("sendLL", (data) => {
      dispatch(updateProvidersCount(data));
    });
    return () => {
      socket.off("sendLL");
    };
  }, [isConnected]);

  useEffect(() => {
    socket.on("notify", (data) => {
      console.log("notify");
    });
    return () => {
      socket.off("notify");
    };
  }, []);

  useEffect(() => {
    // if (Object.keys(leftMember).length !== 0) {
    //   console.log(leftMember);
    // }
  }, [leftMember]);

  const [updatedGroupID, setUpdatedGroupID] = useState(null);

  const groupUpdationEmit = (groupID) => {
    socket.emit("updateGroup", {
      groupID,
    });
    setUpdatedGroupID(groupID);
  };

  const groupInfoUpdate = (groupID, groupName, groupImg) => {
    socket.emit("updateGroupInfo", {
      groupID,
      groupName,
      groupImg,
    });
  };

  useEffect(() => {
    socket.on("sendLeaveGroup", (data) => {
      setLeftMember({
        groupID: data.groupID,
        userID: data.userID,
      });
      if (userData.id === data.userID) {
        dispatch(
          replaceChats(
            chats?.filter((data_G) => data_G.conversationId !== data.groupID)
          )
        );
        if (currentChat?.conversationId === data.groupID) {
          setCurrentChat({});
        }
      }
    });
    socket.on("groupDeleted", (data) => {
      setDeletedGroupID(data.groupID);
      if (chats && chats?.length > 0) {
        dispatch(
          replaceChats(
            chats?.filter((data_G) => data_G.conversationId !== data.groupID)
          )
        );
      }
      if (currentChat?.conversationId === data.groupID) {
        setCurrentChat({});
      }
    });
    socket.on("groupInfoUpdated", (data) => {
      dispatch(
        replaceChats(
          chats?.map((data_G) => {
            if (data_G.conversationId === data.groupID) {
              return {
                ...data_G,
                conversationName:
                  data_G.conversationId === data.groupID
                    ? data.groupName
                    : data_G.conversationName,
                conversationImg:
                  data_G.conversationId === data.groupID
                    ? data.groupImg
                    : data_G.conversationImg,
              };
            }
            return {
              ...data_G,
            };
          })
        )
      );
      setCurrentChat({
        ...currentChat,
        conversationName:
          currentChat?.conversationId === data.groupID
            ? data.groupName
            : currentChat?.conversationName,
        conversationImg:
          currentChat?.conversationId === data.groupID
            ? data.groupImg
            : currentChat?.conversationImg,
      });
    });
    socket.on("groupUpdated", (data) => {
      setUpdatedGroupID(data.groupID);
    });
    return () => {
      socket.off("sendLeaveGroup");
      socket.off("groupDeleted");
      socket.off("groupUpdated");
    };
  }, [chats, isConnected]);

  const deleteMessage = (messageID, groupID) => {
    socket.emit("sendDeleteMessage", { messageID, groupID });
    setMessages(messages.filter((data) => data.id !== messageID));
  };

  const getDeleteMessage = (data) => {
    setMessages(messages.filter((data_M) => data_M.id !== data.messageID));
    dispatch(
      replaceChats(
        chats?.map((data_G) => {
          if (data_G.conversationId === data.groupID) {
            return {
              ...data_G,
              lastMessage: {
                ...data_G.lastMessage,
                messageBody:
                  data_G.lastMessage.id === data.messageID
                    ? "This chat has been deleted!"
                    : data_G.lastMessage.messageBody,
              },
            };
          }
          return {
            ...data_G,
          };
        })
      )
    );
  };

  const getUpdateMessage = (data) => {
    setMessages(
      messages.map((data_M) => {
        if (data_M.id === data.id) {
          return {
            ...data,
            sentOn: moment(data.createdAt).format("hh:mm A"),
            fromMe: userData?.id === data.createdByID,
          };
        }
        return {
          ...data_M,
          sentOn: moment(data_M.createdAt).format("hh:mm A"),
          fromMe: userData?.id === data_M.createdByID,
        };
      })
    );
    dispatch(
      replaceChats(
        chats?.map((data_G) => {
          if (data_G.conversationId === data.conversationId) {
            return {
              ...data_G,
              lastMessage:
                data_G.lastMessage.id === data.id ? data : data_G.lastMessage,
            };
          }
          return {
            ...data_G,
          };
        })
      )
    );
  };

  const showNotification = () => {
    if ("Notification" in window && Notification.permission === "granted") {
      const notification = new Notification("CareSMS", {
        body: "You have a new message!",
        // icon: "/path/to/notification-icon.png", // Replace with the path to your notification icon
      });

      notification.onclick = () => {
        // Handle the click event, e.g., switch to the chat window
        window.focus();
      };
    }
  };

  useEffect(() => {
    if (chats !== null) {
      sendJoin();
    }
    socket.on("message", (data) => {
      userData?.id !== data.createdByID &&
        dispatch(updateUnreadCount(unreadCount + 1));
      // console.log("came in socket");
      updateChatData(data);
      if (document.hidden) {
        showNotification();
      }
    });
    socket.on("getDeleteMessage", getDeleteMessage);
    socket.on("getUpdateMessage", getUpdateMessage);
    return () => {
      socket.off("message");
      socket.off("getDeleteMessage");
      socket.off("getUpdateMessage");
    };
  }, [chats, messages, isConnected]);

  function sendMessage(
    text,
    createdBy,
    groupID,
    taggedUsers = [],
    taggedPatients = [],
    metadata = [],
    messageType = "text",
    fileId,
    forwarded,
    senderName,
    groupName,
    memberIDs,
    notificationMessage
  ) {
    const message = {
      text,
      createdBy: createdBy.toString(),
      groupID: groupID.toString(),
      taggedUsers,
      taggedPatients,
      metadata,
      messageType,
      fileId,
      forwarded,
      senderName,
      groupName,
      memberIDs,
      notificationMessage,
    };
    // console.log(message, "message", isConnected);
    socket.emit("sendMessage", {
      message,
    });
    // getChats(createdBy);
  }

  function updateMessage({
    text,
    createdBy,
    groupID,
    taggedUsers = [],
    taggedPatients = [],
    metadata = [],
    messageType = "text",
    fileId,
    forwarded,
    senderName,
    groupName,
    memberIDs,
    messageID,
    notificationMessage,
    deletedTagMembers,
    addedTagMembers,
    deletedTagPatients,
    addedTagPatients,
    deletedTags,
    addedTags,
  }) {
    const message = {
      text,
      createdBy: createdBy.toString(),
      groupID: groupID.toString(),
      taggedUsers,
      taggedPatients,
      metadata,
      messageType,
      fileId,
      forwarded,
      senderName,
      groupName,
      memberIDs,
      messageID,
      notificationMessage,
      deletedTagMembers,
      addedTagMembers,
      deletedTagPatients,
      addedTagPatients,
      deletedTags,
      addedTags,
    };
    console.log(message, "message", isConnected);
    socket.emit("updateMessage", {
      message,
    });
    // getChats(createdBy);
  }

  const chatConvert = (data_res) => {
    return {
      conversationId: data_res?.Conversation?.id,
      isCreator: data_res?.isCreator,
      isAdmin: data_res?.isAdmin,
      createdAt: data_res?.createdAt,
      conversationName: data_res?.Conversation?.name,
      conversationImg: data_res?.Conversation?.conversationImg,
      conversationType: data_res?.Conversation?.conversationType,
      communityId: data_res?.Conversation?.communityId,
      lastSeen: moment(data_res?.updatedAt).format("YYYY-MM-DD HH:mm:ss"),
      lastMessage: data_res?.Conversation?.Message.length
        ? data_res?.Conversation?.Message[0]
        : undefined,
      selected: false,
      contactId: data_res?.Conversation?.ConversationUser.length
        ? data_res?.Conversation?.ConversationUser.filter(
            (data) => data.User.id !== userData?.id
          )[0].User.id
        : undefined,
      contactFN: data_res?.Conversation?.ConversationUser.length
        ? data_res?.Conversation?.ConversationUser.filter(
            (data) => data.User.id !== userData?.id
          )[0].User.firstName
        : undefined,
      contactLN: data_res?.Conversation?.ConversationUser.length
        ? data_res?.Conversation?.ConversationUser.filter(
            (data) => data.User.id !== userData?.id
          )[0].User.lastName
        : undefined,
      contactImg: data_res?.Conversation?.ConversationUser.length
        ? data_res?.Conversation?.ConversationUser.filter(
            (data) => data.User.id !== userData?.id
          )[0].User.profileImg
        : undefined,
      updatedAt: data_res?.updatedAt,
    };
  };

  const getChats = useCallback(
    async (userId) => {
      handleOpen();
      return await MessagingApi.post(`/group/getall`, {
        userId: userId || userData?.id,
      })
        .then((res) => {
          const data = res.data.data
            .map(chatConvert)
            .filter((data) => data.lastMessage !== undefined);
          dispatch(replaceChats(data || []));
          handleClose();
          return data;
        })
        .catch((err) => {
          console.log(err, "err");
          handleClose();
          return [];
        });
    },
    [dispatch]
  );

  const getLatestMessages = useCallback(async (userId) => {
    handleOpen();
    return await MessagingApi.post(`/group/getlatest`, {
      userId: parseInt(userId || userData?.id),
    })
      .then((res) => {
        const data = res.data.data
          .map(chatConvert)
          .filter((data) => data.lastMessage !== undefined);
        dispatch(updateLatestMessages(data || []));
        handleClose();
        return data;
      })
      .catch((err) => {
        console.log(err, "err");
        handleClose();
        return [];
      });
  }, []);

  const getConversationDetails = async ({ conversationId }) => {
    try {
      handleOpen();
      const response = await MessagingApi.post(
        `/group/fetch/${conversationId}`
      );
      handleClose();
      return response.data.data;
    } catch (err) {
      handleClose();
      throw new Error(err);
    }
  };

  const getUnreadCount = async ({ userId }) => {
    try {
      handleOpen();
      const response = await MessagingApi.post(`/group/getunreadcount`, {
        userId,
      });
      handleClose();
      dispatch(updateUnreadCount(response.data.data));
      return response.data.data;
    } catch (err) {
      handleClose();
      throw new Error(err);
    }
  };

  const getMessages = async ({ conversationId }) => {
    handleOpen();
    try {
      const response = await MessagingApi.post(
        `/group/getlastn/${conversationId}`,
        {
          offset: 0,
        }
      );
      const respData = response.data.data.map((mData) => {
        return {
          ...mData,
          sentOn: `${new Date(mData.createdAt).getHours() % 12}:${
            new Date(mData.createdAt).getMinutes() > 10 ? "" : "0"
          }${new Date(mData.createdAt).getMinutes()} ${
            new Date(mData.createdAt).getHours() > 12 ? "PM" : "AM"
          }`,
          fromMe: userData?.id === mData.createdByID,
        };
      });
      const tmpData = latestMessages.map((message) => {
        const findMsg = respData?.find(
          (msg) => msg?.id === message.lastMessage?.id
        );
        if (findMsg) {
          return {
            ...message,
            lastMessage: findMsg,
          };
        }
        return message;
      });
      dispatch(updateLatestMessages(tmpData));
      handleClose();
      setMessages(respData);
      return respData;
    } catch (err) {
      handleClose();
      throw new Error(err);
    }
  };

  const getUnreadMessages = async ({ conversationId, updatedAt }) => {
    try {
      const response = await MessagingApi.post(
        `/group/getlastn/${conversationId}`,
        {
          offset: 0,
        }
      );
      const respData = response.data?.data
        ?.map((mData) => {
          return {
            createdAt: mData.createdAt,
            fromMe: userData?.id === mData.createdByID,
          };
        })
        ?.filter((fil) => !fil.fromMe);
      const count =
        respData?.filter((res) => moment(updatedAt).isBefore(res.createdAt))
          ?.length || 0;
      dispatch(updateCount({ conversationId, count }));
      return count;
    } catch (err) {
      console.log(err, "err");
      throw new Error(err);
    }
  };

  useEffect(() => {
    if (Array.isArray(messages)) {
      setOffset(messages.length);
    }
  }, [messages]);

  const getPrevMessages = async ({ conversationId, offset }) => {
    // setIsLoading(true);
    // setError(null);
    try {
      const response = await MessagingApi.post(
        `/group/getlastn/${conversationId}`,
        {
          data: {
            offset: offset - 1,
            lastIndex: messages[0].id,
          },
        }
      );
      const respData = response.data.data.map((mData) => {
        return {
          ...mData,
          sentOn: moment(mData.createdAt).format("HH:mm A"),
          fromMe: userData.id === mData.createdByID,
        };
      });
      if (respData.length === 0) {
        // No more messages to load
        setHasMoreMessages(false);
      } else {
        setMessages((prevData) => [...prevData, ...respData]);
        // setOffset(prevOffset => prevOffset + respData.length);
        // setOffset(chatData.length + respData.length);
      }
    } catch (err) {
      //  setError(err.response.data.error);
    }
    // setIsLoading(false);
  };

  const getAllContacts = async (data) => {
    handleOpen();
    return await MessagingApi.post(
      `/group/getcontacts/${data?.id || userData?.id}`
    )
      .then((res) => {
        // dispatch(addChat(res?.data?.data || []));
        return res.data;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const getContactInfo = async (data) => {
    handleOpen();
    return await MessagingApi.post(
      `/user/fetch/contact/${data?.id || userData?.id}`,
      {
        contactId: data.contactId,
      }
    )
      .then((res) => {
        // dispatch(addChat(res?.data?.data || []));
        return res.data.data;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const createPrivateConversation = async (memberIds) => {
    handleOpen();
    return await MessagingApi.post(`/group/create/private`, {
      memberIds: [+userData.id, ...memberIds],
    })
      .then((res) => {
        // dispatch(addChat(res?.data?.data || []));
        return res.data.data;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const response = await MessagingApi.post(`/document/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data.data.url;
  };

  const createNewGroup = async (
    creatorId,
    g_name,
    description,
    conversationImg,
    memberIds,
    selectedImage
  ) => {
    try {
      let grpImg;
      if (selectedImage) {
        grpImg = await uploadImage(selectedImage);
      }
      await MessagingApi.post(`/group/create/meta`, {
        creatorId,
        name: g_name,
        description,
        conversationImg: grpImg ? grpImg : conversationImg,
        memberIds,
        // communityId:
        //   selectedOption !== null ? selectedOption.LocationID : null,
      });
      getChats();
    } catch (err) {
      console.log(err);
    }
  };

  const addChatApi = (data) => {
    handleOpen();
    return MessagingApi.post("chats", data)
      .then((res) => {
        dispatch(addChat(res?.data?.data || []));
        return res;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const updateChatApi = (data) => {
    handleOpen();
    return MessagingApi.put(`chats/${data.id}`, data)
      .then((res) => {
        dispatch(updateChat(res?.data?.data || []));
        handleClose();
        return res;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const deleteChatApi = (data) => {
    handleOpen();
    MessagingApi.delete(`chats/${data.id}`)
      .then((res) => {
        dispatch(deleteChat(res?.data?.data || []));
        handleClose();
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const setCurrentChat = (data) => {
    // console.log(data, "data");
    dispatch(changeCurrentChat(data));
  };

  const starMessage = async ({ conversationId, messageId }) => {
    try {
      await MessagingApi.post("/group/star/message", {
        userId: userData.id,
        groupId: conversationId,
        messageId: messageId,
      });
      getChats();
      getMessages({ conversationId });
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  };

  const unstarMessage = async ({ id, conversationId }) => {
    try {
      await MessagingApi.delete(`/group/star/message/${id}`);
      getChats();
      getMessages({ conversationId });
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  };

  const getStarredMessages = async ({ conversationId }) => {
    handleOpen();
    try {
      const response = await MessagingApi.post("/group/getstarred", {
        offset: 0,
        groupId: conversationId,
        userId: userData.id,
      });
      const respData = response.data?.data.map((mData) => {
        return {
          ...mData,
          sentOn: `${new Date(mData.Message.createdAt).getHours() % 12}:${
            new Date(mData.Message.createdAt).getMinutes() > 10 ? "" : "0"
          }${new Date(mData.Message.createdAt).getMinutes()} ${
            new Date(mData.Message.createdAt).getHours() > 12 ? "PM" : "AM"
          }`,
          fromMe: userData.id === mData.Message.createdByID,
        };
      });
      handleClose();
      return respData.reverse();
    } catch (err) {
      console.log(err);
    }
    handleClose();
  };

  const applyTag = async ({ action, sentToEHR, messageId, conversationId }) => {
    try {
      handleOpen();
      await MessagingApi.post("/message/apply_tag", {
        action,
        sentToEHR,
        messageId,
      }).then(() => {
        getChats();
        getMessages({ conversationId });
        // handleClose();
      });
    } catch (err) {
      console.log(err);
      handleClose();
      throw new Error(err);
    }
  };

  const applyPatientTag = async ({
    patientId,
    messageId,
    startIndex,
    endIndex,
    conversationId,
  }) => {
    try {
      handleOpen();
      await MessagingApi.post("/message/apply_patient", {
        patientId,
        messageId,
        startIndex,
        endIndex,
      }).then(() => {
        getChats();
        getMessages({ conversationId });
        // handleClose();
      });
    } catch (err) {
      handleClose();
      console.log(err);
      throw new Error(err);
    }
  };

  const markUrgent = async ({
    messageId,
    conversationId,
    groupName,
    M_data,
    memberIds,
  }) => {
    try {
      handleOpen();
      const markerName = `${userData.firstName} ${userData.lastName}`;
      const message = [
        {
          text: "You have a message marked urgent from",
          isBold: false,
        },
        {
          text: markerName,
          isBold: true,
        },
      ];
      if (groupName) {
        message.push(
          {
            text: "in",
            isBold: false,
          },
          {
            text: groupName,
            isBold: true,
          }
        );
      }
      await createNotificationApi({
        ids: memberIds,
        type: NotificationType.URGENT_MESSAGE,
        data: { message, M_data },
        metadata: {
          markerName,
          groupName,
        },
      });
      await MessagingApi.post("/message/mark_urgent", {
        messageId,
      }).then(() => {
        dispatch(updateChatMessage({ messageId, conversationId }));
        getLatestMessages(userData?.id);
        handleClose();
      });
    } catch (err) {
      handleClose();
      console.log(err);
      throw new Error(err);
    }
  };

  const removeTag = async ({ id, patientId, messageId, conversationId }) => {
    try {
      handleOpen();
      await MessagingApi.delete(`/message/remove_tag/${id}`, {
        id,
        patientId,
        messageId,
      }).then(() => {
        getChats();
        getMessages({ conversationId });
        // handleClose();
      });
    } catch (err) {
      handleClose();
      console.log(err);
      throw new Error(err);
    }
  };

  const removePatientTag = async ({
    id,
    patientId,
    messageId,
    conversationId,
  }) => {
    try {
      handleOpen();
      await MessagingApi.delete(`/message/remove_patient/${id}`, {
        id,
        patientId,
        messageId,
      }).then(() => {
        getChats();
        getMessages({ conversationId });
        // handleClose();
      });
    } catch (err) {
      handleClose();
      console.log(err);
      throw new Error(err);
    }
  };

  const updateEHR = async ({ metadata, note, message }) => {
    try {
      handleOpen();
      const promises = metadata?.map(async (i) => {
        return await MessagingApi.post(`/message/update_ehr/${i.id}`);
      });
      const athena = metadata?.map(async (i) => {
        return await AthenaApi.post(`/athena/patientcases`, {
          subject: i.action,
          departmentid: 14,
          documentsource: "CAREGIVER",
          documentsubclass: tagMapping[i.action] || "Other",
          internalnote: message?.messageBody || note,
          patientId: message?.TaggedPatients?.[0]?.patientId,
          // providerid: 2,
        });
      });
      return await Promise.all([...athena, ...promises]).then(() => {
        getMessages({ conversationId: message?.conversationId });
        return getChats();
        // handleClose();
      });
    } catch (err) {
      console.log(err);
      handleClose();
      throw new Error(err);
    }
  };

  const uploadDocumentAthena = async (data) => {
    try {
      handleOpen();
      const res = await AthenaApi.post(
        `/athena/documents/clinicaldocuments`,
        data,
        {
          headers: {
            [CONTENT_TYPE]: MULTIPART_FORM_DATA,
          },
        }
      );
      handleClose();
      return res;
    } catch (err) {
      console.log(err);
      handleClose();
      throw new Error(err);
    }
  };

  const setLatestMsg = (data) => {
    const chat = chats.find((i) => i.conversationId === data.conversationId);
    const chatMsg = { ...chat, lastMessage: data };
    // console.log(chatMsg, "chatMsg-new", chat, data);
    const messages = [...latestMessages, chatMsg];
    dispatch(updateLatestMessages([...messages]));
  };

  const setLatestMessages = (data) => {
    dispatch(updateLatestMessages(data));
  };

  const getReferencedPatients = async (data) => {
    try {
      const response = await MessagingApi.post(
        `/group/patient`,
        {
          groupId: data.conversationId,
        },
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.data;
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  };

  const getTaggedCaretags = async (data) => {
    try {
      const response = await MessagingApi.post(
        `/group/caretag`,
        {
          groupId: data.conversationId,
        },
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.data;
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  };

  const getCommunityPatients = async () => {
    try {
      const response = await MessagingApi.post(
        `/community/get/patient/${userData.communityId}`
      );
      const respData = response.data.data.patientdemographic.map((data) => {
        return {
          id: data.PatientID,
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/2048px-Windows_10_Default_Profile_Picture.svg.png",
          name: `${data.PatientFirstName} ${data.PatientLastName}`,
        };
      });
      return respData;
    } catch (err) {
      // setError(err.response.data.error);
      console.log("ERR", err);
      throw new Error(err);
    }
  };

  const getProviderPatients = async (data) => {
    try {
      const response = await MessagingApi.post(
        `/user/get/patients/${userData.ProviderID}`
      );
      const respData = response.data.data.map((data) => {
        return {
          id: data.PatientID,
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/2048px-Windows_10_Default_Profile_Picture.svg.png",
          name: `${data.PatientFirstName} ${data.PatientLastName}`,
        };
      });
      return respData;
    } catch (err) {
      // setError(err.response.data.error);
      console.log("ERR", err);
      throw new Error(err);
    }
  };

  const getMediaFiles = async (data) => {
    try {
      const response = await MessagingApi.post(`/group/files`, {
        groupId: data.conversationId,
        mimeType: "image/png",
      });
      const respData = response.data.data;
      return respData;
    } catch (err) {
      // setError(err.response.data.error);
      console.log("ERR", err);
      throw new Error(err);
    }
  };

  return {
    chats,
    addChatApi,
    updateChatApi,
    deleteChatApi,
    loading,
    handleClose,
    count,
    getChats,
    getAllContacts,
    createPrivateConversation,
    userData,
    newGroupCreationEmit,
    getStarredMessages,
    createNewGroup,
    getMessages,
    sendMessage,
    getContactInfo,
    getConversationDetails,
    leaveGroup,
    messages,
    setMessages,
    currentChat,
    setCurrentChat,
    starMessage,
    applyTag,
    updateEHR,
    uploadDocumentAthena,
    applyPatientTag,
    leftMember,
    setLeftMember,
    groupDeletionEmit,
    deletedGroupID,
    deleteMessage,
    groupInfoUpdate,
    updatedGroupID,
    setUpdatedGroupID,
    groupUpdationEmit,
    socket,
    sendJoin,
    updateChatData,
    removePatientTag,
    removeTag,
    updateLastSeen,
    latestMessages,
    setLatestMessages,
    getDeleteMessage,
    isConnected,
    unstarMessage,
    getUnreadMessages,
    getReferencedPatients,
    getCommunityPatients,
    getProviderPatients,
    getMediaFiles,
    getLatestMessages,
    getTaggedCaretags,
    getUnreadCount,
    unreadCount,
    markUrgent,
    editMessage,
    setEditMsg,
    updateMessage,
  };
}
