import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  region: {
    ZoneId: 1,
    ZoneName: "East Valley",
    dataSource: "Athena EHR",
    ZoneLatitude: 33.4484,
    ZoneLongitude: -112.074,
    latitudeDelta: 0.075,
    longitudeDelta: 0.0721,
  },
  type: "Scheduler",
  view: "Map",
  provider: "All Providers",
  selectedProvider: "All Providers",
  isFullScreen: false,
  date: new Date().toISOString(),
  isOpen: null,
  appointmentOpen: null,
  visit: null,
};

export const selectionSlice = createSlice({
  name: "selection",
  initialState,
  reducers: {
    changeRegion: (state, action) => {
      state.region = action.payload;
    },
    focusMarker: (state, action) => {
      state.region = { ...state.region, ...action.payload.latlng };
      state.isOpen = action.payload?.id || state.isOpen;
      state.appointmentOpen = action.payload?.seq || state.appointmentOpen;
    },
    changeType: (state, action) => {
      state.type = action.payload;
    },
    changeView: (state, action) => {
      state.view = action.payload;
    },
    changeIsFullScreen: (state, action) => {
      state.isFullScreen = action.payload;
    },
    changeSelectedProvider: (state, action) => {
      state.selectedProvider = action.payload;
    },
    changeDate: (state, action) => {
      state.date = action.payload;
    },
    changeIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    changeAppointmentOpen: (state, action) => {
      state.appointmentOpen = action.payload;
    },
    updateVisit: (state, action) => {
      state.visit = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  changeRegion,
  changeType,
  changeView,
  changeIsFullScreen,
  changeSelectedProvider,
  changeDate,
  focusMarker,
  changeIsOpen,
  changeAppointmentOpen,
  updateVisit,
} = selectionSlice.actions;

export default selectionSlice.reducer;
