import React, { useState } from "react";
import { colors } from "../../styles";
import CustomButton from "../Buttons/CustomButton";
import { Dialog, Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./AddRegionModal.css";
// import StaffAddedModal from "./StaffAddedModal";
import PhoneInput from "../Input/PhoneInput";
import useAuth from "../../hooks/useAuth";
import { Form } from "react-bootstrap";

const AddStaff = ({
  open: isOpen,
  handleClose,
  selected,
  communityId,
  init,
}) => {
  const { initializeUser, updateUser } = useAuth();
  const [open, setOpen] = useState(isOpen);
  // const [staff, setStaff] = useState(false);
  const [data, setData] = useState(selected || {});

  const onClose = () => {
    setOpen(true);
    // setStaff(false);
    handleClose();
  };

  const onSave = () => {
    onClose();
    if (selected?.role) {
      updateUser(
        {
          id: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNo: data.phoneNo,
          position: data.position,
        },
        true
      ).then(() => init());
    } else {
      initializeUser({
        ...data,
        role: "COMMUNITY",
        communityId: +communityId,
      }).then(() => init());
    }
  };

  // const onAdd = () => setStaff(true);

  const onChangeHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <Grid>
        <Grid
          className="d-flex justify-content-between py-1 px-3 align-items-center"
          style={{
            backgroundColor: colors.OFFICE_COLOR,
          }}
        >
          <Typography className="region-modal-title">
            {selected?.role
              ? "Edit Community Staff"
              : "Add New Community Staff"}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Grid>
        <Grid className="px-2">
          <Grid className="m-2 px-3">
            <Typography className="fieldName py-2">Name*</Typography>
            <Grid className="d-flex">
              <input
                placeholder="First Name"
                className="inputField px-2  border-1"
                name="firstName"
                value={data?.firstName}
                onChange={onChangeHandler}
              />
              <input
                placeholder="Last Name"
                className="inputField px-2 mx-2  border-1"
                name="lastName"
                value={data?.lastName}
                onChange={onChangeHandler}
              />
            </Grid>
          </Grid>
          <Grid className="m-2 px-3">
            <Typography className="fieldName py-2">Role</Typography>
            {/* <input
              placeholder="Position"
              className="inputField px-2 border-1 w-100"
              name="position"
              value={data?.position}
              onChange={onChangeHandler}
            /> */}
            <Form.Select
              name="position"
              value={data?.position}
              onChange={onChangeHandler}
            >
              <option>Select Role</option>
              {[
                {
                  id: 1,
                  name: "Nurse",
                },
                {
                  id: 2,
                  name: "Manager",
                },
                {
                  id: 3,
                  name: "Administrative Staff",
                },
                {
                  id: 4,
                  name: "Other",
                },
              ]?.map((i) => {
                return (
                  <option key={i.id} value={i.name}>
                    {i.name}
                  </option>
                );
              })}
            </Form.Select>
          </Grid>
          <Grid className="m-2 px-3">
            <Typography className="fieldName py-2">Email*</Typography>
            <input
              placeholder="Email address"
              className="inputField px-2 border-1 w-100"
              name="email"
              value={data?.email}
              onChange={onChangeHandler}
            />
          </Grid>
          <Grid className="m-2 px-3">
            <Typography className="fieldName py-2">Phone</Typography>
            <PhoneInput
              className="inputField px-2 border-1 w-100"
              value={data?.phoneNo}
              onChange={(val) => setData({ ...data, phoneNo: val })}
            />
          </Grid>
          {/* <Grid className="m-2 px-3">
            <Typography className="fieldName py-2">
              CareSMS Password*{" "}
              <img
                src={Icons.Info_Icon2}
                alt="info"
                style={{ width: 20, height: 20 }}
              />
            </Typography>
            <input
              placeholder="Password"
              secureTextEntry
              className="inputField px-2 border-1 w-100"
            />
          </Grid> */}
          <Grid>
            <CustomButton className="button mx-4 px-5 py-2" onClick={onSave}>
              <Typography>Save</Typography>
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
      {/* {staff && <StaffAddedModal open={staff} handleClose={onSave} />} */}
    </Dialog>
  );
};

export default AddStaff;
