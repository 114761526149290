import React, { useEffect, useState } from "react";
import MenuHeader from "../../../components/Header/MenuHeader";
import DataTable from "../../../components/DataTable/DataTable";
import { communityHeader } from "../../../data";
import Name from "../../../components/DataTable/Name";
import IconComponent from "../../../components/DataTable/Icon";
import TableFilter from "../../../components/Filter/TableFilter";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";
import AddCommunityModal from "../../../components/Modals/AddCommunityModal";
import useCommunities from "../../../hooks/useCommunities";
import useDebounce from "../../../hooks/useDebounce";
import { numberToPhone } from "../../../utilities/resuseFunctions";
import FilterModal from "../../../components/Modals/FilterModal";

const Communities = ({ noHeader, noAdd, communityList }) => {
  const { communities, loading, handleClose, count, getCommunities } =
    useCommunities(true);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState(null);
  const [selected, setSelected] = useState({});
  const [sort, setSort] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const debouncedSearchText = useDebounce(search, 750);

  useEffect(() => {
    getCommunities({
      search: debouncedSearchText,
      limit: rowsPerPage,
      skip: page,
      sort,
      filter,
      communityList,
    });
  }, [debouncedSearchText, sort, filter]);

  const onAdd = () => setOpen(true);

  const onChangePage = (data, limit) => {
    setPage(data);
    getCommunities({
      skip: data * 20,
      limit: limit
        ? limit === "View All"
          ? 0
          : limit
        : rowsPerPage === "View All"
        ? 0
        : rowsPerPage,
      sort,
      filter,
      communityList,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const rows = event.target.value;
    setRowsPerPage(rows);
    onChangePage(0, rows);
  };

  const onCancel = () => {
    setOpen(false);
    setSelected({});
  };

  const onFilterClose = () => {
    setOpenFilter(false);
    setFilter(null);
  };

  const onFilterSubmit = (data) => {
    setOpenFilter(false);
    setFilter({ ...data });
  };

  const onFilterOpen = () => {
    setOpenFilter(true);
  };

  const onEdit = (tmp) => {
    setSelected(tmp);
    onAdd();
  };

  const generatedRows = communities.map((x) => {
    return {
      ...x,
      name: (
        <Name
          name={x.LocationName}
          hasAvatar
          i={x}
          onClick={() => navigate(`/communities/${x.LocationID}`)}
        />
      ),
      nameSort: x.LocationName,
      zone: x.zones?.ZoneName,
      phone: numberToPhone(x.LocationPhone),
      edit: (
        <IconComponent icon={"Edit_Icon"} i={x} onClick={() => onEdit(x)} />
      ),
      address:
        [
          x?.LocationAddress1,
          x?.LocationCity,
          x?.LocationState,
          x?.LocationCountry,
          x?.LocationZip,
        ]
          .filter(Boolean)
          .join(", ") ||
        x?.address ||
        "",
    };
  });
  // console.log(generatedRows, "generatedRows");
  return (
    <Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!noHeader && <MenuHeader isSecondary />}
      <Grid className="my-3">
        <TableFilter
          count={count}
          sectionName={"Communities"}
          search={search}
          setSearch={setSearch}
          onAdd={onAdd}
          // onAdd={() => navigate("/communities/add")}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          onChangePage={onChangePage}
          rowsPerPage={rowsPerPage}
          onFilterClick={onFilterOpen}
          noAdd={noAdd}
        />
      </Grid>
      <Grid className="mx-4">
        {communities.length ? (
          <DataTable
            columns={communityHeader}
            rows={generatedRows}
            count={count}
            onChangePage={onChangePage}
            setSort={setSort}
          />
        ) : (
          <Typography>No Data</Typography>
        )}
      </Grid>
      {open && (
        <AddCommunityModal
          open={open}
          handleClose={onCancel}
          selected={selected}
        />
      )}
      {openFilter && (
        <FilterModal
          open={openFilter}
          handleClose={onFilterClose}
          selectedFilter={filter}
          onSubmit={onFilterSubmit}
        />
      )}
    </Grid>
  );
};

export default Communities;
