import React, { useEffect, useState } from "react";
import MenuHeader from "../../../components/Header/MenuHeader";
import DataTable from "../../../components/DataTable/DataTable";
import { scheduleRulesHeader } from "../../../data";
import IconComponent from "../../../components/DataTable/Icon";
import TableFilter from "../../../components/Filter/TableFilter";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import useSchedulingRules from "../../../hooks/useSchedulingRules";
import useDebounce from "../../../hooks/useDebounce";
import useLogs from "../../../hooks/useLogs";
import useAuth from "../../../hooks/useAuth";
import { capitalizeFirstWords } from "../../../utilities/resuseFunctions";
import AddConstraintsModal from "../../../components/Modals/AddConstraintsModal";
import { constraintFrequency } from "../../../data/listOptions";
import DeleteScheduleRuleModal from "../../../components/Modals/DeleteScheduleRuleModal";
import moment from "moment";
import dayjs from "dayjs";
import Name from "../../../components/DataTable/Name";

const ViewScheduleRules = () => {
  const { addLog } = useLogs();
  const { userData } = useAuth();
  const {
    schedulingRules,
    deleteSchedulingRuleApi,
    loading,
    count,
    getSchedulingRules,
  } = useSchedulingRules(true);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const debouncedSearchText = useDebounce(search, 750);
  const [selected, setSelected] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [sort, setSort] = useState(null);

  const init = () => {
    setPage(0);
    getSchedulingRules({
      search: debouncedSearchText,
      take: rowsPerPage === "View All" ? count : rowsPerPage,
      sort,
    });
  };

  useEffect(() => {
    init();
  }, [debouncedSearchText, sort]);

  const onChangePage = (data, take) => {
    setPage(data);
    getSchedulingRules({
      skip: data * rowsPerPage,
      take: take
        ? take === "View All"
          ? count
          : take
        : rowsPerPage === "View All"
        ? count
        : rowsPerPage,
      sort,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const rows = event.target.value;
    setRowsPerPage(rows);
    onChangePage(0, rows);
  };

  const handleClose = () => {
    setIsDelete(false);
    setSelected(null);
  };

  const handleDelete = () => {
    handleClose();
    deleteSchedulingRuleApi({
      id: selected?.id,
    }).then(async () => {
      const logPayload = {
        UserID: userData?.id,
        Activity: `Schedule Rule Deleted for Provider ID ${
          selected?.providerID
        } by User - ${userData?.firstName || ""} ${userData?.lastName || ""} `,
      };
      await addLog(logPayload);
    });
  };

  const handleSelect = (rule) => {
    const { visitDay, frequency } = rule;
    setSelected({
      ...rule,
      dayOfWeek: visitDay,
      additionalRules: frequency,
      locationName: rule?.communityData
        ? {
            label: rule?.communityData?.locationName,
            value: rule?.communityData?.locationId,
          }
        : null,
      patient: rule?.patientData
        ? {
            label: rule?.patientData?.patientName,
            value: rule?.patientData?.patientId,
          }
        : null,
    });
  };

  const generateRows = schedulingRules.map((rule, i) => {
    const additional_rule = constraintFrequency.find(
      (r) => r.freq === rule?.frequency
    );
    const isPatient = rule?.constraintType === "Patient";
    return {
      providerSort:
        rule.providerName && capitalizeFirstWords(rule.providerName),
      provider: (
        <Name
          i={rule}
          name={
            rule.providerName && `${capitalizeFirstWords(rule.providerName)}`
          }
          noUnderline
        />
      ),
      locationType: rule?.constraintType,
      locationName: isPatient
        ? rule?.patientData?.patientName
        : rule?.communityData?.locationName,
      dayOfWeek: rule?.visitDay,
      maxVisits: rule?.maxVisits,
      additionalRules: additional_rule?.name,
      dateRange: rule?.startDate
        ? `${
            rule.startDate === null
              ? "Awaiting start date"
              : moment(rule.startDate).format("MM/DD/YYYY")
          } - ${
            rule.endDate === null
              ? "Till further notice"
              : moment(rule.endDate).format("MM/DD/YYYY")
          }`
        : "Till further notice",
      edit: (
        <IconComponent
          key={i}
          i={rule}
          icon="Edit_Icon"
          onClick={() => {
            handleSelect(rule);
            setOpen(true);
          }}
        />
      ),
      delete: (
        <IconComponent
          key={i}
          i={rule}
          icon="Delete_Icon"
          onClick={() => {
            setIsDelete(true);
            // setSelected(rule);
            handleSelect(rule);
          }}
        />
      ),
    };
  });

  return (
    <Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MenuHeader isSecondary />
      <Grid className="my-3">
        <TableFilter
          count={count}
          sectionName={"Rules Editor"}
          search={search}
          setSearch={setSearch}
          onAdd={() => {
            setOpen(true);
            setSelected(null);
          }}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          onChangePage={onChangePage}
          rowsPerPage={rowsPerPage}
          noSort={true}
        />
      </Grid>
      <Grid className="mx-4 px-2">
        <DataTable
          columns={scheduleRulesHeader}
          rows={generateRows}
          count={count}
          onChangePage={onChangePage}
          page={page}
          setSort={setSort}
        />
      </Grid>
      {open && (
        <AddConstraintsModal
          open={open}
          handleClose={() => {
            setOpen(false);
            setSelected(null);
          }}
          selected={
            selected && {
              ...selected,
              startDate: selected.startDate && dayjs(selected.startDate),
              endDate: selected.endDate && dayjs(selected.endDate),
            }
          }
          init={init}
        />
      )}
      {isDelete && (
        <DeleteScheduleRuleModal
          open={isDelete}
          handleDelete={handleDelete}
          handleClose={handleClose}
          data={selected}
        />
      )}
    </Grid>
  );
};

export default ViewScheduleRules;
