import React, { useEffect, useState } from "react";
import { colors } from "../../styles";
import CustomButton from "../Buttons/CustomButton";
import {
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./AddRegionModal.css";
// import useAuth from "../../hooks/useAuth";
import { Form } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import useCommunities from "../../hooks/useCommunities";
import usePatients from "../../hooks/usePatients";
import useProviders from "../../hooks/useProviders";
import { constraintFrequency, weeks } from "../../data/listOptions";
import useSchedulingRules from "../../hooks/useSchedulingRules";
import useLogs from "../../hooks/useLogs";
import useAuth from "../../hooks/useAuth";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";

const AddConstraintsModal = ({
  open: isOpen,
  handleClose,
  selected,
  communityId,
  init,
}) => {
  //   const { initializeUser, updateUser } = useAuth();
  const { addSchedulingRuleApi, updateSchedulingRuleApi } =
    useSchedulingRules(true);
  const { addLog } = useLogs();
  const { userData } = useAuth();
  const [open, setOpen] = useState(isOpen);
  const { getCommunities } = useCommunities(true);
  const { getPatients } = usePatients({ noInitial: true });
  const { providers } = useProviders();
  const [data, setData] = useState(
    selected || {
      constraintType: "Community",
      startDate: dayjs(),
      maxVisits: "5",
    }
  );
  const [isError, setIsError] = useState("");
  const [isAdded, setIsAdded] = useState(false);

  const isCommunity = data?.constraintType === "Community";

  const comunityOptions = async (inputValue) => {
    const final = await getCommunities({
      search: inputValue || selected?.locationName?.label,
      limit: 0,
      providerId: data?.providerId,
    })
      .then((res) => {
        return res?.map((i) => ({
          label: i.LocationName,
          value: i.LocationID,
        }));
      })
      .finally((res) => res || []);
    return final;
  };

  const patientOptions = async (inputValue) => {
    const final = await getPatients({
      search: inputValue || selected?.locationName?.label,
      limit: 0,
      providerId: data?.providerId,
    })
      .then((res) => {
        return res?.map((i) => ({
          label: `${i.PatientFirstName} ${i.PatientLastName}`,
          value: i.PatientID,
        }));
      })
      .finally((res) => res || []);
    return final;
  };

  const onClose = () => {
    setOpen(true);
    setIsError("");
    setIsAdded(false);
    handleClose();
  };

  const onSave = () => {
    // onClose();
    setIsError("");
    setIsAdded(false);
    const {
      locationName,
      patient,
      id,
      providerId,
      dayOfWeek,
      additionalRules,
      startDate,
      endDate,
      maxVisits,
    } = data;
    if (
      (!locationName && !patient) ||
      !providerId ||
      !dayOfWeek ||
      !additionalRules ||
      (isCommunity && !maxVisits)
    ) {
      return setIsError("Please fill out all the required fields");
    }
    const payload = {
      id: id,
      LocationID: locationName?.value,
      PatientID: patient?.value && String(patient?.value),
      ProviderID: providerId,
      VisitDay: dayOfWeek,
      Frequency: +additionalRules,
      startDate,
      endDate,
      maxVisits: +maxVisits,
    };
    if (selected?.id) {
      updateSchedulingRuleApi(payload).then(async () => {
        init();
        const logPayload = {
          UserID: userData?.id,
          Activity: `Schedule Rule Updated for Provider ID ${
            selected?.providerID
          } by User - ${userData?.firstName || ""} ${
            userData?.lastName || ""
          } `,
        };
        setIsAdded(true);
        await addLog(logPayload);
      });
    } else {
      addSchedulingRuleApi(payload)
        .then(async (res) => {
          if (res.error) {
            setIsError(res.error);
            return;
          }
          init();
          const logPayload = {
            UserID: userData?.id,
            Activity: `Schedule Rule Added for Provider ID ${
              selected?.providerID
            } by User - ${userData?.firstName || ""} ${
              userData?.lastName || ""
            } `,
          };
          setData({ ...data, id: res?.data?.id });
          setIsAdded(true);
          await addLog(logPayload);
        })
        .catch(async (err) => {
          setIsError(err?.response?.data?.error);
          const logPayload = {
            UserID: userData?.id,
            Activity: `Failed: Schedule Rule Adding for Provider ID ${
              selected?.providerID
            } by User - ${userData?.firstName || ""} ${
              userData?.lastName || ""
            } `,
          };
          await addLog(logPayload);
        });
    }
  };

  const onChangeHandler = (e, name) => {
    setData({
      ...data,
      [e.target?.name || name]: e?.target ? e?.target?.value : e,
    });
  };

  const customStyles = {
    container: (baseStyles, state) => ({
      ...baseStyles,
      zIndex: 9999,
    }),
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid>
          <Grid
            className="d-flex justify-content-between py-1 px-3 align-items-center"
            style={{
              backgroundColor: colors.OFFICE_COLOR,
            }}
          >
            <Typography className="region-modal-title">
              {selected?.id
                ? "Edit Location Scheduling Rule"
                : "Add New Location Scheduling Rule"}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon className="close-icon" />
            </IconButton>
          </Grid>
          <Grid className="px-2">
            <Grid className="m-2 px-3">
              <Typography className="fieldName py-2">Provider*</Typography>
              <Grid className="d-flex">
                <Form.Select
                  name="providerId"
                  value={data?.providerId}
                  onChange={onChangeHandler}
                >
                  <option value={""}>Select Provider</option>
                  {providers?.map((i) => {
                    return (
                      <option key={i.ProviderID} value={i.ProviderID}>
                        {i.ProviderFN} {i.ProviderLN}
                      </option>
                    );
                  })}
                </Form.Select>
              </Grid>
            </Grid>
            <Grid className="m-2 px-3">
              <Typography className="fieldName py-2">
                Constraint Type*
              </Typography>
              <RadioGroup
                row
                value={data?.constraintType || "Community"}
                onChange={onChangeHandler}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="constraintType"
                //   className="mx-3"
              >
                <FormControlLabel
                  value="Community"
                  // disabled={!isEdited}
                  control={<Radio />}
                  label="Community"
                />
                <FormControlLabel
                  value="Patient"
                  // disabled={!isEdited}
                  control={<Radio />}
                  label="Patient"
                />
              </RadioGroup>
            </Grid>
            <Grid className="m-2 px-3">
              <Typography className="fieldName py-2">
                {data?.constraintType || "Community"}*
              </Typography>
              {data?.constraintType === "Patient" && (
                <AsyncSelect
                  //   isDisabled={!isEdited}
                  // cacheOptions
                  styles={customStyles}
                  defaultOptions
                  loadOptions={patientOptions}
                  value={data?.patient}
                  onChange={(e) => onChangeHandler(e, "patient")}
                  name="patient"
                />
              )}
              {isCommunity && (
                <AsyncSelect
                  //   isDisabled={!isEdited}
                  // cacheOptions
                  styles={customStyles}
                  defaultOptions
                  loadOptions={comunityOptions}
                  value={data?.locationName}
                  onChange={(e) => onChangeHandler(e, "locationName")}
                  name="locationName"
                />
              )}
            </Grid>
            <Grid container={isCommunity} className="px-2">
              <Grid item md={6} className="px-3">
                <Typography className="fieldName py-2">
                  Day of the week*
                </Typography>
                <Form.Select
                  name="dayOfWeek"
                  value={data?.dayOfWeek}
                  onChange={onChangeHandler}
                >
                  <option value={""}>Select a day</option>
                  {weeks?.map((i) => {
                    return (
                      <option key={i.id} value={i.name}>
                        {i.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Grid>
              {isCommunity && (
                <Grid item md={6} className="px-3">
                  <Typography className="fieldName py-2">
                    Max Visits*
                  </Typography>
                  <Form.Select
                    name="maxVisits"
                    value={data?.maxVisits}
                    onChange={onChangeHandler}
                  >
                    <option value={""}>Select a Max Visit</option>
                    {["5", "6", "7", "8", "9", "10"]?.map((i) => {
                      return (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Grid>
              )}
            </Grid>
            <Grid className="m-2 px-3">
              <Typography className="fieldName py-2">
                Any additional frequency details?
              </Typography>
              <Form.Select
                name="additionalRules"
                value={data?.additionalRules}
                onChange={onChangeHandler}
              >
                <option value={""}>Select Frequency</option>
                {constraintFrequency?.map((i) => {
                  return (
                    <option key={i.freq} value={i.freq}>
                      {i.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Grid>
            <Grid container className="p-2">
              <Grid item md={6}>
                <Typography className="fieldName px-3 py-2">
                  Start Date
                </Typography>
                <Grid className="inputField px-3">
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Start Date"
                      value={data?.startDate}
                      onChange={(val) => onChangeHandler(val, "startDate")}
                    />
                  </DemoContainer>
                </Grid>
              </Grid>
              <Grid item md={6}>
                <Typography className="fieldName py-2 px-3">
                  End Date
                </Typography>
                <Grid className="px-3">
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="End Date"
                      value={data?.endDate}
                      onChange={(val) => onChangeHandler(val, "endDate")}
                    />
                  </DemoContainer>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <CustomButton className="button mx-4 px-5 py-2" onClick={onSave}>
                <Typography>Save</Typography>
              </CustomButton>
            </Grid>
            <Grid className="m-4">
              {isError && (
                <Typography style={{ color: "red" }}>{isError}</Typography>
              )}
              {isAdded && !selected && (
                <Typography style={{ color: "green" }}>
                  New location scheduling rule has been saved!
                </Typography>
              )}
              {isAdded && selected && (
                <Typography style={{ color: "green" }}>
                  location scheduling rule has been updated!
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Dialog>
  );
};

export default AddConstraintsModal;
