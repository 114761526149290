import CommandCenter from "../screens/CommandCenter";
import ProviderView from "../screens/CommandCenter/ProviderView";
import ProviderDetails from "../screens/Admin/Providers/ProviderView";
import Patients from "../screens/Admin/Patients/Patients";
import Communities from "../screens/Admin/Communities/Communities";
import Providers from "../screens/Admin/Providers/Providers";
import Regions from "../screens/Admin/Regions/Regions";
import TagsScreen from "../screens/Admin/Tags/Tags";
import PatientView from "../screens/Admin/Patients/PatientView";
import AddPatients from "../screens/Admin/Patients/AddPatients";
import CommunityView from "../screens/Admin/Communities/CommunityView";
import AddCommunity from "../screens/Admin/Communities/AddCommunity";
import AddProviders from "../screens/Admin/Providers/AddProviders";
import Users from "../screens/Admin/Users/Users";
import NewLogin from "../screens/login/NewLogin";
import SchedulePolicy from "../screens/Admin/SchedulePolicy/SchedulePolicy";
import ViewSchedulePolicy from "../screens/Admin/SchedulePolicy/ViewSchedulePolicy";
import MessageCenter from "../screens/Chat/MessageCenter";
import AccountSettings from "../screens/Settings";
import Login2FA from "../screens/login/Login2fa";
import Register from "../screens/login/Register";
import Report from "../screens/Admin/Report/Report";
import ViewScheduleRules from "../screens/Admin/ScheduleRules/ViewScheduleRules";
import Query from "../screens/Admin/Query/Query";

export const officeRoutes = [
  {
    id: 1,
    path: "/",
    element: Login2FA,
    isPublic: true,
    access: ["*"],
  },
  {
    id: 21,
    path: "/Register",
    element: Register,
    isPublic: true,
    access: ["*"],
  },
  {
    id: 2,
    path: "/home",
    element: CommandCenter,
    access: ["*"],
  },
  {
    id: 3,
    path: "/provider",
    element: ProviderView,
    access: [
      {
        role: "back office",
        position: ["Admin", "Scheduler"],
      },
    ],
  },
  {
    id: 4,
    path: "/regions",
    element: Regions,
    access: [
      {
        role: "back office",
        position: ["Admin", "Scheduler"],
      },
    ],
  },
  {
    id: 5,
    path: "/communities",
    element: Communities,
    access: [
      {
        role: "back office",
        position: ["Admin", "Scheduler"],
      },
    ],
  },
  {
    id: 6,
    path: "/communities/:Id",
    element: CommunityView,
    access: [
      {
        role: "back office",
        position: ["Admin", "Scheduler"],
      },
    ],
  },
  {
    id: 7,
    path: "/communities/add",
    element: AddCommunity,
    access: [
      {
        role: "back office",
        position: ["Admin", "Scheduler"],
      },
    ],
  },
  {
    id: 8,
    path: "/users",
    element: Users,
    access: [
      {
        role: "back office",
        position: ["Admin", "Scheduler"],
      },
    ],
  },
  {
    id: 9,
    path: "/providers",
    element: Providers,
    access: [
      {
        role: "back office",
        position: ["Admin", "Scheduler"],
      },
    ],
  },
  {
    id: 10,
    path: "/providers/:Id",
    element: ProviderDetails,
    access: [
      {
        role: "back office",
        position: ["Admin", "Scheduler"],
      },
    ],
  },
  {
    id: 11,
    path: "/providers/add",
    element: AddProviders,
    access: [
      {
        role: "back office",
        position: ["Admin", "Scheduler"],
      },
    ],
  },
  {
    id: 12,
    path: "/patients",
    element: Patients,
    access: [
      {
        role: "back office",
        position: ["Admin", "Scheduler"],
      },
    ],
  },
  {
    id: 13,
    path: "/patients/:Id",
    element: PatientView,
    access: [
      {
        role: "back office",
        position: ["Admin", "Scheduler"],
      },
    ],
  },
  {
    id: 14,
    path: "/patients/add",
    element: AddPatients,
    access: [
      {
        role: "back office",
        position: ["Admin", "Scheduler"],
      },
    ],
  },
  {
    id: 15,
    path: "/caretags",
    element: TagsScreen,
    access: ["*"],
  },
  {
    id: 16,
    path: "/schedulerpolicy",
    element: SchedulePolicy,
    access: [
      {
        role: "back office",
        position: ["Admin", "Scheduler"],
      },
    ],
  },
  {
    id: 17,
    path: "/schedulerpolicy/:id",
    element: SchedulePolicy,
    access: [
      {
        role: "back office",
        position: ["Admin", "Scheduler"],
      },
    ],
  },
  {
    id: 18,
    path: "/scheduler/settings",
    element: ViewSchedulePolicy,
    access: [
      {
        role: "back office",
        position: ["Admin", "Scheduler"],
      },
    ],
  },
  {
    id: 19,
    path: "/messagecenter",
    element: MessageCenter,
    access: ["*"],
  },
  {
    id: 20,
    path: "/account/settings",
    element: AccountSettings,
    access: ["*"],
  },
  // {
  //   id: 21,
  //   path: "/reports",
  //   element: Report,
  //   access: [
  //     {
  //       role: "back office",
  //       position: ["Admin", "Scheduler"],
  //     },
  //   ],
  // },
  {
    id: 22,
    path: "/schedule/rules",
    element: ViewScheduleRules,
    access: [
      {
        role: "back office",
        position: ["Admin", "Scheduler"],
      },
    ],
  },
  // {
  //   id: 23,
  //   path: "/query",
  //   element: Query,
  //   access: [
  //     {
  //       role: "back office",
  //       position: ["Admin", "Scheduler"],
  //     },
  //   ],
  // },
];
