import React from "react";
import { fonts } from "../../styles";
import { Icons } from "../../assets";
import { Avatar, Grid, Typography } from "@mui/material";

const Name = ({
  i,
  hasAvatar,
  warning,
  flex,
  onClick,
  name,
  avatar,
  invite,
  onInvite,
  noUnderline,
  isExpired,
}) => {
  return (
    <Grid
      key={i.id}
      style={{
        alignSelf: "stretch",
      }}
    >
      <div role="button" className={"d-flex align-items-center"}>
        <Grid className="d-flex align-items-center" onClick={onClick}>
          {hasAvatar && (
            <Avatar size={"sm"} src={avatar}>
              {i.name?.slice(0, 1)}
            </Avatar>
          )}
          <Typography
            className={hasAvatar ? "mx-2" : ""}
            style={{
              textDecorationLine: !noUnderline ? "underline" : "none",
              fontFamily: fonts.BOLD_FONT,
              fontWeight: 600,
              color: "#333333",
            }}
          >
            {name || (i?.firstName ? `${i?.firstName} ${i?.lastName}` : i.name)}
          </Typography>
        </Grid>
        {invite && (
          <Typography
            onClick={onInvite}
            style={{
              fontSize: 12,
              margin: 5,
              textDecoration: "underline",
            }}
          >
            {isExpired ? "Resend Invite" : "Invite"}
          </Typography>
        )}
        {warning && (
          <img
            src={Icons.Warning_Icon}
            alt="warning"
            style={{ width: 18, height: 20 }}
          />
        )}
      </div>
    </Grid>
  );
};

export default Name;
