import React, { useEffect, useState } from "react";
import SearchComponent from "../../components/Search/Search";
import Left_Icon from "../../assets/images/Left_Icon.png";
import ContactCard from "../../components/Cards/ContactCard";
import Sort_Icon from "../../assets/images/Sort.png";
import { providers } from "../../data";
import MemberSelectedCard from "../../components/Cards/MemberSelectedCard";
import CustomButton from "../../components/Buttons/CustomButton";
import { avatarName } from "../../utilities/resuseFunctions";
import {
  Avatar,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useSelector } from "react-redux";
import "./NewMessage.css";
import useMessenger from "../../hooks/useMessenger";
import moment from "moment";
import UsersSvg from "../../assets/svgs/UsersSvg";
import CloseIcon from "@mui/icons-material/Close";

const newGrpImg = "https://caresms.s3.us-east-2.amazonaws.com/defaultGroup.png";

const NewMessageComponent = ({
  isFullScreen,
  setIsNewMessage,
  isNewMessage,
  setOpen,
}) => {
  const { height } = useWindowDimensions();
  const {
    getAllContacts,
    createPrivateConversation,
    newGroupCreationEmit,
    userData,
    createNewGroup,
    setCurrentChat,
    chats,
  } = useMessenger();
  const selections = useSelector((state) => state.selections);
  const [isGroup, setIsGroup] = useState(false);
  const [isMembersAdded, setIsMembersAdded] = useState(false);
  const [members, setMembers] = useState(null);
  const [groupName, setGroupName] = useState("");
  const [search, setSearch] = useState("");
  const [groupPic, setGroupPic] = useState(null);
  const [base64String, setBase64String] = useState(null);
  const [contacts, setContacts] = useState(null);

  const isGroupMessage = isNewMessage === "group";

  useEffect(() => {
    getAllContacts().then((res) => setContacts(res.data));
  }, []);
  const onMemberSelect = (item) => {
    if (isGroup) {
      const isExist = members?.find((i) => i.id === item.id);
      if (isExist) {
        setMembers((prev) => prev.filter((i) => i.id !== item.id));
      } else {
        setMembers((prev) => (members ? [...prev, item] : [item]));
      }
    } else {
      setMembers(item);
    }
  };

  const onAddMedia = () => {
    let input = document.createElement("input");
    input.type = "file";
    input.onchange = (_) => {
      // you can use this method to get file and perform respective operations
      let files = Array.from(input.files);
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setBase64String(reader.result);
      };
      setGroupPic(file);
    };
    input.click();
  };

  const onCreate = async () => {
    isGroup && !isMembersAdded && setIsMembersAdded(true);
    if (isGroup && isMembersAdded) {
      if (
        Array.isArray(members) &&
        members.length > 0 &&
        groupName.length > 4
      ) {
        await createNewGroup(
          userData.id,
          groupName,
          "Group",
          newGrpImg,
          members.map((data) => data.id),
          groupPic
        );
        newGroupCreationEmit(members.map((data) => `U${data.id}`));
      }
      setIsMembersAdded(false);
      setIsGroup(false);
      setIsNewMessage(false);
    }
    if (!isGroup) {
      if (isGroupMessage) {
        setCurrentChat({ ...members });
      } else {
        const data = await createPrivateConversation([members.id]);
        newGroupCreationEmit([`U${members.id}`]);
        setCurrentChat({
          conversationImg: base64String,
          conversationId: data?.id,
          createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
          isAdmin: false,
          isCreator: false,
          updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
          userId: userData.id,
          conversationName: members.firstName + " " + members.lastName,
          contactFN: members?.firstName,
          contactLN: members?.lastName,
          contactImg: members.profileImg,
        });
      }

      !isFullScreen && setOpen(true);
      setIsNewMessage(false);
    }
  };

  const onClose = () => setIsNewMessage(false);

  const groupsList = chats.filter((chat) => chat?.conversationType === "GROUP");

  return (
    <>
      <Dialog open={!!isNewMessage} onClose={onClose} maxWidth={"sm"} fullWidth>
        <Grid
          style={{
            height: height - 100,
            overflow: "auto",
          }}
        >
          <Grid className="d-flex align-items-center justify-content-between p-3 bg-1">
            <Grid className="d-flex">
              {isNewMessage && <UsersSvg color="#fff" />}
              <Typography className="px-2 text-white new-chat-title">
                {isGroup && isMembersAdded
                  ? "Confirm new group"
                  : isGroup && !isMembersAdded
                  ? "Add group members"
                  : isGroupMessage
                  ? "Send a Group Message"
                  : "Send a Direct Message"}
              </Typography>
            </Grid>
            <IconButton
              onClick={() => {
                isGroup && isMembersAdded && setIsMembersAdded(false);
                isGroup && !isMembersAdded && setIsGroup(false);
                !isGroup && !isMembersAdded && setIsNewMessage(false);
              }}
            >
              {/* <img src={Left_Icon} alt="left" className="icon" /> */}
              <CloseIcon className="close-icon" />
            </IconButton>
          </Grid>
          <Grid>
            {!isMembersAdded && (
              <Grid className={clsx("my-3", "mx-3")}>
                <SearchComponent
                  placeholder="Search..."
                  value={search}
                  onChange={setSearch}
                />
              </Grid>
            )}
            {isMembersAdded && (
              <Grid className="p-3 text-center">
                <Grid className="d-flex justify-content-center">
                  <Tooltip title="Add Image">
                    <Avatar
                      className="my-3 mx-2"
                      sx={{ width: 56, height: 56 }}
                      onClick={onAddMedia}
                      // src={{uri: profile}}
                      src={base64String || newGrpImg}
                    >
                      {groupName ? avatarName(groupName) : "PC"}
                    </Avatar>
                  </Tooltip>
                </Grid>
                <input
                  placeholder="Type group name..."
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                  className="justify-content-center align-self-center px-2 text-center border-1 group-name-input"
                />
              </Grid>
            )}
            <Divider ml={6} width={"95%"} thickness="0.5" />
            {!isGroup && isGroupMessage && (
              <ContactCard
                label={"Create New Group"}
                onSelect={() => {
                  setMembers(null);
                  setIsGroup(true);
                }}
                profile={newGrpImg}
              />
            )}
            {isGroup && isGroupMessage && (
              <>
                {members?.length ? (
                  <Grid className="p-2">
                    {isMembersAdded && (
                      <Typography className="group-mem px-2">
                        Group members
                      </Typography>
                    )}
                    <Grid className="d-flex align-items-start">
                      {members?.map((i, idx) => {
                        return (
                          <MemberSelectedCard
                            key={idx}
                            name={`${i?.firstName || ""} ${i?.lastName || ""}`}
                            profile={i?.profileImg}
                            style={{ padding: "0px 15px" }}
                            onRemove={() => onMemberSelect(i)}
                          />
                        );
                      })}
                    </Grid>
                  </Grid>
                ) : (
                  <Typography className="text-center p-3">
                    No members added
                  </Typography>
                )}
              </>
            )}
            <Divider ml={6} width={"95%"} thickness="0.5" />
            {!isMembersAdded && (
              <Grid className="d-flex align-items-center justify-content-between p-3">
                <Typography className="color-1 bold-600">
                  {isGroupMessage ? "Group Chats" : "Your Contacts"}
                </Typography>
                {/* <img src={Sort_Icon} alt="sort" className="icon" /> */}
              </Grid>
            )}
            <Divider ml={6} width={"95%"} thickness="0.5" />
            {!isMembersAdded && (
              <Grid
                style={{
                  height: members ? height - 430 : height - 370,
                  overflowY: "auto",
                }}
              >
                {!isGroup && isGroupMessage
                  ? groupsList
                      ?.filter((data) =>
                        data.conversationName
                          ?.toLowerCase()
                          ?.includes(search?.toLowerCase())
                      )
                      ?.map((item, idx) => {
                        return (
                          <div key={idx}>
                            <ContactCard
                              profile={item.conversationImg}
                              label={item.conversationName}
                              subLabel={""}
                              isSelected={
                                isGroup
                                  ? members?.find(
                                      (i) =>
                                        i.conversationId === item.conversationId
                                    )
                                  : members?.conversationId ===
                                    item.conversationId
                              }
                              isSelectable
                              onSelect={() => onMemberSelect(item)}
                            />
                            {idx !== contacts.length - 1 && (
                              <Divider
                                ml={6}
                                style={{ margin: 10 }}
                                width={"95%"}
                                thickness="0.8"
                              />
                            )}
                          </div>
                        );
                      })
                  : contacts
                      ?.filter(
                        (data) =>
                          data.firstName
                            ?.toLowerCase()
                            .includes(search?.toLowerCase()) ||
                          data.lastName
                            ?.toLowerCase()
                            .includes(search?.toLowerCase()) ||
                          data?.communitylocation?.LocationName?.toLowerCase()?.includes(
                            search?.toLowerCase()
                          ) ||
                          `${data.firstName?.toLowerCase() || ""} ${
                            data.lastName?.toLowerCase() || ""
                          }`?.includes(search?.toLowerCase())
                      )
                      ?.map((item, idx) => {
                        return (
                          <div key={idx}>
                            <ContactCard
                              profile={item.profileImg}
                              label={`${item.firstName || ""} ${
                                item.lastName || ""
                              }`}
                              subLabel={
                                item?.communitylocation
                                  ? `${item?.communitylocation?.LocationName}`
                                  : item.role === "back office"
                                  ? `${item.position}, ${item.role}`
                                  : item.position
                              }
                              isSelected={
                                isGroup
                                  ? members?.find((i) => i.id === item.id)
                                  : members?.id === item.id
                              }
                              isSelectable
                              onSelect={() => onMemberSelect(item)}
                            />
                            {idx !== contacts.length - 1 && (
                              <Divider
                                ml={6}
                                style={{ margin: 10 }}
                                width={"95%"}
                                thickness="0.8"
                              />
                            )}
                          </div>
                        );
                      })}
              </Grid>
            )}
          </Grid>
          {members ? (
            <Grid className="bg-1 d-flex justify-content-between align-items-center bottom-div">
              <Typography className="mx-2 px-2 text-white">
                {/* Coastal Community Group Home */}
              </Typography>
              <CustomButton
                className={"bg-white text-black p-2"}
                onClick={onCreate}
              >
                <Typography>
                  {isMembersAdded
                    ? "Create new group"
                    : isGroup
                    ? "Next"
                    : "Send Message"}
                </Typography>
              </CustomButton>
            </Grid>
          ) : null}
        </Grid>
      </Dialog>
    </>
  );
};

export default NewMessageComponent;
