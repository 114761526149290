import React, { useCallback, useEffect, useState } from "react";
import Tag_Icon from "../../assets/images/Tag.png";
import MessageCard from "../../components/Cards/MessageCard";
import { Avatar, Grid, IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import "./ConversationScreen.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ViewGroupModal from "../../components/Modals/ViewGroupModal";
import ViewContactModal from "../../components/Modals/ViewContactModal";
import { Icons } from "../../assets";
import AddMedia from "../../components/Cards/AddMedia";
import useMessenger from "../../hooks/useMessenger";
import Loading from "../../components/Loading";
import useChatScroll from "../../hooks/useChatScroll";
import usePatients from "../../hooks/usePatients";
import useCareTags from "../../hooks/useCareTags";
import useAuth from "../../hooks/useAuth";
import UploadScreen from "../../components/Modals/UploadScreen";
import AddDocumentModal from "../../components/Modals/AddDocumentModal";
import ChatInput from "./ChatInput";
import CareTagsModal from "../../components/Modals/CareTagsModal";
import {
  convertMentionsMessage,
  parseHash,
  parseMessage,
  parseRef,
  regexHash,
  regexRef,
} from "../../utilities/resuseFunctions";

const medicines = [
  { id: "medicine1", display: "Medicine 1" },
  { id: "medicine2", display: "Medicine 2" },
];

const providers = [
  { id: "provider1", display: "Provider 1(Physician)" },
  { id: "provider2", display: "Provider 2(Cardiologist)" },
  { id: "provider3", display: "Provider 3(Podiatrist)" },
  { id: "provider4", display: "Provider 4(Psychiatrist)" },
];

const ConversationScreen = ({ onClose, isFullScreen, setCurrentChat }) => {
  const { height } = useWindowDimensions();
  const {
    getMessages,
    loading,
    sendMessage,
    userData,
    messages,
    setMessages,
    getConversationDetails,
    currentChat,
    uploadDocumentAthena,
    leaveGroup,
    leftMember,
    setLeftMember,
    groupDeletionEmit,
    deletedGroupID,
    editMessage,
    setEditMsg,
    updateMessage,
  } = useMessenger();
  const { uploadDocument } = useAuth();
  const { getTagPatients, tagPatients } = usePatients({ noInitial: true });
  const { careTags } = useCareTags();
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [documentOpen, setDocumentOpen] = useState(false);
  const [tagOpen, setTagOpen] = useState(false);
  const [tagSelected, setTagSelected] = useState("");

  const [groupInfo, setGroupInfo] = useState(false);
  const [contactInfo, setContactInfo] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [data, setData] = useState({});
  const [documentData, setDocumentData] = useState({});
  const [tagData, setTagData] = useState([]);

  const [users, setUsers] = useState([]);

  const [refresh, setRefresh] = useState(false);

  const isCommunity = userData.role === "COMMUNITY";

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    // console.log(refresh);
    if (refresh === true) {
      if (currentChat?.conversationId) {
        getConversationDetails({
          conversationId: currentChat.conversationId,
        }).then((res) => {
          setData({
            ...res,
            createdBy: res.memberUsernames?.find((user) => user.isCreator),
          });

          setUsers(
            res.memberUsernames
              ?.map((user) => {
                return {
                  id: user.id,
                  display: `${user.firstName} ${user.lastName}`,
                };
              })
              ?.filter((data) => data.id !== userData.id) || []
          );
        });
      }
      setRefresh(false);
    }
  }, [refresh]);

  const scrollToMessage = () => {
    const scrollToMessageId = 102;
    if (scrollToMessageId) {
      const messageElement = document.getElementById(
        `message-${scrollToMessageId}`
      );

      if (messageElement) {
        messageElement.scrollTop = messageElement.scrollHeight;
        // messageElement.scrollIntoView({
        //   behavior: "smooth",
        //   block: "nearest",
        //   inline: "start",
        // });
      }
    }
  };

  const ref = useChatScroll();

  const isGroup = currentChat?.conversationType === "GROUP";

  const resultRef = useCallback(
    () => parseRef({ message, regexRef }),
    [message]
  )();

  const resultHash = useCallback(
    () => parseHash({ message, regexHash }),
    [message]
  )();

  const handleSubmit = (data) => {
    setDocumentData(data);
    setDocumentOpen(false);
    setUploadModal(true);
  };

  const handleTagSubmit = (data) => {
    setTagData([...tagData, data]);
    setMessage(`${message} "${data.docData?.name}"`);
    setTagOpen(false);
  };

  const onAddTag = (tag, tagName) => {
    setTagSelected(tag);
    setTagOpen(true);
  };

  const hashTags =
    careTags?.map((i) => {
      return {
        id: i.id,
        display: i.Tag,
      };
    }) || [];

  useEffect(() => {
    if (Object.keys(leftMember).length !== 0) {
      // console.log(leftMember);
    }
  }, [leftMember]);

  useEffect(() => {
    if (currentChat?.conversationId) {
      // setMessages([]);
      setMessage("");
      getConversationDetails({
        conversationId: currentChat.conversationId,
      }).then((res) => {
        const communityUser = res.memberUsernames.find(
          (user) => user?.communityId
        );
        const providerUser = res.memberUsernames.find(
          (user) => user?.providerId
        );

        const community = userData.communityId || communityUser?.communityId;
        const provider =
          !community && (userData.ProviderID || providerUser?.providerId);
        // !tagPatients.length &&
        getTagPatients({
          communityId: community,
          providerId: provider,
          limit: 0,
        });
        setData({
          ...res,
          createdBy: res.memberUsernames?.find((user) => user.isCreator),
        });

        setUsers(
          res.memberUsernames
            ?.map((user) => {
              return {
                id: user.id,
                display: `${user.firstName} ${user.lastName}`,
                providerId: user.providerId,
                communityId: user.communityId,
              };
            })
            ?.filter((data) => data.id !== userData.id) || []
        );
      });
      getMessages({ conversationId: currentChat?.conversationId }).then(
        (res) => {
          scrollToMessage();
        }
      );
      if (currentChat.conversationType === "GROUP") {
        if (contactInfo) {
          setContactInfo(false);
          setGroupInfo(true);
        }
      } else {
        if (groupInfo) {
          setContactInfo(true);
          setGroupInfo(false);
        }
      }
    }
  }, [currentChat]);

  useEffect(() => {
    if (editMessage?.conversationId === currentChat?.conversationId) {
      const text = convertMentionsMessage({
        message: editMessage?.messageBody || "",
        patients: tagPatients,
        caretags: hashTags,
        users: users,
      });
      setMessage(text || "");
    } else {
      message && setMessage("");
    }
  }, [editMessage, currentChat, users]);

  const handleKickMember = (deleteID) => {
    setData({
      ...data,
      memberUsernames: data.memberUsernames.filter((x) => x.id !== deleteID),
    });
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default Enter key behavior (adding a newline)
      editMessage ? onUpdate() : onSend();
      setEditMsg(null);
      setMessage(""); // Clear the input after sending the message
    }
  };

  const onSend = async () => {
    try {
      if (!message.trim()) {
        setMessage("");
        return;
      }

      const { taggedMembers, taggedPatients, currentHashTags, replaceHash } =
        parseMessage({
          message,
        });

      let fileRes = undefined;
      // console.log(taggedPatients, "taggedPatients");

      if (file) {
        const docData = new FormData();
        docData.append("file", file);
        docData.append("departmentid", 14);
        docData.append("patientId", taggedPatients?.[0]?.patientId);
        docData.append("documentsubclass", documentData?.documentsubclass);
        docData.append("internalnote", documentData?.internalnote);
        // docData.append("providerid", 4);
        isGroup && uploadDocumentAthena(docData);
        const formData = new FormData();
        formData.append("file", file);
        fileRes = await uploadDocument(formData);
      }

      let notificationMessage = [];

      if (!!taggedMembers.length) {
        notificationMessage = [
          {
            text: "You were mentioned in a message from",
            isBold: false,
          },
          {
            text: `${userData.firstName} ${userData.lastName}`,
            isBold: true,
          },
          {
            text: "in",
            isBold: false,
          },
          {
            text: data.name || "",
            isBold: true,
          },
        ];
      }
      sendMessage(
        replaceHash, // message text
        userData.id, // createdBy
        currentChat?.conversationId, //groupId
        taggedMembers, // taggedUsers
        taggedPatients, //taggedPatients
        currentHashTags.map((data) => ({
          action: data.name,
          sentToEHR: false,
          startIndex: data.startIndex,
          endIndex: data.endIndex,
        })), // metadata
        "text", // messageType
        fileRes?.data?.data?.id, // fileId
        false, // forwarded
        `${userData.firstName} ${userData.lastName}`, // senderName
        data.name, // groupName
        data.memberUsernames.map((d) => d.id), // memberIDs
        notificationMessage // notificationMessage
      );
      setMessage("");
      setFile(null);
      setSelectedImage(null);
      setUploadModal(false);
      setFileType("");
      setDocumentData({});
    } catch (e) {
      console.log(e, "err");
    }
  };

  const onUpdate = async () => {
    try {
      if (!message.trim()) {
        setMessage("");
        return;
      }

      const { taggedMembers, taggedPatients, currentHashTags, replaceHash } =
        parseMessage({
          message,
        });

      const deletedTagMembers =
        editMessage?.TaggedUsers?.filter(
          (fil) => !taggedMembers.find((f) => f.userId === fil.userId)
        ) || [];

      const addedTagMembers =
        taggedMembers.filter(
          (fil) =>
            !editMessage?.TaggedUsers?.find((f) => f.userId === fil.userId)
        ) || [];

      const updateTagMembers =
        editMessage?.TaggedUsers.filter((fil) =>
          taggedMembers?.find((f) => f.userId === fil.userId)
        ) || [];

      const deletedTagPatients =
        editMessage?.TaggedPatients?.filter(
          (fil) => !taggedPatients.find((f) => f.patientId === fil.patientId)
        ) || [];

      const addedTagPatients =
        taggedPatients.filter(
          (fil) =>
            !editMessage?.TaggedPatients?.find(
              (f) => f.patientId === fil.patientId
            )
        ) || [];

      const updateTagPatients =
        editMessage?.TaggedPatients.filter((fil) =>
          taggedPatients?.find((f) => f.patientId === fil.patientId)
        ) || [];

      const deletedTags =
        editMessage?.Metadata?.filter(
          (fil) => !currentHashTags.find((f) => f.name === fil.action)
        ) || [];

      const addedTags =
        currentHashTags.filter(
          (fil) => !editMessage?.Metadata?.find((f) => f.action === fil.name)
        ) || [];

      const updateTags =
        editMessage?.Metadata.filter((fil) =>
          currentHashTags?.find((f) => f.name === fil.action)
        ) || [];

      updateMessage({
        text: replaceHash, // message text
        createdBy: userData.id, // createdBy
        groupID: currentChat?.conversationId, //groupId
        taggedUsers: updateTagMembers, // taggedUsers
        taggedPatients: updateTagPatients, //taggedPatients
        metadata: updateTags.map((data) => {
          const careTag = currentHashTags?.find((f) => f.name === data.action);
          return {
            ...data,
            startIndex: careTag.startIndex,
            endIndex: careTag.endIndex,
          };
        }), // metadata
        messageType: "text", // messageType
        fileId: editMessage?.fileId, // fileId
        forwarded: false, // forwarded
        senderName: `${userData.firstName} ${userData.lastName}`, // senderName
        groupName: data.name, // groupName
        memberIDs: data.memberUsernames.map((d) => d.id), // memberIDs
        messageID: editMessage?.id,
        // notificationMessage // notificationMessage
        deletedTagMembers,
        addedTagMembers,
        deletedTagPatients,
        addedTagPatients,
        deletedTags,
        addedTags,
      });
      setMessage("");
      setFile(null);
      setSelectedImage(null);
      setUploadModal(false);
      setFileType("");
      setDocumentData({});
    } catch (e) {
      console.log(e, "err");
    }
  };

  const filterSuggestions = () => {
    const parts = message.split(" ");
    if (parts.length > 1) {
      const tag = parts?.[parts.length - 2];
      const regex = /#\[([\w\s]+)\]\((\w+)\)/;
      const match = tag.match(regex);
      // console.log(match, "match", tag);
      if (match) {
        const id = match[2];
        if (id === "fill") return medicines;
        if (id === "visit") return providers;
        if (id === "ref") return tagPatients;
        return [];
      }
      return [];
    }
    return [];
  };

  const refList = filterSuggestions();

  // console.log(message, "mes");

  const messagesList = messages.length ? [...messages].reverse() : [];

  return (
    <Grid className="w-100 ">
      {loading && <Loading loading={loading} />}
      <Grid
        className={clsx(
          "d-flex align-items-center p-2 header justify-content-between",
          isFullScreen && "header-full"
        )}
      >
        <Grid
          role="button"
          onClick={() => {
            if (isGroup) {
              setGroupInfo(true);
            } else {
              setContactInfo(true);
            }
          }}
          className="d-flex align-items-center"
        >
          <Avatar
            size={"lg"}
            className="mx-2"
            src={isGroup ? currentChat.conversationImg : currentChat.contactImg}
          />
          <Grid className="mx-3">
            <Typography
              className={clsx("chat-title", isFullScreen && "chat-title-full")}
            >
              {/* {chat?.conversation?.name} */}
              {isGroup
                ? currentChat?.conversationName
                : `${currentChat?.contactFN || ""} ${
                    currentChat?.contactLN || ""
                  }`}
            </Typography>
            {isFullScreen && (
              <Grid className="d-flex align-items-center">
                <Typography className="chat-subtext">
                  Tap here for {isGroup ? "group" : "contact"} info
                </Typography>
              </Grid>
            )}
            {isFullScreen && isGroup && (
              <Grid className="d-flex align-items-center">
                {currentChat?.tagName && (
                  <img src={Tag_Icon} alt="tag" className="icon-chat" />
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
        {!isFullScreen && (
          <IconButton onClick={onClose}>
            <img src={Icons.Close_Icon1} alt="close" className="close-icon" />
          </IconButton>
        )}
      </Grid>
      <Grid
        ref={ref}
        style={{
          height: isFullScreen ? height - 210 : height / 2,
          overflowY: "auto",
        }}
        className="p-3"
      >
        {messagesList
          ?.filter((msg) => msg?.conversationId === currentChat?.conversationId)
          ?.map((item, idx) => {
            return (
              <MessageCard
                key={idx}
                id={`message-${item.id}`}
                message={item}
                data={{ ...currentChat, ...data }}
                isFullScreen={isFullScreen}
              />
            );
          })}
      </Grid>
      <Grid
        className={clsx(
          "d-flex align-items-center p-2 header",
          isFullScreen && "footer-chat"
        )}
      >
        <AddMedia
          isFullScreen={isFullScreen}
          setFile={(val) => {
            setFile(val);
            const reader = new FileReader();

            reader.onload = () => {
              setSelectedImage(reader.result);
            };
            reader.readAsDataURL(val);
          }}
          setDocumentOpen={setDocumentOpen}
          setFileType={setFileType}
          handleSubmit={handleSubmit}
          isGroup={isGroup}
        />
        <ChatInput
          message={message}
          onKeyDown={onKeyDown}
          setMessage={setMessage}
          users={users}
          hashTags={resultHash?.length ? [] : hashTags}
          tagPatients={resultRef?.length ? [] : tagPatients}
          refList={refList}
          onSend={onSend}
          disabled={!message?.trim() && !file}
          onAddTag={onAddTag}
          editMessage={editMessage}
          setEditMsg={setEditMsg}
          conversationId={currentChat?.conversationId}
          onUpdate={onUpdate}
        />
      </Grid>
      {groupInfo && (
        <ViewGroupModal
          isOpen={groupInfo}
          onClose={() => setGroupInfo(false)}
          chat={currentChat}
          data={data}
          handleKickMember={handleKickMember}
          handleDeleteGroup={groupDeletionEmit}
          handleLeaveGroup={leaveGroup}
          onCloseConv={isFullScreen ? () => {} : onClose}
          handleRefresh={handleRefresh}
          isCommunity={isCommunity}
        />
      )}
      {contactInfo && (
        <ViewContactModal
          isOpen={contactInfo}
          onClose={() => setContactInfo(false)}
          chat={currentChat}
          isCommunity={isCommunity}
        />
      )}
      {documentOpen && (
        <AddDocumentModal
          open={documentOpen}
          handleClose={() => setDocumentOpen(false)}
          chat={currentChat}
          handleSubmit={handleSubmit}
        />
      )}
      {tagOpen && (
        <CareTagsModal
          open={tagOpen}
          handleClose={() => setTagOpen(false)}
          chat={currentChat}
          handleSubmit={handleTagSubmit}
          tag={tagSelected}
        />
      )}
      {uploadModal && (
        <UploadScreen
          open={uploadModal}
          handleClose={() => setUploadModal(false)}
          chat={currentChat}
          InputBox={ChatInput}
          file={file}
          fileType={fileType}
          selectedImage={selectedImage}
          message={message}
          onKeyDown={onKeyDown}
          setMessage={setMessage}
          users={users}
          hashTags={hashTags}
          tagPatients={tagPatients}
          refList={refList}
          onSend={onSend}
          onAddTag={onAddTag}
        />
      )}
    </Grid>
  );
};

export default ConversationScreen;
