import React, { useEffect, useState } from "react";
import { colors } from "../../styles";
import CustomButton from "../Buttons/CustomButton";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import "./AddRegionModal.css";
import CloseIcon from "@mui/icons-material/Close";
import { Form } from "react-bootstrap";
import useCareTags from "../../hooks/useCareTags";

const CareTagsModal = ({ open: isOpen, handleClose, handleSubmit, tag }) => {
  const { careTags, loading, getDocTypes, docTypes } = useCareTags();
  const [open, setOpen] = useState(isOpen);
  const [edit, setEdit] = useState({
    tag: tag,
    type: null,
  });

  useEffect(() => {
    getDocTypes();
  }, []);

  const onChangeHandler = (val, name) => {
    setEdit({
      ...edit,
      [name]: val,
    });
  };

  const onClose = () => {
    setOpen(true);
    handleClose();
    setEdit({});
  };

  const onSubmit = () => {
    handleSubmit({
      ...edit,
      docData: docTypes.find((doc) => doc.documentId == edit.type),
    });
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        // open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid>
        <Grid
          className="d-flex justify-content-between py-1 px-3 align-items-center"
          style={{
            backgroundColor: colors.OFFICE_COLOR,
          }}
        >
          <Typography className="region-modal-title">
            {"CareTag Document Selection"}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Grid>
        <Grid className="px-2">
          <Grid className="m-2">
            <Typography className="fieldName px-3 py-2">CareTag</Typography>

            <Grid className="inputField px-3">
              <Form.Select
                aria-label="Default select example"
                disabled={true}
                value={edit?.tag}
                onChange={(val) => onChangeHandler(val.target.value, "tag")}
              >
                <option>Select CareTag</option>
                {careTags?.map((caretag) => {
                  return <option value={caretag.id}>{caretag?.Tag}</option>;
                })}
              </Form.Select>
            </Grid>
          </Grid>
          <Grid className="m-2">
            <Typography className="fieldName px-3 py-2">
              Document Type
            </Typography>

            <Grid className="inputField px-3">
              <Form.Select
                aria-label="Default select example"
                value={edit?.type}
                onChange={(val) => onChangeHandler(val.target.value, "type")}
              >
                <option>Select CareTag</option>
                {docTypes?.map((doc) => {
                  return <option value={doc.documentId}>{doc?.name}</option>;
                })}
              </Form.Select>
            </Grid>
          </Grid>
          {/* <Grid className=" m-2">
            <Typography className="fieldName px-3 py-2">
              Internal Note
            </Typography>
            <Grid className="d-flex px-3">
              <Form.Control
                placeholder="Internal Note"
                className="inputField px-2 border-1 w-100"
                value={edit?.internalnote}
                onChange={(val) =>
                  onChangeHandler(val.target.value, "internalnote")
                }
              />
            </Grid>
          </Grid> */}
          <Grid className="d-flex justify-content-end">
            <CustomButton className="button mx-4 p-1 px-4" onClick={onSubmit}>
              <Typography>Add</Typography>
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CareTagsModal;
