import React, { useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./CalendarNew.css";
import { changeDate } from "../../redux/reducers/selections.reducer";
import { useDispatch, useSelector } from "react-redux";
import useProviders from "../../hooks/useProviders";
import moment from "moment";
import Loading from "../Loading";
import useAuth from "../../hooks/useAuth";

const CalendarNew = () => {
  const dispatch = useDispatch();
  const { date, selectedProvider } = useSelector((state) => state.selections);
  const { getCalendarSchedules, visitsCount, loading } = useProviders(true);
  const { userData } = useAuth();

  const isCommunity = userData.role === "COMMUNITY" || userData?.isSageHospice;

  const onChangeDate = (val) => {
    dispatch(changeDate(val?.toISOString()));
  };

  const onMonthChange = (val) => {
    // console.log(val);
    getCalendarSchedules({
      date: moment(val.activeStartDate).format("YYYY-MM-DD"),
      isFor: isCommunity ? "Community" : "Provider",
      ID: isCommunity
        ? userData?.communityId
        : selectedProvider === "All Providers"
        ? undefined
        : selectedProvider,
      isHospice: userData.isSageHospice,
    });
  };

  useEffect(() => {
    onMonthChange({
      activeStartDate: date,
    });
  }, []);

  return (
    <div>
      {loading && <Loading loading={loading} />}
      <Calendar
        // selectRange
        // allowPartialRange
        onClickMonth={onMonthChange}
        onActiveStartDateChange={onMonthChange}
        onChange={onChangeDate}
        value={date}
        tileContent={({ activeStartDate, date: tileDate, view }) => {
          const tile = moment(tileDate?.toISOString()?.slice(0, 10)).format(
            "YYYY-MM-DD"
          );

          const data = visitsCount.find((i) => {
            return moment(i.VisitDate?.slice(0, 10)).isSame(
              moment(tile).format("YYYY-MM-DD"),
              "day"
            );
          });
          const isSelected = moment(date).isSame(
            moment(tile).format("YYYY-MM-DD"),
            "day"
          );

          return data ? (
            <div>
              <span
                className={`py-0 m-0 calendar-custom ${
                  isSelected ? "calendar-selected" : ""
                }`}
              >
                {data?._count}
              </span>
              <span
                className={`calendar-custom ${
                  isSelected ? "calendar-selected" : ""
                }`}
              >
                {" "}
                Visits
              </span>
            </div>
          ) : null;
        }}
      />
    </div>
  );
};

export default CalendarNew;
