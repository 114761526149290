export const NotificationType = {
  RECEIVED_MESSAGE: "RECEIVED_MESSAGE",
  MESSAGE_MENTION: "MESSAGE_MENTION",
  USER_ADDED_TO_GROUP: "USER_ADDED_TO_GROUP",
  URGENT_MESSAGE: "URGENT_MESSAGE",
  MESSAGE_SENT_TO_ATHENA: "MESSAGE_SENT_TO_ATHENA",
  TODAYS_SCHEDULES: "TODAYS_SCHEDULES",
  SCHEDULE_PUBLISHED: "SCHEDULE_PUBLISHED",
  SCHEDULE_PUBLISHED_FAILED: "SCHEDULE_PUBLISHED_FAILED",
  NEW_SCHEDULES_POSTED: "NEW_SCHEDULES_POSTED",
  NEW_APPOINTMENT_ADDED: "NEW_APPOINTMENT_ADDED",
  APPOINTMENT_CANCELED: "APPOINTMENT_CANCELED",
};
