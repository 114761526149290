import React from "react";
import { ChevronRightOutlined as RightIcon } from "@mui/icons-material";
import { Avatar, Typography } from "@mui/material";
import { avatarName } from "../../utilities/resuseFunctions";
import Delete from "../../assets/images/Delete.png";
import "./SectionCard.css";

const SectionCard = ({
  text,
  icon: Icon,
  count,
  className,
  profileImg,
  showDeleteButton,
  handleDelete,
  handleClick,
}) => {
  return (
    <div
      onClick={handleClick}
      className={"d-flex justify-content-between section-card " + className}
    >
      <div className="d-flex align-items-center">
        {Icon ? (
          <Icon className="mx-3" style={{ fontSize: 18 }} />
        ) : (
          <Avatar className="avatar-user mx-3" src={profileImg}>
            {avatarName(text)}
          </Avatar>
        )}
        <Typography className="font-14 text-color font-medium">
          {text}
        </Typography>
      </div>
      <div className="d-flex align-items-center">
        <Typography className="section-count px-2">{count}</Typography>
        {showDeleteButton === true && (
          <button onClick={handleDelete} className="button-unset">
            <img
              src={Delete}
              alt="Delete Button"
              width={16}
              height={16}
              className="delete-button"
            />
          </button>
        )}
        <RightIcon className="right-icon" />
      </div>
    </div>
  );
};

export default SectionCard;
