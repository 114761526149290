import React from "react";
import { Icons } from "../../assets";
import { Grid } from "@mui/material";

const IconComponent = ({ i, icon, iconStyle, style, onClick = () => {} }) => {
  return (
    <Grid key={i.id} style={style}>
      <div role="button" onClick={onClick}>
        {Icons[icon] && (
          <img src={Icons[icon]} alt="icon" style={{ width: 18, height: 18 }} />
        )}
      </div>
    </Grid>
  );
};

export default IconComponent;
