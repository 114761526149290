import React, { useState } from "react";
import { Icons } from "../../../assets";
import CustomButton from "../../../components/Buttons/CustomButton";
import MenuHeader from "../../../components/Header/MenuHeader";
import { Grid, IconButton, Typography } from "@mui/material";
import "./FileUpload.css";
import { Link } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import { convertToMb } from "../../../utilities/resuseFunctions";
import CloseIcon from "@mui/icons-material/Close";

const fileTypes = ["xls", "xlxs", "csv"];

const FileUpload = ({ title, onCancel, onAdd }) => {
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };
  return (
    <Grid>
      <MenuHeader isSecondary />
      <Grid className="p-4">
        <Grid className="px-2">
          <Typography className="file-upload-title">{title}</Typography>
          <Typography className="file-upload-subText py-2">
            Upload a file to add one or more Facilities to your CareSMS
            instance. If there are Patients in your system that match the new
            Facility’s address, then they will automatically be associated with
            this new Facility in CareSMS.
          </Typography>
          <Link className="font-14">
            Review the required spreadsheet format for upload
          </Link>
        </Grid>
        <Grid container className="justify-content-center d-flex py-3 mx-auto">
          <Grid item xs={12} md={6}>
            <FileUploader
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              classes="w-100"
              multiple
            >
              <Grid className="uploadView d-flex flex-column justify-content-center align-items-center align-self-center px-3">
                <img
                  src={Icons.Upload_Icon}
                  alt="upload icon"
                  className="icon mx-2"
                />
                <Typography className="text py-2">
                  Drag & Drop or{" "}
                  <span className="highlightText">Choose file</span> to upload
                </Typography>
                <Typography className="formats">
                  XLS, XSLX or CSV Max size of 50MB
                </Typography>
              </Grid>
            </FileUploader>
          </Grid>
        </Grid>
        {file && !!file?.length && (
          <Grid className="d-flex justify-content-center align-items-center mx-auto">
            <Grid className="w-50">
              <Typography className="my-1 uploadText">
                Uploaded Files
              </Typography>
              {Object.values(file)?.map((i, idx) => {
                return (
                  <Grid
                    key={idx}
                    className="selectedFile d-flex justify-content-between align-items-center p-2 my-1 "
                  >
                    <Typography className="selectedText">
                      {i?.name} {convertToMb(i.size)}
                    </Typography>
                    <IconButton
                      className="p-1"
                      onClick={() => {
                        const tmpFile = [...file];
                        tmpFile.splice(idx, 1);
                        setFile(tmpFile);
                      }}
                    >
                      <CloseIcon className="close-icon" />
                    </IconButton>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
        <Grid
          container
          className="d-flex my-2 justify-content-center align-items-center"
        >
          <Grid item xs={12} md={3} className="px-md-2">
            <CustomButton
              className={
                "button cancelButton border-1 p-2 px-4 align-items-center w-100"
              }
              onClick={onCancel}
            >
              <Typography className="cancelText">Cancel</Typography>
            </CustomButton>
          </Grid>
          <Grid item xs={12} md={3} className="px-md-2">
            <CustomButton
              disabled={!file || !file.length}
              className={"button addButton p-2 px-4 w-100"}
              onClick={onAdd}
            >
              <Typography className="addText">Confirm and Add</Typography>
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FileUpload;
