import React, { useCallback, useEffect, useState } from "react";
import MenuHeader from "../../../components/Header/MenuHeader";
import IconText from "../../../components/Text/IconText";
import IconText2 from "../../../components/Text/IconText2";
import Providers from "../Providers/Providers";
import { avatarName } from "../../../utilities/resuseFunctions";
import { Avatar, Card, Grid, Typography } from "@mui/material";
import usePatients from "../../../hooks/usePatients";
import { useParams } from "react-router-dom";
import UserSvg from "../../../assets/svgs/user";
import UsersSvg from "../../../assets/svgs/UsersSvg";
import PointofContact from "./PointofContact";
import UpdatePatientModal from "../../../components/Modals/UpdatePatientModal";
import ClipboardSvg from "../../../assets/svgs/ClipboardSvg";
import HomeSvg from "../../../assets/svgs/HomeSvg";
import LocationSvg from "../../../assets/svgs/LocationSvg";
import { useNavigate } from "react-router-dom";
import Notes from "./Notes";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import useZones from "../../../hooks/useZones";

const PatientGrid = () => {
  const navigate = useNavigate();
  const { height } = useWindowDimensions();
  const { zones } = useZones(true, true);
  const { Id } = useParams();
  const [patient, setPatient] = useState({});
  const [selected, setSelected] = useState("Care Providers");
  const [update, setUpdate] = useState(null);
  const { getPatientApi } = usePatients();

  const initCall = useCallback(() => {
    getPatientApi({ PatientID: Id }).then((res) => setPatient(res));
  }, [Id]);

  useEffect(initCall, [initCall]);

  const onSubmit = (submitData) => {
    initCall();
    // getPatientApi({ PatientID: Id }).then((res) => setPatient(res));
    // setData([...data, ...submitData]);
  };

  const zone = zones?.find((zone) => zone.ZoneID == patient.PatientZoneID);

  return (
    <Grid>
      <MenuHeader isSecondary />
      <Grid container>
        <Grid item sm={12} md={3} className="px-md-4">
          <Card
            className="p-0"
            style={{
              position: "relative",
              height: height - 100,
              overflow: "auto",
            }}
          >
            <Grid className="d-flex justify-content-between p-3">
              <Typography
                className="color-1 font-medium"
                role="button"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </Typography>
            </Grid>
            <Grid
              className="d-flex flex-column align-items-center py-2"
              style={{ borderBottom: "1px solid #E9ECEF" }}
            >
              <Avatar
                size={"lg"}
                className="my-1"
                style={{ fontFamily: "Inter" }}
              >
                {avatarName(
                  `${patient.PatientFirstName || ""} ${
                    patient.PatientLastName || ""
                  }`
                )}
              </Avatar>
              <Typography
                className="py-1"
                style={{
                  fontSize: 18,
                  fontFamily: "Inter",
                  fontWeight: 600,
                  color: "#333333",
                }}
              >
                {`${patient.PatientFirstName || ""} ${
                  patient.PatientLastName || ""
                }`}
              </Typography>
              <Typography
                style={{
                  fontSize: 12,
                  color: "#828282",
                  fontFamily: "Inter",
                }}
              >
                PatientID {patient?.PatientID || ""}{" "}
                {patient.PatientGender && "|"} {patient.PatientGender || ""}
              </Typography>
              <Typography
                className="my-1"
                style={{
                  fontSize: 12,
                  color: "#828282",
                  fontFamily: "Inter",
                }}
              >
                {zone?.ZoneName}
              </Typography>
            </Grid>
            <Grid
              className="mx-2"
              style={{
                alignSelf: "flex-start",
              }}
            >
              {/* <IconText
                text={moment(patient.PatientDOB).format("MMM DD, YYYY")}
                // icon={Icons.Heart_Icon}
                Icon={HeartSvg}
                textClass="mx-4"
                className={"py-2"}
              /> */}
              <IconText
                text={patient.Facility || ""}
                Icon={HomeSvg}
                textClass="mx-4"
                className={"py-2"}
              />
              <IconText
                text={[
                  patient?.AddressLine1,
                  patient?.City,
                  patient?.State,
                  patient?.Zip,
                ]
                  ?.filter(Boolean)
                  ?.join(", ")}
                Icon={LocationSvg}
                textClass="px-4"
                className={"py-2"}
              />
            </Grid>
            <Grid
              className="align-self-start p-2"
              style={{ borderTop: "1px solid #E9ECEF", flexGrow: 2 }}
            >
              <IconText2
                text="Care Providers"
                Icon={UsersSvg}
                selected={selected === "Care Providers"}
                onClick={() => setSelected("Care Providers")}
              />
              <div className="my-2">
                <IconText2
                  text="Points Of Contact"
                  Icon={UserSvg}
                  selected={selected === "Points Of Contact"}
                  onClick={() => setSelected("Points Of Contact")}
                />
              </div>
              <IconText2
                text="Notes"
                Icon={ClipboardSvg}
                onClick={() => setSelected("Notes")}
              />
            </Grid>
          </Card>
        </Grid>
        <Grid item sm={12} md={9}>
          {selected === "Care Providers" && (
            <Providers
              noHeader
              noAdd
              title="Care Providers"
              noSearchFilter
              provider={[
                patient?.PhysicianID,
                patient?.PodiatristID,
                patient?.PsychID,
                patient?.CardioID,
              ].filter(Boolean)}
            />
          )}
          {selected === "Points Of Contact" && (
            <PointofContact data={patient} initCall={initCall} />
          )}
          {selected === "Notes" && <Notes data={patient} initCall={initCall} />}
        </Grid>
      </Grid>
      {update && (
        <UpdatePatientModal
          isOpen={!!update}
          selected={update}
          handleClose={() => setUpdate(false)}
          onSubmit={onSubmit}
          isEdited={true}
        />
      )}
    </Grid>
  );
};

export default PatientGrid;
