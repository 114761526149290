import { useCallback, useEffect, useState } from "react";
import api from "../services/api";
import {
  addSchedulingRule,
  updateSchedulingRule,
  replaceSchedulingRules,
  deleteteSchedulingRule,
} from "../redux/reducers/schedulingrules.reducer";
import { useDispatch, useSelector } from "react-redux";
import { queryString } from "../utilities/resuseFunctions";

export default function useSchedulingRules(noStartup) {
  const dispatch = useDispatch();
  const { data: schedulingRules, count } = useSelector(
    (state) => state.schedulingRules
  );
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setLoading(false);
  };
  const handleOpen = () => {
    setLoading(true);
  };

  const getSchedulingRules = useCallback(
    ({ take = "20", skip = "", providerId = "", search, sort } = {}) => {
      const { order, orderBy } = sort || {};
      handleOpen();
      api
        .get(
          `/location/constraints/?${queryString({
            limit: take,
            skip,
            searchTerm: search,
            order,
            orderBy,
          })}`
        )
        .then((res) => {
          dispatch(replaceSchedulingRules(res?.data || []));
        })
        .finally((res) => {
          handleClose();
          return res;
        });
    },
    [dispatch]
  );

  useEffect(() => {
    !noStartup && getSchedulingRules();
  }, []);

  const addSchedulingRuleApi = async (data) => {
    handleOpen();
    return await api
      .post("/location/constraints/", data)
      .then((res) => {
        dispatch(addSchedulingRule(res?.data?.data || []));
        return res;
      })
      .finally((res) => {
        handleClose();
        return res;
      });
  };

  const updateSchedulingRuleApi = async (data) => {
    handleOpen();
    return await api
      .patch(`/location/constraints/${data.id}`, data)
      .then((res) => {
        dispatch(updateSchedulingRule(res?.data?.data || []));
        handleClose();
        return res;
      })
      .finally((res) => {
        handleClose();
        return res;
      });
  };

  const deleteSchedulingRuleApi = async (data) => {
    handleOpen();
    return await api
      .delete(`/location/constraints/${data.id}`)
      .then((res) => {
        dispatch(deleteteSchedulingRule(res?.data?.data || []));
        handleClose();
        return res;
      })
      .finally((res) => {
        handleClose();
        return res;
      });
  };

  return {
    schedulingRules,
    addSchedulingRuleApi,
    updateSchedulingRuleApi,
    deleteSchedulingRuleApi,
    loading,
    handleClose,
    count,
    getSchedulingRules,
  };
}
