import React, { useState } from "react";
import MenuHeader from "../../../components/Header/MenuHeader";
import DataTable from "../../../components/DataTable/DataTable";
import { tagHeader } from "../../../data";
import IconComponent from "../../../components/DataTable/Icon";
import TableFilter from "../../../components/Filter/TableFilter";
import TagComponent from "../../../components/DataTable/TagComponent";
import AddCareTag from "../../../components/Modals/AddCareTag";
import { Grid } from "@mui/material";
import useCareTags from "../../../hooks/useCareTags";
import DeleteModal from "../../../components/Modals/DeleteModal";
import Loading from "../../../components/Loading";
import moment from "moment";
import useAuth from "../../../hooks/useAuth";

const TagsScreen = () => {
  const { userData } = useAuth();
  const { careTags, count, deleteCareTagApi, loading } = useCareTags();
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selected, setSelected] = useState({});

  const isAdmin = userData.role === "back office";

  const onDelete = () => {
    deleteCareTagApi(selected);
  };

  return (
    <Grid>
      {loading && <Loading loading={loading} />}
      <MenuHeader isSecondary />
      <Grid className="my-3">
        <TableFilter
          noAdd={!isAdmin}
          length={count}
          sectionName={"CareTags"}
          search={search}
          setSearch={setSearch}
          onAdd={() => setOpen(true)}
        />
      </Grid>
      <Grid className="mx-5">
        <DataTable
          columns={tagHeader.filter((f) =>
            isAdmin ? f : f.dataKey !== "edit" && f.dataKey !== "delete"
          )}
          rows={careTags
            .filter((fil) =>
              search
                ? fil.Tag?.toLowerCase()?.includes(search?.toLowerCase())
                : true
            )
            .map((x) => ({
              // ...x,
              tag: <TagComponent i={x} />,
              description: x.Description,
              createdAt: moment(x.CreatedAt).format("YYYY-MM-DD"),
              edit: (
                <IconComponent
                  icon="Edit_Icon"
                  i={x}
                  onClick={() => {
                    setSelected(x);
                    setOpen(true);
                  }}
                />
              ),
              delete: (
                <IconComponent
                  icon="Delete_Icon"
                  i={x}
                  iconStyle={{ height: 20 }}
                  onClick={() => {
                    setSelected(x);
                    setIsDeleteOpen(true);
                  }}
                />
              ),
            }))}
        />
      </Grid>
      {open && (
        <AddCareTag
          open={open}
          handleClose={() => {
            setOpen(false);
            setSelected({});
          }}
          selected={selected}
        />
      )}
      {isDeleteOpen && (
        <DeleteModal
          open={isDeleteOpen}
          handleClose={() => setIsDeleteOpen(false)}
          handleDelete={onDelete}
          title={"Delete CareTag"}
          subtext={"Are you sure you want to delete this caretag?"}
        />
      )}
    </Grid>
  );
};

export default TagsScreen;
