import React from "react";
import FileUpload from "../FileUpload/FileUpload";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

const AddCommunity = () => {
  const navigate = useNavigate();
  const onAdd = () => {};
  const onCancel = () => {
    navigate("/communities");
  };
  return (
    <Grid>
      <FileUpload
        title="Add a new Community"
        onAdd={onAdd}
        onCancel={onCancel}
      />
    </Grid>
  );
};

export default AddCommunity;
