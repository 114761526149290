import * as React from "react";
const SpeedMeterSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#000"}
      fillRule="evenodd"
      d="M10 2.5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15ZM1 10a9 9 0 1 1 18 0 9 9 0 0 1-18 0Zm9-4.5A4.5 4.5 0 0 0 5.5 10 .75.75 0 0 1 4 10a6 6 0 0 1 6-6 .75.75 0 0 1 0 1.5Zm4.78-.28a.75.75 0 0 1 0 1.06L11.842 9.22a2 2 0 1 1-1.06-1.06l2.938-2.94a.75.75 0 0 1 1.06 0ZM10 9.5a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SpeedMeterSvg;
