import { useCallback, useEffect, useState } from "react";
import api from "../services/api";
import {
  replaceProviders,
  addProvider,
  updateProvider,
  replaceVisits,
  replaceVisitsCount,
  replaceProvidersCount,
  setIsPublishEnabled,
  setProviderLock,
  replaceEligibleVisits,
  updateLoading,
  deleteProvider,
} from "../redux/reducers/providers.reducer";
import { useDispatch, useSelector } from "react-redux";
import { getNextWeekDates, queryString } from "../utilities/resuseFunctions";
import ScheduleApi from "../services/scheduleApi";
import AthenaApi from "../services/athenaApi";
import moment from "moment";
import useLogs from "./useLogs";
import useAuth from "./useAuth";

export default function useProviders(noStartUp, start_limit) {
  const dispatch = useDispatch();
  const { addLog } = useLogs();
  const { updateAlertNote } = useAuth();
  const {
    data,
    visits,
    count,
    visitsCount,
    providersCount,
    isPublishEnabled,
    isEditEnabled,
    lockedUser,
    eligibleVisits,
    // loading,
  } = useSelector((state) => state.providers);
  const { date } = useSelector((state) => state.selections);
  const { data: zones } = useSelector((state) => state.zones);
  const [loading, setLoading] = useState(false);

  // const setLoading = (bool) => dispatch(updateLoading(bool));

  const handleClose = () => {
    setLoading(false);
    return;
  };
  const handleOpen = () => {
    setLoading(true);
  };

  const getProviders = useCallback(
    ({ limit = start_limit, skip = "", search, sort, filter } = {}) => {
      handleOpen();
      const { order, orderBy } = sort || {};
      const { ZoneID, Type } = filter || {};
      return api
        .get(
          `/providers?${queryString({
            limit,
            skip,
            search,
            order,
            orderBy,
            ZoneID,
            Type,
          })}`
        )
        .then((res) => {
          dispatch(replaceProviders(res?.data || []));
          return res.data.data;
        })
        .finally(handleClose);
    },
    [dispatch, start_limit]
  );

  const getProvider = async (data) => {
    handleOpen();
    return api
      .get(`/providers/${data.ProviderID}`)
      .then((res) => {
        // dispatch(replaceProviders(res?.data?.data || []));
        return res?.data?.data;
      })
      .finally(handleClose);
  };

  useEffect(() => {
    !noStartUp && getProviders();
    const data = { date: moment(date).format("YYYY-MM-DD") };
    !noStartUp && getProvidersWithCount(data);
  }, []);

  const addProviderApi = (data) => {
    handleOpen();
    api
      .post("/providers", data)
      .then((res) => {
        dispatch(addProvider(res?.data?.data || []));
        handleClose();
        updateAlertNote(res?.message, "success");
      })
      .catch((err) => {
        handleClose();
        updateAlertNote(err.response?.message, "error");
      });
  };

  const updateProviderApi = (data) => {
    handleOpen();
    api
      .put(`/providers/${data.ProviderID}`, data)
      .then((res) => {
        const resData = res?.data?.data;
        const region = zones.find((zone) => zone.ZoneID === resData.ZoneID);
        dispatch(updateProvider({ ...resData, zones: region } || []));
        handleClose();
        updateAlertNote(res?.message, "success");
      })
      .catch((err) => {
        handleClose();
        updateAlertNote(err.response?.message, "error");
      });
  };

  const deleteProviderApi = (data) => {
    handleOpen();
    api
      .delete(`/providers/${data.ProviderID}`)
      .then(() => {
        dispatch(deleteProvider({ ProviderID: data.ProviderID }));
        handleClose();
        // updateAlertNote(res?.message,"success" );
      })
      .catch((err) => {
        handleClose();
        updateAlertNote(err.response?.message, "error");
      });
  };

  const getVisitsApi = (data) => {
    handleOpen();
    dispatch(replaceVisits([]));
    return api
      .post("/visits", data)
      .then((res) => {
        dispatch(replaceVisits(res?.data?.data || []));
        return res?.data?.data;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const getSchedulesApi = async (data) => {
    handleOpen();
    dispatch(replaceVisits([]));
    return await ScheduleApi.post("/schedule/get", data)
      .then((res) => {
        dispatch(replaceVisits(res?.data?.data || []));
        return res?.data?.data || [];
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const getEligibleSchedules = async ({ id, date }) => {
    // const { startDate, endDate } = getNextWeekDates(date);
    handleOpen();
    // dispatch(replaceVisits([]));
    return await ScheduleApi.post("/schedule/get", {
      ID: id,
      date: moment().format("YYYY-MM-DD"),
      isAll: true,
      isFor: "Provider",
    })
      .then((res) => {
        dispatch(replaceEligibleVisits(res?.data?.data || []));
        return res?.data?.data || [];
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  // const lockSchedule = async ({ ScheduleID }) =>
  //   await ScheduleApi.patch(`/schedule/lock/${ScheduleID}`)
  //     .then((res) => {
  //       return res?.data?.data;
  //     })
  //     .finally(() => {
  //       return;
  //     });

  // const unlockSchedule = async ({ ScheduleID }) =>
  //   await ScheduleApi.patch(`/schedule/unlock/${ScheduleID}`)
  //     .then((res) => {
  //       return res?.data?.data;
  //     })
  //     .finally(() => {
  //       return;
  //     });

  const updateSchedule = async ({
    ScheduleID,
    Reason,
    PatientID,
    ProviderID,
    type,
    AppointmentID,
    date,
    canceledBy,
  }) =>
    await ScheduleApi.post("/schedule/update", {
      ScheduleID,
      Reason,
      PatientID,
      ProviderID,
      type,
      AppointmentID,
      date,
      canceledBy,
    })
      .then((res) => {
        // dispatch(replaceVisits(res?.data?.data || []));
        return res?.data?.data || [];
      })
      .finally(() => {
        handleClose();
        return;
      });

  const cancelSchedulesApi = async ({
    ScheduleID,
    Reason,
    PatientID,
    ProviderID,
    AppointmentID,
    type,
    canceledBy,
  }) => {
    handleOpen();
    return await updateSchedule({
      ScheduleID,
      Reason,
      PatientID,
      ProviderID,
      AppointmentID,
      type: type || "CANCEL",
      canceledBy,
    });
  };

  const createAppointment = async ({
    appointmentdate,
    providerid,
    patientid,
    ScheduleID,
    SequenceNo,
    LastVisitDate,
    Rotation,
    TargetVisitDate,
    Slack,
    VisitType,
    CommunityID,
    Distance,
    DistanceFromHome,
    updatedBy,
    type,
    userData,
    prevDate,
  }) => {
    handleOpen();
    return await ScheduleApi.post("/schedule/reschedule", {
      ScheduleID,
      SequenceNo,
      VisitDate: appointmentdate,
      ProviderID: providerid,
      PatientID: patientid,
      LastVisitDate,
      Rotation,
      TargetVisitDate,
      Slack,
      VisitType,
      CommunityID,
      Distance,
      DistanceFromHome,
      updatedBy,
      type,
    })
      .then(async (res) => {
        // dispatch(replaceVisits(res?.data?.data || []));
        const logPayload = {
          UserID: userData?.id,
          Activity: `Schedule (${ScheduleID}) on ${prevDate} Rescheduled to ${appointmentdate} for Provider ID ${providerid} and Patient ID ${patientid} by User - ${
            userData?.firstName || ""
          } ${userData?.lastName || ""} with Type ${VisitType}`,
        };
        await addLog(logPayload);
        return res?.data?.data || [];
      })
      .catch(async (err) => {
        const logPayload = {
          UserID: userData?.id,
          Activity: `Failed (${
            err?.Response?.data?.message || err?.message || ""
          }) -> Schedule (${ScheduleID}) on ${prevDate} Rescheduled to ${appointmentdate} for Provider ID ${providerid} and Patient ID ${patientid} by User - ${
            userData?.firstName || ""
          } ${userData?.lastName || ""} with Type ${VisitType}`,
        };
        await addLog(logPayload);
        throw new Error(err);
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const getCalendarSchedules = async (data) => {
    handleOpen();
    // dispatch(replaceVisitsCount([]));
    return await ScheduleApi.post("/schedule/get/calendar", data)
      .then((res) => {
        dispatch(replaceVisitsCount(res?.data?.data || []));
        return res?.data?.data;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const getProvidersWithCount = async (data) => {
    handleOpen();
    dispatch(replaceProvidersCount([]));
    return await ScheduleApi.post("/schedule/get/providers", data)
      .then((res) => {
        dispatch(replaceProvidersCount(res?.data?.data || []));
        return res?.data?.data;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const getPublishDateRange = async (data) => {
    handleOpen();
    return await ScheduleApi.post("/schedule/daterange", data)
      .then((res) => {
        return res?.data?.data;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const updateSequence = async (data) => {
    handleOpen();
    return await ScheduleApi.post("/schedule/seq/update", data)
      .then((res) => {
        const payload = {
          ID: data.ProviderID,
          date: data?.VisitDate,
          isFor: "Provider",
        };
        getSchedulesApi(payload);
        return res?.data?.data;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const noteToProvider = async (data) => {
    handleOpen();
    return await ScheduleApi.post("/schedule/note/create", data)
      .then((res) => {
        updateAlertNote("Added note", "success");
        return res?.data?.data;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const getIsProviderLocked = async (data) => {
    handleOpen();
    return await api
      .get(`/visits/provider/lock?ProviderID=${data.ProviderID}`)
      .then((res) => {
        dispatch(setProviderLock(res?.data?.data));
        return res?.data?.data;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const ProviderLock = async (data) => {
    handleOpen();
    return await api
      .post(`/visits/provider/lock`, data)
      .then((res) => {
        return res?.data?.data;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const getIsPublishEnabled = async (data) => {
    handleOpen();
    return await AthenaApi.get(`/athena/publish?ProviderID=${data.ProviderID}`)
      .then((res) => {
        dispatch(setIsPublishEnabled(res?.data?.isEnabled));
        return res?.data?.isEnabled;
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  const publishToAthena = async (data) => {
    const { ProviderID, startDate, endDate, userId, userData } = data;
    handleOpen();
    return await AthenaApi.post("/athena/publish", data)
      .then(async (res) => {
        dispatch(setIsPublishEnabled(false));
        const logPayload = {
          UserID: userId,
          Activity: `Schedules were published to Athena for Provider ID ${ProviderID} from ${startDate} to ${endDate} by User - ${
            userData?.firstName || ""
          } ${userData?.lastName || ""}`,
        };
        await addLog(logPayload);
        return res?.data;
      })
      .catch(async (err) => {
        const logPayload = {
          UserID: userId,
          Activity: `Failed (${
            err?.Response?.data?.message || err.message || ""
          }) -> Schedules publish to Athena for Provider ID ${ProviderID} from ${startDate} to ${endDate} by User - ${
            userData?.firstName || ""
          } ${userData?.lastName || ""}`,
        };
        await addLog(logPayload);
        throw new Error(err);
      })
      .finally(() => {
        handleClose();
        return;
      });
  };

  return {
    providers: data,
    addProviderApi,
    updateProviderApi,
    loading,
    handleClose,
    getProvider,
    getVisitsApi,
    visits,
    count,
    getProviders,
    getSchedulesApi,
    getCalendarSchedules,
    visitsCount,
    getProvidersWithCount,
    providersCount,
    cancelSchedulesApi,
    createAppointment,
    getEligibleSchedules,
    publishToAthena,
    getIsPublishEnabled,
    isPublishEnabled,
    getIsProviderLocked,
    isEditEnabled,
    lockedUser,
    ProviderLock,
    eligibleVisits,
    getPublishDateRange,
    updateSequence,
    noteToProvider,
    deleteProviderApi,
  };
}
