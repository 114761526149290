import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import "./VisitCancelModal.css";
import CustomButton from "../Buttons/CustomButton";
import { colors } from "../../styles";
import { constraintFrequency } from "../../data/listOptions";

const DeleteScheduleRuleModal = ({
  open: isOpen,
  handleClose,
  handleDelete,
  data,
}) => {
  const [open, setOpen] = useState(isOpen);

  const onClose = () => {
    setOpen(false);
    handleClose();
  };
  const onDelete = () => {
    onClose();
    handleDelete();
  };

  const Item = ({ label, value }) => {
    return (
      <Grid className="mb-4">
        <Typography className="bold-700">{label}</Typography>
        <Typography className="font-14">{value}</Typography>
      </Grid>
    );
  };

  const additional_rule = constraintFrequency.find(
    (r) => r.freq === data?.additionalRules
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
      fullWidth
    >
      <Grid
        className="d-flex justify-content-between py-2 px-3 align-items-center"
        style={{
          backgroundColor: colors.OFFICE_COLOR,
        }}
      >
        <Grid className="d-flex align-items-center">
          <Typography className="cancel-title px-2 text-white">
            Delete Location Scheduling Rule
          </Typography>
        </Grid>
        <IconButton onClick={handleClose}>
          <CloseIcon className="text-white" />
        </IconButton>
      </Grid>
      <DialogContent>
        <Item label={"Provider"} value={data?.providerName} />
        <Item label={"Constraint Type"} value={data?.constraintType} />
        {data?.patient ? (
          <Item label={"Patient"} value={data?.patient?.label} />
        ) : (
          <Item label={"Community"} value={data?.locationName?.label} />
        )}
        <Item label={"Day of the week"} value={data?.dayOfWeek} />
        <Item
          label={"Any additional frequency details"}
          value={additional_rule?.name || "NA"}
        />
        <Typography
          id="alert-dialog-description"
          // className="cancel-subtext"
          style={{ fontSize: 18 }}
        >
          Are you sure you want to delete this scheduling rule?
        </Typography>
      </DialogContent>
      <DialogActions>
        <DialogActions>
          <CustomButton className={"button-cancel"} onClick={onDelete}>
            Delete
          </CustomButton>
          <CustomButton className={"button-dont"} onClick={onClose}>
            Cancel
          </CustomButton>
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteScheduleRuleModal;
