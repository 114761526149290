import React from "react";
import AppointmentButton from "../Buttons/AppointmentButton";
import { Avatar, Card, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./ProviderCard.css";
import { avatarName } from "../../utilities/resuseFunctions";
import {
  changeSelectedProvider,
  focusMarker,
} from "../../redux/reducers/selections.reducer";
import { useDispatch } from "react-redux";

const ProvidersCard = ({ data, view, type, index }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function randomMinute(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  function randomDistance(min, max) {
    // min and max included
    return (
      Math.random() * (Number(max) - Number(min) + 1) +
      Number(min)
    ).toFixed(2);
  }

  const viewSchedule = () => {
    dispatch(changeSelectedProvider(data.ProviderID));
    navigate("/provider", {
      state: {
        id: data.ProviderID,
      },
    });
  };
  const disabled = !data?._count;

  const onFocusPatientLocation = () => {
    dispatch(
      focusMarker({
        latlng: {
          ZoneLatitude: data.ProviderLatitude,
          ZoneLongitude: data.ProviderLongitude,
        },
        id: `p${index}`,
      })
    );
  };

  return (
    <Card className="my-3 p-3" style={{ marginLeft: 18, marginRight: 10 }}>
      <Grid className="d-flex justify-content-between">
        <Grid item md={6} container className="my-2">
          <Avatar
            alt="avatar"
            className="avatar"
            src={data?.User?.[0]?.profileImg}
            onClick={onFocusPatientLocation}
          >
            {avatarName(`${data.ProviderFN} ${data.ProviderLN}`)}
          </Avatar>
          <Grid item md={6} className="mx-3">
            <Typography className="provider-card-name">
              {`${data.ProviderFN} ${data.ProviderLN}`}
            </Typography>
            <Typography className="provider-card-subtext">
              {data.ProviderSpecialty}
            </Typography>
          </Grid>
        </Grid>
        <Grid item md={6} container>
          <Grid className="w-50 px-1 align-items-end">
            <Typography className="provider-card-subhead font-14">
              Windshield time
            </Typography>
            <Typography className="provider-card-subtext">
              0 mins
            </Typography>
          </Grid>
          <Grid className="w-50  align-items-end">
            <Typography className="provider-card-subhead font-14">
              Distance traveled
            </Typography>
            <Typography className="provider-card-subtext">
              {data?.distanceTravelled
                ? (
                    +(data?.distanceTravelled?.DistanceFromHome || 0) +
                      +(data?.distanceTravelled?.Distance || 0) || 0
                  )?.toFixed(2)
                : 0}{" "}
              miles
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className="d-flex justify-content-between align-items-center">
        <Grid className="d-flex px-3 align-items-center">
          <Typography className="font-14 provider-visits">
            {data?._count || 0} visits |{" "}
          </Typography>
          <Typography className="px-1 font-14 provider-visit-number">
            {data.completedCount || "0"} visits completed
          </Typography>
        </Grid>
        <Grid className="px-3">
          <AppointmentButton
            disabled={disabled}
            variant={"blue"}
            title="View Schedule"
            onClick={viewSchedule}
            textClass={disabled ? "text-black" : "text-white"}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProvidersCard;
