import { useCallback } from "react";
import api from "../services/api";
import { queryString } from "../utilities/resuseFunctions";

export default function useLogs() {
  const getLogs = useCallback(async ({ limit = "20", skip = "" } = {}) => {
    return await api.get(`/logs?${queryString({ limit, skip })}`);
  }, []);

  const addLog = async (data) => {
    return await api.post("/logs", data);
  };

  return {
    getLogs,
    addLog,
  };
}
